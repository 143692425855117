import React from 'react'
import styles from './Link.module.css'

interface Props {
	href: string
	children: React.ReactNode
	style?: string
}

const Link = ({ href, children, style }: Props) => (
	<a target='_blank' rel='noopener noreferrer' href={href} className={`${styles.link} ${style}`}>
		{children}
	</a>
)

export default Link
