import {
    isMessage,
    Message,
    SystemMessage,
} from '@pixel-kraft/commulino-types';
import {t_RootState, t_Action} from './types'

const initialState: t_RootState = {
    Chats: {},
    ChatListner: function () {
    },
    MessengeListners: {},
    aktiveChat: '',
    contacts: [],
}

const sortByDate = (messages: Array<Message | SystemMessage>) => messages.sort((a, b) => (a.date > b.date ? 1 : -1))

const messenger = (state = initialState, action: t_Action) => {
    switch (action.type) {
        case "TODEFAULT":
            return {...initialState}
        case 'SET_CHATS_LISTNER':
            return {
                ...state,
                ChatListner: action.listner
            }
        case 'REMOVE_CHATS_LISTNER':
            state.ChatListner();
            return {
                ...state,
                ChatListner: null,
            }
        case 'MESSENGER_UPDATE_CHAT': {
            const chats = {
                ...state.Chats,
                [action.chatID]: {
                    ...state.Chats[action.chatID],
                    ...action.chat
                }
            }
            return {
                ...state,
                Chats: chats
            }
        }
        case 'MESSENGER_UPDATE_MESSAGE':
            // Seems like chat got deleted
            if (!state.Chats[action.chatId]) {
                return {
                    ...state,
                }
            }
            const msgs = action.message.map(({id}) => id);
            return {
                ...state,
                Chats: {
                    ...state.Chats,
                    [action.chatId]: {
                        ...state.Chats[action.chatId],
                        messages: state.Chats[action.chatId].messages
                            ? sortByDate([
                                ...(state.Chats[action.chatId]?.messages || []).filter(({id}) => !msgs.includes(id)),
                                ...action.message
                            ])
                            : action.message
                    }
                }
            }
        case "updateMessage": {
            const chat = {...state.Chats[action.chatId]};
            if(!chat.messages) return state;
            const msg = chat.messages.findIndex(({id})=>id===action.id);
            if(msg===undefined || msg < 0) return state;
            chat.messages[msg]={...chat.messages[msg],...action.message};
            return {
                ...state,
                Chats: {
                    ...state.Chats,
                    [action.chatId]: {
                        ...state.Chats[action.chatId],
                        messages: [...chat.messages]
                    }
                }
            }
        }
        case 'MESSENGER_SET_MESSAGE_LISTNER':
            return {
                ...state,
                MessengeListners: {
                    ...state.MessengeListners,
                    [action.chatID]: action.listner
                }
            }
        case 'MESSENGER_DELETE_CHAT': {
            delete state.Chats[action.chatID];
            return {
                ...state,
                Chats: {...state.Chats}
            }
        }
        case 'SETUNREAD': {
            let unRead = state.unRead;
            if (action.unRead === 0 && unRead) {
                const chat = state.Chats[action.chatID].unRead;
                if (chat)
                    unRead = unRead ? -chat : 0;
            } else {
                unRead = action.unRead;
            }
            return {
                ...state,
                unRead: unRead < 0 ? 0 : unRead,
                Chats: {
                    ...state.Chats,
                    [action.chatID]: {
                        ...state.Chats[action.chatID],
                        unRead: action.unRead
                    }
                }
            }
        }
        case 'ADDUNREAD': {
            const unRead = state.Chats[action.chatID]?.unRead;
            return {
                ...state,
                unRead: (state.unRead ?? 1) + (action.unRead ?? 1),
                Chats: {
                    ...state.Chats,
                    [action.chatID]: {
                        ...state.Chats[action.chatID],
                        unRead: (unRead ?? 1) + (action.unRead ?? 1)
                    }
                }
            }
        }
        case 'SETLASTDATE': {
            state.Chats[action.chatID].lastDate = action.lastDate;
            return {
                ...state
            }
        }
        case 'SETFINISHCHAT': {
            state.Chats[action.chatID].finishd = action.finishd;
            return {
                ...state
            }
        }
        case 'SETAKTIVECHAT': {
            return {
                ...state,
                aktiveChat: action.chatID
            }
        }
        case 'SETALLCHATMSG': {
            state.Chats[action.chatID].allMsgCount = action.allChatMsg;
            return {
                ...state
            }
        }
        case 'ADDALLCHATMSG': {
            let allMsg = state.Chats[action.chatID].allMsgCount;
            if (allMsg) {
                allMsg += action.add ? action.add : 1;
            } else {
                allMsg = action.add ? action.add : 1;
            }
            state.Chats[action.chatID].allMsgCount = allMsg;
            return {
                ...state,
            }
        }
        case 'SETCONTACTS': {
            return {
                ...state,
                contacts: action.contacts
            }
        }
        case 'ADDREADBYCHAT': {
            const chat = state.Chats[action.chatID];
            chat.readBy.push(action.uid);
            state.Chats[action.chatID] = {...chat}
            return {
                ...state
            }
        }
        case 'UPDATECHATSUNREAD': {
            return {
                ...state,
                unRead: action.unread,
            }
        }
        case 'DELETEMSG' : {
            const tmp = state.Chats[action.chatID].messages;
            if (tmp) {
                return {
                    ...state,
                    Chats: {
                        ...state.Chats,
                        [action.chatID]: {
                            ...state.Chats[action.chatID],
                            messages: [...tmp.filter(m => m.id !== action.msgID)]
                        }
                    }
                }
            }
            return {
                ...state
            }
        }
        case "DELETEMSGFOR": {
            const {messages: _messagesRaw} = state.Chats[action.chatID];

            if (_messagesRaw) {
                const messages = _messagesRaw.map((msg): Message | SystemMessage => {
                    if (isMessage(msg) && action.msgID === msg.id) {
                        return {
                            ...msg,
                            deleteFor: [
                                ...(msg.deleteFor ? msg.deleteFor : []),
                                action.uid,
                            ]
                        }
                    }

                    return msg;
                });

                return {
                    ...state,
                    Chats: {
                        ...state.Chats,
                        [action.chatID]: {
                            ...state.Chats[action.chatID],
                            messages,
                        }
                    }
                }
            }
            return {
                ...state
            }
        }
        case "SOFTDELETEMSG": {
            const {messages: _messagesRaw} = state.Chats[action.chatID];

            if (_messagesRaw) {
                const messages = _messagesRaw.map((msg): Message | SystemMessage => {
                    if (isMessage(msg) && action.msgID === msg.id) {
                        const {text, images, attachments, ...msgData} = msg;
                        return {
                            ...msgData,
                        }
                    }

                    return msg;
                });

                return {
                    ...state,
                    Chats: {
                        ...state.Chats,
                        [action.chatID]: {
                            ...state.Chats[action.chatID],
                            messages,
                        }
                    }
                }
            }
            return {
                ...state
            }
        }

        default:
            return state;
    }
}

export default messenger;
