import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from "../reducers";
import {Department, Location, Role, User} from "@pixel-kraft/commulino-types";
import {sortString} from "lib/helpers";

/**
 * Types
 */

export type t_userError = {
    what: string,
    where: string,
    msg: string
}

export interface UserFilter {
    department?: Department | null
    location?: Location | null
    role?: Role['id'] | null
}

export interface t_User extends User {
    groups: string[]
}

export interface UsersState {
    users: t_User[]
    byId: {
        [uid: string]: t_User
    }
    filter: UserFilter
    filtered: t_User[]
    loading: boolean
    creatingUser: boolean
    editingUser: boolean,
    error?: t_userError
}

/**
 * Init State
 */

const initialState: UsersState = {
    users: [],
    byId: {},
    filter: {
        department: null,
        location: null
    },
    filtered: [],
    loading: false,
    creatingUser: false,
    editingUser: false
}

/**
 * Helper Functions
 */

function filter(users: t_User[], filter: UserFilter) {
    return users.filter(({departments, locations, role}) => {
        if (filter.role && role !== filter.role) {
            return false
        }
        if (filter.department && !departments.includes(filter.department)) {
            return false
        }
        if (filter.location && !locations.includes(filter.location)) {
            return false
        }
        return true
    })
}


export function sort(users: t_User[]) {
    return users.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
}

/**
 * Store
 */

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        /**
         * Sets users, byId, filtered and loading. Loading will be set to false. Users will be sorted by name
         * @param state
         * @param action Array of User to set
         */
        setUsers: (state, action: PayloadAction<UsersState['users']>) => {
            let byId: { [uid: string]: t_User } = {}

            action.payload.forEach((user) => {
                user.departments.sort(sortString);
                user.locations.sort(sortString);
                byId[user.uid as string] = user
            })

            const users = sort(action.payload);

            state.users = users;
            state.byId = byId;
            state.filtered = filter(users, state.filter);
            state.loading = false;
        },
        setFilter: (state, action: PayloadAction<UsersState['filter']>) => {
            const nFilter = {...state.filter, ...action.payload};
            state.filter = nFilter;
            state.filtered = filter(state.users, nFilter);
        },
        createUserRequest: (state) => {
            state.loading = true;
            state.creatingUser = true;
        },
        addUser: (state, action: PayloadAction<t_User>) => {
            const sUsers = sort([...state.users, action.payload]);
            state.users = sUsers;
            state.filtered = sUsers;
            state.filter = initialState.filter;
            state.creatingUser = false;
            state.byId[action.payload.uid] = action.payload;
            state.loading = false;
        },
        editUserRequest: (state) => {
            state.editingUser = true;
        },
        editUsers: (state,action: PayloadAction<t_User[]>) => {
            const sUsers = sort([...state.users.filter((user)=>!action.payload.find((b)=>user.uid===b.uid)), ...action.payload]);
            const byId = {...state.byId};
            action.payload.forEach((user)=>byId[user.uid]=user);
            state.users = sUsers;
            state.filtered = sUsers;
            state.filter = initialState.filter;
            state.byId = byId;
            state.editingUser = false;
        },
        startOnboarding: (state, action: PayloadAction<{ uid: string, onboarding: t_User['onboarding'] }>) => {
            const users = sort([
                ...state.users.filter(user => user.uid !== action.payload.uid),
                {
                    ...(state.users.find(u => u.uid === action.payload.uid) as t_User),
                    onboarding: action.payload.onboarding
                }
            ]);

            state.users = users;
            state.filtered = users;
            state.filter = initialState.filter;
        },
        setError: (state, action: PayloadAction<{ what: string, where: string, msg: string }|undefined>) => {
            state.creatingUser = false;
            state.editingUser = false;
            state.error = action.payload;
            state.loading = false;
        }
    }
});

export const {
    createUserRequest,
    addUser,
    editUserRequest,
    setError,
    setFilter,
    startOnboarding,
    setLoading,
    setUsers,
    editUsers
} = usersSlice.actions;

/**
 * Selectors
 */

export const selectUsers = (state: RootState) => state.users.users;
export const selectFilteredUsers = (state: RootState) => state.users.filtered;
export const selectLoading = (state: RootState) => state.users.loading;
export const selectFilter = (state:RootState) => state.users.filter;
export const selectUserByUid = (state: RootState,uid: string) => state.users.byId[uid];


export default usersSlice.reducer;

