import React from 'react'
import { ColorButton, Group } from 'components'
import styles from './Pagination.module.css'

interface Props {
	count: number
	currentPage: number
	goTo: (index: number) => void
	style?: string
	complete?: boolean
}

const Pagination = ({ count, currentPage, goTo, style, complete }: Props) => {
	const pagination = []

	for (let i = 0; i < count; i++) {
		pagination.push(
			<ColorButton
				key={'paginationBtn' + i}
				text={complete?`${i + 1}`:(i===count-1)?`...`:`${i + 1}`}
				aktive={currentPage===i}
				disableStyle={{backgroundColor: 'lightgray',color: 'black'}}
				onClick={() => {
					goTo(i)
						; (document.querySelector('.content-container') as HTMLDivElement).scrollTo(0, 0)
				}}
			/>
		)
	}

	return <Group style={`${styles.container} ${style}`}>{pagination}</Group>
}

export default Pagination
