import {CONFIG_REQUEST, CONFIG_RECEIVE} from './types'
import {
    Category,
    Department,
    Location,
    Config_Mail,
    Config_Posts,
    Config_Color,
    Config_Data
} from '@pixel-kraft/commulino-types'
import {AppThunk} from '../store'

import {firebaseApp} from 'lib/firebase'
import {
    getFirestore,
    collection,
    query,
    getDoc,
    getDocs,
    doc,
    addDoc,
    deleteDoc,
    updateDoc,
    arrayUnion,
    arrayRemove
} from 'firebase/firestore';
import {reactIconToExpoIconName} from 'lib/icon'
import {getInvertColor} from "lib/colors";
import {getMainPoint, getSubPoints} from "lib/SubPointApi";

export const getCategories = async (): Promise<Category[]> => {
    let categories: Category[] = []
    const snapshots = await getDocs(
        query(
            collection(
                getFirestore(firebaseApp), "categories"
            )
        )
    );
    snapshots.forEach(snapshot =>
        categories.push({id: snapshot.id, ...(snapshot.data() as { name: string; icon: string })})
    )
    return categories
}

interface Groups {
    locations: Location[]
    departments: Department[]
}

const getGroups = async (): Promise<Groups> => {
    try {
        const snapshot = await getDoc(doc(getFirestore(firebaseApp), 'config', 'groups'));

        if (snapshot.exists()) {
            const {locations, departments} = snapshot.data() as Groups

            return {
                locations: locations || [],
                departments: departments || []
            }
        }

        return {locations: [], departments: []}
    } catch (e) {
        console.log("--------FAILED-------");
        console.log(e);
        return {locations: [], departments: []};
    }
}

interface Data {
    impressum: string,
    datenschutz: string,
}

const getData = async (): Promise<Data> => {
    try {
        const snapshot = await getDoc(doc(getFirestore(firebaseApp), 'config', 'data'));

        if (snapshot.exists()) {
            const {impressum, datenschutz} = snapshot.data() as Config_Data

            return {
                impressum,
                datenschutz,
            }
        }
        return {
            impressum: "",
            datenschutz: "",
        }
    } catch (e) {
        console.warn("-------Failed--------");
        console.log("Cant load Data firebase");
        console.log("Load starndat Data");
        return {
            impressum: "",
            datenschutz: "",
        }
    }
}

interface Color {
    mainColor: string,
    subColor: string,
}

const getColors = async (): Promise<Color> => {
    try {
        const snapshot = await getDoc(doc(getFirestore(firebaseApp), 'config', 'color'));

        if (snapshot.exists()) {
            const {mainColor, subColor} = snapshot.data() as Config_Color

            return {
                mainColor,
                subColor,
            }
        }
        return {
            mainColor: "rgba(179,179,179,1)",
            subColor: "rgba(0,82,204,1)",
        }
    } catch (e) {
        console.warn("-------Failed--------");
        console.log("Cant load color from firebase");
        console.log("Load starndat color");
        return {
            mainColor: "rgba(179,179,179,1)",
            subColor: "rgba(0,82,204,1)",
        }
    }
}

type t_Mail = {
    stellenanzeige: string,
    verbesserungsvorschlag: string,
}
/**
 * Wird dies überhaubt noch benötigt?
 */
const getMail = async (): Promise<Config_Mail> => {
    try {
        const snapshot = await getDoc(doc(getFirestore(firebaseApp), 'config', 'mail'));

        if (snapshot.exists()) {
            const {stellenanzeige, verbesserungsvorschlag} = snapshot.data() as Config_Mail
            return {
                stellenanzeige,
                verbesserungsvorschlag,
            }
        }
        return {
            stellenanzeige: "",
            verbesserungsvorschlag: "",
        }
    } catch (e) {
        console.log("-------Failed--------");
        console.log("Cant load mail");
        console.log("Job advertisements dont work!");
        return {
            stellenanzeige: "",
            verbesserungsvorschlag: "",
        }
    }
}

const getPostsConfig = async (): Promise<Config_Posts> => {
    try {
        const snapshot = await getDoc(doc(getFirestore(firebaseApp), 'config', 'posts'));

        if (snapshot.exists()) {
            const {job} = snapshot.data() as Config_Posts
            if (!job) throw new Error("Job dos not exist in Posts config");
            return {
                job
            }
        }
        throw new Error("Posts not exist!");
    } catch (e) {
        console.log("-------Failed--------");
        console.log("Cant load Posts Config");
        console.log("Settings for Posts module dont work");
        return {
            job: {
                email: "",
                uid: ""
            }
        };
    }
}

function setCssVar(varName: string, value: string) {
    document.documentElement.style.setProperty(`--${varName}`, value);
}

export const clearMenu = ():AppThunk=> {
    return (dispatch) => {
        dispatch({type: "CLEARMENU"});
    }
}

export const getConfig = (): AppThunk => {
    return async dispatch => {
        dispatch({type: CONFIG_REQUEST})
        const categories = await getCategories();
        const {departments, locations} = await getGroups();
        const {mainColor, subColor} = await getColors();
        const mainTextColor = getInvertColor(mainColor, true);
        const subTextColor = getInvertColor(subColor, true);
        const {impressum, datenschutz} = await getData();
        const {stellenanzeige, verbesserungsvorschlag} = await getMail();
        const posts = await getPostsConfig();
        const menuPoint = await getMainPoint();
        const subPoints = await dispatch(getSubPoints());
        const costumeMenu = {menuPoint, subPoints};
        setCssVar('primaryColor', mainColor);
        setCssVar('primaryTextColor', mainTextColor);
        setCssVar('secondaryColor', subColor);
        setCssVar('secondaryTextColor', subTextColor);
        dispatch({
            type: CONFIG_RECEIVE,
            categories,
            departments,
            locations,
            mainColor,
            mainTextColor,
            subTextColor,
            subColor,
            impressum,
            datenschutz,
            stellenanzeige,
            verbesserungsvorschlag,
            posts,
            costumeMenu,
        })
    }
}

export const addCategory = ({name, icon}: Omit<Category, 'id'>): AppThunk => {
    return async dispatch => {
        dispatch({type: CONFIG_REQUEST})
        await addDoc(collection(getFirestore(firebaseApp), 'categories'), {name, icon: reactIconToExpoIconName(icon)})
        dispatch(getConfig())
    }
}

export const deleteCategory = ({id}: Category): AppThunk => {
    return async dispatch => {
        dispatch({type: CONFIG_REQUEST})
        await deleteDoc(doc(getFirestore(firebaseApp), "categories", id));

        dispatch(getConfig())
    }
}

export const editCategory = ({id, name, icon}: Category): AppThunk => {
    return async dispatch => {
        dispatch({type: CONFIG_REQUEST})
        await updateDoc(doc(getFirestore(firebaseApp), "categories", id), {name, icon: reactIconToExpoIconName(icon)})

        dispatch(getConfig())
    }
}

export const addDepartment = (department: Department): AppThunk => {
    return async dispatch => {
        dispatch({type: CONFIG_REQUEST})
        await updateDoc(doc(getFirestore(firebaseApp), "config", "groups"), {departments: arrayUnion(department)})

        dispatch(getConfig())
    }
}

export const deleteDepartment = (department: Department): AppThunk => {
    return async dispatch => {
        dispatch({type: CONFIG_REQUEST})
        await updateDoc(doc(getFirestore(firebaseApp), "config", "groups"), {departments: arrayRemove(department)})

        dispatch(getConfig())
    }
}

export const addLocation = (location: Location): AppThunk => {
    return async dispatch => {
        dispatch({type: CONFIG_REQUEST})
        await updateDoc(doc(getFirestore(firebaseApp), "config", "groups"), {locations: arrayUnion(location)})

        dispatch(getConfig())
    }
}

export const deleteLocation = (location: Location): AppThunk => {
    return async dispatch => {
        dispatch({type: CONFIG_REQUEST})
        await updateDoc(doc(getFirestore(firebaseApp), "config", "groups"), {locations: arrayRemove(location)})

        dispatch(getConfig())
    }
}

export const setMainColor = (color: string): AppThunk => {
    return async dispatch => {
        dispatch({type: 'SETMAINCOLOR', color})
    }
}
export const setSubColor = (color: string): AppThunk => {
    return async dispatch => {
        dispatch({type: 'SETSUBCOLOR', color})
    }
}
export const setDatenschutz = (datenschutz: string): AppThunk => {
    return async dispatch => {
        dispatch({type: 'SETDATENSCHUTZ', datenschutz});
    }
}
export const setImpressum = (impressum: string): AppThunk => {
    return async dispatch => {
        dispatch({type: 'SETIMPRESSUM', impressum});
    }
}
export const setJobEmail = (email: string): AppThunk => {
    return async dispatch => {
        dispatch({type: "SETJOBEMAIL", email});
    }
}
export const setJobUid = (uid: string): AppThunk => {
    return async dispatch => {
        dispatch({type: "SETJOBUID", uid});
    }
}
export const setPostsConfig = (posts: Config_Posts): AppThunk => {
    return async dispatch => {
        dispatch({type: "SETPOSTSCONFIG", posts});
    }
}

export const syncCostumenMenu = (): AppThunk<Promise<void>> => {
    return async dispatch => {
        const p = await getMainPoint();
        const s = await dispatch(getSubPoints());
        dispatch({type: "SETCOSTUMENMENU", costumeMenu: {menuPoint: p, subPoints: s}});
    }
}

export const syncMenuPoint = (): AppThunk<Promise<void>> => {
    return async dispatch => {
        const p = await getMainPoint();
        dispatch({type: "SETMENUPOINT", menuPoint: p});
    }
}

export const syncSubPoints = (): AppThunk<Promise<void>> => {
    return async dispatch => {
        const s = await dispatch(getSubPoints());
        dispatch({type: "SETSUBPOINTS", subPoints: s});
    }
}
