import React, {memo, useEffect, useState} from 'react';
import styles from "./MessageImgElm.module.css";
import {ActivityIndicator} from "components";
import {resizeComponents} from "lib/helpers";
import {MessengerImage} from "@pixel-kraft/commulino-types";
import {resizeByHeight, resizeByWidth} from "lib/media";


type t_props = {
    data: MessengerImage[],
    loading: boolean,
    imgOnClick?: (index: number) => void,
    getCursor: (func: any) => React.CSSProperties,
    imgStyle?: React.CSSProperties,
    maxWidth?: number
}

const areEqual = (prev: t_props, next: t_props) => {
    return prev.loading === next.loading
}

//Use to scale the images
const standardHeight = 250;
const standardWidth = 250;

/**
 *  A Helper Component for Message. Render all images of the Message and scale this images to fit
 *  @param data to Render data
 *  @param loading is used to indicate if the images are forwarded if not loading is true
 *  @param imgOnclick is triggert if the user click on the Image with the image index out of data
 *  @param getCursor a function to change the cursor css prop if the cursor hovers over a clickable object
 *  @param imgStyle add to the image style
 *  @param maxWidth used to test if the image must be scaled to fit
 */
const MessageImgElm = memo(({data, loading, imgOnClick, getCursor, imgStyle, maxWidth}: t_props) => {
        const {height} = resizeComponents(data?.length > 0 ? data[0].width ?? standardWidth : standardWidth, data?.length > 0 ? data[0].height ?? standardHeight : standardHeight, maxWidth ? maxWidth * 0.7 : standardWidth, standardHeight)
        const [myLoad, setMyLoad] = useState(0);
        const [width, setWidth] = useState(standardWidth);

        useEffect(() => {
            if (maxWidth) {
                let tmp = 0;
                data.forEach(({width}) => tmp += width);
                const w = (maxWidth * 0.7) - 100;
                setWidth(tmp < w ? tmp : w);
            }
        }, [maxWidth])

        const addLoad = () => {
            setMyLoad(myLoad + 1);
        }


        const [images, setImages] = useState<{
            url: string,
            width: number,
            end?: number,
        }[]>([]);

        useEffect(() => {
            if (data) {
                const toRender: {
                    url: string,
                    width: number,
                    end?: number
                }[] = [];
                let size = ((maxWidth ? maxWidth : standardWidth) * 0.7) - 100;
                const add = () => {
                    for (let i = 0; i < data.length; ++i) {
                        const w = data[i].height <= standardHeight ? data[i].width > size ? size - 20 : data[i].width : standardHeight / (data[i].height / data[i].width)
                        size -= w;
                        if (size <= 0) {
                            toRender.push({url: data[i].uri, width: w, end: data.length - i});
                            return;
                        }
                        toRender.push({url: data[i].uri, width: w})
                    }
                }
                add();
                setImages(toRender);
            }
        }, [data, maxWidth])


        return (
            <>
                {(loading || images.length === 0) &&
                    <div style={{
                        width: standardWidth,
                        height: standardHeight,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <ActivityIndicator large/>
                    </div>
                }
                {!loading && images.length > 0 &&
                    <div style={{display: "flex", marginBottom: 10}}>
                        {myLoad < images.length && <div style={{
                            width: width,
                            height: standardHeight,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}><ActivityIndicator large/></div>}
                        {images.map((imgData, index) => {
                            if (imgData.end && index > 0)
                                return (
                                    <div onClick={(e) => {
                                        if (imgOnClick) {
                                            e.stopPropagation();
                                            imgOnClick(index);
                                        }
                                    }} className={styles.container} key={index}
                                         style={{width: 100, height: height <= standardHeight ? height : standardHeight}}>
                                        <img alt={"404"} className={styles.imgCon} src={imgData.url} onLoad={addLoad}/>
                                        <div className={styles.overlay}>+{data.length - index}</div>
                                    </div>
                                )

                            return (
                                <img key={index} alt={'404'} className={styles.img} onError={addLoad} onLoad={(event) => {
                                    event.currentTarget.style.height = 'auto'; //Wenn Bild geladen wurde auf 'auto' stellen damit Bildschirme mit geringer Auflösung nicht eine größere MsgBox mit kleinen Bild haben
                                    event.currentTarget.style.width = 'auto';
                                    addLoad();
                                }
                                } onClick={(e) => {
                                    if (imgOnClick) {
                                        e.stopPropagation();
                                        imgOnClick(index);
                                    }
                                }
                                } src={imgData.url} style={{
                                    display: myLoad < index ? 'none' : 'unset',
                                    maxHeight: standardHeight,
                                    width: imgData.width,
                                    height, //Höhe und breite berechnen damit nach dem das Bild geladen wurde sich nicht alles verschiebt
                                    ...getCursor(imgOnClick),
                                    ...imgStyle
                                }}/>
                            )
                        })}
                    </div>
                }
            </>
        )
    }
    , areEqual)
MessageImgElm.displayName = "MessageImgElm";


const RecImage = ({
                      height,
                      width,
                      url,
                      onClick,
                      style,
                      className
                  }: { className?: string, url: string, width?: number, height?: number, onClick?: (element: React.MouseEvent) => void, style?: React.CSSProperties }) => {
    const [load, setLoad] = useState(true);

    useEffect(() => {
        const img = new Image(width, height);
        img.src = url;
        img.onload = () => setLoad(false);
    }, [url, height, width])

    return (load ?
            <div className={className}
                 style={{width, height, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <ActivityIndicator/></div>
            :
            <img alt={'404'} className={className} src={url} style={style} onClick={onClick} width={width}
                 height={height}/>

    )
}

const maxHeight = 250;
const MessageResizeImage = ({data, loading, imgOnClick, imgStyle, getCursor, maxWidth = 700}: t_props) => {
    let currentWidth = maxWidth * 0.7 - 100;
    let w = 0;
    let h = 0;
    const toRender = data.flatMap((imgData,index) => {
        let {height, width} = resizeByHeight(imgData.width, imgData.height, maxHeight);
        if(index===0&&width>currentWidth) {
            const d=resizeByWidth(width,height,currentWidth);
            height = d.height;
            width = d.width;
        }
        currentWidth -= width;
        if (currentWidth < 0) return [];
        if(h<height) h=height;
        w += width;
        return [{...imgData, width, height}]
    });


    const renderImg = (img: MessengerImage, index: number) => (
        <RecImage onClick={(e) => {
            if (imgOnClick) {
                e.stopPropagation();
                imgOnClick(index);
            }
        }} key={`${img.uri}_${index}`} style={{...getCursor(imgOnClick), marginRight: 5}} url={img.uri}
                  width={img.width} height={img.height}/>
    )


    return (
        <div style={{display: 'flex',height: h??maxHeight, minWidth: w + (toRender.length === 1 ? 0 : (toRender.length - 1) * 5)}}>
            {loading ? <ActivityIndicator large/> :
                <>
                    {toRender.map(renderImg)}
                    {toRender.length < data.length &&
                        <div onClick={(e) => {
                            if (imgOnClick) {
                                e.stopPropagation();
                                imgOnClick(toRender.length);
                            }
                        }} className={styles.container} key={`end`}
                             style={{width: 100, height: h??maxHeight}}>
                            <RecImage url={data[toRender.length].uri} className={styles.imgCon}/>
                            <div className={styles.overlay}>+{data.length - toRender.length}</div>
                        </div>
                    }
                </>
            }
        </div>
    )

}


export default MessageResizeImage;
