import {AppThunk} from "store/store";
import {logout as store_logout, login as store_login, LoginData} from 'store/auth/actions'
import {getAuth, signInWithCustomToken} from "firebase/auth";
import {httpsCallable} from "firebase/functions";
import {firebaseApp, functions} from "lib/firebase";

namespace auth {
    let uid: string='';
    const beforeLogout: (() => void)[] = [];
    const afterLogin: ((uid: string) => void)[] = [];

    export const addAfterLogin = (...func: ((uid: string) => void)[]) => {
        afterLogin.push(...func);
    }

    export const executeAfterLogin = () => {
        afterLogin.forEach((f) => f(uid));
        //afterLogin = [];
    }

    export const addBeforeLogout = (...func: (() => void)[]) => {
        beforeLogout.push(...func);
    }

    export const removeBeforeLogout = (...func: (() => void)[]) => {
        func.forEach((f) => {
            const index = beforeLogout.indexOf(f);
            if (index > -1) beforeLogout.splice(index, 1);
        });
    }

    export const executeBeforeLogout = () => {
        beforeLogout.forEach((f) => f());
        //beforeLogout = [];
    }

    export const setUid = (u: string) => {
        uid=u;
    }


    export const singInWithPerNrAndPassword = async (perNr: string, password: string) => {
        const {data: token} = await httpsCallable<unknown, string>(functions, 'loginWidthPerNr')({perNr,password});
        await signInWithCustomToken(getAuth(firebaseApp),token);
    }

}

/**Add functions to be executed before logout.
 * This function should be used for Snapshot listener because if you logout before the listener is closed you
 * get a Firebase Permission error.
 */
export const beforeLogout = auth.addBeforeLogout;
export const removeBeforeLogout = auth.removeBeforeLogout;

/**
 * Functions to execute after the login action.
 */
export const afterLogin = auth.addAfterLogin;

export const login = (data: LoginData): AppThunk => {
    auth.setUid(data.uid);
    return (dispatch) => {
        dispatch(store_login(data));
        auth.executeAfterLogin();
    };
}

export const logout = (): AppThunk<Promise<void>> => {
    auth.setUid('');
    return async (dispatch) => {
        auth.executeBeforeLogout();
        dispatch(store_logout());
        await getAuth().signOut();
    }
}


export const singInWithPerNrAndPassword = auth.singInWithPerNrAndPassword;
