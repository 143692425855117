import React from 'react';
import {i_props} from "./VideoTypes";
import VideoEditor from "./VideoEditor";
import VideoShow from "pages/posts/componets/Video/VideoShow";

const Video=(props:i_props)=>{
    if(props.edit) return <VideoEditor />
    if(!props.edit) return <VideoShow {...props.post} />

    return null;
}
export default Video;