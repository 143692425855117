import React from 'react';
import {IconType} from "react-icons";
import {ColorButton, Row} from "components";

type t_props = {
    buttons: { icon: IconType, onClick: (event: React.MouseEvent) => void }[] | { icon: IconType, onClick: (event: React.MouseEvent) => void }
}

export type buttonsType = Partial<t_props>;

const RenderButtons = ({buttons}: t_props) => {
    const b='length' in buttons?buttons:[buttons];
    return (<Row justifyContent={'space-between'} style={{width: b.length*30+((b.length-1)*5),marginRight: 5}}>
        {b.map((b, i) => <ColorButton key={i} {...b} />)}
    </Row>)
}

export default RenderButtons;
