import React, {useState, useEffect} from 'react';
import {Calendars} from '../index';
import styles from './SelectCalendars.module.css';
import {BsThreeDotsVertical, GoCalendar} from 'react-icons/all';
import {Button, CheckBox, ColorButton, ContentContainer, Modal, Typo} from 'components';
import EditingCalendars from './EditingCalendars';
import {t_eventData} from "components/Kalender/types";
import {deleteDoc, doc, getFirestore} from "firebase/firestore";
import {firebaseApp} from "lib/firebase";
import * as api from "lib/api";

interface Props {
    calendars: Calendars[],
    setCalendars: (calendars: Calendars[]) => void,
    admin?: boolean,
    setChange?: (to: Partial<t_eventData>) => void,
    setAvoidAlertAfterDelete?:(to:boolean) => void,
}

const SelectCalendars = ({calendars, setCalendars, admin, setChange,setAvoidAlertAfterDelete}: Props) => {
    const [dropDownEditColor, setDropDownEditColor] = useState(false);
    const [calendar, setCalendar] = useState<Calendars>()
    const [selectAll, setSelectAll] = useState(true);
    const [modalMakeSureToDelete, setModalMakeSureToDelete] = useState<boolean>(false);

    useEffect(()=>{
        setSelectAll(true)
    },[calendars.length])

    const onChecked = (id: string, selected: boolean) => {
        for (let i = 0; i < calendars.length; ++i) {
            if (calendars[i].id === id) {
                const tmp = [...calendars];
                tmp[i].selected = selected;
                setCalendars(tmp);
                return;
            }
        }
    }

    const selectAllFunction = (bol:boolean) => {
        setSelectAll((prev)=>!prev)
        const allCheckboxes = calendars.map((calendar) => {
            calendar.selected = bol
            return calendar
        })
        setCalendars(allCheckboxes)
    }

    const handleDelete = async () => {
        if (calendar?.type === 'outlook') {   // Outlook just needs to delete the entry in Firebase
            await deleteDoc(doc(getFirestore(firebaseApp), 'calendars', calendar?.id));
        } else {                                // The others calendars are deleted through the API
            await api.delete(`calendars/${calendar?.calendarId}`).then(()=>setDropDownEditColor(false));
        }
        setAvoidAlertAfterDelete && setAvoidAlertAfterDelete(true);
        setChange && setChange({})
        setModalMakeSureToDelete(false);
    }

    return(
        <div className={styles.SelectWrapper}>
            <div style={{display:"flex", alignItems: "center"}}>
                <div className={styles.label}>Ihre Kalender</div>
                <div style={{margin:"6px 0 0 11px"}}>
                    <CheckBox
                        checked={selectAll}
                        onChange={(e)=>{
                            selectAllFunction(e)
                        }}
                        round={true}
                    />
                </div>
            </div>
            {calendars.map((calendar)=>{
                const {name, color, id, calendarId, selected} = calendar
                return(
                    <div
                        key={calendarId}
                        className={styles.dropDownDivToBack}
                    >
                        <div className={styles.dropDownDiv}>
                            <CheckBox
                                checked={selected}
                                onChange={(checked)=>onChecked(id, checked)}
                                round={true}
                                color={color}
                            />
                            <div className={styles.dropDownLabel}>{name}</div>
                            {admin && (
                                <BsThreeDotsVertical
                                    className={styles.dots}
                                    onClick={() => {
                                        setCalendar(calendar)
                                        setDropDownEditColor(prevState => !prevState )
                                    }}
                                />
                            )}
                        </div>

                    </div>
                )
            })}
            {calendar && dropDownEditColor && (
                <EditingCalendars
                    setDropDownEditColor={setDropDownEditColor}
                    oldCalendar={calendar}
                    calendars={calendars}
                    setModalMakeSureToDelete={setModalMakeSureToDelete}
                />
            )}
            <Modal
                isVisible={modalMakeSureToDelete}
                hide={()=> setModalMakeSureToDelete(false)}
                modalStyle={styles.modal_width}
            >
                <ContentContainer
                    hideSpacer
                >
                    <Typo variant='h3'>Kalender löschen</Typo>
                    <div style={{marginTop: "10px"}} >Sind Sie sicher, dass Sie die folgenden Kalender löschen möchten?
                        <br/>Die Daten werden verloren gehen und die Benutzer werden vom Kalender entfernt.</div>
                    <div className={styles.calendarsWrapper}>
                        <div key={calendar?.id} className={styles.calendarsDivWrapper}>
                            <div style={{width:"25px", height:"25px", position:"relative", margin: "0 8px"}}>
                                <GoCalendar className={styles.iconExample} style={{color:calendar?.color}}/>
                            </div>
                            <div>{calendar?.name}</div>
                        </div>
                    </div>
                    <div className={styles.rowButton}>
                        <Button
                            text='Zurück'
                            light
                            style={styles.cancelButton}
                            onClick={()=> {
                                setDropDownEditColor(true);
                                setModalMakeSureToDelete(false)
                            }}
                        />
                        <ColorButton
                            style={{margin: 'auto 4px'}}
                            text="Ja"
                            onClick={handleDelete}
                        />
                    </div>
                </ContentContainer>
            </Modal>
        </div>
    )
}

export default SelectCalendars
