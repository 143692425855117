import {initializeApp} from 'firebase/app';
import {
    deleteObject,
    getStorage,
    ref,
    uploadBytesResumable,
    connectStorageEmulator
} from 'firebase/storage';
import {firebaseConfig} from 'config'
import {connectFirestoreEmulator, getFirestore} from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import {getAuth,connectAuthEmulator} from "firebase/auth";

/**
 * NEW
 */

const useEmulator =  process.env.NODE_ENV === 'development'&&false;

if (useEmulator) console.info("Use Emulator")

const firebaseApp = initializeApp(firebaseConfig);
const firestore = getFirestore();
if (useEmulator) connectFirestoreEmulator(firestore, 'localhost', 8080);

const store = getStorage();
if (useEmulator) connectStorageEmulator(store, "localhost", 9199);

const functions = getFunctions(firebaseApp,'europe-west1');
if (useEmulator) connectFunctionsEmulator(functions, "localhost", 5001);

const auth = getAuth(firebaseApp);
if(useEmulator) connectAuthEmulator(auth,"http://127.0.0.1:9099",{disableWarnings: true});

function compatRef(path?: string) {
    const storageRef = ref(store, path);

    const put = (data: any) => {
        return uploadBytesResumable(storageRef, data);
    }
    const remove = async () => {
        await deleteObject(storageRef);
    }

    const child = (childPath: string) => compatRef(childPath);

    return {
        put,
        child,
        delete: remove,
    }
}

function compatStorage() {
    const $ref = (url?: string) => compatRef(url);

    return {
        ref: $ref,
    }
}

const storage = compatStorage();

export {firebaseApp, storage, firestore, functions,auth}
