import {collection, doc, getDoc, getDocs, getFirestore, query, where} from "firebase/firestore";
import {AppThunk} from "store/store";
import {
    addRelatives, selectAreas,
    selectRelative, selectResident, selectResidents, setAreas,
    setResidents,
} from "store/residentAndRelatives/store";
import {chunkArray} from "lib/helpers";
import {i_RelUser, i_ResidentDirectory, i_ResUser, } from "./types";

const dbResident = 'residentDirectory';
const dbUsers = 'users';


const relGet = async (residentId: string): Promise<i_RelUser[]> => {
    const response = await getDocs(
        query(collection(getFirestore(), dbUsers),
            where('mediCare.type','==','relative'),
            where('mediCare.residentIds', 'array-contains', residentId))
    );
    const erg: i_RelUser[] = [];
    response.forEach((doc) => {
        erg.push({...doc.data() as i_RelUser, uid: doc.id})
    })

    return erg;
}

export const getRelatives = (residentId: string, forceReload = false): AppThunk<Promise<i_RelUser[]>> => {
    return async (dispatch, getState) => {
        let rels: i_RelUser[] = [];
        if (!forceReload) {
            rels = selectRelative(getState(), residentId);
            if (rels&&rels.length>0) return rels;
        }
        rels = await relGet(residentId);
        dispatch(addRelatives({residentId, users: rels}));
        return rels;
    }
}

const resGetAll = async (): Promise<i_ResUser[]> => {
    const [response,resD] = await Promise.all([getDocs(query(collection(getFirestore(), dbUsers),where('mediCare.type','==','resident'))),resDAll()]);
    const map = new Map<string,i_ResUser>();
    response.forEach((resident) => {
        const res = {...resident.data() as i_ResUser, uid: resident.id};
        map.set(res.mediCare.id,res);
    })
    resD.forEach((val)=>{
        const res=map.get(val.id);
        if(res) res.mediCare.data=val;
    })

    return [...map.values()];
}

export const getResidentsAll = (forceReload? :boolean): AppThunk<Promise<i_ResUser[]>> => {
    return async (dispatch, getState) => {
        if (!forceReload) {
            const res = selectResidents(getState());
            if (res && res.length > 0)
                return res;
        }
        const res = await resGetAll();
        dispatch(setResidents(res));
        return res;
    }
}

const resDAll = async () => {
    const response = await getDocs(collection(getFirestore(),dbResident));
    const resD: i_ResidentDirectory[]=[]
    response.forEach((res)=>resD.push(res.data() as i_ResidentDirectory))
    return resD;
}

/**
 * @return i_ResidentDirectory[]
 */
const resGetByIDs = async (residentIds: string[]) => {
    const ids2D = chunkArray(residentIds, 10);
    const result: i_ResidentDirectory[] = [];
    for (const ids of ids2D) {
        const response = await getDocs(
            query(collection(getFirestore(), dbResident),
                where('id', 'in', ids)
            )
        )
        response.forEach((d) => result.push({...d.data() as i_ResidentDirectory}));
    }
    return result;
}

export const getResByResId = (residentId: string):AppThunk<i_ResUser|undefined>=> {
    return (dispatch, getState) => {
        return selectResident(getState(),residentId)
    }
}

const loadAreas = async ()=>{
    const response = await getDoc(doc(getFirestore(),'config','import'));
    return (response.data() as {areas: string[]}).areas;
}

export const getAreas = (forceReload?: boolean):AppThunk<Promise<string[]>> => {
    return async (dispatch, getState) => {
        if(!forceReload) {
            const areas = selectAreas(getState());
            if(areas && areas.length>0)
                return areas;
        }
        const areas = await loadAreas();
        dispatch(setAreas(areas));
        return areas;
    }
}
