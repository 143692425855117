import {i_RelUser, i_ResUser, t_chatTypes} from "./types";

import {t_Contact, User} from "@pixel-kraft/commulino-types";

export const convertToMinUser = <T extends t_chatTypes.t_smallUser>(user: T) => ({
    uid: user.uid,
    name: user.name,
    ...(user.photo ? {photo: user.photo} : {}),
    ...(user.areas ? {areas: user.areas} : {})
})


export const convertToContact = (a: t_chatTypes.t_smallUser | i_ResUser | i_RelUser | User): t_Contact => {
    const min = convertToMinUser(a);

    if ('mediCare' in a && a.mediCare) {
        if (a.mediCare.type === "relative") {
            return {
                ...min,
                type: a.mediCare.type,
                relatedContacts: a.mediCare.residents.map((res) => ({
                    ...convertToMinUser({...res, uid: ''}),
                    type: 'resident',
                    relation: res.relation,
                    residentId: res.residentId
                })) ?? []
            }
        }
        if (a.mediCare.type === "resident")
            return {
                ...min,
                type: a.mediCare.type,
                residentId: a.mediCare.id,
                areas: [a.mediCare.area]
            }
    }

    return {
        ...min,
        type: 'user'
    }
}

export const convertForChat = (a: t_Contact) => {
    const min = convertToMinUser(a);
    delete min.areas;
    return {
        ...min,
        type: a.type,
        ...(a.type === "resident" ? {residentId: a.residentId} : {}),
        ...(a.type === "relative" ? {relation: a.relation} : {})
    }
}
