import React from 'react';
import {useSelector} from "react-redux";
import {RootState} from "store/reducers";
import UserImage from "components/UserImage";

type t_userProfileStyle = {
    outerBox?: React.CSSProperties,
    image?: React.CSSProperties,
    imageBox?: React.CSSProperties,
    name?: React.CSSProperties,
}

type t_uid = {
    uid: string
}

type t_data = {
    name: string,
    photo?: string
}

type t_props= {
    data: t_uid|t_data,
    style?: t_userProfileStyle,
}
type t_propsHooks= {
    uid: string,
    style?: t_userProfileStyle,
}

const UserProfileHook=({uid,style}:t_propsHooks)=> {
    const userData = useSelector((state: RootState) => state.userProfiles[uid]);
    return (
        <UserProfileData name={userData.name} photo={userData.photo} style={style}/>
    )
}

type t_propsData= {
    name: string,
    photo?: string,
    style?: t_userProfileStyle,
}

const UserProfileData=({name,photo,style}:t_propsData)=>{

    return (
        <div style={{display: 'flex',...style?.outerBox}}>
            <div style={{...style?.imageBox}}>
                <UserImage style={{width: 40, height: 40 ,borderRadius: '50%',...style?.image}} src={photo} />
            </div>
            <div style={{display: 'flex',alignItems: 'center',marginLeft: 10,...style?.name}}>
                {name}
            </div>
        </div>
    )
}


/**
 * Wenn data den Member uid hat wird ein hook auf dieses UserProfile erstellt und das UserProfile dargestellt. (Bei liste von uid)
 * Wenn data den Member uid NICHT hat wir mit hilfe von name und photo das UserProfile dargestellt. (Bei Liste von userProfilen)
 * @param style
 * @param data
 * @constructor
 */
const UserProfile=({style,data}:t_props)=>{
    if("uid" in data && !("name" in data)){
        return (
            <UserProfileHook uid={data.uid} style={style} />
    )
    } else {
        return (
            <UserProfileData name={data.name} photo={data.photo} style={style}/>
        )
    }
}

export default UserProfile;
