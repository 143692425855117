import {AppThunk} from "store/store";
import {firebaseApp} from "lib/firebase";
import {userProfiles} from "store/userProfiles/types";
import {collection, getFirestore, onSnapshot} from "firebase/firestore";

export const getUserProfiles = (): AppThunk => {
    return async (dispatch) => {
        const list = onSnapshot(collection(getFirestore(firebaseApp),'userProfiles'),snapshot => {
            const map:{[uid: string]:userProfiles} = {};
            snapshot.forEach(doc => {
                const data = doc.data() as userProfiles;
                const uid = doc.id;
                map[uid]=data;
            })
            dispatch({type: 'SETUSERPROFILES', userProfiles: map});

        })
        dispatch({type: 'SETUSERPROFILESLISTENER',listener:list});
    }
}

export const stopListener = (): AppThunk => {
    return async (dispatch, getState) => {
        getState().userProfiles.listener();
        dispatch({type: 'SETUSERPROFILESLISTENER',listener: ()=>null});
    }
}
