import {ActivityIndicator, ColorButton, ContentContainer, PageTitle} from 'components/index'
import React, {useState, lazy, Suspense} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {setMainColor, setSubColor} from 'store/config/actions'
import {RootState} from 'store/reducers'
import {FiSave, FiX} from 'react-icons/fi'
import {firebaseApp} from 'lib/firebase';
import {getFirestore, updateDoc, doc, setDoc} from 'firebase/firestore';

const ColorPicker = lazy(() => import('./ColorPicker'));
const ColorEditor = lazy(() => import('./ColorEditor'));



const Layout = () => {
    const mainColor = useSelector((state: RootState) => state.config.mainColor)
    const subColor = useSelector((state: RootState) => state.config.subColor)
    const [oldMainColor, setOldMainColor] = useState(mainColor)
    const [oldSubColor, setOldSubColor] = useState(subColor)
    const [load, setLoad] = useState(false)
    const dispatch = useDispatch()
    const [simple, setSimple] = useState(true)

    const save = () => {
        setLoad(true)
        setDoc(doc(getFirestore(firebaseApp),'config','color'),{
            mainColor,
            subColor
        },{merge: true}).finally(()=>
            setLoad(false)
        )
        setOldMainColor(mainColor)
        setOldSubColor(subColor)
    }
    //handleFMC();
    return (
        <ContentContainer loading={load}>
            <PageTitle text={'Layout bearbeiten'}/>
            <ColorButton text={simple ? 'Erweitert' : 'Einfach'} onClick={() => setSimple(!simple)}/>
            <h2>Hauptfarbe</h2>
            <Suspense fallback={<ActivityIndicator large/>}>
                {simple && (
                    <ColorPicker
                        oldColor={oldMainColor}
                        color={mainColor}
                        onChange={c => dispatch(setMainColor(c))}
                        text={'Beeinflusst die Farbbar links und die Message Topbar'}
                    />
                )}
                {!simple && <ColorEditor color={mainColor} onSubmit={c => dispatch(setMainColor(c))}/>}
            </Suspense>
            <h2>Sekundärefarbe</h2>
            <Suspense fallback={<ActivityIndicator large/>}>
                {simple && (
                    <ColorPicker
                        oldColor={oldSubColor}
                        color={subColor}
                        onChange={c => dispatch(setSubColor(c))}
                        text={'Beeinflusst die Buttonfarbe und Filterfarbe'}
                    />
                )}
                {!simple && <ColorEditor color={subColor} onSubmit={c => dispatch(setSubColor(c))}/>}
            </Suspense>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <ColorButton
                    icon={FiX}
                    text={'Auf Standart'}
                    onClick={() => {
                        dispatch(setMainColor('rgba(179,179,179,1)'))
                        dispatch(setSubColor('rgba(0,82,204,1)'))
                    }}
                />
                {(mainColor !== oldMainColor || subColor !== oldSubColor) && (
                    <ColorButton
                        icon={FiSave}
                        text={'Save'}
                        onClick={() => {
                            save()
                        }}
                    />
                )}
            </div>
        </ContentContainer>
    )
}

export default Layout
