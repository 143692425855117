import {Editor} from '@tiptap/react';
import {
    FaBold, FaHeading, FaImage,
    FaItalic,
    FaLink,
    FaListOl,
    FaListUl,
    FaRedo,
    FaSmile,
    FaUnderline,
    FaUndo, FaVideo,
} from 'react-icons/fa';
import styles from './EditorToolbar.module.css';
import ReactDOM from 'react-dom';
import EmojiPicker from 'emoji-picker-react';
import React, {
    MouseEventHandler,
    MutableRefObject,
    useEffect,
    useRef,
    useState,
} from 'react';
import {t_config} from "./Editor";

type EmojiPickerProps = {
    editor: Editor | null
    wrapper: MutableRefObject<HTMLDivElement | null>
    visible: boolean
}

const EmojiPopUp = React.forwardRef<HTMLDivElement | null, any>(({
                                                                     wrapper,
                                                                     visible,
                                                                     editor
                                                                 }: EmojiPickerProps, ref): JSX.Element | null => {
    if (wrapper.current instanceof HTMLDivElement) {
        return ReactDOM.createPortal(
            (<div id="emoji-popup" className={styles.emojiPicker} style={{display: (!visible ? 'none' : undefined)}}
                  ref={ref}>
                <EmojiPicker onEmojiClick={(event, data) => {
                    if (editor) {
                        editor.chain().focus().insertContent(data.emoji).run();
                    }
                }}/>
            </div>),
            wrapper.current,
        )
    }
    return null;
});

type Props = {
    editor: Editor | null
    wrapper: MutableRefObject<HTMLDivElement | null>
    config?: t_config
}


const EditorToolbar = ({editor, wrapper, config}: Props) => {
    const emojiPicker = useRef<HTMLDivElement | null>(null);
    const emojiToggler = useRef<HTMLButtonElement | null>(null);
    const [emojiVisible, setEmojiVisible] = useState(false);

    // Auto close EmojiPicker
    useEffect(() => {
        const closeHandler: (event: MouseEvent) => void = (event) => {
            if (event.target === emojiToggler.current) return;

            if (!((emojiPicker.current instanceof HTMLDivElement) && emojiPicker.current.contains(event.target as Node))) {
                setEmojiVisible(false);
            }
        }

        window.addEventListener('click', closeHandler, {
            passive: true,
            capture: true,
        });

        return () => window.removeEventListener('click', closeHandler);
    }, []);

    if (!editor) {
        return null
    }

    const removeLink = () => {
        editor.chain().focus().unsetLink().run()
    }

    const setLink = () => {
        const {href: oldLink} = editor.getAttributes('link');

        const url = window.prompt('Link', oldLink);

        if (!url) return removeLink();

        editor
            .chain()
            .focus()
            .extendMarkRange('link')
            .setLink({href: url})
            .run()
    }

    const toggleEmoji: MouseEventHandler<HTMLButtonElement> = (event) => {
        if (!emojiVisible) {
            const {clientHeight, offsetTop, offsetLeft} = event.nativeEvent.target as HTMLButtonElement;

            const newStyle: Partial<CSSStyleDeclaration> = {
                top: `${clientHeight + offsetTop}px`,
                left: `${offsetLeft}px`,
            };

            Object.assign(emojiPicker.current?.style, newStyle);
        }
        setEmojiVisible((cur) => !cur);
    }
    const setImage = (url: string) => {
        editor.chain().focus().setImage({src: url}).run()
    }

    const addImage = () => {
        config?.image && config.image(setImage, true)
    }

    const addEmbed = () => {
        const {src: oldLink} = editor.getAttributes('embeds');

        const url = window.prompt('embeds', oldLink);

        if (!url) return removeLink();

        editor
            .chain()
            .focus()
            .setEmbeds({src: url})
            .run()
    }


    return (
        <>
            <div className={styles.toolbar}>
                <button
                    onClick={() => editor.chain().focus().undo().run()}
                    disabled={!editor.can().undo()}
                    title="Rückgängig"
                    className={styles.button}
                ><FaUndo/></button>
                <button
                    onClick={() => editor.chain().focus().redo().run()}
                    disabled={!editor.can().redo()}
                    title="Wiederholen"
                    className={styles.button}
                ><FaRedo/></button>
                <div className={styles.separator}/>
                <button
                    onClick={() => editor.chain().focus().toggleBold().run()}
                    title="Fettgedruckt"
                    className={[styles.button, editor.isActive('bold') ? styles.buttonActive : ''].join(' ')}
                ><FaBold/></button>
                <button
                    onClick={() => editor.chain().focus().toggleItalic().run()}
                    title="Kursiv"
                    className={[styles.button, editor.isActive('italic') ? styles.buttonActive : ''].join(' ')}
                ><FaItalic/></button>
                <button
                    onClick={() => editor.chain().focus().toggleUnderline().run()}
                    title="Unterstreichen"
                    className={[styles.button, editor.isActive('underline') ? styles.buttonActive : ''].join(' ')}
                ><FaUnderline/></button>
                <div className={styles.separator}/>
                <button
                    onClick={() => editor.chain().focus().toggleBulletList().run()}
                    title="Einfache Aufzählung"
                    className={[styles.button, editor.isActive('bulletList') ? styles.buttonActive : ''].join(' ')}
                ><FaListUl/></button>
                <button
                    onClick={() => editor.chain().focus().toggleOrderedList().run()}
                    title="Nummerierte Aufzählung"
                    className={[styles.button, editor.isActive('orderedList') ? styles.buttonActive : ''].join(' ')}
                ><FaListOl/></button>
                <div className={styles.separator}/>
                <button
                    onClick={setLink}
                    title="Link"
                    className={[styles.button, editor.isActive('link') ? styles.buttonActive : ''].join(' ')}
                ><FaLink/></button>
                <button
                    onClick={toggleEmoji}
                    title="Emoji"
                    className={[styles.button].join(' ')}
                    ref={emojiToggler}
                ><FaSmile style={{pointerEvents: 'none'}}/></button>
                {config?.image &&
                    <button
                        title={"Bild"}
                        onClick={addImage}
                        className={[styles.button].join(' ')}
                    >
                        <FaImage/>
                    </button>
                }
                <div className={styles.separator}/>
                {config?.headline?.map((val) => (
                    <button
                        key={val}
                        className={[styles.button, editor.isActive('heading', {level: val}) ? 'is-active' : ''].join(' ')}
                        onClick={() => editor.chain().focus().toggleHeading({level: val}).run()}
                    >
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <FaHeading/>
                            <div>{val}</div>
                        </div>
                    </button>
                ))}
                {config?.embeds&&
                    <button
                        title={"Embed"}
                        onClick={addEmbed}
                        className={[styles.button].join(' ')}
                    >
                        <FaVideo/>
                    </button>
                }
            </div>
            <EmojiPopUp wrapper={wrapper} visible={emojiVisible} ref={emojiPicker} editor={editor}/>

        </>
    )
}

export default EditorToolbar;
