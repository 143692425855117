import React, {lazy, Suspense} from 'react'
import {useSelector} from 'react-redux'
import {RootState} from 'store/reducers'
import {Post as PostType} from '@pixel-kraft/commulino-types'
import Attachments from './componets/Attachments/Attachments'
import Category from './componets/Category/Category'
import Content from './componets/Content/Content'
import Date from './componets/Date/Date'
import Event from './componets/Event/Event'
import Images from './componets/Images/Images'
import Survey from './componets/Survey/Survey'
import Title from './componets/Title/Title'
import styles from './Post.module.css'
import Author from './Author'
import Footer from "./Footer";
import {ActivityIndicator} from "components";
import Video from "pages/posts/componets/Video/Video";

const Job = lazy(() => import('./Job'));
const ReadConfirmPost = lazy(() => import('./componets/ReadConfimations/ReadConfirmPost'));

interface Props {
    post: PostType
}

const Post = ({post}: Props) => {
    const mode = useSelector((state: RootState) => state.posts.editing.mode)
    const edit = useSelector((state: RootState) => state.posts.editing.id === post.id);
    const isOnboarding = mode === 'onboarding'

    return (
        <Suspense fallback={<ActivityIndicator large/>}>
            <div>
                {!isOnboarding && (
                    <div className={styles.row}>
                        <Category edit={edit} post={post}/>
                        <Date edit={edit} post={post}/>
                    </div>
                )}
                <Author post={post}/>
                <Title edit={edit} post={post}/>
                <div style={edit?{maxHeight: '60vh',overflowY: 'auto',overflowX: 'hidden'}:undefined}>
                    <Content edit={edit} post={post}/>
                    <Images edit={edit} post={post}/>
                    <Video edit={edit} post={post}/>
                    <Event edit={edit} post={post}/>
                    {!isOnboarding && <Survey edit={edit} id={post.id}/>}
                    {!isOnboarding && <Job id={post.id}/>}
                    {!isOnboarding && <ReadConfirmPost id={post.id}/>}
                    <Attachments edit={edit} post={post}/>
                </div>
                <Footer post={post} like={!isOnboarding} comment={post.hasComments !== undefined}/>
            </div>
        </Suspense>
    )
}

export default Post
