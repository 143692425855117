import React from 'react';
import {MultiImagePicker, FilePicker} from 'components/index'
//Picker cant be lazy loaded because they have mount events (if you try you get a to many update error)

type t_file = {
    type: 'file',
    onChange: (file: File) => void
}

type t_image = {
    type: 'image',
    onChange: (image: string[]) => void
}

type t_props = t_file | t_image

/**
 * A helper Element for TexBox prop TopElem
 * @param props.type Change the picker
 * @param props.onChange Is triggert if a user modify a Object of the picker (image or file)
 */
const TopElem = (props: t_props) => {

    switch (props.type) {
        case "file": {
            return <FilePicker onChange={props.onChange} disable={false}/>;
        }
        case "image": {
            return <MultiImagePicker maxSelect={6} useParentAsMaxWidth size={160} reduce={3000} crop onImageChange={props.onChange}/>
        }
        default: {
            return null;
        }
    }

}
export default TopElem;
