import React from 'react';
import {Button, Row} from "components";
import {IconType} from "react-icons";


type t_props<T> = {
    value: T,
    onChange: (value: T) => void,
    isSame?: (value: T, currentValue: T) => boolean,
    spaceBetween?: number
    children: ({
        key?: React.Key
        label: string,
        value: T,
        icon?: IconType
    }[])[]
}

/**
 * Will put all children in the same sub array in one row with the same size
 */
const GroupButtons = <T, >({onChange, children, value: currentValue, isSame, spaceBetween = 10}: t_props<T>) => {


    const renderButtonRow = (value: typeof children[0], index: number) => {
        const flex = 1 / value.length;
        return (
            <Row flex={1} style={{width: '100%'}} key={value[0].key || value[0].label || index}>
                {value.map(({value, key, label,icon}, index,array) => <Button
                    icon={icon}
                    key={key || label} light={!(isSame ? isSame(value, currentValue) : value === currentValue)}
                    cssStyle={{flex, minWidth: 'unset', justifyContent: 'center', marginRight: (index<array.length-1)?spaceBetween / 2:0,marginLeft: (index>0)?spaceBetween/2:0}}
                    text={label}
                    onClick={(e) => {
                        e.stopPropagation();
                        onChange(value);
                    }}/>)}
            </Row>
        )
    }


    return (
        <Row flex={1} style={{display: 'unset', width: '100%'}} flexWrap={'wrap'}>
            {children.map(renderButtonRow)}
        </Row>
    )

}

export default GroupButtons;
