import {ResRelSDK} from 'lib/sdk';
import {AppThunk} from "store/store";
import {ChatData} from "@pixel-kraft/commulino-types";
import {addDoc, collection, getFirestore} from "firebase/firestore";
import {firebaseApp} from "lib/firebase";
import {t_Contact} from "@pixel-kraft/commulino-types";

export const createChat=(users: t_Contact[],mode: 'chat'| ResRelSDK.t_ChatData['mediCare']['type'],groupInfo?: {name: string,photo?: string},includeRes?:boolean):AppThunk<Promise<string>> =>{
    return async (dispatch, getState) => {
        if(mode!=='chat') return dispatch(ResRelSDK.createChat(users,mode,groupInfo,includeRes));

        const {uid,name,photo} = getState().auth;
        if(!uid||!name) throw new Error("Not login!");
        const userCopy=[...ResRelSDK.sortContacts(users,uid).map((user)=>{
            return ResRelSDK.convertForChat(user);
        })];
        if(userCopy[0].uid!==uid){
            userCopy.unshift(ResRelSDK.convertToContact({uid,name,photo}));
            console.info("Added current user");
        }

        const chat: ChatData = {
            users:userCopy,
            uids: [...userCopy.map(user => user.uid)],
            readBy: [userCopy[0].uid],
            date: Date.now(),
        }

        if (groupInfo?.name) {
            const g = groupInfo.name.trim();
            if (g.length !== 0) {
                chat.group = {
                    name: g,
                    createdBy: userCopy[0].uid,
                    createdAt: Date.now()
                }
            } else
                throw new Error("Groupname not valid");
        }
        const {id} = await addDoc(collection(getFirestore(firebaseApp), 'chats'), chat);
        return id;
    }
}
