export function isValidHEX(color: string) {
    return color.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i);
}

export function isValidRGB(color: string) {
    try {
        const [, values] = color.match(/^rgb\(([^)]*)\)/) ?? [];

        const colorValues = values.split(',').map((str: string): number => parseInt(str.trim()));

        colorValues.forEach((value) => {
            if (value < 0 || value > 255 ) throw new RangeError('Color out of range');
        });

        return true;
    } catch {
        return false;
    }
}

export function getInvertColor(color: string, bw: boolean = true) {
    const [, values] = color.match(/^rgba?\(([^)]*)\)/) ?? [];

    const [r, g, b] = values.split(',').map((str: string): number => parseInt(str.trim()));

    if (bw)
        return (Number(r) * 0.299 + Number(g) * 0.587 + Number(b) * 0.114) > 186 ? "rgb(0,0,0)" : "rgb(255,255,255)";

    const nr = 255 - Number(r);
    const ng = 255 - Number(g);
    const nb = 255 - Number(b);

    return `rgb(${nr},${ng},${nb})`;
}

export function rgbToHex(rgb: string) {
    const [, values] = rgb.match(/^rgba?\(([^)]*)\)/) ?? [];

    const [r, g, b] = values.split(',')
        .map((str: string): string => parseInt(str.trim()).toString(16).padStart(2, '0'));

    return `#${([r, g, b].join(''))}`;
}

export function hexToRgb(hex: string) {
    const [, ...values] = hex.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i) ?? [];

    const [ r, g, b ] = values.map((e: string) => parseInt(e, 16));

    return `rgb(${r},${g},${b})`;
}


export function toRGB(color: string) {
    return (isValidHEX(color)) ? hexToRgb(color) : color;
}

export function toHEX(color: string) {
    return (isValidRGB(color)) ? rgbToHex(color) : color;
}

export function convertColor(color: string, hex: boolean = true) {
    return (hex) ? toHEX(color) : toRGB(color);
}
