import React from 'react';
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import TextField from "@mui/material/TextField";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {Moment} from "moment";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import { PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar'
import MenuItem from '@mui/material/MenuItem';

// I've create two Props for a future requests.
interface DateTimeProps {
    value: string | Moment | Date | undefined,
    onChange: (date: Moment | string | null) => void,
    sx?: {[css:string]:object},
    setInvalid:(to:boolean)=>void
}

interface DateProps {
    value: string | Moment | Date | undefined,
    onChange: (date: Moment | string | null) => void,
    sx?: {[css:string]:object},
    setInvalid:(to:boolean)=>void

}

export const DateTimePickerMui = ({value, onChange, sx, setInvalid}:DateTimeProps) => {
    const actionBar = (props: PickersActionBarProps) => {
        const { onCancel } = props;
        return (
            <MenuItem
                onClick={() => {
                    onCancel();
                }}
                key={'1'}
                style={{width: 'fit-content', borderRadius: '5px'}}
            >
                <div style={{fontSize: 'small', color: '#0000ff96'}}>Abbrechen</div>
            </MenuItem>
        );
    }

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
                onChange={onChange}
                value={value}
                onError={(reason)=>{
                    setInvalid(typeof reason === "string")
                }}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        sx={sx}
                    />}
                components={{
                    ActionBar: actionBar,
                }}

                componentsProps={{
                    actionBar: {
                        actions: ["cancel", "accept"]
                    }
                }}
            />
        </LocalizationProvider>
    )
}

export const DatePickerMui = ({value, onChange, sx, setInvalid}:DateProps) => {
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
                onChange={onChange}
                value={value}
                onError={(reason)=>{
                    setInvalid(typeof reason === "string")
                }}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        sx={sx}
                    />}
            />
        </LocalizationProvider>
    )
}
