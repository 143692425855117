import React, {lazy, useEffect, useRef, useState, Suspense} from 'react'
import {setAktiveChat, getContacts, selectActiveChat} from 'store/chat/actions'
import {FormItem, ActivityIndicator, ColorButton} from 'components'
import useWindowDimensions from 'lib/useWindowDimensions'
import {FiPlus} from 'react-icons/fi'
import {t_chatData} from './type'
import {Redirect, useHistory} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "store/hooks";
import ChatList from './ChatList';
import {
    Switch,
    useRouteMatch
} from "react-router-dom";
import MessangerScreen from "./Screens/MessangerScreen";
import AuthRoute from "../../navigation/AuthRoute";

const NewChat = lazy(() => import('./NewChat'));

/**
 * Subnav for 'messanger/*'
 */
const MyChat = () => {
    const isLogin = useAppSelector((state) => state.auth.isLoggedIn)
    const permissions = useAppSelector((state) => state.auth.permissions);
    const activeChat = useAppSelector(selectActiveChat);
    const dispatch = useAppDispatch();
    const history = useHistory();
    const [searchTerm, setSearchTerm] = useState<string>('');
    const {height} = useWindowDimensions();
    const searchRef = useRef<HTMLDivElement | null>();
    const {path, url} = useRouteMatch();


    useEffect(() => {
        if(isLogin) {
            dispatch(getContacts());
            return () => {
                dispatch(setAktiveChat(''));
            }
        }
    }, [dispatch,isLogin]);


    const ShowNewChat = ()=>(
        <Suspense fallback={<ActivityIndicator large/>}>
            <NewChat goBack={() => {
                history.goBack();
            }} onCreate={(id) => {
                history.replace(`${url}/${id}`);
            }}/>
        </Suspense>
    )

    return (
        <div style={{display: 'flex', flex: '1 1', backgroundColor: 'lightgrey', height, marginLeft: 5, minWidth: 0}}>
            <div
                style={{
                    height: '100%',
                    maxHeight: height,
                    backgroundColor: 'white',
                    position: 'relative',
                    paddingLeft: 5,
                    paddingRight: 5
                }}>
                {isLogin ? <>
                    <div ref={(ref) => searchRef.current = ref}
                         style={{height: 'max-content', paddingRight: 10, paddingLeft: 10}}>
                        <FormItem
                            type='text'
                            value={searchTerm}
                            placeholder='Suchen...'
                            onChange={setSearchTerm}
                            style={{borderColor: '#ccc', marginTop: 10}}
                        />
                    </div>
                    <ChatList
                        style={{
                            height: searchRef.current ? height - searchRef.current.clientHeight : height - 55.8,
                            minWidth: 250
                        }}
                        filter={{
                            data: searchTerm,
                            filter: (chat, data) => chat.name.toUpperCase().includes(data.toUpperCase())
                        }}
                        active={activeChat}
                        onClick={(elm: t_chatData) => {
                            if (activeChat === elm.id) return;
                            history.push(`${url}/${elm.id}`)

                        }}
                    />
                    {permissions.messenger?.start_chat &&
                        <ColorButton style={{position: 'absolute', bottom: 40, right: 20, borderRadius: '50%'}}
                                     icon={FiPlus}
                                     onClick={() => {
                                         history.push(`${url}/newChat`)
                                     }}
                        />
                    }
                </>:<Redirect to='/login' />}
            </div>
            <div style={{flex: 1, minWidth: 0, position: 'relative'}}>
                <Switch>
                    <AuthRoute path={`${path}/newChat`} component={ShowNewChat} auth={isLogin&&!!permissions.messenger?.start_chat} />
                    <AuthRoute path={`${path}/:chatId`} component={MessangerScreen} auth={isLogin&&!!permissions.messenger?.enabled} />
                </Switch>
            </div>
        </div>
    )
}


export default MyChat
