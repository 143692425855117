import React, {useState, useEffect, lazy, Suspense} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from 'store/reducers'
import {UsersSDK} from 'lib/sdk'
import {User} from '@pixel-kraft/commulino-types'
import {useHistory} from 'react-router-dom'
import {FiEdit2, FiPlus, FiTrash2, FiRefreshCw, FiCornerRightDown, FiCornerRightUp} from 'react-icons/fi'
import {
    Button,
    ContentContainer,
    Dropdown,
    FormItem,
    Group,
    PageTitle,
    Tag,
    Typo,
    ColorButton, ActivityIndicator, ExpandableTable
} from 'components'
import * as api from 'lib/api'
import {i_expandableRow} from "components/ExpandableTable";
import {sortSortedStringArray, sortString} from "lib/helpers";
import {useAppSelector} from "store/hooks";
import {usersStore} from "store/store";

const DeleteModal = lazy(() => import('./DeleteModal'))
const CodePdfGen = lazy(() => import('./CodePdfGen'))

const moment = require('moment')

interface Props {
    user: User
}

const UserStatus = ({user}: Props) => {
    const [verificationResent, setVerificationResent] = useState(false)
    const {lastSignIn, code, isRegistered, uid, name, personnelId} = user

    if (code && !isRegistered) {
        return (
            <Suspense fallback={<ActivityIndicator large/>}>
                <Typo br>Registrierungs-Code:</Typo>
                <Typo strong>{code}</Typo>
                <CodePdfGen {...{name, code, personnelId}} />
            </Suspense>
        )
    }

    if (lastSignIn && lastSignIn > 0) {
        return <Typo>{moment(lastSignIn).format('DD.MM.YYYY  HH:mm [Uhr]')}</Typo>
    }

    if (!code && !isRegistered) {
        return (
            <div>
                {!verificationResent && <Typo br>Bestätigungs-Mail gesendet.</Typo>}
                {!verificationResent && (
                    <Button
                        text='Erneut senden'
                        light
                        onClick={() => {
                            api.post('users/resend-verification-mail', {uid})
                            setVerificationResent(true)
                        }}
                        icon={FiRefreshCw}
                    />
                )}
                {verificationResent && <Typo>Bestätigungs-Mail erneut gesendet.</Typo>}
            </div>
        )
    }

    return <Typo>Registriert</Typo>
}

function searchForUser(users: User[], searchTerm: string) {
    return users.filter(({personnelId, name}) => {
        return (
            (personnelId && personnelId.toLowerCase().includes(searchTerm.toLowerCase())) ||
            name.toLowerCase().includes(searchTerm.toLowerCase())
        )
    })
}

const Users = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const users = useAppSelector(usersStore.selectFilteredUsers);
    const filter = useAppSelector(usersStore.selectFilter);
    const departments = useSelector((state: RootState) => state.config.departments)
    const locations = useSelector((state: RootState) => state.config.locations)
    const roles = useSelector((state: RootState) => state.roles.roles)
    const loading = useSelector(
        (state: RootState) => state.users.loading || state.users.creatingUser || state.users.editingUser
    )
    const [searchTerm, setSearchTerm] = useState('')
    const [selectedUsers, setSelectedUsers] = useState<User[]>([])
    const [deleteModalVisible, setDeleteModalVisible] = useState(false)
    const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
    const [sortMode, setSortMode] = useState<{ mode: 'perNr' | 'name' | 'abteilung' | 'standort' | 'status', up: boolean }>({
        mode: 'name',
        up: false
    });

    function mapData(users: User[]): i_expandableRow<User>[] {
        return users.map(user => ({
                key: user.uid,
                data: user,
                cells: [
                    {key: 'personnelId', minContent: ({personnelId}) => personnelId || '–'},
                    {key: 'name', minContent: ({name}) => name},
                    {
                        key: 'departments',
                        minContent: ({departments}) => departments[0] &&
                          <span>{departments[0]}</span>,
                        maxContent: ({departments}) => departments.map((department) => (
                          <Tag text={department} key={department} style={{display: 'block', width: 'max-content'}} />
                        ))
                    },
                    {
                        key: 'locations',
                        minContent: ({locations}) => locations[0] &&
                          <span>{locations[0]}</span>,
                        maxContent: ({locations}) => locations.map(location => (
                          <Tag text={location} key={location} style={{ display: 'block', width: 'max-content' }} />
                        ))
                    },
                    {
                        key: 'status',
                        minContent: ({lastSignIn, code}) => lastSignIn && lastSignIn > 0 ?
                            <Typo>{moment(lastSignIn).format('DD.MM.YY')}</Typo> : code ?
                                <Typo strong>Code: {code}</Typo> : ' - ',
                        maxContent: (data) => <UserStatus user={data}/>
                    },
                    {
                        key: 'edit',
                        minContent: ({uid}) => (
                            <Button
                                onClick={() => history.push({pathname: '/users/edit', state: {uid}})}
                                icon={FiEdit2}
                                light
                            />
                        )
                    }
                ]
            })
        )
    }

    useEffect(() => {
        dispatch(UsersSDK.getUsers())
    }, [dispatch])

    useEffect(() => {
        setFilteredUsers([...(searchTerm ? searchForUser(users, searchTerm) : users)]);
    }, [users, searchTerm])

    const sortUser = (users: User[]) => {
        const sortWidthMode = (s: -1 | 0 | 1) => s * (sortMode.up ? -1 : 1);

        const sortByPerNr = (a: User, b: User) => {
            if (a.personnelId && b.personnelId) return a.personnelId > b.personnelId ? -1 : 1;
            if (a.personnelId) return 1;
            if (b.personnelId) return -1;
            return 0;
        }

        const sortByName = (a: User, b: User) => sortString(a.name, b.name);
        const sortByAbteilung = (a: User, b: User) => sortSortedStringArray(a.departments, b.departments);
        const sortByStandort = (a: User, b: User) => sortSortedStringArray(a.locations, b.locations);

        const sortByStatus = (a: User, b: User) => {
            if (a.lastSignIn && b.lastSignIn) return a.lastSignIn > b.lastSignIn ? 1 : -1;
            if(a.lastSignIn) return -1;
            if(b.lastSignIn) return 1;
            if (a.code && b.code) return sortString(a.code, b.code);
            return 0;
        }

        switch (sortMode.mode) {
            case "perNr":
                return users.sort((a, b) => sortWidthMode(sortByPerNr(a, b)));
            case "name":
                return users.sort((a, b) => sortWidthMode(sortByName(a, b)));
            case "abteilung":
                return users.sort((a, b) => sortWidthMode(sortByAbteilung(a, b)));
            case "standort":
                return users.sort((a, b) => sortWidthMode(sortByStandort(a, b)));
            case "status":
                return users.sort((a, b) => sortWidthMode(sortByStatus(a, b)));
            default:
                return users.sort((a, b) => sortWidthMode(sortByName(a, b)));
        }

    }

    const changeSortMode = (mode: 'perNr' | 'name' | 'abteilung' | 'standort' | 'status') =>
        setSortMode((old) => {
                if (old.mode === mode) return {mode: mode, up: !old.up};
                return {mode, up: false};
            }
        )

    const getIcon = (mode: 'perNr' | 'name' | 'abteilung' | 'standort' | 'status') => {
        if (sortMode.mode !== mode) return FiCornerRightDown;
        return sortMode.up ? FiCornerRightUp : FiCornerRightDown;
    }

    const HeaderItem = ({
                            text,
                            mode
                        }: { text: string, mode: 'perNr' | 'name' | 'abteilung' | 'standort' | 'status' }) => (
        <Button
            text={text}
            transparent={sortMode.mode!==mode}
            light={sortMode.mode===mode}
            onClick={() => changeSortMode(mode)}
            icon={getIcon(mode)}
            cssStyle={{flexFlow: 'row-reverse'}}
        />
    )

    return (
        <ContentContainer loading={loading}>
            <PageTitle
                text='Mitarbeiter'
                button={
                    <ColorButton
                        text='Mitarbeiter hinzufügen'
                        icon={FiPlus}
                        onClick={() => history.push('/users/add')}
                        large
                    />
                }
            />
            <Group margin={4}>
                <Dropdown
                    name='Abteilungen filtern'
                    options={departments.map(department => ({name: department}))}
                    onChange={department => dispatch(UsersSDK.filterUsers({department}))}
                    value={filter.department}
                />
                <Dropdown
                    name='Standorte filtern'
                    options={locations.map(location => ({name: location}))}
                    onChange={location => dispatch(UsersSDK.filterUsers({location}))}
                    value={filter.location}
                />
                <Dropdown
                  name='Berechtigungsgruppe filtern'
                  valueField="id"
                  options={roles.map(({ name, id }) => ({id, name}))}
                  onChange={role => dispatch(UsersSDK.filterUsers({role}))}
                  value={filter.role}
                />
                <FormItem
                    type='text'
                    value={searchTerm}
                    placeholder='Suchen...'
                    onChange={setSearchTerm}
                    style={{maxWidth: 220, borderColor: '#ccc', marginTop: 0}}
                />
            </Group>
            <div style={{opacity: selectedUsers.length > 0 ? 1 : 0.5, transition: '0.3s'}}>
                <Button
                    onClick={() => selectedUsers.length > 0 && setDeleteModalVisible(true)}
                    text='Mitarbeiter entfernen'
                    icon={FiTrash2}
                    light
                />
            </div>
            <ExpandableTable
                headers={[
                    <HeaderItem text={'Personal-Nr.'} mode={'perNr'}/>,
                    <HeaderItem text={'Name'} mode={'name'}/>,
                    <HeaderItem text={'Abteilung'} mode={'abteilung'}/>,
                    <HeaderItem text={'Standort'} mode={'standort'}/>,
                    <HeaderItem text={'Status/Angemeldet'} mode={'status'}/>
                ]}
                rows={mapData(sortUser(filteredUsers)) || []}
                selectable
                onSelect={(selected) => setSelectedUsers(selected.map(({data})=>data))}
                maxRows={50}
                complete={true}
            />
            {deleteModalVisible &&
                <Suspense fallback={<ActivityIndicator large/>}>
                    <DeleteModal
                        visible={deleteModalVisible}
                        users={selectedUsers}
                        hide={() => setDeleteModalVisible(false)}
                        refresh={() => dispatch(UsersSDK.getUsers(true))}
                    />
                </Suspense>
            }

        </ContentContainer>
    )
}

export default Users
