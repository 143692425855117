import React, {useEffect, useRef, useState} from 'react';
import {Alert, ChatButton, ColorButton, ImageCropPicker} from "components/index";
import {FiAlertOctagon, FiCrop, FiPlus, FiTrash2} from "react-icons/fi";
import styles from './MultiImagePicker.module.css'
import {t_ImageCropPickerRef} from "components/ImageCropPicker/ImageCropPicker";
import {dataURItoBlob, resizeImage} from "lib/media";


type t_images = {
    id: number
    src: string,
    original: string,
}

type t_props = {
    size: number
    onImageChange: (images: string[]) => void,
    useParentAsMaxWidth?: boolean,
    maxSelect?: number,
    crop?: boolean,
    reduce?: number
}
/**
 * A animated multi image inline Picker. Scale image automatically to fit if useParentAsMaxWidth is set.
 * Ref can add with the click function an image.
 * Open a OS file picker on mount
 * @param size Height oof images
 * @param onImageChange Function to trigger on image change (images is the src and files is the image file)
 * @param useParentAsMaxWidth If active use the width of the Parent node (example: <div id="parent"><MultiImagePicker /></div> width= Width of div parent)
 * @param maxSelect If set shows Alert if you click add
 */
const MultiImagePicker = React.forwardRef<HTMLDivElement, t_props>(({
                                                                        size,
                                                                        onImageChange,
                                                                        useParentAsMaxWidth,
                                                                        maxSelect,
                                                                        crop,
                                                                        reduce
                                                                    }, ref) => {
    const IMGRef = useRef<HTMLInputElement | null>();
    const cropRef = useRef<t_ImageCropPickerRef>(null);
    const [images, setImages] = useState<t_images[]>([]);
    const [aktIndex, setAktIndex] = useState(-1);
    const [width, setWidth] = useState(0);
    const [widthArray, setWithArray] = useState<number[]>([]);
    const [imgWidth, setImgWidth] = useState<number[]>([]);
    const [maxWidth, setMaxWidth] = useState(useParentAsMaxWidth ? IMGRef.current?.parentElement?.parentElement?.clientWidth : undefined);
    const [aktID, setAktID] = useState(0);
    const [alert, setAlert] = useState(false);

    useEffect(() => {
        let width = 0;
        imgWidth.forEach(w => width += w);//Get sum of all originale width
        const widthsArray: number[] = [];
        if (maxWidth && width > maxWidth - size - 16) { //16px from padding of ChatButton (left and right 8px)
            const minus = ((width) - (maxWidth - size - 16)) / imgWidth.length; //How much smaller to fit in maxWidth
            imgWidth.forEach((wid, akt) => {
                widthsArray[akt] = wid - minus;
            })
            setWithArray(widthsArray);
        } else {
            setWithArray([...imgWidth]);//If delete frees enough space change images back to originale size
        }

    }, [imgWidth, maxWidth, size]);

    useEffect(() => {
        let tmp = 0;
        widthArray.forEach(w => tmp += w);
        setWidth(tmp);
    }, [widthArray])


    useEffect(() => {
        onImageChange(images.map(({src}) => src));
    }, [images])

    useEffect(() => {
        IMGRef.current?.click();
        //Needed because of animation
        const t=setTimeout(()=>setMaxWidth(useParentAsMaxWidth ? IMGRef.current?.parentElement?.parentElement?.clientWidth : undefined),310);
        return () => {
            clearTimeout(t);
        }
    }, []) //Only on mount


    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const {target} = event;
        const {files} = target;

        if (files && files[0]) {
            let src:string|undefined;
            const file:File|Blob=files[0];
            if(reduce&&!file.type.includes('gif')){
                src=(await resizeImage({file,maxSize: reduce},false)) as string;
            } else {
                const read=async ():Promise<void>=>{
                    const reader = new FileReader();
                    return new Promise((resolve)=>{
                        reader.onload = async event => {
                            if (event.target) {
                                src = event.target.result as string;
                                resolve();
                            }
                        };
                        reader.readAsDataURL(files[0]);
                    })
                }
                await read();

            }
            if(!src) throw Error('Cant get image src');
            const tmp = [...images];
            if (aktIndex < 0) {
                tmp.push({id: aktID, src, original: src})
            } else {
                tmp[aktIndex] = {id: aktID, src, original: src};
            }
            setImages(tmp);
            setAktID((id) => id + 1);
        }
    }

    const click = (index: number) => {
        if (IMGRef.current && (!maxSelect || images.length < maxSelect)) {
            setAktIndex(index);
            IMGRef.current.click();
        } else if (maxSelect)
            setAlert(true);
    }

    const remove = (index: number) => {
        const tmp = [...images];
        const tmpWidth = [...imgWidth];
        tmpWidth.splice(index, 1);
        tmp.splice(index, 1);
        setImgWidth(tmpWidth);
        setAktIndex(-1);
        setImages(tmp);
    }

    return (
        <div className={styles.mainContainer}>
            <input ref={(ref) => IMGRef.current = ref} style={{display: 'none'}}
                   id="tk_imgPicker"
                   type="file"
                   accept="image/*"
                   capture="user"
                   onChange={(e) => handleFileChange(e)}
            />
            <div className={styles.imageContainer} style={{width}}>
                {images.map((imgDat, index) =>
                    <div key={imgDat.id} className={styles.divRelative}>
                        <img alt={'404'} onClick={() => click(index)}
                             style={{
                                 height: size,
                                 width: aktIndex !== index ? widthArray[index] : "auto",
                                 opacity: 0,
                                 transition: "all 0.3s ease 0.3s",
                             }}
                             onLoad={e => {
                                 const tmp = [...imgWidth];
                                 tmp[index] = e.currentTarget.width;
                                 setImgWidth(tmp);
                                 e.currentTarget.style.opacity = "1";
                             }}
                             src={imgDat.src}/>
                        <div className={styles.buttonContainer}>
                            <ChatButton
                                onClick={() => remove(index)}
                                className={styles.removeButton}
                                icon={FiTrash2}
                            />
                            {crop && !imgDat.original.includes('image/gif') && <ColorButton
                                icon={FiCrop}
                                className={styles.cropButton}
                                onClick={() => {
                                    setAktIndex(index);
                                    cropRef.current?.setImgSrc((index >= 0 && index < images.length) ? images[index].original : undefined);
                                    cropRef.current?.showCrop();
                                }}
                            />}
                        </div>

                    </div>)}
            </div>
            <ChatButton className={styles.addButton} ref={ref} onClick={() => click(-1)} style={{
                height: size,
                width: size,
                fontSize: size,
            }} icon={FiPlus}/>
            {maxSelect && <Alert title={"Zu viel"} text={"Sie haben die Maximalanzahl an Bildern ausgewählt"}
                                 icon={FiAlertOctagon} visible={alert} hide={() => setAlert(false)}/>}
            {crop && <ImageCropPicker
                hidePreview
                hideImage
                imageStyle={{objectFit: 'cover',width: "unset",maxWidth: 500,borderRadius: "unset"}}
                ref={cropRef}
                onImageSrc={(src) => {
                    const tmp = [...images];
                    tmp[aktIndex].src = src;
                    setImages(tmp);
                }}/>
            }
        </div>
    )
})

export default MultiImagePicker;
