/**
 * A File to Config the Editor
 * Her you can put all Plugins that should be used.
 * In minimumExtension a the Extension for the Post Editor. This Extension are used in every Editor
 * In maximumExtension are all Extension.
 */

import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Link from "@tiptap/extension-link";
import Image from "@tiptap/extension-image";
import Heading from "@tiptap/extension-heading";
import Embeds from "lib/tipTapExensions/Embeds";

export const minimumExtension = [
    StarterKit.configure({
        heading: false,
        horizontalRule: false,
        code: false,
        codeBlock: false,
    }),
    Underline,
    Link.configure({
        openOnClick: false,
    }),
]

export const maximumExtension = (imgClass: string,embedClass: string)=>[
    StarterKit.configure({
        heading: false,
        horizontalRule: false,
        code: false,
        codeBlock: false,
    }),
    Underline,
    Link.configure({
        openOnClick: false,
    }),
    Image.configure({
        HTMLAttributes: {
            class: imgClass
        }
    }),
    Heading,
    Embeds.configure({
        HTMLAttributes: {
            class: embedClass
        }
    })
]
