import React from "react";
import {useSelector} from "react-redux";
import {RootState} from "store/reducers";
import {Category as CategoryType} from "@pixel-kraft/commulino-types";
import Category from "./Category";

type t_props = {
    id: string,
    categories?: CategoryType[]
}

const CategoryById=({id,categories: c}:t_props)=>{
    const categories = (c??useSelector((state: RootState) => state.config.categories)).filter(c => c.id === id);
    if(categories.length!==1) return null;
    const cat=categories[0];
    return <Category {...cat} />
}
export default CategoryById;