import {
  serverTimestamp,
} from 'firebase/firestore';
import {fileObject, folderObject} from "store/folders/types";

export const database = {
    formatDocFolder: (doc: { id: string; data: () => any; }):folderObject => {
        return { ...doc.data(),id: doc.id }
    },
    formatDocFiles: (doc: { id: string; data: () => any; }):fileObject => {
        return { ...doc.data(),id: doc.id }
    },
    getCurrentTimestamp: serverTimestamp,
}
