import React, {useEffect, useState} from 'react';
import {ActivityIndicator, CheckBox} from "./index";
import MediaQuery from "react-responsive";
import Pagination from "./Pagination";
import styles from "./ExpandableTable.module.css";

interface Cell<T> {
    key: string,
    minContent: (data: T) => React.ReactNode,
    maxContent?: (data: T) => React.ReactNode;
}

interface RowData<T> {
    key: string,
    data: T,
}

export interface i_expandableRow<T> extends RowData<T> {
    cells: Cell<T>[]
}


interface i_props<T> {
    headers: React.ReactNode[],
    rows: i_expandableRow<T>[],
    loading?: boolean,
    selectable?: boolean,
    maxRows?: number,
    className?: string,
    onSelect?: (selected: RowData<T>[]) => void,
    onRowClick?: (row: RowData<T>) => void,
    complete?: boolean,
    loadNext?: () => void,
    goToStart?: React.DependencyList,
}

function ExpandableTable<T>({
                                goToStart,
                                headers,
                                rows,
                                loading,
                                loadNext,
                                maxRows,
                                onRowClick,
                                complete,
                                className,
                                onSelect,
                                selectable
                            }: i_props<T>) {
    const [RowMap,setRowMap] = useState(new Map<string, i_expandableRow<T>>());
    const [selected, setSelected] = useState<string[]>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [currentRows, setCurrentRows] = useState<i_expandableRow<T>[]>([]);
    const [expandRow, setExpandRow] = useState<string>('');

    useEffect(() => {
        goToStart && setCurrentPage(0);
    }, [goToStart])

    useEffect(() => {
        if (maxRows)
            setCurrentRows(rows.slice(maxRows * currentPage, maxRows * (currentPage + 1)));
    }, [maxRows, currentPage, rows])

    useEffect(()=>{
        RowMap.clear();
        rows.forEach((row)=>RowMap.set(row.key,row));
        setRowMap(RowMap);
    },[rows])

    const onChange = (keys: string[], change: boolean) => {
        let s = selected.filter(key => !keys.includes(key))

        if (change) {
            s = [...selected, ...keys]
        }

        setSelected(s)
        onSelect && onSelect(s.flatMap((key) => {
            const d = RowMap.get(key);
            return d ? [{key: d.key, data: d.data}] : [];
        }))
    }

    const renderHeadItem = (head: React.ReactNode, index: number) => (
        <th className={styles.th} key={index}>
            {head}
        </th>
    )

    const renderRow = (row: i_expandableRow<T>) => (
        <tr key={row.key}
            className={`${styles.tr} ${onRowClick && styles.clickable}`}
            onClick={()=>{
            setExpandRow((c)=>c===row.key?'':row.key);
            onRowClick&&onRowClick({key: row.key,data: row.data});
        }}>
            {selectable && (
                <td className={styles.td} onClick={(e)=>e.stopPropagation()}>
                    <CheckBox
                        onChange={change => onChange([row.key], change)}
                        checked={selected.includes(row.key)}
                    />
                </td>
            )}
            {row.cells.map((cell) => (
                <td className={styles.td} key={cell.key}>
                    {cell.maxContent&&expandRow === row.key ?
                        cell.maxContent(row.data) :
                        cell.minContent(row.data)
                    }
                </td>
            ))}
        </tr>
    )

    return (
        <div className={`${className ?? ''}`}>
            {loading && (
                <ActivityIndicator/>
            )}
            <MediaQuery minWidth={961}>
                <table className={styles.table}>
                    <thead>
                    <tr>
                        {selectable &&
                            <th className={styles.th}>
                                <CheckBox
                                    onChange={change => onChange(rows.map(({key}) => key), change)}
                                    checked={selected.length > 0 && selected.length === rows.length}
                                />
                            </th>
                        }
                        {headers.map(renderHeadItem)}
                    </tr>
                    </thead>
                    <tbody>
                        {currentRows.map(renderRow)}
                    </tbody>
                </table>
            </MediaQuery>

            {maxRows && maxRows < rows.length && (
                <Pagination count={Math.ceil(rows.length / maxRows)} currentPage={currentPage} goTo={(page) => {
                    if (loadNext) {
                        if ((!complete) && page === Math.ceil(rows.length / maxRows) - 1)
                            loadNext();
                    }
                    setCurrentPage(page);
                }} complete={complete}/>
            )}
        </div>
    )

}

export default ExpandableTable;
