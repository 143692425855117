import {Post, Category, Department, Location, User, Survey, OnboardingPost, Image} from '@pixel-kraft/commulino-types'

export const POSTS_REQUEST = 'POSTS_REQUEST'
export const POSTS_RECEIVE = 'POSTS_RECEIVE'

export const POSTS_BY_GROUP_REQUEST = 'POSTS_BY_GROUP_REQUEST'
export const POSTS_BY_GROUP_RECEIVE = 'POSTS_BY_GROUP_RECEIVE'

export const POSTS_SET_FILTER = 'POSTS_SET_FILTER'

export const POSTS_SET_VISIBLE = 'POSTS_SET_VISIBLE'
export const POSTS_HIDE_VISIBLE = 'POSTS_HIDE_VISIBLE'

export const POSTS_INIT_NEW_POST = 'POSTS_INIT_NEW_POST'

export const POSTS_START_EDIT_MODE = 'POSTS_START_EDIT_MODE'
export const POSTS_END_EDIT_MODE = 'POSTS_END_EDIT_MODE'
export const POSTS_ON_EDIT_CHANGE = 'POSTS_ON_EDIT_CHANGE'

export const POSTS_EDIT_REQUEST = 'POSTS_EDIT_REQUEST'
export const POSTS_EDIT = 'POSTS_EDIT'

export const POSTS_PUBLISH_REQUEST = 'POSTS_PUBLISH_REQUEST'
export const POSTS_PUBLISH = 'POSTS_PUBLISH'

export const POSTS_DELETE = 'POSTS_DELETE'

export const POSTS_REMOVE_LIKE = 'POSTS_REMOVE_LIKE'
export const POSTS_ADD_LIKE = 'POSTS_ADD_LIKE'

export const POSTS_RESET = 'POSTS_RESET'

export const POSTS_UPDATE_SURVEY = 'POSTS_UPDATE_SURVEY'

export const POST_UPDATE_UNREAD = 'POST_UPDATE_UNREAD'

export const POSTS_INIT_NEW_POST_ONBOARDING = 'POSTS_INIT_NEW_POST_ONBOARDING'
export const POSTS_EDIT_ONBOARDING_POST = 'POSTS_EDIT_ONBOARDING_POST'

export const POSTS_BY_GROUP_MERGE = 'POSTS_BY_GROUP_MERGE';
export const POSTS_BY_PERSONAL_MSG = 'POSTS_BY_PERSONAL_MSG';
export const POSTS_MERGE = 'POSTS_MERGE';
export const LOAD_FINISH_MANUEL = 'LOAD_FINISH_MANUEL';
export const POSTS_SET_UNREAD_LISTENER='POSTS_SET_UNREAD_LISTENER';
export const POSTS_CATEGORY_COMPlETE='POSTS_CATEGORY_COMPlETE';
export const POSTS_COMPlETE ='POSTS_COMPlETE';
export const POSTS_START = 'POSTS_START';
export const POSTS_ADD_COUNT_ALL='POSTS_ADD_COUNT_ALL';
export const POSTS_ADD_COUNT_GROUP='POSTS_ADD_COUNT_GROUP';
export const SET_LAST_POST_OF='SET_LAST_POST_OF';

export const UPDATE_POST_HASCOMMENTS='UPDATE_POST_HASCOMMENTS';

export const SET_IMAGEGALLRYOVERLAY='SET_IMAGEGALLRYOVERLAY';
export const SET_COMMENTARIESOVERLAY="SET_COMMENTARIESOVERLAY";
export const SET_SURVEYDETAILS="SET_SURVEYDETAILS";
export const SET_READCONFERMATION="SET_READCONFERMATION";
export const RELOADPOST="RELOADPOST";

export interface SetLastPostOf {
	type: typeof SET_LAST_POST_OF,
	of: 'group'|'all',
	id: string,
}

export type t_filterFor="category"|"departments"|"locations"|"author.uid"|"isPersonalMessage";

export interface PostsComplete {
	type: typeof POSTS_COMPlETE,
	which: 'postComplete'|'postByGroupComplete',
	complete: boolean,
}

export interface PostsSetUnreadListener {
	type: typeof POSTS_SET_UNREAD_LISTENER,
	listener: ()=>void,
}

export interface LoadFinishManuel {
	type: typeof LOAD_FINISH_MANUEL,
}

export interface PostsMerge {
	type: typeof POSTS_MERGE,
	posts: Post[],
	complete_All?: boolean,
	category?: string,
	complete_cat?: boolean,
	context?: t_filterFor,
}

export interface  PostsByGroupMerge {
	type: typeof  POSTS_BY_GROUP_MERGE,
	posts: Post[],
	complete_All?: boolean,
	category?: string,
	complete_cat?: boolean,
	context?: t_filterFor,
}

export interface PostsByPersonalMsg {
	type: typeof POSTS_BY_PERSONAL_MSG,
	posts: Post[],
	complete: boolean,
	uid: string,
}


export interface Filter {
	category: Category | null,
	department: Department | null,
	location: Location | null
}

export interface UpdateSurvey {
	type: typeof POSTS_UPDATE_SURVEY,
	postID: string,
	survey: Survey
}

export interface i_cachedDataPost extends Post {
	vimeoCache?: File,
}

export interface PostsState {
	posts: Post[],
	postComplete: boolean,
	postsByGroup: Post[],
	postByGroupComplete: boolean,
	byId: {
		[id: string]: Post
	},
	filter: Filter,
	filtered: Post[],
	loading: boolean,
	visible: string | null,
	editing: {
		state: boolean,
		loading: boolean,
		id: string | null,
		data?: i_cachedDataPost,
		mode: 'editing' | 'new' | 'onboarding',
	},
	unread: number,
	unread_listener: ()=>void,
	postsByCategory: t_postBy,
	postsByDepartment: t_postBy,
	postsByLocation: t_postBy,
	postsByPersonalMsg: t_postBy,
	postLoadCountAll: number,
	postLoadCountGroup: number,
	lastPostID: {
		group: string,
		all: string,
	},
	imageGalleryOverlay: t_ImageGalleryOverlay,
	commentariesOverlay?: Post,
	surveyDetails: t_surveyPostDetails,
	readConfirmations: t_readConfirmations,
	surveyMap: Map<string,Survey>,
}

export type t_readConfirmations = {
	[postID:string] : boolean
}

interface SetReadConfirmations {
	type: typeof SET_READCONFERMATION,
	postID: string,
	read: boolean
}

export type t_postBy = {
	[id:string]: {
		posts: {id:string,date:number}[],
			complete: boolean
	}
}

interface RequestAction {
	type: typeof POSTS_REQUEST
}

interface ReceiveAction {
	type: typeof POSTS_RECEIVE,
	posts: Post[]
}

interface PostsByGroupRequestAction {
	type: typeof POSTS_BY_GROUP_REQUEST
}

interface PostsByGroupReceiveAction {
	type: typeof POSTS_BY_GROUP_RECEIVE,
	posts: Post[],
	cat_ID?: string,
	complete?: boolean,
}

interface SetFilterAction {
	type: typeof POSTS_SET_FILTER,
	filter: Filter
}

interface SetVisibleAction {
	type: typeof POSTS_SET_VISIBLE,
	id: string
}

interface HideVisibleAction {
	type: typeof POSTS_HIDE_VISIBLE
}

interface InitNewPostAction {
	type: typeof POSTS_INIT_NEW_POST,
	id: string,
	post: Post
}

interface StartEditModeAction {
	type: typeof POSTS_START_EDIT_MODE,
	id: string
}

interface EndEditingModeAction {
	type: typeof POSTS_END_EDIT_MODE
}

interface OnEditChangeAction {
	type: typeof POSTS_ON_EDIT_CHANGE,
	change: Partial<Post>
}

interface PublishRequestAction {
	type: typeof POSTS_PUBLISH_REQUEST
}

interface PublishAction {
	type: typeof POSTS_PUBLISH
}

interface EditRequestAction {
	type: typeof POSTS_EDIT_REQUEST
}

interface EditAction {
	type: typeof POSTS_EDIT,
}

interface DeleteAction {
	type: typeof POSTS_DELETE,
	id: string
}
export interface ReadConfirmationType {
	uid: string,
	date: number
}

interface AddLikeAction {
	type: typeof POSTS_ADD_LIKE,
	id: Post['id'],
	uid: User['uid']
}

interface RemoveLikeAction {
	type: typeof POSTS_REMOVE_LIKE,
	id: Post['id'],
	uid: User['uid']
}

interface ResetPosts {
	type: typeof POSTS_RESET
}

interface PostUpdateUnread {
	type: typeof POST_UPDATE_UNREAD,
	unread: number
}

interface InitNewPostOnboardingAction {
	type: typeof POSTS_INIT_NEW_POST_ONBOARDING,
	post: OnboardingPost,
	id: string
}

interface EditOnboardingPostAction {
	type: typeof POSTS_EDIT_ONBOARDING_POST,
	post: Post
}

interface PostsCategoryComplete {
	type: typeof POSTS_CATEGORY_COMPlETE,
	category_ID: string,
	complete: boolean,
}

interface PostsStart {
	type: typeof POSTS_START,
	cat: string[],
	dep: string[],
	loc: string[],
	uid: string|null,
}

interface AddPostCountAll {
	type: typeof POSTS_ADD_COUNT_ALL
}
interface AddPostsCountGroup {
	type: typeof POSTS_ADD_COUNT_GROUP
}

interface UpdatePostHasComments {
	type: typeof  UPDATE_POST_HASCOMMENTS,
	postID: string,
	update: boolean
}

interface ReloadPostAction {
	type: typeof RELOADPOST
	post: Post,
}

/**
 * Start: For better rendering
 */
export interface t_ImageGalleryOverlayProps {
	images: Image[],
	startIndex: number
}

export type t_ImageGalleryOverlay = t_ImageGalleryOverlayProps|undefined

interface setImageGalleryOverlay {
	type: typeof SET_IMAGEGALLRYOVERLAY,
	imageGalleryOverlay: t_ImageGalleryOverlay
}

interface setCommentariesOverlay {
	type: typeof SET_COMMENTARIESOVERLAY,
	commentariesOverlay: Post
}

export interface t_surveyPostDetailsProps {
	survey: Survey,
	totalVotes: number,
	answers: {
		answer: string,
		votes: string[],
		index: number,
	}[],
	Bar: React.ComponentType<{ onClick: () => void, answer: string, active: boolean, hidden: boolean, multi: boolean, votes: string[], totalVotes: number, style?: React.CSSProperties }>
}

export type t_surveyPostDetails = t_surveyPostDetailsProps | undefined

interface setSurveyDetails {
	type: typeof SET_SURVEYDETAILS,
	surveyDetails: t_surveyPostDetails
}

/**
 * Stop: For better rendering
 */

export type PostsActionTypes =
	| RequestAction
	| ReceiveAction
	| PostsByGroupRequestAction
	| PostsByGroupReceiveAction
	| SetFilterAction
	| SetVisibleAction
	| HideVisibleAction
	| InitNewPostAction
	| StartEditModeAction
	| EndEditingModeAction
	| OnEditChangeAction
	| PublishRequestAction
	| PublishAction
	| EditRequestAction
	| EditAction
	| DeleteAction
	| AddLikeAction
	| RemoveLikeAction
	| ResetPosts
	| UpdateSurvey
	| PostUpdateUnread
	| InitNewPostOnboardingAction
	| EditOnboardingPostAction
	| PostsByGroupMerge
	| PostsMerge
	| LoadFinishManuel
	| PostsSetUnreadListener
	| PostsCategoryComplete
	| PostsComplete
	| PostsStart
	| AddPostCountAll
	| AddPostsCountGroup
	| SetLastPostOf
	| UpdatePostHasComments
	| PostsByPersonalMsg
	| setImageGalleryOverlay
    | setCommentariesOverlay
	| setSurveyDetails
	| SetReadConfirmations
	| ReloadPostAction
