import React, {useEffect, useState} from 'react'
import {Redirect, useHistory} from 'react-router-dom'
import {FiLogIn} from 'react-icons/fi'
import Button from 'components/Button'
import CheckBox from 'components/CheckBox'
import ErrorMessageInline from 'components/ErrorMessageInline'
import FormItem from 'components/FormItem'
import styles from './index.module.css';
import commuLinoLogo from '../../assets/commulino-color.svg'
import {logo} from 'config'
import {ColorButton, Typo} from 'components/index'
import {useSelector} from 'react-redux'
import {RootState} from 'store/reducers'
import {get_loginIP_URL} from 'lib/config_help'
import {
    getAuth,
    signInWithCustomToken,
    signInWithEmailAndPassword,
    setPersistence,
    browserSessionPersistence,
    browserLocalPersistence
} from "firebase/auth";
import {firebaseApp} from "lib/firebase";
import {AuthSDK} from "lib/sdk";

const Login = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loggingIn, setLoggingIn] = useState(false)
    const [loginFailed, setLoginFailed] = useState(false)
    const [loginOverIPFailed, setLoginOverIPFailed] = useState(false)
    const [notPermitted, setNotPermitted] = useState(false)
    const [persistSession, setPersistSession] = useState(false)
    const history = useHistory()
    const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn)
    const loginIPURL = get_loginIP_URL()
    const [IPToken, setIPToken] = useState('')

    useEffect(() => {
        if (loginIPURL !== '') {
            fetch(loginIPURL, {
                method: 'GET',
                mode: 'cors'
            }).then(res => {
                res.json().then(data => {
                    if ('token' in data) setIPToken(data.token)
                })
            }).catch(err => {
                console.log(err);
            })
        }
    }, [loginIPURL])

    if (isLoggedIn) return <Redirect to='/feed'/>

    async function login(overIp: string = '') {
        const auth = getAuth(firebaseApp);
        setLoggingIn(true)
        setLoginFailed(false)
        setLoginOverIPFailed(false)
        await setPersistence(auth, persistSession ? browserSessionPersistence : browserLocalPersistence);
        try {
            const _login = async () => {
                if (overIp !== '') {
                    await signInWithCustomToken(auth, overIp);
                } else if(!email.includes('@')) { //PerNr
                    await AuthSDK.functions.singInWithPerNrAndPassword(email,password);
                } else await signInWithEmailAndPassword(auth, email, password);
            }

            const timeOut = setTimeout(() => setNotPermitted(true), 15000); //Firebase Function can need a long time to start if their where a sleep
            await _login();
            clearTimeout(timeOut);
            return () => {
                clearTimeout(timeOut);
            }
        } catch {
            overIp ? setLoginOverIPFailed(true) : setLoginFailed(true)
        } finally {
            setLoggingIn(false)
        }
    }

    return (
        <div className={styles.container} style={{overflowY: 'auto'}}>
            <div className={styles.content}>
                <img src={logo} className={styles.logo} alt=''/>
                <form
                    className={styles.form}
                    onSubmit={event => {
                        event.preventDefault()
                        login()
                        return false
                    }}
                >
                    <ErrorMessageInline
                        text='E-Mail oder Passwort sind nicht korrekt.'
                        visible={loginFailed}
                        style={styles.errorMessage}
                    />
                    <ErrorMessageInline
                        text='IP ist nicht regestriert.'
                        visible={loginOverIPFailed}
                        style={styles.errorMessage}
                    />
                    <ErrorMessageInline
                        text='Sie sind nicht zur Anmeldung berechtigt.'
                        visible={notPermitted}
                        style={styles.errorMessage}
                    />
                    <FormItem name='E-Mail' type='text' value={email} onChange={setEmail}/>
                    <FormItem name='Passwort' type='password' value={password} onChange={setPassword}/>
                    <div style={{height: 12}}/>
                    <CheckBox
                        text='Angemeldet bleiben'
                        checked={persistSession}
                        onChange={setPersistSession}
                    />
                    <ColorButton
                        text='Anmelden'
                        icon={FiLogIn}
                        fullWidth
                        large
                        loading={loggingIn}
                        style={{justifyContent: 'center', marginTop: 16}}
                    />
                </form>
            </div>
            {loginIPURL !== '' && IPToken !== '' && (
                <ColorButton
                    onClick={() => login(IPToken)}
                    text='Ohne Registrierung anmelden'
                    style={{justifyContent: 'center', marginTop: 16}}
                />
            )}
            <Button
                onClick={() => history.push('/forgot-password')}
                text='Passwort vergessen?'
                transparent
                light
                style={styles.button}
            />
            <Button
                onClick={() => history.push('/register-by-code')}
                text='Mit Registrierungscode registrieren'
                transparent
                light
                style={styles.button}
            />
            <div style={{position: 'relative', top: 50}}>
                <Typo>Powered by</Typo>
                <img
                    src={commuLinoLogo}
                    alt=''
                    className={styles.logo}
                    style={{margin: 0, marginLeft: 5}}
                />
            </div>
        </div>
    )
}

export default Login
