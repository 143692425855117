import React from 'react';
import {Post} from "@pixel-kraft/commulino-types";
import Commentaries from "./componets/Commentaries/Commentaries";
import LikeButton from "./LikeButton";

type t_footer = {
    post: Post,
    like: boolean,
    comment: boolean
}

const Footer = ({comment, like, post}: t_footer) => {

    if (!comment && !like) return null;

    return (
        <div style={{display: 'flex', paddingTop: 10,marginTop: 10, borderTop: '1px solid lightgray'}}>
            {post.hasComments !== undefined &&
                <Commentaries
                    post={post}
                    // postID={post.id}
                    // hasComments={post.hasComments}
                    // showAllComments={(!(post.hiddenComments === true)) || post.author.uid === uid}
                />
            }
            {like &&
                <LikeButton id={post.id} />
            }
        </div>
    )
}

export default Footer;
