import {ActivityIndicator, ColorButton} from 'components/index';
import {uploadGroupImage, DeleteGroupImage} from 'lib/media';
import moment from 'moment';
import React, {useEffect, useState} from 'react'
import {FiChevronLeft, FiLogOut, FiSave, FiTrash2, FiX} from 'react-icons/fi';
import {useSelector} from 'react-redux';
import {RootState} from 'store/reducers';
import {AlertDialog} from 'components/index';
import {firebaseApp} from "lib/firebase";
import {getFirestore, updateDoc, doc, arrayRemove, deleteDoc} from "firebase/firestore";
import checkGroupPermissions from "lib/checkGroupPermissions";
import styles from "./ChatInfo.module.css";
import {MdArchive, MdUnarchive} from "react-icons/all";
import ChatArchive from "pages/chat/functions/archive";
import ChatHead from "pages/chat/componets/ChatInfo/ChatHead";
import Container from "pages/chat/componets/ChatInfo/Container";
import useWindowDimensions from "lib/useWindowDimensions";
import ChatUserListManipulate from "./componets/ChatUserListManipolate/ChatUserListManipolate";

type t_props = {
    id: string,
    onSubmit: () => void,
    goBack: () => void,
    preLoadImg?: string,
    onAction: (type: 'leave'|'delete',chatId: string)=>void
}
/**
 * Rendert Chatinformation
 */
const ChatInfo = ({id, goBack, onSubmit, preLoadImg,onAction}: t_props) => {
    const currentUser = useSelector((state: RootState) => state.auth);
    const {uid} = currentUser;
    const chat = useSelector((state: RootState) => state.messenger.Chats[id]);
    const [groupName, setGroupName] = useState(chat.group?.name);
    const [imgSrc, setImgSrc] = useState(preLoadImg ?? chat.group?.photo);
    const [imgFile, setImgFile] = useState<Blob>();
    const [alert, setAlert] = useState<React.ComponentProps<typeof AlertDialog> | undefined>();
    const [load, setLoad] = useState(false);
    const creator = uid === chat?.group?.createdBy;
    const {height} = useWindowDimensions();
    if (uid === null)
        throw new Error('Not authenticated');
    const mode = chat.mediCare?.type??'chat';

    const getUserNameFromGroupByID = (id: string | undefined) => {
        if (id !== undefined) {
            for (let i = 0; i < chat.users.length; ++i) {
                if (chat.users[i].uid === id)
                    return chat.users[i].name;
            }
        }
        return 'Unbekannt';
    }


    useEffect(() => {
        if (uid !== null && !chat.group && !preLoadImg)
            setImgSrc(chat.users.filter(user => user.uid !== uid)[0].photo);
        else if (uid !== null && !preLoadImg)
            setImgSrc(chat.group?.photo);
        if (chat.group)
            setGroupName(chat.group?.name);
    }, [chat, uid, preLoadImg])

    useEffect(() => {
        if (chat.group?.photo && !preLoadImg)
            setImgSrc(chat.group?.photo);
    }, [chat.group?.photo, preLoadImg])

    const ArchiveButton = () => {
        const isArchive = !!chat.archivedBy?.includes(uid);
        return <ColorButton text={isArchive ? "Aus dem Archiv holen" : "Archivieren"}
                            icon={isArchive ? MdUnarchive : MdArchive}
                            onClick={() => ChatArchive(id, uid, !isArchive)}
        />
    }

    return (
        <div className={styles.container}>
            <div className={styles.centerRow}>
                <div className={styles.backButton}>
                    <ColorButton icon={FiChevronLeft} onClick={() => goBack()}/>
                </div>
                <h1>Info</h1>
            </div>
            <div className={styles.contentContainer}>
                {chat.group ?
                    <ChatHead
                        chatName={groupName ?? 'Loading...'}
                        imgUrl={imgSrc}
                        type={"group"}
                        onChatNameChange={setGroupName}
                        onImgChange={(url, file) => {
                            setImgSrc(url);
                            setImgFile(file);
                        }}
                        editName={mode==="relativeChat"?false:checkGroupPermissions(chat.group, uid, 'name')}
                        editImage={checkGroupPermissions(chat.group, uid, 'photo')}
                        extraButtons={(chat.group.name !== groupName || imgSrc !== chat.group?.photo) ?
                            [
                                {
                                    icon: FiSave,
                                    onClick: async () => {
                                        setLoad(true);
                                        if (imgSrc !== chat.group?.photo) {
                                            if (imgFile !== undefined) {
                                                const images = await uploadGroupImage(imgFile, id);
                                                setImgSrc(images);
                                            } else {
                                                await DeleteGroupImage(id)
                                            }
                                        }
                                        if (groupName && chat.group?.name !== groupName) {
                                            const n = groupName.trim();
                                            if (n !== '') {
                                                await updateDoc(doc(getFirestore(firebaseApp), 'chats', id), {'group.name': n})
                                            }
                                        }
                                        setLoad(false);
                                    }
                                },
                                {
                                    icon: FiX,
                                    onClick: () => {
                                        setGroupName(chat.group?.name);
                                        setImgSrc(chat.group?.photo)
                                    }
                                }
                            ]
                            : undefined}
                    /> :
                    <ChatHead
                        chatName={chat.users.filter(({uid: _uid}) => uid !== _uid)[0].name}
                        type={"single"}
                        imgUrl={preLoadImg ?? imgSrc}
                    />
                }
                <Container>
                    <div>{chat.allMsgCount ? chat.allMsgCount : 0}{chat.finishd === true ? '' : '+'} Nachrichten</div>
                    {chat.group &&
                        <div>Erstellt
                            von {getUserNameFromGroupByID(chat.group?.createdBy)} am {(moment(chat.group?.createdAt).format('D. MMMM YYYY'))}</div>
                    }
                </Container>
                <ChatUserListManipulate
                    chatId={id}
                    height={height * 0.7}
                />
                <Container style={{paddingTop: 15, paddingBottom: 15}}>
                    {chat.group &&
                        <div>
                            {creator &&
                                <ColorButton style={{marginBottom: 5}} icon={FiTrash2} text={'Gruppe löschen'}
                                             onClick={() =>
                                                 setAlert({
                                                     title: 'Gruppen löschen?',
                                                     text: 'Willst du folgende Gruppen wirklich löschen?',
                                                     item: (<div>{chat.group?.name}</div>),
                                                     onCancel: () => setAlert(undefined),
                                                     onSuccess: async () => {
                                                         setAlert(undefined);
                                                         console.log("del")
                                                         onAction('delete',id);
                                                         /*setLoad(true);
                                                         if (chat.group?.photo !== undefined)
                                                             await DeleteGroupImage(id);
                                                         await deleteDoc(doc(getFirestore(firebaseApp), 'chats', id));
                                                         setLoad(false);
                                                         onSubmit();*/
                                                         //setLoad(false);
                                                     }
                                                 })}
                                />}
                        </div>
                    }
                    {chat.group && !creator &&
                        <ColorButton style={{marginBottom: 5}} icon={FiLogOut} text={'Gruppe verlassen'} onClick={() =>
                            setAlert({
                                title: 'Gruppe verlassen',
                                text: 'Willst du folgende Gruppen wirklich verlassen?',
                                item: (<div>{chat.group?.name}</div>),
                                onCancel: () => setAlert(undefined),
                                onSuccess: async () => {
                                    setAlert(undefined);
                                    onAction('leave',id);
                                    /*setLoad(true);
                                    await updateDoc(doc(getFirestore(firebaseApp), 'chats', id), {
                                        uids: arrayRemove(uid)
                                    })
                                    onSubmit();*/
                                }
                            })}
                        />
                    }
                    {!currentUser.mediCare&&<ArchiveButton/>}
                </Container>
            </div>
            {alert &&
                <AlertDialog  {...alert} />
            }
            <div style={{
                display: 'flex',
                position: "fixed",
                bottom: 20,
                right: 20,
                backgroundColor: 'rgb(248,248,248)',
                boxShadow: '6px 7px 12px 1px rgba(0,0,0,0.73)',
                borderRadius: 5,
                width: 100,
                justifyContent: 'space-around',
                alignItems: 'center',
                opacity: load ? 1 : 0,
                zIndex: load ? 1 : -6,
                transition: 'opacity 0.3s ease'
            }}>
                <ActivityIndicator/> <p>Laden...</p>
            </div>
        </div>
    )
}

export default ChatInfo;
