import React, {CSSProperties, useEffect, useState,memo} from 'react';
import {ActivityIndicator, UserImage} from "components/index";
import styles from './UserList.module.css'
import {getContacts, selectContacts} from "store/chat/actions";
import {FixedSizeList as List} from 'react-window';
import {compareObject} from "lib/helpers";
import {useAppDispatch, useAppSelector} from "store/hooks";
import {t_Contact} from "@pixel-kraft/commulino-types";

type t_props<T> = {
    containerStyle?: CSSProperties,
    itemStyle?: CSSProperties,
    imgStyle?: CSSProperties,
    onClick?: (userID: string, name: string, photo?: string) => void,
    height: number,
    filter?: {
        filter: (item: t_Contact,data: T)=>boolean,
        data: T
    }
}
/**
 * Eine Liste von Benutzern die per react-window gerendert wird um das Init Rednern zu verringern.
 */
const UserList = <T,>({containerStyle, itemStyle, imgStyle, onClick,height, filter}: t_props<T>) => {
    const dispatch = useAppDispatch();
    const uid = useAppSelector((state) => state.auth.uid);
    const users = useAppSelector(selectContacts);
    const [filteredUsers,setFilteredUsers] = useState<t_Contact[]>([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        dispatch(getContacts()).finally(() => setLoading(false));
    }, [dispatch])

    useEffect(()=>{
        if(filter?.data!==undefined){
            setFilteredUsers(users.filter((item)=>filter.filter(item,filter.data)))
        } else
            setFilteredUsers(users);
    },[filter?.data,users])

    if (!uid || users.length <= 0) return null;

    type t_Row = {
        data: any,
        index: number,
        style: React.CSSProperties,
    }

    const areEqual = (prev:t_Row,next:t_Row)=>{
        const erg=compareObject(prev,next);
        return erg;
    }

    const Row = memo(({data, index, style}: t_Row) => {
        //console.log(`Render ${data[index].name}`)
        return (
            <div style={style}>
                <div className={styles.item} style={{...itemStyle}} key={index} onClick={(event) => {
                    event.stopPropagation();
                    if (onClick) onClick(data[index].uid, data[index].name, data[index].photo);
                }}>
                    <UserImage src={data[index].photo} style={{
                        width: 45,
                        height: 45,
                        borderRadius: '50%',
                        backgroundColor: 'lightgray', ...imgStyle
                    }}/>
                    <div className={styles.text}>{data[index].name}</div>
                </div>
            </div>
        )
    },areEqual);
    Row.displayName="UserItem"

    return (
        <div className={styles.container} style={containerStyle}>
            {(loading || height===0) && <ActivityIndicator large/>}
            {!loading && filteredUsers &&
            <List
                height={height}
                itemCount={filteredUsers.length}
                itemData={filteredUsers}
                itemSize={60}
                width={'100%'}
            >
                {Row}
            </List>
            }
        </div>
    )

}
export default UserList;
