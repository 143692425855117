import {
    Filter,
    LOAD_FINISH_MANUEL,
    POSTS_ADD_COUNT_ALL,
    POSTS_ADD_COUNT_GROUP,
    POSTS_ADD_LIKE,
    POSTS_BY_GROUP_MERGE,
    POSTS_BY_GROUP_RECEIVE,
    POSTS_BY_GROUP_REQUEST,
    POSTS_BY_PERSONAL_MSG,
    POSTS_DELETE,
    POSTS_EDIT,
    POSTS_EDIT_ONBOARDING_POST,
    POSTS_EDIT_REQUEST,
    POSTS_END_EDIT_MODE,
    POSTS_HIDE_VISIBLE,
    POSTS_INIT_NEW_POST,
    POSTS_INIT_NEW_POST_ONBOARDING,
    POSTS_MERGE,
    POSTS_ON_EDIT_CHANGE,
    POSTS_PUBLISH,
    POSTS_PUBLISH_REQUEST,
    POSTS_RECEIVE,
    POSTS_REMOVE_LIKE,
    POSTS_REQUEST,
    POSTS_RESET,
    POSTS_SET_FILTER,
    POSTS_SET_VISIBLE,
    POSTS_START,
    POSTS_START_EDIT_MODE,
    POSTS_UPDATE_SURVEY,
    PostsActionTypes,
    SET_COMMENTARIESOVERLAY,
    SET_IMAGEGALLRYOVERLAY,
    SET_LAST_POST_OF,
    t_filterFor,
    t_ImageGalleryOverlay,
    t_postBy,
    UPDATE_POST_HASCOMMENTS,
    SET_SURVEYDETAILS,
    t_surveyPostDetails,
    SET_READCONFERMATION,
    i_cachedDataPost, RELOADPOST
} from './types'
import * as api from 'lib/api'
// import {firestore} from 'lib/firebase'
import {
    Category,
    Department,
    Group,
    Location,
    OnboardingPost,
    Post,
    PostData,
    Survey
} from '@pixel-kraft/commulino-types'
import {AppThunk} from '../store'
import {chunkArray} from "lib/helpers";
import {default as RootState} from '../reducers';
import {firebaseApp} from "lib/firebase";
import {
    getFirestore,
    getDocs,
    collection,
    orderBy,
    where,
    startAfter,
    endBefore,
    limit,
    arrayUnion, updateDoc, doc, arrayRemove, getDoc
} from "firebase/firestore";
import QueryBuilder from "lib/QueryBuilder";
import {deleteVideo, replaceVideo, uploadVideo} from "lib/media";


export const standardPostNum_byGroup = 21;
export const standardPostNum_All = 51;

interface ParseInterface {
    (content: Post['content']): Post['content']
}

const parseContent: ParseInterface = (content) => {
    try {
        return (typeof content === 'string' && content.startsWith('{')) ? JSON.parse(content) : content;
    } catch (err) {
        // If invalid JSON just return as text
        return content as string;
    }
};

//Um zu testen ob alles geladen wurde werden alle Kategorien durchlaufen die in der Config stehen und mit denn Geladenen
//verglichen. Damit das funktioniert müssen alle Kategorien am anfang eingetragen werden.
export const Posts_start = (): AppThunk => {
    return async (dispatch, getState) => {
        dispatch({
            type: POSTS_START,
            cat: getState().config.categories.map(c => c.id),
            dep: getState().config.departments,
            loc: getState().config.locations,
            uid: getState().auth.uid
        })
    }
}


const processPostData =(id:string,postData: PostData,map: Map<string, Survey>):Post=>{
    const {categories} = RootState.getState().config;
    const {content, ...data} = postData;
    const category = categories.find(c => c.id === data.category) || {
        id: data.category,
        icon: '',
        name: ''
    }

    // if (data.survey)
    //     survey_jobs.push({index: i, survey: data.survey});
    let survey: Survey | undefined = undefined;
    if (data.survey) {
        const s = map.get(data.survey);
        if (s) survey = s;
        else
            survey = {
                id: data.survey,
                createdAt: 0,
                answers: [],
                multipleChoice: false,
                question: ""
            }
    }
    return  {
        ...data, content: parseContent(content), category, survey: survey, id
    };
}

/**
 * Führt eine Firestore abfrage aus und wandelt das Ergebnis in einen Post array um.
 * Falls der Post eine Umfrage beinhaltet wir diese nach geladen.
 * @param PostRequest Die auszuführende firestore abfrage
 * @return Ein nach Date sortiertes Postarray der auch die aufgelöste ID von Category und Survey enthält.
 */
const execute_Post_request = async (PostRequest: QueryBuilder) => {
    const request_erg = await getDocs(PostRequest.getQuery());
    const map = RootState.getState().posts.surveyMap;
    const posts: Post[] = [];
    /**
     * Da in der forEach keine async aufgaben aufgerufen werden können
     * werden die SurveyIDs in einem Array gespeichert der dann später
     * durchlaufen wird um die Surveys in das Post Array einzufügen.
     */
    // const survey_jobs:{index:number,survey:string}[]=[];
    //An der Stelle i wird der Post in das array eingefügt
    // let i: number = 0;
    request_erg.forEach((doc) => {
        posts.push(processPostData(doc.id,doc.data() as PostData,map));
    });
    // for(let ui=0;ui<survey_jobs.length;++ui){
    //     const survey_erg=await firestore.collection('surveys').doc(survey_jobs[ui].survey).get();
    //     posts[survey_jobs[ui].index].survey={id: survey_erg.id, ...survey_erg.data()} as Survey;
    // }
    return posts.sort((a, b) => {
        if (a.date < b.date)
            return 1;
        if (b.date < a.date)
            return -1;
        return 0;
    });
}
/**
 * Diese Funktion liefert alle Post zurück die für die übergeben Gruppen verfügbar sind.
 * Fürs erste mal Laden sollte startAfter,endBefore, filter und filterFor undefiniert sein.
 * Um alle neuen Post zuladen muss startAfter undefiniert sein und endBefore muss gesetzt sein.
 * Um Gefilterte Post zuladen muss startAfter gesetzt sein und endBefore undefiniert und Filter,filterFor gesetzt.
 * @param groups Ein Array mit Group namen
 * @param size Die größe in denen groups aufgeteilt werden soll. (Für Firestore abfragen 10)
 * @param PostRequest Die Firestore Abfrage
 * @param mLimit Die maximale Anzahl der Post die die PostRequest zurückliefern soll
 * @param mStartAfter Ein Datum in der UTC in ms. Es werden nur Post nach diesem Datum geladen. Wenn undefiniert werden die neusten Post abgefragt
 * @param mEndBefore Ein Datum in der UTC in ms. Es werden nur Post vor diesem Datum geladen. Wenn undefiniert wird ignoriert
 * @param filter Die ID einer Category,Location oder Department nach der gefiltert werden soll. Wenn undefiniert wird ignoriert
 * @param filterFor Nach was gefiltert werden soll.
 * @return Liefert ein nach Datum sortiertes Post Array zurück
 */
const PostPerGroup = async (groups: string[], size: number
    , PostRequest: QueryBuilder, mLimit: number,
                            mStartAfter?: number, mEndBefore?: number, filter?: Category["id"], filterFor?: t_filterFor) => {
    let erg: Post[] = [];
    const groups_ten = chunkArray(groups, size);
    const forAllGroups = async (req: (g: string[]) => QueryBuilder) => {
        for (const g of groups_ten) {
            const tmp = await execute_Post_request(req(g));
            erg = erg.concat(tmp);
        }

    }
    if (groups.length === 0) {
        PostRequest.add(where('isPublic', '==', true));
        if (mStartAfter && mEndBefore && filter && filterFor) {
            return await execute_Post_request(PostRequest.add(where(filterFor, '==', filter), startAfter(mStartAfter), endBefore(mEndBefore)));
        } else if (mEndBefore && filter && filterFor) {
            return await execute_Post_request(PostRequest.add(where(filterFor, '==', filter), endBefore(mEndBefore)));
        } else if (mStartAfter && filter && filterFor) {
            return await execute_Post_request(PostRequest.add(where(filterFor, '==', filter), startAfter(mStartAfter), limit(mLimit)));
        } else if (mStartAfter && mEndBefore) {
            return await execute_Post_request(PostRequest.add(startAfter(mStartAfter), endBefore(mEndBefore)));
        } else if (mStartAfter) {
            return await execute_Post_request(PostRequest.add(startAfter(mStartAfter), limit(mLimit)));
        } else if (mEndBefore) {
            return await execute_Post_request(PostRequest.add(endBefore(mEndBefore)));
        } else {
            console.error({
                error: 'Diese Abfrage wurde nicht implementiert!',
                groups,
                size,
                PostRequest,
                limit: mLimit,
                startAfter: mStartAfter,
                endBefore: mEndBefore,
                filter,
                filterFor
            })
            throw Error("Diese Abfrage wurde nicht implementiert!");
        }
    }

    if (mLimit === 1 && !mStartAfter && !mEndBefore && !filter && !filterFor)
        await forAllGroups((g: string[]) => PostRequest.addToNew(where('groups', 'array-contains-any', g)
            , limit(mLimit)));
    else if (mStartAfter && !filter)
        await forAllGroups((g: string[]) => PostRequest.addToNew(where('groups', 'array-contains-any', g)
            , startAfter(mStartAfter), limit(mLimit)));
    else if (mEndBefore)
        await forAllGroups((g: string[]) => PostRequest.addToNew(where('groups', 'array-contains-any', g)
            , endBefore(mEndBefore)));
    else if (mStartAfter && filter && filter !== '' && filterFor) {
        await forAllGroups((g: string[]) => PostRequest.addToNew(where('groups', 'array-contains-any', g)
            , where(filterFor, '==', filter), startAfter(mStartAfter), limit(mLimit)));
    } else if (filter && filter !== '' && (filter === 'true' || filter === 'false') && filterFor) {
        await forAllGroups((g: string[]) => PostRequest.addToNew(where('groups', 'array-contains-any', g)
            , where(filterFor, '==', filter === "true"), limit(mLimit)));
    } else if (filter && filter !== '' && filterFor) {
        await forAllGroups((g: string[]) => PostRequest.addToNew(where('groups', 'array-contains-any', g),
            where(filterFor, '==', filter), limit(mLimit)));
    } else if (filter && filter !== '') {
        await forAllGroups((g: string[]) => PostRequest.addToNew(where('groups', 'array-contains-any', g),
            where('category', '==', filter), limit(mLimit)));
    } else
        await forAllGroups((g: string[]) => PostRequest.addToNew(where('groups', 'array-contains-any', g),
            limit(mLimit)));
    return erg;
}

/**
 * Liefert alle Posts die dem Filter entsprechen zurück und begrenzt bei mLimit
 * Wenn mLimit==1 und alle anderen undefiniert wird PostRequest mit mLimit 1 ausgeführt. (Wird benutzt um den ältesten Post zu laden)
 * Wenn mStartAfter definiert und Filter,mEndBefore undefiniert werden maximal mLimit Posts nach mStartAfter geladen
 * Wenn mEndBefore definiert werden alle Posts vor mEndBefore geladen (ignoriert mLimit)
 * Wenn mStartAfter, mFilter ,filterFor definiert werden maximal mLimit Posts nach mStartAfter geladen die dem Filter entsprechen.
 * Wenn mStartAfter undefiniert und filterFor, mFilter definiert dann werden die Neusten Posts die dem Filter entsprechen geladen. (max mLimit)
 * @param PostRequest Der Pfad zur Postsammlung
 * @param mLimit Die maximale Anzahl an Posts
 * @param mStartAfter Ein Datum in der UTC in ms. Es werden nur Post nach diesem Datum geladen. Wenn undefiniert werden die neusten Post abgefragt
 * @param mEndBefore Ein Datum in der UTC in ms. Es werden nur Post vor diesem Datum geladen. Wenn undefiniert wird ignoriert
 * @param mFilter Die id des Filters (Kategorie,Standort,Abteilung id)
 * @param filterFor Nach was gefiltert werden soll (nötig da Standort und Abteilung ein Array sind)
 * @param opStr Vergleichsoperator (bei Category ==,sonst array-contains)
 */
const getAllPost = async (PostRequest: QueryBuilder, mLimit: number,
                          mStartAfter?: number, mEndBefore?: number, mFilter?: string, filterFor?: t_filterFor, opStr: '==' | 'array-contains' = '==') => {
    let erg: Post[] = [];
    const Post_start = PostRequest/*.where('isPersonalMessage','==',false)*/
    if (mLimit === 1 && !mStartAfter && !mEndBefore && !mFilter && !filterFor) {
        const tmp = await execute_Post_request(Post_start.add(limit(mLimit)));
        erg = erg.concat(tmp);
    } else if (mStartAfter && !mFilter && !mEndBefore) {
        const tmp = await execute_Post_request(Post_start.add(startAfter(mStartAfter), limit(mLimit)));
        erg = erg.concat(tmp);

    } else if (mEndBefore) {
        const tmp = await execute_Post_request(Post_start.add(endBefore(mEndBefore)));
        erg = erg.concat(tmp);
    } else if (mStartAfter && mFilter && filterFor) {
        const tmp = await execute_Post_request(Post_start.add(where(filterFor, opStr, mFilter), startAfter(mStartAfter), limit(mLimit)));
        erg = erg.concat(tmp);
    } else if (!mStartAfter && mFilter && filterFor) {
        const tmp = await execute_Post_request(Post_start.add(where(filterFor, opStr, mFilter), limit(mLimit)));
        erg = erg.concat(tmp);
    } else {
        const tmp = await execute_Post_request(Post_start.add(limit(mLimit)));
        erg = erg.concat(tmp);
    }
    return erg;
}

/**
 * Entscheidet ob alle Posts oder nur Posts für die user Gruppe geladen werden sollen.
 * @param limit Die Maximal Anzahl an Posts die geladen werden soll.
 * @param startAfter Die Posts die ein Datum haben das vor startAfter liegt werden geladen
 * @param groups Wenn definiert werden Posts für die user Gruppe geladen.
 * @param filter Falls definiert wird nach dieser ID verglichen
 * @param filterFor Falls definiert wird hier nach gefiltert.
 */
const getMorePosts = async (limit: number = 21, startAfter?: number, groups?: Group[], filter?: string, filterFor?: t_filterFor) => {
    let PostRequest_start = new QueryBuilder(collection(getFirestore(firebaseApp), 'posts'), orderBy('date', "desc"));
    // if(filterFor!=="isPersonalMessage")
    //     PostRequest_start=PostRequest_start.where('isPersonalMessage','==',false);
    if (groups) {
        return await PostPerGroup(groups, 10, PostRequest_start.add(where('date', '<=', Date.now())),
            limit, startAfter, undefined, filter, filterFor);
    }
    return await getAllPost(PostRequest_start, limit, startAfter, undefined, filter, filterFor, filterFor !== 'category' && filterFor !== 'author.uid' ? 'array-contains' : '==');
};

/**
 * Lädt alle Post vor endBefore
 * @param endBefore
 * @param groups
 */
const getDifferenz = async (endBefore: number, groups?: Group[], startAfter?: number) => {
    const PostRequest_start = new QueryBuilder(collection(getFirestore(firebaseApp), 'posts'), orderBy('date', "desc"));
    if (groups) {
        return await PostPerGroup(groups, 10, PostRequest_start.add(where('date', '<=', Date.now())),
            0, startAfter, endBefore);
    }
    return await getAllPost(PostRequest_start, 0, undefined, endBefore);
}

export const getPosts = (): AppThunk => {
    return async (dispatch, getState) => {
        dispatch({type: POSTS_REQUEST});
        if (getState().posts.lastPostID.all === '') {
            const erg = await LastPostsOf();
            dispatch({type: SET_LAST_POST_OF, of: 'all', id: erg.id});
        }
        if (getState().posts.posts.length === 0) {
            //dispatch(Posts_start());
            const edit = getState().auth.permissions.posts?.edit;
            const group = edit === 'group' ? getState().auth.groups : undefined;
            let filter: string | undefined;
            if (edit === 'own') {
                const tmp = getState().auth.uid;
                if (tmp !== null)
                    filter = tmp;
            }
            const posts = await getMorePosts(standardPostNum_All, undefined, group, filter, filter ? "author.uid" : undefined);
            dispatch({type: POSTS_RECEIVE, posts});
        } else {
            const posts = await getDifferenz(getState().posts.posts[0].date);
            if (posts && posts.length > 0)
                dispatch({type: POSTS_MERGE, posts});
            else
                dispatch({type: LOAD_FINISH_MANUEL});
        }
    };
}

export const reloadPost =(postId:string):AppThunk=> {
    return async (dispatch,getState)=>{
        if(!postId) throw new Error("Post id is empty!");
        const post=await getDoc(doc(getFirestore(firebaseApp), 'posts',postId));
        dispatch({type: RELOADPOST,post: processPostData(post.id,post.data() as PostData,getState().posts.surveyMap)});

    }
}


/**
 * Fragt die nächsten gefilterte Posts ab und setzt von der jeweiligen Category categoryComplete wenn es keine weiteren
 * Posts in dieser Category gibt
 * @param filter_ID Die ID einer Category ohne den Prefix 'A_' oder 'G_'
 * @param all Bestimmt ob alle posts geladen werden sollen oder nur die mit einer Gruppe in die auch der User ist.
 * @param end Bestimmt ob nach dem letzten Element im Post Array geladen werden soll oder nach der Standard länge.
 */
export const getNextFilteredPosts = (filter_ID: string, filterFor?: t_filterFor, all: boolean = false, end = true, customLength?: number): AppThunk => {
    return async (dispatch, getState) => {
        const {uid, isLoggedIn, groups} = getState().auth;
        if (isLoggedIn && uid !== null) {
            const tmp = all ? getState().posts.posts : getState().posts.postsByGroup;
            if (tmp.length === 0) return;
            const isComplete = (m_cat: t_postBy) => {
                const cat = all ? getState().config.categories.map(c => c.id).concat(getState().config.departments).concat(getState().config.locations) : getState().config.categories.map(c => c.id);
                for (let i = 0; i < cat.length; ++i) {
                    if (!m_cat[(all ? 'A_' : 'G_' + cat[i])]?.complete)
                        return false;
                }
                return true;
            }
            const orderBYCat = filterFor === 'category' ? getState().posts.postsByCategory : filterFor === 'departments' ? getState().posts.postsByDepartment : getState().posts.postsByLocation;
            const getLastDateOFCategory = (filter: string, postByCat: t_postBy) => {
                if (filter === 'A_' || filter === 'G_') return -1;
                if (filter in postByCat && postByCat[filter].posts.length > 0)
                    return postByCat[filter].posts[postByCat[filter].posts.length - 1].date;
                return -1;
            }


            dispatch({type: all ? POSTS_REQUEST : POSTS_BY_GROUP_REQUEST});
            const multi = all ? getState().posts.postLoadCountAll : getState().posts.postLoadCountGroup;
            const count = customLength ? customLength : all ? standardPostNum_All : standardPostNum_byGroup;
            const d = getLastDateOFCategory((all ? 'A_' + filter_ID : 'G_' + filter_ID), orderBYCat);
            const date = tmp[(multi * count) - 1] ? tmp[(multi * count) - 1].date : tmp[tmp.length - 1].date;
            const last = filter_ID === '' ? date : d;
            const posts = await getMorePosts(count * (end ? 1 : 2),
                (last === -1 ? undefined : last),
                all ? undefined : filterFor === 'isPersonalMessage' ? [uid] : groups.concat([uid]), filter_ID === '' ? undefined : filter_ID, filterFor);
            dispatch({
                type: all ? POSTS_MERGE : POSTS_BY_GROUP_MERGE,
                posts,
                complete_All: (filter_ID === '' && posts.length < (count)) ? true : isComplete(orderBYCat),
                category: (all ? 'A_' + filter_ID : 'G_' + filter_ID),
                complete_cat: (filter_ID !== '' && posts.length < (count)),
                context: filterFor,
            });
            if (filter_ID === '') {
                dispatch({type: all ? POSTS_ADD_COUNT_ALL : POSTS_ADD_COUNT_GROUP})
            }
        }
    }
}

export const getNextPosts = (): AppThunk => {
    return async (dispatch, getState) => {
        const {uid, isLoggedIn} = getState().auth;
        if (isLoggedIn && uid !== null) {
            dispatch({type: POSTS_REQUEST});
            const lastDate = getState().posts.posts.length > 0 ? getState().posts.posts[getState().posts.posts.length - 1].date : undefined;
            const posts = await getMorePosts(standardPostNum_All, lastDate);
            if (posts)
                dispatch({type: POSTS_MERGE, posts, complete_All: posts.length < standardPostNum_All});
            else
                throw new Error("Old post dont load");
        } else
            throw new Error("The user is not login!");
    }
}

export const getNextPostsByGroup = (): AppThunk => {
    return async (dispatch, getState) => {
        const {uid, isLoggedIn, groups} = getState().auth;
        if (isLoggedIn && uid !== null) {
            dispatch({type: POSTS_BY_GROUP_REQUEST});
            const lastDate = getState().posts.postsByGroup[getState().posts.postsByGroup.length - 1].date;
            const posts = await getMorePosts(standardPostNum_byGroup, lastDate, groups.concat([uid]));
            if (posts)
                dispatch({type: POSTS_BY_GROUP_MERGE, posts, complete: posts.length < standardPostNum_byGroup});
            else
                throw new Error("Old post dont load");
        } else {
            throw new Error("The user is not login!");
        }
    }
}


/**
 * Lädt denn ältesten Posts von der Gruppe des users oder von allen Posts.
 * @param groups Falls definiert wird von dieser Gruppe der älteste Posts geladen
 */
const LastPostsOf = async (groups?: string[]) => {
    const Posts_start = new QueryBuilder(collection(getFirestore(firebaseApp), 'posts'), orderBy('date', "asc"));
    if (groups) {
        const erg = await PostPerGroup(groups, 10, Posts_start, 1);
        return erg.sort((a, b) => {
            if (a.date < b.date)
                return -1;
            if (b.date < a.date)
                return 1;
            return 0;
        })[0];
    }
    const erg = await getAllPost(Posts_start, 1)
    return erg[0];
}

/**
 * Lädt posts die in der user Gruppe sind, gefiltert bei filterFor nach filter
 * @param filter Die ID nach der gefiltert werden soll.
 * @param load_new Wenn gesetzt werden die neusten Posts der user Gruppe geladen
 * @param filterFor Nach welchen Feld gefiltert werden soll.
 */
export const getPostsByGroup = (filter?: string, load_new: boolean = false, filterFor?: t_filterFor, customLength?: number): AppThunk => {
    return async (dispatch, getState) => {
        dispatch({type: POSTS_BY_GROUP_REQUEST})
        const {uid, isLoggedIn, groups} = getState().auth;
        if (isLoggedIn && uid !== null) {
            if (getState().posts.lastPostID.group === '') {
                const lastPost = await LastPostsOf(groups);
                if (lastPost)
                    dispatch({type: SET_LAST_POST_OF, of: 'group', id: lastPost.id});
            }
            const count = (customLength ? customLength : standardPostNum_byGroup);
            if (filter) {
                const postsByCat = getState().posts.postsByCategory['G_' + filter];
                if (postsByCat) {
                    if (postsByCat.posts.length < count && !postsByCat.complete && postsByCat.posts.length > 0) {
                        const posts = await getMorePosts(count, postsByCat.posts[postsByCat.posts.length - 1].date, filterFor === 'isPersonalMessage' ? [uid] : groups.concat([uid]), filter, filterFor);
                        dispatch({type: POSTS_BY_GROUP_MERGE, posts, cat_ID: filter, complete: posts.length < count});
                    }
                } else {
                    const posts = await getMorePosts(count, undefined, filterFor === 'isPersonalMessage' ? [uid] : groups.concat([uid]), filter, filterFor);
                    dispatch({
                        type: POSTS_BY_GROUP_RECEIVE,
                        posts,
                        cat_ID: filter,
                        complete: posts.length < standardPostNum_byGroup
                    });
                }
            }
            if (load_new || getState().posts.postsByGroup.length === 0) {
                const posts = await getMorePosts(count, undefined, filterFor === 'isPersonalMessage' ? [uid] : groups.concat([uid]), filter, filterFor);
                if (filter)
                    dispatch({type: POSTS_BY_GROUP_RECEIVE, posts, cat_ID: filter, complete: posts.length < count});
                else
                    dispatch({type: POSTS_BY_GROUP_RECEIVE, posts});
            } else {
                const posts = await getDifferenz(getState().posts.postsByGroup[0].date, groups);
                if (posts && posts.length > 0)
                    dispatch({type: POSTS_BY_GROUP_MERGE, posts});
                else
                    dispatch({type: LOAD_FINISH_MANUEL});
            }
        } else {
            throw new Error("Der User ist nicht eingeloggt");
        }
    }
}

export const getPersonalPosts = (): AppThunk => {
    return async (dispatch, getState) => {
        const uid = getState().auth.uid;
        const groups = getState().auth.groups;
        if (uid === null) throw Error("User is not login!");
        let post: Post[];
        if (uid in getState().posts.postsByPersonalMsg) {
            const pre = getState().posts.postsByPersonalMsg[uid];
            const length = pre.posts.length;
            post = await getMorePosts(standardPostNum_byGroup, length > 0 ? pre.posts[length - 1].date : undefined, groups.concat([uid]), 'true', 'isPersonalMessage');

        } else {
            //throw Error("Personal msg for "+uid+" is not set");
            post = await getMorePosts(standardPostNum_byGroup, undefined, groups.concat([uid]), 'true', 'isPersonalMessage');
        }
        dispatch({
            type: POSTS_BY_PERSONAL_MSG, posts: post,
            complete: post.length < standardPostNum_All,
            uid: uid,
        })
    }
}

interface FilterData {
    category?: Category['id']
    department?: Department
    location?: Location
}

export const filterPosts = (filterData: FilterData): AppThunk => {
    return (dispatch, getState) => {
        const {
            config: {categories}
        } = getState()
        let filter: Filter = {category: null, location: null, department: null};
        if (filterData.location) {
            filter = {...filter, location: filterData.location};
        } else if (filterData.department) {
            filter = {...filter, department: filterData.department};
        } else if (filterData.category) {
            const cat = categories.find(c => c.name === filterData.category);
            if (cat)
                filter = {...filter, category: cat};
        }
        dispatch({type: POSTS_SET_FILTER, filter})
    }
}

export const setPostVisible = (state: boolean, id?: string): PostsActionTypes => {
    if (state && id) {
        return {
            type: POSTS_SET_VISIBLE,
            id
        }
    } else {
        return {
            type: POSTS_HIDE_VISIBLE
        }
    }
}

export const likePost = (id: string): AppThunk => {
    return async (dispatch, getState) => {
        const {
            auth: {uid}
        } = getState()

        dispatch({type: POSTS_ADD_LIKE, id, uid})

        await updateDoc(doc(getFirestore(firebaseApp), 'posts', id), {
            likes: arrayUnion(uid)
        })
    }
}

export const removeLike = (id: string): AppThunk => {
    return async (dispatch, getState) => {
        const {
            auth: {uid}
        } = getState()
        dispatch({type: POSTS_REMOVE_LIKE, id, uid})

        await updateDoc(doc(getFirestore(firebaseApp), 'posts', id), {
            likes: arrayRemove(uid)
        })
    }
}

export const resetPosts = (): AppThunk => {
    return dispatch => {
        dispatch({type: POSTS_RESET})
    }
}

export const initNewPost = (): AppThunk => {
    return async (dispatch, getState) => {
        const {
            auth: {name, uid},
            config: {categories}
        } = getState()

        if (!name || !uid) return
        const comment = getState().auth.permissions.posts?.comments?.create_post;
        const id = doc(collection(getFirestore(firebaseApp), 'posts')).id; //doc().id
        const post: Post = {
            id,
            author: {name, uid},
            createdAt: Date.now(),
            date: Date.now(),
            category: categories[0],
            title: '',
            content: '',
            departments: [],
            locations: [],
            isPublic: false,
            isPersonalMessage: false,
            likes: [],
            pushEnabled: true,
            notificationSent: false
        }
        if (comment)
            post.hasComments = false;
        dispatch({type: POSTS_INIT_NEW_POST, id, post})
    }
}

const uploadVimeo = async (data: i_cachedDataPost) => {
    if (data.vimeoCache) {
        data.vimeo = await uploadVideo(data.vimeoCache);
        data.hidden = true;
        data.vimeoCache = undefined; //Undefined will be not pusht to firebase
    }

    if (data.vimeo?.id === "-1") throw new Error("Vimeo id is -1!!!!!!");
}

const replaceVimeo = async (data: i_cachedDataPost) => {
    if (data.vimeoCache && data.vimeo) {
        data.vimeo = await replaceVideo(data.vimeoCache, data.vimeo.id);
        data.hidden = true;
        data.vimeoCache = undefined; //Undefined will be not pusht to firebase
    }

    if (data.vimeo?.id === "-1") throw new Error("Vimeo id is -1!!!!!!");
}

export const publish = (): AppThunk => {
    return async (dispatch, getState) => {
        dispatch({type: POSTS_PUBLISH_REQUEST})

        const {
            posts: {
                editing: {data}
            }
        } = getState()


        if (data) {
            await uploadVimeo(data);
            await api.post('posts', data);
            dispatch({type: POSTS_MERGE, posts: [data]});
        }
        dispatch({type: POSTS_PUBLISH})
    }
}

export const saveEdit = (): AppThunk => {
    return async (dispatch, getState) => {
        dispatch({type: POSTS_EDIT_REQUEST})

        const {
            posts: {
                editing: {data}
            }
        } = getState()

        if (data) {
            const oldVimeo = getState().posts.byId[data.id].vimeo?.id;
            if (oldVimeo && data.vimeoCache)
                await replaceVimeo(data);
            else if (!oldVimeo && data.vimeoCache)
                await uploadVimeo(data);
            else if (oldVimeo&&oldVimeo!==data.vimeo?.id)
                await deleteVideo(oldVimeo);

            await api.put('posts', data)
            dispatch(reloadPost(data.id));
        }


        dispatch({type: POSTS_EDIT})
    }
}

export const toggleEditMode = (state: boolean, id?: string): PostsActionTypes => {
    if (state && id) {
        return {
            type: POSTS_START_EDIT_MODE,
            id
        }
    } else {
        return {
            type: POSTS_END_EDIT_MODE
        }
    }
}

export const onEditChange = (data: Partial<i_cachedDataPost>) => {
    return {
        type: POSTS_ON_EDIT_CHANGE,
        change: data
    }
}

export const updateSurvey = (postID: string, survey: Survey): AppThunk => {
    return async dispatch => {
        dispatch({type: POSTS_UPDATE_SURVEY, postID, survey})
    }
}

export const deletePost = (id: string): AppThunk => {
    return async dispatch => {
        dispatch({type: POSTS_REQUEST});
        await api.delete(`posts/${id}`)

        dispatch({type: POSTS_DELETE, id})
    }
}

export const initNewOnboardingPost = (index: number): AppThunk => {
    return async (dispatch, getState) => {
        const {
            auth: {name, uid}
        } = getState()

        if (!name || !uid) return
        const id = doc(collection(getFirestore(firebaseApp), 'posts')).id;

        const post: OnboardingPost = {
            index,
            content: '',
            title: '',
            author: {name, uid},
            createdAt: Date.now(),
            pushEnabled: true
        }

        dispatch({type: POSTS_INIT_NEW_POST_ONBOARDING, id, post})
    }
}

export const editOnboardingPost = (index: number): AppThunk => {
    return async (dispatch, getState) => {
        const {
            onboarding: {editing}
        } = getState()

        if (!editing) return

        const post = editing.data.posts.find(post => post.index === index)

        if (post) dispatch({type: POSTS_EDIT_ONBOARDING_POST, post})
    }
}

export const updatePostHasComments = (postID: string, update: boolean): AppThunk => {
    return async (dispatch) => {
        dispatch({type: UPDATE_POST_HASCOMMENTS, postID, update});
    }
}

export const setImageGalleryOverlay = (imageGalleryOverlay: t_ImageGalleryOverlay): AppThunk => {
    return async dispatch => dispatch({type: SET_IMAGEGALLRYOVERLAY, imageGalleryOverlay})
}

export const setCommentariesOverlay = (commentariesOverlay?: Post): AppThunk => {
    return async dispatch => dispatch({type: SET_COMMENTARIESOVERLAY, commentariesOverlay})
}
export const setSurveyDetails = (surveyDetails: t_surveyPostDetails): AppThunk => {
    return async dispatch => dispatch({type: SET_SURVEYDETAILS, surveyDetails});
}

export const setReadConformation = (postID: string, read: boolean): AppThunk => {
    return async dispatch => dispatch({type: SET_READCONFERMATION, postID, read});
}
