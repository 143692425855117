import React, {lazy} from 'react';
import MessageImgElm from "./MessageImgElm";
import {MessengerImage} from "@pixel-kraft/commulino-types";

const MessageForwardImage=lazy(()=>import('./MessageForwardImage'))

type t_props = {
    data:MessengerImage[],
    chatID:string,
    id: string,
    forwarded: boolean,
    imgOnClick?: (index:number) => void,
    getCursor: (func: any) => React.CSSProperties,
    imgStyle?: React.CSSProperties,
    maxWidth?: number
}

const forwardComplete=(imgs:MessengerImage[],chatID:string)=>{
    for(let i=0;i<imgs.length;++i){
        if(!imgs[i].uri.includes(chatID))
            return false;
    }
    return true;
}

const MessageImage=({chatID,data,id,forwarded,imgOnClick,imgStyle,getCursor,maxWidth}:t_props)=>{
    if(forwarded&&forwardComplete(data,chatID)) return <MessageForwardImage data={data} maxWidth={maxWidth} getCursor={getCursor} id={id} chatID={chatID} imgStyle={imgStyle} imgOnClick={imgOnClick} />;
    else return <MessageImgElm maxWidth={maxWidth} imgOnClick={imgOnClick} data={data} loading={false} imgStyle={imgStyle} getCursor={getCursor} />
}

export default MessageImage;