import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "store/hooks";
import {

    getContacts,
    selectContacts
} from "store/chat/actions";
import {ResRelSDK} from 'lib/sdk';
import {ActivityIndicator} from "components";

import ChatUserItem from "./ChatUserItem";
import {t_Contact} from "@pixel-kraft/commulino-types";

type t_props = {
    mode?: 'users' | 'resident' | 'all',
    selectedUser: t_Contact[],
    onChangeUser: (selected: t_Contact[]) => void,
    search?: string

}

/**
 * A Fullscreen ChatUser Selector. Used the Contacts from RestApi.
 * @param mode Difine with user should be shown. all=All | users=Only User that are not Res or Rel | resident= Res or Rel
 * @param selectedUser Array of selected Users.
 * @param onChangeUser Callback for changes in users
 * @param search Name to filter the users for
 * @constructor
 */
const ChatUserSelector = ({
                              mode = 'all',
                              selectedUser,
                              onChangeUser,
                              search
                          }: t_props) => {

    const [loading, setLoading] = useState(false);
    const contacts = useAppSelector(selectContacts);
    const [selectFrom, setSelectFrom] = useState<t_Contact[]>([]);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getContacts()).then(() => {
            let sel: t_Contact[] = [];
            switch (mode) {
                case 'all':
                    sel = contacts.filter((user) => !ResRelSDK.isRel(user));
                    break;
                case 'users':
                    sel = contacts.filter(ResRelSDK.isUser);
                    break;
                case 'resident':
                    sel = contacts.filter((res) => ResRelSDK.isRes(res) || ResRelSDK.isRel(res));
                    break;
            }
            setSelectFrom(sel);
        }).finally(() => setLoading(false))

    }, [mode, dispatch, contacts])


    const manipulateArray = <T extends { uid: string }, >(arr: T[], val: T, add: boolean, searchFunc?: (val: T[]) => number) => {
        if (add) {
            return [...arr, val];
        }
        const tmp = [...arr];
        tmp.splice(searchFunc ? searchFunc(tmp) : tmp.indexOf(val), 1)
        return tmp;
    }

    const changeUser = (user: t_Contact, add: boolean) => {
        onChangeUser(manipulateArray(selectedUser, user, add));
    }


    const isChecked = (arr: t_Contact[], item: t_Contact) => !!arr.find(({uid}) => item.uid === uid);

    const renderItem = (item: t_Contact) => (
        <ChatUserItem key={item.uid} style={{backgroundColor: 'transparent'}}
                      checked={isChecked(selectedUser, item)}
                      onChange={(add) => changeUser(item, add)} user={item}/>
    )


    return (
        <div style={{flex: 1, overflowY: 'auto', height: 'calc(100% - 70px)'}}>
            {loading && <ActivityIndicator large/>}
            {ResRelSDK.sortContacts(selectFrom).filter(({name})=>search?name.toUpperCase().includes(search.toUpperCase()):true).map(renderItem)}
        </div>
    )
}

export default ChatUserSelector;
