import React, { useLayoutEffect, useRef, useState } from 'react'
import styles from './Tooltip.module.css'

interface Props {
	visible: boolean
	hide: () => void
	children: React.ReactNode
}

const Tooltip = ({ visible, hide, children }: Props) => {
	const contentRef = useRef<HTMLDivElement>(null)
	const [offsetLeft, setOffsetLeft] = useState(0)

	useLayoutEffect(() => {
		const width = contentRef.current?.clientWidth
		if (width) setOffsetLeft(width / 2)
	}, [visible])

	if (!visible) return null

	return (
		<div>
			<div className={styles.container} style={{ left: `-${offsetLeft}px` }}>
				<div className={styles.content} ref={contentRef}>
					{children}
				</div>
			</div>
			<div className={styles.overlay} onClick={hide}></div>
		</div>
	)
}

export default Tooltip
