import React, {memo} from 'react';
import memoize from 'memoize-one';
import {FixedSizeList as List, areEqual} from 'react-window';
import {User} from '@pixel-kraft/commulino-types';
import {CheckBox, UserImage} from 'components/index';
import styles from './UserSelect.module.css';
//react-window rendert nur die Items die Aktuell zusehen sind. (Am besten wenn alle Items die gleiche Größe haben)

type t_Row = {
    data: any,
    index: number,
    style: React.CSSProperties,
}

type t_user_props<T> = {
    user: T,
}
/**
 * Rendert User mit Bild und Name
 * @param props
 */
const UserItem= <T extends {photo?: string,name: string},>(props: t_user_props<T>) => {
    return (
        <div style={{display: "flex", alignItems: 'center'}}>
            <UserImage src={props.user.photo}
                       style={{width: 40, height: 40, borderRadius: '50%', objectFit: 'cover', marginRight: 10}}/>
            {props.user.name}
        </div>
    )
}
/**
 * Rendert die Zeile mit einer Checkbox und UserItem
 */
const Row = memo(({data, index, style}: t_Row) => {
    const {items, toggleItemActive} = data;
    const item = items[index];
    return (
        <div style={{...style}}>
            <div
                className={styles.row}
                onClick={() => toggleItemActive(index)}>
                <CheckBox checked={item.isSelected} onChange={() => {
                    toggleItemActive(index);
                }}/>
                <UserItem user={item.data}/>
            </div>
        </div>
    );
}, areEqual);
/**
 * Erzeugt ein Object aus items und einer Function die den State eines
 * Object in items ändert.
 */
const createItemData = memoize((items, toggleItemActive) => ({
    items,
    toggleItemActive,
}));

type t_renderList = {
    height: number,
    items: any,
    toggleItemActive: any,
    width: React.ReactText,
}
/**
 * Rendert eine Liste die nur die Objekte Rendert die grade zu sehen sind.
 * Mithilfe der toggleItemActive funktioniert können Object nochmal gerendert werden, falls
 * sich z.B. der State des Objects ändern wurde.
 */
const RenderList = ({height, items, toggleItemActive, width}: t_renderList) => {
    const itemData = createItemData(items, toggleItemActive);
    return (
        <List
            height={height}
            itemCount={items.length}
            itemData={itemData}
            itemSize={50}
            width={width}
        >
            {Row}
        </List>
    );
}

interface i_Item<T extends t_minType> {
    data: T,
    isSelected: boolean,
}


type t_minType = {name: string,uid: string,photo?: string};

interface i_props<T extends t_minType> {
    height: number,
    users: T[],
    selected?: T[],
    onSelect: (selected: T[])=>void,
    listContainerStyle?: React.CSSProperties,
    listContainerClassName?: string,
}

/**
 * Redernt eine Liste aus auswählbaren Usern.
 * Es werden nur User gerendert wenn sie zu sehen sind und sie werden nur
 * aktualisiert wenn die Checkbox gesetzt wird.
 * @param onSubmit Eine Function die beim Submit mit den Ausgewählten Usern ausgeführt wird
 */
const UserSelect = <T extends t_minType>(props: i_props<T>) => {
    const items:i_Item<T>[]=props.selected && props.selected.length > 0 ? props.users.map(user => {
            return {data: user, isSelected: props.selected?.filter(u => u.uid === user.uid).length === 1}
        }) : props.users.map(user => {
            return {data: user, isSelected: false}
        });

    const toggleItemActive = (index: number) => {
        props.onSelect([...items.flatMap(({isSelected,data})=>isSelected?[data]:[]),items[index].data])
    }

    return (
        <div style={{position: 'relative', display: 'flex', flexFlow: 'column'}}>
            <div style={{width: '100%', paddingTop: 5, ...props.listContainerStyle}}
                 className={props.listContainerClassName}>
                <RenderList height={props.height} width={'100%'} items={items}
                            toggleItemActive={toggleItemActive}/>
            </div>
        </div>

    )
};

export default UserSelect;
