import React, {useEffect, useRef, useState} from 'react';
import {Row} from "components";

type t_props = {
    children: React.ReactNode[],
    defaultCount?: number
    itemWidth: number,
    itemHeight?: number,
    justifyContent?: React.CSSProperties['justifyContent'],
}

const genArray = (count: number) => {
    const erg: number[] = [];
    for (let i = 0; i < count; ++i)
        erg.push(i);
    return erg;
}

/**
 * Wraps children in a Parent div with itemWidth and itemHeight. Will Fill Row width dummy Items
 * @param itemWidth width of Parent div
 * @param defaultCount If the last elements flicker define a default value (Math.floor(width/itemWidth))
 * @param children To Render content
 * @param itemHeight Height of Parent div
 * @param justifyContent (default) space-between
 */
const RowFill = ({itemWidth, children, itemHeight, justifyContent = 'space-between',defaultCount}: t_props) => {
    const [count,setCount] = useState(defaultCount??children.length);

    const ref= useRef<HTMLDivElement>(null);

    useEffect(()=>{
        if(ref.current){
            const obv = new ResizeObserver(([row])=>{
                setCount( Math.floor(row.contentRect.width/itemWidth))
            });
            obv.observe(ref.current);
            return () => {
                obv.disconnect()
            }
        }

    },[ref.current,itemWidth])

    return (
        <Row ref={ref} flex={1} flexFlow={'row wrap'} justifyContent={justifyContent}>
            {children.map((child, index) =>
                <div
                    key={`child_${index}`}
                    style={{width: itemWidth, height: itemHeight}}>
                    {child}
                </div>)
            }
            {genArray(children.length % count).map((i) =>
                <div
                    key={`dummy_${i}`}
                    style={{width: itemWidth, height: itemHeight, visibility: 'hidden'}}
                />
            )}
        </Row>
    )
}


export default RowFill;
