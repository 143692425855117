import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/reducers'
import { filterPosts } from 'store/posts/actions'
import { Dropdown, FormItem, Group } from 'components'

interface Props {
	searchTerm: string,
	setSearchTerm: (term: string) => void
}

const PostsFilter = ({ searchTerm, setSearchTerm }: Props) => {
	const dispatch = useDispatch()
	const filter = useSelector((state: RootState) => state.posts.filter)
	const { categories, departments, locations } = useSelector((state: RootState) => state.config)

	return (
		<Group margin={4}>
			<Dropdown
				name='Kategorien filtern'
				options={categories}
				onChange={category => dispatch(filterPosts({ category }))}
				value={filter.category?.name}
			/>
			<Dropdown
				name='Abteilungen filtern'
				options={departments.map(department => ({ name: department }))}
				onChange={department => dispatch(filterPosts({ department }))}
				value={filter.department}
			/>
			<Dropdown
				name='Standorte filtern'
				options={locations.map(location => ({ name: location }))}
				onChange={location => dispatch(filterPosts({ location }))}
				value={filter.location}
			/>
			<FormItem
				type='text'
				value={searchTerm}
				placeholder='Suchen...'
				onChange={setSearchTerm}
				style={{ maxWidth: 220, borderColor: '#ccc', marginTop: 0 }}
			/>
		</Group>
	)
}

export default PostsFilter
