import React from 'react'
import Typo from './Typo'
import styles from './ErrorMessageInline.module.css'

interface Props {
	text: string
	visible: boolean
	style?: string
}

const ErrorMessageInline = ({ text, visible, style }: Props) => {
	return (
		<div className={`${styles.container} ${visible && styles.visible} ${style}`}>
			<Typo style={styles.text}>{text}</Typo>
		</div>
	)
}

export default ErrorMessageInline
