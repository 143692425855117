import React from 'react'
import { FiDownload } from 'react-icons/fi'
import jsPDF from 'jspdf'
import { Button } from '../../components'
import { appName } from 'config'

interface UserDetails {
	name: string,
	code: string,
	personnelId?: string,
	addText?: string,
	onClick?: ()=>void
}

const generatePdf = ({ name, code, personnelId }: UserDetails) => {
	const doc = new jsPDF()

	doc.setFontSize(24)
	doc.text(name, 15, 15)
	doc.setFontSize(18)
	if (personnelId) doc.text(personnelId, 15, 25)
	doc.setFontSize(34)
	doc.text('Herzliche Einladung', 15, 50)
	doc.text('zu unserer Mitarbeiter-App', 15, 65)
	doc.setFontSize(24)
	doc.text('Hier ist Dein Registrierungscode:', 15, 100)
	doc.setFontSize(34)
	doc.text(code, 15, 120)
	doc.setFontSize(18)
	doc.text('So kommt die App auf Dein Smartphone:', 15, 150)
	doc.setFontSize(12.5)
	doc.text(
		'1. Gehe in Deinem Smartphone in den App Store (iPhone) oder Google Play (Android).',
		15,
		160
	)
	doc.text(`2. Benutze das Suchfeld im Store und suche nach "${appName}".`, 15, 170)
	doc.text('3. Installiere kostenlos die App.', 15, 180)
	doc.text('4. Öffne die App auf Deinem Smartphone.', 15, 190)
	doc.text(
		'5. Registriere Dich mit dem "Registrierungscode". Dabei wirst Du nach einer E-Mail-Adres-',
		15,
		200
	)
	doc.text(
		'    se gefragt. Die E-Mail-Adresse wird ausschließlich benötigt, um Dein Passwort wieder-',
		15,
		210
	)
	doc.text(
		'    herstellen zu können, falls Du es mal vergessen hast. Ansonsten ist die E-Mail-Adresse',
		15,
		220
	)
	doc.text('    nicht sichtbar.', 15, 230)
	doc.text(
		'6. Schließe die Registrierung ab, indem Du in der Bestätigungs-Email den Aktivierungslink',
		15,
		240
	)
	doc.text('    anklickst.', 15, 250)

	doc.save(`${name.replace(/[. -]/g, '_')}_Registrierungscode.pdf`)
}

const CodePdfGen = ({ name, code, personnelId, addText = '', onClick }: UserDetails) => (
	<div>
		<Button
			light
			text={`PDF erzeugen${addText}`}
			icon={FiDownload}
			onClick={() => {
				generatePdf({ name, code, personnelId })
				if(onClick) onClick();
			}}
		/>
	</div>
)

export default CodePdfGen
