import {infoDev, logDev} from "lib/log";
import {addDoc, arrayUnion, collection, doc, getDocs, getFirestore, query, updateDoc, where} from "firebase/firestore";
import {AppThunk} from "store/store";
import {arrayIncludesAny, filterTypeChange, isDev} from "lib/helpers";
import {t_ChatContact, t_chatTypes} from "./types";
import * as operation from './oparations';
import * as converter from './converter';
import {t_WebChat} from "store/chat/types";
import {t_Contact} from "@pixel-kraft/commulino-types";
import {getContacts} from "store/chat/actions";
import {RootState} from "store/reducers";

/**
 * Add the Chat to Firebase.
 * @param chat The Chat to add to Firebase
 * @param resetDates Set Date again
 * @return Promise that's returns the Chat id
 */
const addChat = async (chat: t_chatTypes.t_ChatData, resetDates: boolean = true) => {
    if (resetDates) {
        infoDev("Reset Chat dates");
        chat.date = Date.now();
        if (chat.group) chat.group.createdAt = chat.date;
    }
    logDev('add', chat);
    const {id} = await addDoc(collection(getFirestore(), 'chats'), chat);
    return id;
}

/**
 * Prepare the Medicare Chat for Firebase, and call the addChat function.
 * @param me Current uid
 * @param users [me,res,...rel] | [me,...res]
 * @param groupInfo
 * @param type relativeChat | residentChat
 * @return The id of the created Chat.
 */
const makeChat = (me: string, users: t_ChatContact[], groupInfo: t_chatTypes.t_ChatData['group'], type: t_chatTypes.t_mediCareChatData['type'], areas: string[], includeRes?: boolean) => {


    const genMediCare = (): t_chatTypes.t_mediCareChatData => {
        return {
            type,
            residents: filterTypeChange(users, (u) => {
                if (u.type === "resident") return u.residentId;
            }),
            areas
        }
    }


    const uids = filterTypeChange(users, ({type, uid}) => {
        if (type !== "resident" || includeRes) return uid;
    })


    const chat: t_chatTypes.t_ChatData = {
        users,
        group: groupInfo,
        uids,
        readBy: [me],
        date: Date.now(),
        mediCare: genMediCare()
    }

    return addChat(chat);
}

/**
 * Check is a relativeChat with the residentId exist
 * @param residentId Resident to check for
 * @return A Promise with the WebChat if exist or false
 */
export const checkIfChatExist = async (residentId: string): Promise<t_WebChat | false> => {
    const response = await getDocs(
        query(collection(getFirestore(), 'chats'),
            where('mediCare.type', '==', 'relativeChat'),
            where('mediCare.residents', 'array-contains', residentId))
    )
    if (response.size < 1) return false;
    return {...response.docs[0].data() as t_WebChat, id: response.docs[0].id};
}

/**
 * Load all Leaders of specific areas. Use Contacts from Rest-API or store.
 * @param areas Areas where the Contacts should be the Leader of
 */
const getLeaders = (areas: string[]): AppThunk<Promise<t_Contact[]>> => {

    const hasAnyAreas = (searchIn: string[], searchFor: string[]) => arrayIncludesAny(searchIn, searchFor);

    return async (dispatch, getState) => {
        const currentUser = getState().auth;
        if (!currentUser) throw Error("No current user!");
        const contacts:t_Contact[] = await dispatch(getContacts());

        //Set of uids that are in res
        const resSet = new Set<string>();
        const res: t_Contact[] = [];
        if (currentUser.mediCare && currentUser.mediCare.type === "relative") {

            contacts.forEach((contact) => {
                if (contact.type === "resident" && contact.relatedContacts && contact.relatedContacts?.length > 0 && contact.areas && hasAnyAreas(contact.areas, areas)) {
                    contact.relatedContacts.forEach((rel) => {
                        if (rel.type === "user" && !resSet.has(rel.uid)) {
                            res.push(rel);
                            resSet.add(rel.uid);
                        }
                    })
                }
            })

        } else { //User
            for (const area of areas) {
                contacts.forEach((user) => {
                    if (user.type === "user" && user.areas && user.areas.includes(area) && !resSet.has(user.uid)) {
                        res.push(user);
                        resSet.add(user.uid);
                    }
                })
            }
        }

        return res;
    }
}

/**
 * Creates a Medicare Chat.
 * Adds all Leaders of the Areas of the users from the user Array
 * @param users Users in Chat
 * @param mode Rel Chat or Res Chat
 * @param groupInfo
 * @param includeRes Should the res be included
 * @return A Promise of the Chat ID of the created Chat
 */
export const createChat = (users: t_Contact[], mode: t_chatTypes.t_ChatData['mediCare']['type'], groupInfo?: { name: string, photo?: string }, includeRes?: boolean): AppThunk<Promise<string>> => {
    return async (dispatch, getState) => {
        const me = getState().auth;
        if (!me || !me.uid || !me.name) throw Error("Your not login!");

        const areas = new Set<string>();
        users.forEach((user) => user.areas && user.areas.forEach((a) => a && areas.add(a)));

        const userCopy = users.map((user) => {
            return converter.convertForChat(user) as t_ChatContact;
        });

        //Add Leaders
        const admins = (await dispatch(getLeaders([...areas.values()]))).map((user) => {
            return converter.convertForChat(user) as t_ChatContact;
        });
        userCopy.push(...admins);

        const createAdminObj = (admin: string) => {
            const adminObj:{[p: string]: {grantedBy: string, permissions: {admins: boolean, members: boolean, photo: boolean, name: boolean}}}={};
            admins.forEach((a)=>{
                adminObj[a.uid] = {grantedBy: admin,permissions: {admins: true,members: true,photo: true,name: false}}
            })
            return adminObj;
        }

        if(isDev) console.log([...userCopy], mode, includeRes);

        let group: t_chatTypes.t_ChatData['group'];
        if (mode === 'relativeChat') {
            const resIndex = userCopy.findIndex(operation.isRes);
            if (resIndex < 0) throw Error('No Resident Found!');
            const res = userCopy[resIndex];
            const admin = me.mediCare?admins[0].uid:me.uid;
            group = {
                createdAt: Date.now(),
                name: groupInfo?.name ?? res.name,
                createdBy: admin,
                admins: createAdminObj(admin)
            }

        } else if (groupInfo) group = {
            ...groupInfo,
            createdBy: me.uid,
            createdAt: Date.now(),
            admins: createAdminObj(me.uid)
        };

        return makeChat(me.uid, userCopy, group, mode, [...areas], includeRes);
    }
}

//User join Chat
const joinChat = async (chatId: string, user: t_Contact) => {
    const u = converter.convertForChat(user);
    await updateDoc(doc(getFirestore(), 'chats', chatId), {
        uids: arrayUnion(u.uid),
        users: arrayUnion(u)
    })
}

/**
 * Let the current user join an exiting relativeChat
 * @param chat CHat to join
 */
export const currentUserJoinChat = (chat: t_WebChat): AppThunk<Promise<void>> => {
    return async (dispatch, getState) => {
        const currentUser = (getState() as RootState).auth;
        if (!currentUser.uid || !currentUser.name) throw Error("Not Login!");
        let con: t_Contact | undefined = undefined;
        const min = {
            uid: currentUser.uid,
            name: currentUser.name,
            photo: currentUser.photo,
        }
        if (currentUser.mediCare?.type === "relative") {
            const res = currentUser.mediCare.residents.find(({residentId}) => residentId === chat.mediCare.residents[0]);
            if (!res) throw Error("Relative is not connect with resident");
            con = {
                type: 'relative',
                ...min,
                relation: res.relation
            };
        } else if (currentUser.mediCare?.type === "resident")
            con = {
                type: "resident",
                ...min,
                residentId: currentUser.mediCare.id
            }
        else
            con = {
                type: 'user',
                ...min,
                areas: currentUser.contactFor
            };

        await joinChat(chat.id, con)
    }
}
