import React from 'react'
import styles from './ActivityIndicator.module.css'

interface Props {
	light?: boolean
	large?: boolean
	style?: string,
	color?: React.CSSProperties['color']
}

const ActivityIndicator = ({ light, large, style, color }: Props) => (
	<div
		style={{borderTopColor: color}}
		className={`${styles.indicator} ${large && styles.large} ${light && styles.light} ${style}`}
	/>
)

export default ActivityIndicator
