import { ColorButton, ContentContainer, PageTitle } from 'components/index';
import React from 'react'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'store/reducers';

const Impressum = () => {
    const impressum = useSelector((state: RootState) => state.config.impressum);
    const history = useHistory();
    const premission = useSelector((state: RootState) => state.auth.permissions.edit_settings)
    return (
        <ContentContainer>
            <PageTitle
                text="Impressum"
            />
            {impressum.length === 0 &&
                <div>
                    Sie haben kein Impressum angeben.
                    {premission &&
                        <ColorButton text={"Impressum einstellen"} onClick={() => history.push("/settings/data")} />
                    }
                </div>
            }
            {impressum.length > 0 &&
                <div style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: impressum }}>

                </div>
            }
        </ContentContainer>
    )
}
export default Impressum;