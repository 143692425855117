import { Button, ColorButton, Group, Modal, Typo } from './index';
import React from 'react';

type t_AlertDialog = {
    title: string,
    text: string,
    item: JSX.Element,
    onCancel: ()=>void,
    onSuccess: ()=>void,
    loading?: boolean,
    
}
/**
 * Render ein Alert Dialog
 * @return JSX.Element 
 */
const AlertDialog = (props:t_AlertDialog) => {
    return (
        <Modal isVisible={true} hide={()=>{}} flexWidth>
            <div style={{ padding: 24 }}>
                <Typo variant='h4' br>
                    {props.title}
				</Typo>
                <Typo>{props.text}</Typo>
                <div style={{ padding: 8 }}>
                    {props.item}
                </div>
                <div style={{display: "flex",justifyContent:"space-between"}}>
                    <ColorButton loading={props.loading} style={{height: 35}} text="OK" onClick={()=>{
                        props.onSuccess();
                    }} />
                    <Button cssStyle={{height: 35}} text={'Abbrechen'} light onClick={()=>{
                        props.onCancel();
                    }} />
                </div>
            </div>
        </Modal>
    )
}

export default AlertDialog;
