import React, {useState} from 'react';
import ChatList from "../../ChatList";
import forwardToChat from "../../functions/forwardToChat";
import {FormItem, Modal, Row, SegmentedButtons, UserList} from "components";
import {createNewChat} from "store/chat/actions";
import useWindowDimensions from "lib/useWindowDimensions";
import {Message} from "@pixel-kraft/commulino-types";
import {useSelector} from "react-redux";
import {RootState} from "store/reducers";
import config from 'config';
import {icons} from "lib/constants";
import {ResRelSDK} from "lib/sdk";

type t_props = {
    forwardMsg: Message,
    close: () => void,
}

type t_p = t_props & {
    flex: React.CSSProperties['flex'],
    uid: string,
    height: number
}

const ChatSelect = ({flex, forwardMsg, close, uid, height}: t_p) => {
    const [searchChat, setSearchChat] = useState('');

    return (
        <Row flex={flex} flexFlow={'column'}>
            <FormItem placeholder={'Chats...'} type={'text'} value={searchChat} onChange={setSearchChat}/>
            <ChatList filter={{
                data: searchChat,
                filter: (chat, data) => chat.name.toUpperCase().includes(data.toUpperCase())
            }} hideUnread fullWidth showScrollBar style={{height: height * 0.65, width: '100%'}}
                      onClick={async (chatData) => {
                          if (forwardMsg) {
                              if (!forwardMsg.text && !forwardMsg.images && !forwardMsg.attachments) return;
                              await forwardToChat(chatData.id, {...forwardMsg}, uid);
                              close();
                          }
                      }}/>
        </Row>
    )
}

const UserSelect = ({forwardMsg, close, uid, height, flex}: t_p) => {
    const [search, setSearch] = useState('');
    const [mode, setMode] = useState<'users' | 'mediCare'>('users');

    return (
        <Row flex={flex} alignItems={'normal'} flexFlow={'column'}>
            <FormItem placeholder={'Benutzer...'} type={'text'} value={search} onChange={setSearch}/>
            {(config as any).residents &&
                <SegmentedButtons buttonStyle={{flex: 1}} options={{activeColor: 'mainColor',defaultColor: '#edeff2'}} value={mode} onValueChange={setMode} buttons={[
                    {
                        value: 'users',
                        icon: icons.users.icon,
                        label: icons.users.label
                    },
                    {
                        value: 'mediCare',
                        icon: icons.mediCare.icon,
                        label: icons.mediCare.label + "/Kontaktpersonen"
                    }
                ]}/>}
            <div style={{overflowY: 'auto'}}>
                <UserList filter={{
                    filter: (item, data) => (data[1] === "users" ? ResRelSDK.isUser(item) : !ResRelSDK.isUser(item)) && item.name.toUpperCase().includes(data[0].toUpperCase()),
                    data: [search, mode]
                }} height={height * 0.65} onClick={(userID, name) => {
                    if (forwardMsg) {
                        if (!forwardMsg.text && !forwardMsg.images) return;
                        if (forwardMsg.text)
                            createNewChat(forwardMsg.text, {
                                name,
                                uid: userID
                            }).then(close);
                    }
                }}/>
            </div>
        </Row>
    )
}

const MessageForward = ({forwardMsg, close}: t_props) => {
    const {height} = useWindowDimensions();
    const uid = useSelector((state: RootState) => state.auth.uid);

    if (!uid) return null;

    return (
        <Modal isVisible={true} hide={close}>
            <Row flexFlow={'column'} alignItems={'normal'} style={{padding: 10, height: height * 0.8}}>
                <div style={{paddingLeft: 5}}>
                    <h3>Weiter leiten an</h3>
                </div>
                <Row alignItems={'start'} justifyContent={'space-between'}>
                    <ChatSelect height={height} forwardMsg={forwardMsg} close={close} flex={0.45} uid={uid}/>
                    <div style={{borderRight: '1px solid #f2f2f2', height: '100%'}}/>
                    <UserSelect forwardMsg={forwardMsg} close={close} flex={0.45} uid={uid} height={height}/>
                </Row>
            </Row>
        </Modal>
    )
}

export default MessageForward;
