import React, {lazy} from 'react';
import {Post as PostType} from "@pixel-kraft/commulino-types";

const AttachmentsShow=lazy(()=>import('./AttachmentsShow'));
const AttachmentsEditScreen=lazy(()=>import('./AttachmentsEditScreen'));

type t_props = {
    post: PostType,
    edit: boolean
}

const Attachments=({post,edit}:t_props)=>{

    if(edit&&post.attachments) return <AttachmentsEditScreen attachments={post.attachments} />;
    else if (post.attachments) return <AttachmentsShow attachments={post.attachments} />;
    else return null;
}
export default Attachments;
