import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/reducers'
import { useHistory, useLocation } from 'react-router-dom'
import { ContentContainer, EditPermissions, PageTitle, FormItem, Button, ColorButton } from 'components/index'
import { FiSave, FiX } from 'react-icons/fi'
import { editRole } from 'store/roles/actions'
import styles from './index.module.css'

const RolesEdit = () => {
	const history = useHistory()
	const location: any = useLocation()
	const dispatch = useDispatch()
	const role = useSelector((state: RootState) =>
		state.roles.roles.find(r => r.id === location.state.id)
	)
	const [name, setName] = useState(role?.name)
	const [permissions, setPermissions] = useState(role?.permissions || {})
	const [error, setError] = useState(false)

	function save() {
		if (!name) return setError(true)
		dispatch(editRole({ id: location.state.id, name, permissions }))
		history.goBack()
	}

	return (
		<ContentContainer>
			<PageTitle text='Gruppe ändern' />
			<FormItem
				name='Name'
				onChange={setName}
				value={name}
				showRequired={error && !name}
				requiredMessage='Bitte einen Namen angeben'
			/>
			<EditPermissions {...{ permissions, setPermissions }} />
			<div className={styles.buttonContainer}>
				<Button onClick={history.goBack} text='Abbrechen' icon={FiX} large light />
				<ColorButton onClick={save} text='Speichern' icon={FiSave} style={{marginLeft: 12}} large />
			</div>
		</ContentContainer>
	)
}

export default RolesEdit
