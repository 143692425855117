import {ActivityIndicator, ColorButton, ContentContainer, PageTitle} from 'components/index';
import useWindowDimensions from 'lib/useWindowDimensions';
import React, {useState, Suspense, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {setDatenschutz, setImpressum, setPostsConfig} from 'store/config/actions';
import {RootState} from 'store/reducers';
import ShowText from './showText';
import {firebaseApp} from "lib/firebase";
import {Config_Posts} from "@pixel-kraft/commulino-types";
import {checkPost, isValidEmail} from "lib/helpers";
import PostsSettings from "./postsSettings";
import {setDoc, doc, getFirestore} from "firebase/firestore"
import {t_User} from "pages/settings/data/postsSettings/type";
import {useParams} from "react-router-dom";
import {Bubble, Row} from "components";

const Data = () => {
    const [select, setSelect] = useState(0);
    const {height} = useWindowDimensions();
    const {impressum, datenschutz} = useSelector((state: RootState) => state.config);
    const [OldImpressum, setOldImpressum] = useState(impressum);
    const [OldDatenschutz, setOldDatenschutz] = useState(datenschutz);
    const oldJobEmail = useSelector((state: RootState) => state.config.posts.job.email);
    const oldJobUser = useSelector((state: RootState) => state.config.posts.job.uid);
    const [jobUser, setJobUser] = useState<t_User | undefined>(oldJobUser ? {uid: oldJobUser, name: ''} : undefined);
    const [jobEmail, setJobEmail] = useState<string | undefined>(oldJobEmail);
    const [jobMode, setJobMode] = useState<'email' | 'user'>(oldJobUser ? 'user' : 'email');
    const [load, setLoad] = useState(false);
    const {type} = useParams<{type?: "post"|"impressum"|"datenschutz"}>();
    const dispatch = useDispatch();
    const setShow = (id: number) => {
        if (select === id) setSelect(0);
        else setSelect(id);
    }

    useEffect(()=>{
        if(type) {
            switch (type) {
                case "impressum":
                    setSelect(1);
                    break;
                case "datenschutz":
                    setSelect(2);
                    break;
                case "post":
                    setSelect(3);
                    break;
                default:
                    setSelect(0);
            }
        }
    },[type])

    const save = async (i: string, d: string, p: Config_Posts) => {
        try {
            setLoad(true);
            console.log(p);
            const db = getFirestore(firebaseApp);
            const testImpressum = () => i !== OldImpressum && i.length > 0;
            const testDatenschutz = () => d !== OldDatenschutz && d.length > 0;
            const testPosts = () => checkPost(p, {job: {uid: oldJobUser, email: oldJobEmail}});
            const saveIandD = async () => {
                await saveImpressum();
                await saveDatenschutz();
            }

            // if (i !== OldImpressum && i.length > 0 && d !== OldDatenschutz && d.length > 0) {
            //     await firestore.collection('config').doc('data').set({
            //         impressum: i,
            //         datenschutz: d
            //     }, {merge: true});
            //     setOldImpressum(i);
            //     setOldDatenschutz(d);
            //     dispatch(setImpressum(i));
            //     dispatch(setDatenschutz(d));
            // }
            const saveP = async () => {
                await setDoc(doc(db, 'config', 'posts'), {
                    job: {
                        ...p.job
                    }
                }, {merge: true})
                dispatch(setPostsConfig(p));
            }
            const saveIDP = async () => {
                await saveIandD();
                await saveP();
            }
            const saveImpressum = async () => {
                await setDoc(doc(db, 'config', 'data'), {
                    impressum: i
                }, {merge: true});
                setOldImpressum(i);
                dispatch(setImpressum(i));
            };
            const saveDatenschutz = async () => {
                await setDoc(doc(db, 'config', 'data'), {
                    datenschutz: d
                }, {merge: true})
                setOldDatenschutz(d);
                dispatch(setDatenschutz(d));
            };
            if (testImpressum() && testDatenschutz() && testPosts()) {
                await saveIDP();
                setLoad(false);
                return;
            }
            if (testImpressum() && testPosts()) {
                await saveImpressum();
                await saveP();
                setLoad(false);
                return;
            }
            if (testDatenschutz() && testPosts()) {
                await saveDatenschutz();
                await saveP();
                setLoad(false);
                return;
            }
            if (testImpressum() && testDatenschutz()) {
                await saveIandD();
                setLoad(false);
                return;
            }
            if (testImpressum()) {
                await saveImpressum();
                setLoad(false);
                return;
            }
            if (testDatenschutz()) {
                await saveDatenschutz();
                setLoad(false);
                return;
            }
            if (testPosts()) {
                await saveP();
                setLoad(false);
                return;
            }
        } finally {
            setLoad(false);
        }
    }

    const buttons: { text: string, check: boolean }[] = [
        {text: "Impressum", check: impressum.length === 0},
        {text: "Datenschutz", check: datenschutz.length === 0},
        {
            text: "Beiträge Einstellung",
            check: !((jobMode === 'email' && jobEmail && isValidEmail(jobEmail)) || (jobMode === 'user' && !!jobUser?.uid))
        }
    ]

    return (
        <ContentContainer loading={load}>
            <PageTitle text={"Daten bearbeiten"}/>
            <div style={{height: '100%', width: '100%'}}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                    {buttons.map((data, i) => (
                        <Row key={i}>
                            <ColorButton style={{marginRight: 10, transition: '0.3s'}}
                                         disableStyle={{color: 'black', backgroundColor: 'lightgray'}}
                                         aktive={select === i + 1} text={data.text} onClick={() => setShow(i + 1)}/>
                            {data.check && <Bubble>!</Bubble>}
                        </Row>
                    ))}
                    {(impressum.trim() !== OldImpressum || datenschutz.trim() !== OldDatenschutz ||
                            ((jobMode === 'email' && oldJobEmail !== jobEmail) || (jobMode === 'user' && oldJobUser !== (jobUser?.uid??'')))) &&
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <ColorButton text={"Speichern"}
                                         onClick={() => save(
                                             impressum.trim(),
                                             datenschutz.trim(),
                                             {
                                                 job: jobMode === 'user' ? {uid: jobUser?.uid ?? '', email: ''} : {
                                                     uid: '',
                                                     email: jobEmail ?? ''
                                                 }
                                             })}/>
                            <ColorButton style={{marginLeft: 10}} text={"Zurücksetzen"} onClick={() => {
                                dispatch(setImpressum(OldImpressum));
                                dispatch(setDatenschutz(OldDatenschutz));
                                dispatch(setPostsConfig({job: {email: oldJobEmail, uid: oldJobUser}}));
                                setJobEmail(oldJobEmail);
                                setJobUser(oldJobUser ? {uid: oldJobUser, name: ''} : undefined);
                            }}/>
                        </div>
                    }
                </div>
                <div style={{height: height - 300, display: 'flex', paddingTop: 20}}>
                    <ShowText show={select === 1} impressum={true}/>
                    <ShowText show={select === 2} impressum={false}/>
                    {select === 3 &&
                        <Suspense fallback={<ActivityIndicator large/>}>
                            <PostsSettings
                                onUserChange={setJobUser}
                                onEmailChange={setJobEmail}
                                jobEmail={jobEmail}
                                jobUser={jobUser}
                                onModeChange={setJobMode}
                                jobMode={jobMode}
                            />
                        </Suspense>
                    }
                </div>
            </div>
        </ContentContainer>
    )
}
export default Data;
