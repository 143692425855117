import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/reducers'
import styles from './CheckBox.module.css'

interface Props {
	text?: string,
	checked: boolean | any,
	onChange: (checked: boolean,event: React.MouseEvent<HTMLDivElement>) => void,
	style?: string,
	round?: boolean,
	color?: string,
	labelStyles?:string,
}

const CheckBox = ({ text, checked, onChange, style, round, color, labelStyles}: Props) => {
	const subColor = useSelector((state: RootState)=>state.config.subColor)
	return (
		<div className={`${styles.container} ${style}`} onClick={(event) => onChange(!checked,event)}>
			<div className={styles.boxContainer}>
				{checked ? (
					<div className={styles.checked}>
						<svg width='32' height='32' viewBox='0 0 24 24' focusable='false' role='presentation'>
							<g fillRule='evenodd'>
								{round ? (
										<rect fill={color ? color : subColor} x='4.7' y='4.7' width='15' height='15' rx='10'> </rect>
									):(

										<rect fill={color ? color : subColor} x='6' y='6' width='12' height='12' rx='2'> </rect>
									)}
								<path
									d='M9.707 11.293a1 1 0 1 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 1 0-1.414-1.414L11 12.586l-1.293-1.293z'
									fill='inherit'
								> </path>
							</g>
						</svg>
					</div>
				) : (
					round ? (
						<div
							className={`${styles.borderBoxRound} borderBox`}
							style={color ? {border: `2px solid ${color}`} : {border: "2px solid rgba(9, 30, 66, 0.09)"}}> </div>
						):(
						<div className={`${styles.borderBox} borderBox`} > </div>
						)
					)
				}
			</div>
			{text && <div className={`${styles.label} ${labelStyles}`}>{text}</div>}
		</div>
	)
}

export default CheckBox
