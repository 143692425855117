import React from 'react';
import UserRow from "../UserRow";
import {Tag} from "components";
import RenderButtons from "./RenderButtons";
import {t_moreProps} from "./index";
import {t_RelContact} from "@pixel-kraft/commulino-types";

type t_props = {
    user: t_RelContact
}&t_moreProps

const RelUser = (data:t_props)=> (
    <UserRow
        key={'rel_' + data.user.uid}
        user={data.user}
        me={data.me}
        buttons={data.buttons&&<RenderButtons buttons={data.buttons} />}
        nameCellStyle={{marginLeft: 5,...data.nameCellStyle}}
        reverse
    >
        <Tag>{data.user.relation}</Tag>

    </UserRow>
)

export default RelUser;
