import React, {lazy, useEffect, useRef,Suspense} from 'react';
import {FiVideo} from "react-icons/fi";
import ChatButton from "components/ChatButton";
import styles from './VideoPicker.module.css';
import {e_WrongProps} from "lib/errors";
import {ActivityIndicator} from "components/index";

const ReactHlsVideo= lazy(()=>import('react-hls-player'));

type t_props = {
    setVideoSrc: (src:string)=>void,
    setVideoFile: (file:File)=>void,
    onCreate?: boolean,
    videoSrc?: string,
    playable?: boolean,
    videoWidth?: number,
    videoOverlay?: JSX.Element,
    videoHeight?: number
    videoFile?: File,
    showVideo?: boolean,
    className?: string,
    disable?: boolean,
    noButton?: boolean,
    buttonClass?: string,
}

/**
 * @param props
 * @param ref A reference on the Input element. NOT the video element!
 */
const VideoPicker = React.forwardRef<HTMLDivElement,t_props>((props,ref)=>{
    if(props.videoOverlay && !props.showVideo) throw e_WrongProps(new Error("You cant use videoOverlay with out showVideo"));

    useEffect(()=>{
        if(props.onCreate&&VideoRef.current)
            VideoRef.current?.click();
    },[props.onCreate])

    const VideoRef = useRef<HTMLInputElement | null>();
    const VideoHSLRef = useRef<HTMLVideoElement>(null);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { target } = event;
        const { files } = target;

        if (files && files[0]) {
            let reader = new FileReader();
            reader.onload = event => {
                if (event.target)
                    props.setVideoSrc(event.target.result as string)
                if (props.setVideoFile)
                    props.setVideoFile(files[0]);
            };
            reader.readAsDataURL(files[0]);

        }
    }
    const click = () => {
        if (VideoRef.current !== null) {
            if (!props.disable)
                VideoRef.current?.click();
        }
    };
    return (
        <div className={`${styles.videoContainer} ${props.className}`}>
            <input ref={(ref) => VideoRef.current = ref} style={{display: 'none'}}
                   id="tk_videoPicker"
                   type="file"
                   accept="video/mp4"
                   capture="user"
                   onChange={(e) => handleFileChange(e)}
            />
            {props.showVideo && props.videoSrc &&
                <div style={{position: 'relative'}}>
                    <Suspense fallback={<ActivityIndicator large />}>
                        {props.videoSrc.includes('https://player.vimeo.com/') ?
                            <ReactHlsVideo
                                playerRef={VideoHSLRef}
                                src={props.videoSrc}
                                height={props.videoHeight}
                                width={props.videoWidth ?? 350}
                                controls={props.playable}
                            />
                            :
                            <video width={props.videoWidth ?? 350} height={props.videoHeight} controls={props.playable}
                                   src={props.videoSrc}/>
                        }
                    </Suspense>

                    {props.videoOverlay}
                </div>
            }
            {!props.noButton &&
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <ChatButton
                        className={props.buttonClass}
                        ref={ref}
                        style={{backgroundColor: 'transparent', padding: 0, fontSize: '1.5em'}}
                        icon={FiVideo}
                        onClick={click}/>

                </div>
            }

        </div>
    )
})
VideoPicker.displayName="VideoPicker";
export default VideoPicker;