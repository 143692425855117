import {UserProfilesActionsType,userProfilesState} from './types'

const initState:userProfilesState={
    listener: ()=>null
};

export default function userProfiles(state = initState, action: UserProfilesActionsType): userProfilesState {
    switch (action.type) {
        case "SETUSERPROFILES": {
            return {
                ...state,
                ...action.userProfiles
            }
        }
        case "SETUSERPROFILESLISTENER" : {
            return {
                ...state,
                listener: action.listener
            }
        }
        default:
            return state
    }
}
