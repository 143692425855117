import {ROOT_FOLDER} from "store/folders/reducer";
import {database} from "pages/folders/components/database";
import {AppThunk} from "store/store";
import {CurrentFolder} from "pages/folders/components/AddFolderButton";
import {RootFolder} from "store/folders/types";
import {collection, doc, getDoc, getFirestore, onSnapshot, orderBy, query, where} from "firebase/firestore";
import {firebaseApp} from "lib/firebase";


export const setRootFolderId = (rootFolderId: string): AppThunk => {
    return dispatch => dispatch({type: "SET_ROOTFOLDERID", rootFolderId});
}

/*
If folderId or folder are not passed as a params, the value is set as null. A function
that save the files and folders as a parent or child. Traces a root. Select set the value
to the variables from the params (not passed=> null/[]).
*/

export const selectFolder = (folderId: null | string = null, folder: null | CurrentFolder | RootFolder | undefined): AppThunk => {
    return dispatch => {
        dispatch({type: "SELECT_FOLDER", folderId, folder})
    }

}

export const setFolderId = (folderId: string | null | undefined): AppThunk => {
    return dispatch => {
        dispatch({type: "SET_FOLDER_ID", folderId})
    }
}

export const updateFolder = (rootFolderId: string, folderId: string | null): AppThunk => {
    return async (dispatch, getState) => {
        const {uid, name} = getState().auth;
        const toUseFolderID = folderId === null || folderId === uid || folderId === rootFolderId ? rootFolderId : folderId;
        if (folderId === null || folderId === uid || folderId === rootFolderId) {
            return dispatch({
                type: "UPDATE-FOLDER", folder: {
                    name: rootFolderId === uid ? name : rootFolderId,
                    id: rootFolderId,
                    path: [],
                    deleteId: [],
                    child: false
                }
            })
        }
        getDoc(doc(getFirestore(firebaseApp), 'folders', rootFolderId, 'folders', toUseFolderID))
            .then(doc => {
                dispatch({
                    type: "UPDATE-FOLDER",
                    folder: database.formatDocFolder(doc),
                })
            })
            .catch(() => {
                dispatch({
                    type: "UPDATE-FOLDER",
                    folder: {folder: ROOT_FOLDER}
                })
            })
    }
}

export const setChildFolders = (rootFolderId: string, folderId: string | null): AppThunk => {
    return async dispatch => {
        const l = onSnapshot(query(collection(getFirestore(firebaseApp), 'folders', rootFolderId, 'folders'),
            where("parentId", "==", folderId === null ? rootFolderId : folderId),
            orderBy("createdAt", "desc"),
        ), snapshot => {
            dispatch({
                type: "SET-CHILD-FOLDERS",
                childFolders: snapshot.docs.map(database.formatDocFolder),
            })
        })
        dispatch({type: "SET_CHILD_FOLDERS_LISTENER", listener: l})
    }
}

export const setChildFoldersEmpty = (childFolders: Array<Object>): AppThunk => {
    return dispatch => {
        dispatch({type: "SET_CHILD_EMPTY", childFolders})
    }
}

export const setChildFiles = (rootFolderId: string, folderId: string | null/*, currentUser: any*/): AppThunk => {
    return async dispatch => {
        const l= onSnapshot(query(collection(getFirestore(firebaseApp), 'folders', rootFolderId, 'files'),
            where("folderId", "==", folderId??rootFolderId),
            orderBy("createdAt")
        ), snapshot => {
            dispatch({
                type: "SET-CHILD-FILES",
                childFiles: snapshot.docs.map(database.formatDocFiles),
            })
        })
        dispatch({type: "SET_CHILD_FILES_LISTENER", listener: l})
    }
}

export const stopAllListener = () : AppThunk => {
    return dispatch => dispatch({type: "STOP_ALL_LISTENER"});
}
