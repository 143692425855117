import React, {useState} from 'react';
import {AlertDialog, ColorButton} from "components";
import {FiCheck, FiX} from "react-icons/fi";
import {Chat} from '@pixel-kraft/commulino-types';
import {firebaseApp} from "lib/firebase";
import {doc, getFirestore, updateDoc,deleteField} from "firebase/firestore";
import styles from "./ChatUserPermission.module.css"

// eslint-disable-next-line no-undef
type t_admins = Required<Required<Chat>['group']>['admins'][string];
// eslint-disable-next-line no-undef
type t_permission = Required<t_admins>['permissions'];

type t_props = {
    myID: string,
    userID: string,
    userName: string,
    chatID: string,
    permission?: t_permission,
    finish: () => void,
}
/**
 * A AlertDialog where you can set the chat permission of a user in specific chat.
 * @param userID id of the user where the permission will be change
 * @param userName user name of user
 * @param myID your own uid
 * @param permission current permissions
 * @param finish is called if AlertDialog close (onCancel,onSuccess)
 * @param chatID id of Chat where the permission will be change
 */
const ChatUserPermission = ({userID, userName, myID, permission, finish, chatID}: t_props) => {
    const [admins, setAdmins] = useState(!!permission?.admins);
    const [members, setMembers] = useState(!!permission?.members);
    const [photo, setPhoto] = useState(!!permission?.photo);
    const [name, setName] = useState(!!permission?.name);


    type t_checkBox = {
        check: boolean,
        onClick: (check: boolean) => void
    }

    const CheckBox = ({check, onClick}: t_checkBox) => (
        <ColorButton
            icon={check ? FiCheck : FiX}
            onClick={() => onClick(!check)}
            mainColor={check}
        />
    )

    type t_row = {
        text: string,
        checkBox: t_checkBox
    }

    const Row = ({text, checkBox}: t_row) => (
        <div className={styles.row}>
            <div className={styles.cell}>{text}</div>
            <CheckBox {...checkBox} />
        </div>
    )

    //Array of all permissions
    const arr: t_row[] = [
        {text: "Benutzerberechtigungen ändern", checkBox: {check: admins, onClick: setAdmins}},
        {text: "Benutzer hinzufügen und entfernen", checkBox: {check: members, onClick: setMembers}},
        {text: "Gruppenbild ändern", checkBox: {check: photo, onClick: setPhoto}},
        {text: "Gruppennamen ändern", checkBox: {check: name, onClick: setName}}
    ]

    const save = (permission: t_admins) => {
        //if all permissions are false delete user from admins
        updateDoc(doc(getFirestore(firebaseApp), 'chats', chatID), {
            ["group.admins." + userID]: Object.values(permission.permissions).some((val) => val) ?
                permission : deleteField()
        });
    }
    const permissionChange=()=>{
        return permission?.admins !== admins || permission?.name !== name || permission?.photo !== photo || permission?.members !== members;
    }


    return (
        <AlertDialog
            title={`Berechtigung von ${userName} ändern`}
            text={"Berechtigungen:"}
            item={(<div>
                {arr.map((per, i) => <Row key={i} {...per} />)}
            </div>)}
            onCancel={finish}
            onSuccess={() => {
                //Only change permission if current permission are different.
                //Because if not the grantBy filed would be updatet without permissions changes
                if (!permission || permissionChange())
                    save({
                        grantedBy: myID,
                        permissions: {
                            admins,
                            name,
                            photo,
                            members
                        }
                    });
                finish();
            }}
        />
    )
}

export default ChatUserPermission;
