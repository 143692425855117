import { combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

import posts from './posts/reducer'
import usersSlice from './users/stroe'
import config from './config/reducer'
import auth from './auth/reducer'
import roles from './roles/reducer'
import messenger from './chat/reducer'
import onboarding from './onboarding/reducer'
import userProfiles from './userProfiles/reducer'
import folders from "./folders/reducer";
import resAndRelSlice from "./residentAndRelatives/store";
import todoListSlice from "./todoList/store";
import {configureStore} from "@reduxjs/toolkit";
import logger from 'redux-logger'

declare global {
	interface Window {
		__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
	}
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const reducer = combineReducers({ posts, users: usersSlice, config, auth, roles, messenger, onboarding, userProfiles, folders,resAndRel: resAndRelSlice,todoList: todoListSlice })
const store = configureStore({
	reducer,
	enhancers: [composeEnhancers(applyMiddleware(thunk))],
	middleware: (getDefaultMiddleware) =>process.env.NODE_ENV === 'development' && true ? getDefaultMiddleware({serializableCheck: false,immutableCheck: false}).concat(logger) : getDefaultMiddleware({serializableCheck: false,immutableCheck: false})
})




export type RootState = ReturnType<typeof store.getState>

export default store
