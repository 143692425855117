import ChatButton from "./ChatButton";
import React, {useEffect, useRef} from "react";
import {FiX} from "react-icons/fi";
import {ColorButton} from "components/index";
import insertTextAtCursor from 'insert-text-at-cursor';
import styles from './TextBox.module.css';

type t_button = {
    style?: React.CSSProperties,
    icon: React.ComponentType<{ style?: React.CSSProperties, className?: string }>,
    onClick: () => void,
}

type t_ButtonArray = t_button | JSX.Element

type t_props = {
    disabled?: boolean,
    text: string,
    placeholder?: string,
    updateText: (text: string) => void,
    updateHeight: (height: number) => void,
    buttons?: t_ButtonArray[],
    TopElem?: JSX.Element | null,
    showTop: boolean,
    closeTop: () => void,
    loading?: boolean,
    useColorButtons?: boolean,
    insertInTextAtCurrentPostions?: string,
    insertInTextAtCurrentPostionsReset?: () => void,
    textBoxStyle?: React.CSSProperties,
    onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>
}

/**
 * Rendert eine Textbox mit Button links von ihr und liefert per updateHeight die Aktuelle höhe zurück
 * @param disabled Deaktiviert die TextBox
 * @param updateText Wird immer ausgeführt wenn sich der Text ändert.
 * @param updateHeight Wird immer ausgeführt wenn sich die Höhe ändert. Auch bei showTop
 * @param buttons Ein Array von Buttons die in der übergeben Reihenfolge Links von der TextBox gerendert werden
 * @param text Der Text der in der TextBox steht
 * @param TopElem Ein JSX.Element das über der TextBox gerendert wird (min Height 80px)
 * @param closeTop Wird ausgeführt wenn der Close Button für Top geklickt wird
 * @param showTop Gibt an ob das TopElement gerendert gezeigt werden soll oder nicht. (Wird immer gerendert, ist bloß nicht immer sichtbar)
 * @param loading
 */
const TextBox = React.forwardRef<HTMLDivElement, t_props>(({
                                                               disabled,
                                                               placeholder,
                                                               updateText,
                                                               updateHeight,
                                                               buttons,
                                                               text,
                                                               TopElem,
                                                               closeTop,
                                                               showTop,
                                                               loading,
                                                               useColorButtons,
                                                               insertInTextAtCurrentPostions,
                                                               insertInTextAtCurrentPostionsReset,
                                                               textBoxStyle,
                                                               onKeyDown
                                                           }, ref) => {
    const TextAreRef = useRef<HTMLTextAreaElement>();
    const TopRef = useRef<HTMLDivElement>(null);
    const ButtonRef = useRef<HTMLDivElement>(null);
    const ToUseButtons = useColorButtons ? ColorButton : ChatButton;

    useEffect(() => {
        if (TextAreRef.current) {

            TextAreRef.current.style.height = "0px";
            const p_top = TextAreRef.current.style.paddingTop;
            const p_bottom = TextAreRef.current.style.paddingBottom;
            const top = Number(p_top.substr(0, p_top.length - 2));
            const bottom = Number(p_bottom.substr(0, p_bottom.length - 2));
            TextAreRef.current.style.height = (TextAreRef.current.scrollHeight - top - bottom) + "px";
            const tmp = Number(TextAreRef.current?.style.height.replace('px', ''));
            const topHeight = showTop ? TopRef.current?.clientHeight : 0;
            if (tmp <= 150)
                updateHeight(tmp + 20 + (topHeight ?? 0));
            else
                updateHeight(170 + (topHeight ?? 0));
        }
    }, [text, TextAreRef, updateHeight, showTop, TopRef, TopElem]) //TopElem

    useEffect(() => {
        if (!disabled && insertInTextAtCurrentPostions && insertInTextAtCurrentPostions.length > 0) {
            if (TextAreRef.current) {
                const pos = TextAreRef.current?.selectionStart + insertInTextAtCurrentPostions.length;
                setTimeout(() => {
                    TextAreRef.current?.focus();
                    TextAreRef.current?.setSelectionRange(pos, pos); // Emojis are multibyte
                }, 0);
                insertTextAtCursor(TextAreRef.current, insertInTextAtCurrentPostions);
            }
        }
        if (insertInTextAtCurrentPostionsReset)
            insertInTextAtCurrentPostionsReset();
    }, [insertInTextAtCurrentPostions, updateText, insertInTextAtCurrentPostionsReset, disabled])


    return (
        <div onKeyDown={onKeyDown} style={{marginLeft: 10, marginRight: 10}} ref={ref}
             onBlur={(e) => {
                 //console.log(e.relatedTarget);
                 if (e.relatedTarget === null) {
                     if (TextAreRef.current) {
                         setTimeout(() => TextAreRef.current?.focus(), 0);
                     }
                 }
             }}
        >
            {/* top*/}
            <div ref={TopRef}
                 className={`${styles.topContainer} ${showTop ? styles.topContainerShow : styles.topContainerHide}`}
                 style={{
                     width: TextAreRef.current?.scrollWidth ? TextAreRef.current?.scrollWidth + 15 : 0,
                 }}>
                <ChatButton icon={FiX} onClick={() => {
                    if (!loading)
                        closeTop();
                }} className={styles.topCloseButton}/>

                <div className={styles.topBackground}/>

                <div
                    className={`${styles.topContentContainer} ${showTop ? styles.topContentContainerShow : styles.topContentContainerHidden}`}>
                    {TopElem}
                </div>
            </div>

            {/* TextBox*/}
            <div style={{display: "flex"}}>
                <div style={{width: '100%', position: 'relative', zIndex: 1, whiteSpace: 'pre-wrap'}}>
                    <textarea
                        className={styles.textBoxClass}
                        disabled={disabled}
                        value={text}
                        ref={(elm) => {
                            if (elm)
                                TextAreRef.current = elm;
                        }}
                        onChange={() => {
                            if (TextAreRef.current) {
                                updateText(TextAreRef.current.value) //Sorgt dafür das ScrollView gerezist wird
                            }
                        }}
                        rows={1}
                        placeholder={placeholder??'Nachricht...'}
                        style={{//Kann nicht in className weil sich sonst die height ändert weiß aber nicht warum.
                            padding: 10,
                            paddingRight: 0,
                            borderRightColor: disabled ? '#e3e3e3' : 'white',
                            ...textBoxStyle
                        }}
                    />

                </div>
                {/* Buttons */}
                <div ref={ButtonRef} className={styles.buttonContainer}>
                    {buttons?.map((Element, i) => {
                        if ("onClick" in Element && "icon" in Element)
                            return (
                                <ToUseButtons key={i} icon={Element.icon} onClick={Element.onClick}
                                              style={{...Element.style}}/>
                            );
                        else
                            return (
                                Element
                            )
                    })}
                </div>

            </div>
        </div>
    );
})
TextBox.displayName = 'TextBox';
export default TextBox;
