import React, {useRef} from "react";
import DropArea from "./DropArea";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";


// type t_props<T>={
//     items: T[],
//     renderItem:(item:T,index:number,items: T[])=>JSX.Element,
//     columns: number
// }

function DragRaster<Type>({renderItem, items, onChange, children}: {
    items: Type[],
    renderItem: (item: Type, index: number, items: Type[]) => JSX.Element,
    onChange: (items: Type[]) => void,
    children?: JSX.Element | JSX.Element[] | null
}) {
    const itemWrapper = (item: Type, index: number, items: Type[]) => {
        const changeIndex = (destinationIndex: number, item: any) => {
            const _items: Type[] = [...items];
            const toSwap = _items.splice(item.index, 1);
            _items.splice(destinationIndex, 0, toSwap[0]);
            onChange(_items);
        }
        const change = (i: number) => changeIndex(index, i);
        return (
            <DropArea key={index} index={index} changeIndex={change}>
                {renderItem(item, index, items)}
            </DropArea>
        )
    }


    return (
        <DndProvider backend={HTML5Backend}>
            <div style={{display: "flex", flexFlow: "wrap",alignItems: "center",justifyContent: "space-between"}}>
                {items.map(itemWrapper)}
                {children}
            </div>
        </DndProvider>
    )

}

export default DragRaster;