import {ActivityIndicator, Card, ContentContainer, PageTitle, Pagination} from 'components/index';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "store/reducers";
import {
    getPersonalPosts,
    standardPostNum_byGroup,
} from "store/posts/actions";
import Post from "../posts/Post";
import {Post as t_Post} from "@pixel-kraft/commulino-types";
import styles from './personal.module.css';
import useWindowDimensions from "lib/useWindowDimensions";

const PersonalMsg = () => {
    const isLoading = useSelector((state: RootState) => state.posts.loading);
    const uid = useSelector((state:RootState)=> state.auth.uid);
    const [localLoad, setLocalLoad] = useState(false);
    const byId = useSelector((state: RootState) => state.posts.byId);
    const [posts, setPosts] = useState<t_Post[]>([]);
    const isFinish = useSelector((state: RootState) => {
        const uid = state.auth.uid;
        if (uid !== null && uid in state.posts.postsByPersonalMsg)
            return state.posts.postsByPersonalMsg[uid].complete;
    });
    const postsByPersonalMsg = useSelector((state:RootState) => state.posts.postsByPersonalMsg);
    const dispatch = useDispatch();
    const [page, setPage] = useState(0)
    const count = 10
    const pages = Math.ceil(posts.length / count);
    const {width} = useWindowDimensions();

    useEffect(() => {
        if(uid!==null) {
            setLocalLoad(true);
            if (!(uid in postsByPersonalMsg)) {
                dispatch(getPersonalPosts());
            }else {
                const filtered = postsByPersonalMsg[uid].posts.map(data=>byId[data.id]);
                if (!isFinish && ((!filtered) || (filtered && filtered.length < standardPostNum_byGroup))) {
                    dispatch(getPersonalPosts());
                } else {
                    setPosts(filtered);
                    setLocalLoad(false);
                }
            }
        }
    }, [dispatch, byId, isFinish,postsByPersonalMsg,uid])
    return (
        <ContentContainer loading={isLoading} style={styles.contentContainer}>
            <PageTitle
                text={"Persönliche Nachricht"}
            />
            {posts.slice(page * count, page * count + count).map(post => (
                <Card cssStyle={{minWidth: width / 2}} style={styles.card} key={post.id}>
                    <ContentContainer hideSpacer contentStyle={styles.cardContent}>
                        <Post post={post}/>
                    </ContentContainer>
                </Card>
            ))}
            <Pagination count={pages} currentPage={page} goTo={(p) => {
                if (!isFinish && p === pages - 1)
                    dispatch(getPersonalPosts());
                setPage(p);
            }} style={styles.pagination} complete={isFinish}/>
            {(isLoading || localLoad) &&
            <Card cssStyle={{minWidth: width / 2}} style={styles.loadCard} key={'load'}>
                <ContentContainer hideSpacer contentStyle={styles.cardContent}>
                    <ActivityIndicator large/>
                </ContentContainer>
            </Card>
            }
            {!isLoading && !localLoad && posts.length === 0 &&
            <Card cssStyle={{minWidth: width / 2}} style={styles.loadCard} key={'load'}>
                <ContentContainer hideSpacer contentStyle={styles.cardContent}>
                    <div>
                        Es wurde noch kein Persönliche Nachricht erstellt.
                    </div>
                </ContentContainer>
            </Card>
            }
        </ContentContainer>
    )
}

export default PersonalMsg;
