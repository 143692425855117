import React, {useRef} from 'react'
import {FiImage} from 'react-icons/fi';
import ChatButton from './ChatButton';
import styles from './ImgPicker.module.css'

type t_props = {
    setImgSrc?: (src: string) => void,
    setImgFile?: (file: File) => void,
    setImg?: (src: string, file: File) => void,
    disable?: boolean,
    style?: React.CSSProperties,
    noButton?: boolean,
    noOverlay?: boolean,
    showImage?: {
        show: boolean,
        src?: string,
    },
    imgStyle?: React.CSSProperties,
    buttonStyle?: React.CSSProperties,
    costumeButton?: (click: () => void) => React.ReactNode,
    icon?: React.ComponentType<{ style?: React.CSSProperties, className?: string }>,
}
/**
 * Rendert einen ImagePicker
 * @param props.setImgSrc ruft die Function mit dem Pfad des Bildes auf
 * @param props.setImgFile ruft die Function mit dem File Object auf
 * @param props.disable legt fest ob das Click event ausgeführt
 * @param props.style für den root div container
 * @param props.noButton legt fest ob ein Button gerendert wird
 * @param props.noOverlay deaktiviert das Overlay des Bildes
 * @param props.showImage render ein Bild mit src wenn show auf true sonst icon falls nicht undefiniert
 * @param props.imgStyle für Bild und Icon
 * @param props.buttonStyle für Button falls aktive
 * @param props.icon Icon das gerendert wird wenn src undefiniert oder leer
 */
const ImagePicker = React.forwardRef<HTMLDivElement, t_props>((props, ref) => {
    const IMGRef = useRef<HTMLInputElement | null>();
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {target} = event;
        const {files} = target;

        if (files && files[0]) {
            let reader = new FileReader();
            reader.onload = event => {
                event.target && props.setImgSrc && props.setImgSrc(event.target.result as string)
                props.setImgFile && props.setImgFile(files[0]);
                event.target && props.setImg && props.setImg(event.target.result as string, files[0]);
            };
            reader.readAsDataURL(files[0]);
        }
    }
    const click = () => {
        if (IMGRef.current !== null) {
            if (!props.disable)
                IMGRef.current?.click();
        }
    };
    return (
        <div style={{position: 'relative', ...props.style}}>
            <input ref={(ref) => IMGRef.current = ref} style={{display: 'none'}}
                   id="tk_imgPicker"
                   type="file"
                   accept="image/*"
                   capture="user"
                   onChange={(e) => handleFileChange(e)}
            />
            {props.showImage && props.showImage.show &&
                <div className={props.disable || props.noOverlay ? `${styles.disContainer}` : `${styles.container}`}
                     style={{position: 'relative', display: 'flex',justifyContent: 'center',alignItems: 'center'}} onClick={(e)=>{
                         e.target!==e.currentTarget&&click()
                }}>
                    {props.showImage.src && props.showImage.src !== "" &&
                        <img className={`${props.disable || props.noOverlay ?styles.disImage:styles.image}`} alt={"profile"} src={props.showImage.src} style={{
                            cursor: !props.disable ? 'pointer' : 'default',
                            ...props.imgStyle
                        }}/>
                    }
                    {(!props.showImage.src || props.showImage.src === "") && props.icon &&
                        <props.icon className={`${props.disable || props.noOverlay ?styles.disImage:styles.image}`} style={{
                            cursor: !props.disable ? 'pointer' : 'default',
                            ...props.imgStyle
                        }}/>
                    }
                    {!props.disable &&
                        <div className={`${styles.middle}`}>
                            <FiImage className={`${styles.text}`}/>
                        </div>
                    }
                </div>
            }
            {!props.noButton &&
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div ref={ref} style={{display: 'none'}} onClick={click}/>
                    {props.costumeButton ? <>{props.costumeButton(click)}</> :
                        <ChatButton
                            style={{
                                backgroundColor: 'transparent',
                                padding: 0,
                                fontSize: '1.5em', ...props.buttonStyle
                            }}
                            icon={FiImage}
                            onClick={click}/>
                    }

                </div>
            }

        </div>
    )
})
ImagePicker.displayName = 'ImagePicker';
export default ImagePicker;
