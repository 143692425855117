import React, {lazy, Suspense} from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {RootState} from 'store/reducers'
import {ActivityIndicator, Bubble, Button, ContentContainer, PageTitle, Row, Typo} from 'components'
import styles from './index.module.css'
import {isValidEmail} from "lib/helpers";
import {FaClinicMedical,FiDatabase, FiList, FiTrello, FiUsers} from "react-icons/all";
import config from 'config';

const GroupsConfig = lazy(() => import('./GroupsConfig'));
const CategoriesConfig = lazy(() => import('./CategoriesConfig'));

const Settings = () => {
    const loading = useSelector((state: RootState) => state.config.loading);
    const {push} = useHistory();
    const {impressum, datenschutz, posts} = useSelector((state: RootState) => state.config);

    return (
        <ContentContainer loading={loading}>
            <PageTitle text='Einstellungen'/>
            <Suspense fallback={<ActivityIndicator large/>}>
                <CategoriesConfig/>
                <GroupsConfig/>
            </Suspense>
            <div className={styles.roles}>
                <Typo variant='h4'>Benutzer-Gruppen</Typo>
                <Button
                    onClick={() => push('/settings/roles')}
                    text='Benutzer-Gruppen verwalten'
                    icon={FiUsers}
                    large
                    light
                    style={styles.rolesButton}
                />
            </div>
            <div className={styles.roles}>
                <Typo variant='h4'>Layout und Daten</Typo>
                <Row>
                    <Button
                        onClick={() => push('/settings/layout')}
                        text='Layout bearbeiten'
                        icon={FiTrello}
                        large
                        light
                        style={styles.rolesButton}
                        cssStyle={{marginRight: 10}}
                    />
                    <Row>
                        <Button
                            onClick={() => push('/settings/data')}
                            text='Daten bearbeiten'
                            icon={FiDatabase}
                            large
                            light
                            style={styles.rolesButton}
                        />
                        {impressum.length !== 0 && datenschutz.length !== 0 && ((posts.job.uid === "" && isValidEmail(posts.job.email)) || (posts.job.email === "" && posts.job.uid)) ? null : <Bubble style={{marginRight: 10,marginLeft: 5}} >!</Bubble>}
                    </Row>
                    <Button
                        onClick={() => push('/settings/costumeMenu')}
                        text={"Benutzerdefinierter Menüpunk"}
                        icon={FiList}
                        large
                        light
                        style={styles.rolesButton}
                    />
                    {(config as any).residents&&<Button
                        cssStyle={{marginLeft: 10}}
                        onClick={() => push('/settings/resAndRel')}
                        text={"Medicare Einstellungen"}
                        icon={FaClinicMedical}
                        large
                        light
                        style={styles.rolesButton}
                    />}
                </Row>
            </div>
        </ContentContainer>
    )
}

export default Settings
