import React, {useLayoutEffect, useRef, useState} from 'react';
import {useDrop} from 'react-dnd'
import {ItemTypes} from "../constant";
import DragItem from "components/DragAndDrop/DragItem";

type t_props = {
    children: JSX.Element | JSX.Element[],
    index: number,
    changeIndex: (index: number) => void
}

const DropArea = ({children, index, changeIndex}: t_props) => {
    const conRef=useRef<HTMLDivElement>(null);
    const [height,setHeight] = useState<number|undefined>(undefined);

    const [{isOver}, drop] = useDrop(() => ({
            accept: ItemTypes.ITEM,
            drop: (item) => changeIndex(item as number),
            collect: monitor => ({
                isOver: monitor.isOver()
            })
        })
        , [index, children]) //Children must be her. Drop don't work correct without it.

    useLayoutEffect(()=>{
        if(conRef.current?.clientHeight){
            setHeight(conRef.current.clientHeight);
        }
    },[conRef.current?.clientHeight])

    return (
        <div style={{height: isOver?(height??20)*2:height,display: "flex",flexDirection: "column",justifyContent: "end",transition: "height 0.3s"}} ref={drop}>
            <DragItem ref={conRef} index={index}>{children}</DragItem>
        </div>
    )
}
export default DropArea;