import React from "react";
import {useSelector} from "react-redux";
import {RootState} from "store/reducers";
import styles from "./ModalHead.module.css";
import {getInvertColor} from "lib/colors";

interface i_default {
    children: JSX.Element | JSX.Element[] | null | string,
    childStyle?: React.CSSProperties,
    childClass?: string
}

interface i_sync extends i_default {
    type: "globaleColor",
    mode: "sync"
    mainColor: boolean,
}

interface i_cssVars extends i_default {
    type: "globaleColor",
    mode: "cssVars",
    mainColor: boolean,
}

interface i_costume extends i_default {
    type: "costumeColor",
    style?: React.CSSProperties,
    className?: string,
}

type t_props = i_costume | i_sync | i_cssVars


const ModalHead = (props: t_props) => {
    const {mainColor, subColor} = useSelector((state: RootState) => state.config);

    const Head = ({
                      children,
                      style,
                      className
                  }: { children: t_props["children"], style?: React.CSSProperties, className?: string }) => {
        return (
            <div style={{...style,...props.childStyle}} className={`${styles.head} ${props.childClass??''} ${className ?? ''}`}>{children}</div>
        )
    }

    switch (props.type) {
        case "globaleColor": {
            if (props.mode === "cssVars") return <Head
                className={props.mainColor ? styles.cssVarsMain : styles.cssVarsSub}>{props.children}</Head>
            return <Head
                style={{
                    backgroundColor: props.mainColor ? mainColor : subColor,
                    color: getInvertColor(props.mainColor ? mainColor : subColor, true)
                }}>
                {props.children}</Head>
        }
        case "costumeColor":{
            return <Head style={props.style} className={props.className}>{props.children}</Head>
        }
        default:
            return <Head className={styles.cssVarsMain} children={null} />
    }
}
export default ModalHead;