import {Category, Config_Posts, MenuPoint, SubPointTypes} from '@pixel-kraft/commulino-types'

export const CONFIG_REQUEST = 'CONFIG_REQUEST'
export const CONFIG_RECEIVE = 'CONFIG_RECEIVE'

interface ConfigData {
	categories: Category[],
	departments: string[],
	locations: string[],
	mainColor: string,
	mainTextColor: string
	subColor: string,
	subTextColor: string
	impressum: string,
	datenschutz: string,
	stellenanzeige: string,
	verbesserungsvorschlag: string,
	posts: Config_Posts,
	costumeMenu?: t_costumeMenu,
}

type t_costumeMenu = {
	menuPoint?: MenuPoint,
	subPoints?: SubPointTypes[],
}

export interface ConfigState extends ConfigData{
	loading: boolean
}

interface RequestAction {
	type: typeof CONFIG_REQUEST
}

interface ReceiveAction extends ConfigData {
	type: typeof CONFIG_RECEIVE
}

type SETMAINCOLOR = {
	type: "SETMAINCOLOR",
	color: string,
}

type SETSUBCOLOR = {
	type: "SETSUBCOLOR",
	color: string,
}

type SETDATENSCHUTZ = {
	type: "SETDATENSCHUTZ",
	datenschutz: string,
}

type SETIMPRESSUM = {
	type: "SETIMPRESSUM",
	impressum: string,
}
type SETJOBEMAIL = {
	type: "SETJOBEMAIL",
	email: string,
}
type SETJOBUID = {
	type: "SETJOBUID",
	uid: string,
}
type SETPOSTSCONFIG = {
	type: "SETPOSTSCONFIG",
	posts: Config_Posts
}

type SETCOSTUMENMENU = {
	type: "SETCOSTUMENMENU",
	costumeMenu: t_costumeMenu
}

type SETMENUPOINT = {
	type: "SETMENUPOINT",
	menuPoint: MenuPoint,
}

type SETSUBPOINTS = {
	type: "SETSUBPOINTS",
	subPoints: SubPointTypes[],
}

type CLEARMENU = {
	type: "CLEARMENU",
}

export type ConfigActionTypes = RequestAction |
	ReceiveAction |
	SETMAINCOLOR |
	SETSUBCOLOR |
	SETDATENSCHUTZ |
	SETIMPRESSUM |
	SETJOBUID |
	SETJOBEMAIL |
	SETPOSTSCONFIG |
	SETMENUPOINT |
	SETSUBPOINTS |
	SETCOSTUMENMENU |
	CLEARMENU
	;
