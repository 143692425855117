import React from 'react';
import ActivityIndicator from './ActivityIndicator';
import styles from './ColorButton.module.css';
import {useAppSelector} from "store/hooks";



type t_props = {
    aktive?: boolean,
    text?: string,
    icon?: React.ComponentType<{ style?: React.CSSProperties }>,
    onClick?: (event: React.MouseEvent) => void,
    loading?: boolean,
    disableStyle?: React.CSSProperties|((style: {mainColor: string,subColor:string,mainTextColor:string,subTextColor:string})=>React.CSSProperties),
    style?: React.CSSProperties|((style: {mainColor: string,subColor:string,mainTextColor:string,subTextColor:string})=>React.CSSProperties),
    large?: boolean,
    fullWidth?: boolean,
    mainColor?: boolean,
    iconRight?: boolean,
    className?:string
}

const ColorButton = (props: t_props) => {
    const colors= useAppSelector((state) => ({
        mainColor: state.config.mainColor,
        mainTextColor: state.config.mainTextColor,
        subColor: state.config.subColor,
        subTextColor: state.config.subTextColor
    }))
    const color = props.mainColor?({back: colors.mainColor,text: colors.mainTextColor}):({back: colors.subColor,text: colors.subTextColor});
    return (
        <button
            className= {`${styles.tk_button} ${props.large && styles.tk_large} ${props.fullWidth && styles.tk_fullWidth} ${props.className??""}`}
            style={{ backgroundColor: color.back, color: color.text, ...(typeof props.style === "function"?props.style(colors):props.style), ...(props.aktive===undefined|| props.aktive?{}:typeof props.disableStyle === "function"? props.disableStyle(colors):props.disableStyle)}}
            onClick={(e)=>{!props.loading&&props.onClick&&props.onClick(e)}}>
            {props.loading === true ? (
                <div style={{ margin: 'auto' }}>
                    <ActivityIndicator light={color.text !== "rgb(0,0,0)"} />
                </div>
            ) : (
                <div style={{paddingLeft: 8,paddingRight: 8}} className={`${styles.tk_content}`}>
                    {props.icon && !props.iconRight && <props.icon />}
                    {props.icon && props.text && <div style={{ width: 8 }} />}
                    {props.text && props.text}
                    {props.icon && props.text && <div style={{ width: 4 }} />}
                    {props.icon && props.iconRight && <props.icon />}
                </div>
            )}
        </button>
    )
}
export default ColorButton;
