import { Role } from '@pixel-kraft/commulino-types'

export const ROLES_REQUEST = 'ROLES_REQUEST'
export const ROLES_RECEIVE = 'ROLES_RECEIVE'
export const ROLES_CREATE = 'ROLES_CREATE'
export const ROLES_DELETE = 'ROLES_DELETE'
export const ROLES_EDIT = 'ROLES_EDIT'

export interface RolesState {
	roles: Role[]
	loading: boolean
}

interface RequestAction {
	type: typeof ROLES_REQUEST
}

interface ReceiveAction {
	type: typeof ROLES_RECEIVE
	roles: Role[]
}

interface CreateAction {
	type: typeof ROLES_CREATE
}

interface DeleteAction {
	type: typeof ROLES_DELETE
}

interface EditAction {
	type: typeof ROLES_EDIT
}

export type RolesActionTypes =
	| RequestAction
	| ReceiveAction
	| CreateAction
	| DeleteAction
	| EditAction
