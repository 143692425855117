import React, {useEffect, useState} from 'react';
import {ResRelSDK} from "lib/sdk";
import {ActivityIndicator, Tag, Button, Row} from "components";
import {useAppDispatch, useAppSelector} from "store/hooks";
import {selectRelative} from "store/residentAndRelatives/store";
import {IconType} from "react-icons";

type t_inlineButton = {
    key: string,
    Icon: IconType,
    onClick: (event: React.MouseEvent, user: ResRelSDK.i_RelUser) => void
    className?: string,
    style?: React.CSSProperties,
    light?: boolean,
}

type t_ownRender = {
    renderRelative: (relative: ResRelSDK.i_RelUser)=>React.ReactNode,
    renderNoRelatives?: React.ReactNode,
    activeIndicator?: React.ReactNode,
}

type t_standard = {
    tagStyle? : React.CSSProperties,
    tagClassName?: string
    inlineButtons?: t_inlineButton[]|((data: ResRelSDK.i_RelUser)=>t_inlineButton[])
    inlineButtonsJustifyContent?: React.CSSProperties['justifyContent']
}

type t_props = {
    residentId: string,
} & (t_standard|t_ownRender)

const LoadRelatives = (props: t_props) => {
    const relatives = useAppSelector((state) => selectRelative(state, props.residentId));
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!relatives && !loading) {
            setLoading(true)
            dispatch(ResRelSDK.getRelatives((props.residentId))).finally(()=>setLoading(false))
        }
    }, [relatives, props.residentId, dispatch, loading])


    return (
        <div>
            {loading && (('activeIndicator' in props && props.activeIndicator)?props.activeIndicator:<ActivityIndicator/>)}
            {!loading && relatives?.map('renderRelative' in props?props.renderRelative:(user) => <div key={user.uid}>
                    <Row style={{width: '100%'}} justifyContent={'space-between'}>
                        <Row flex={0.8}>
                            <Tag className={props.tagClassName} style={{marginRight: 5,...props.tagStyle}}>{ResRelSDK.convertToContact(user).relatedContacts?.find((res)=>res.type==="resident"&&res.residentId===props.residentId)?.relation??"-"}</Tag>
                            <Tag className={props.tagClassName} style={{marginRight: 5,...props.tagStyle}}>{user.name}</Tag>
                        </Row>
                        {props.inlineButtons && (
                            <Row flex={0.2} justifyContent={props.inlineButtonsJustifyContent}>
                                {(typeof props.inlineButtons === 'function'?props.inlineButtons(user):props.inlineButtons).map(({Icon, onClick, style, key, className,light}) => (
                                    <Button
                                        key={key}
                                        icon={Icon}
                                        onClick={(event) => onClick(event, user)}
                                        cssStyle={style}
                                        style={className}
                                        light={light}
                                    />
                                ))
                                }
                            </Row>
                        )}

                    </Row>
                </div>
            )}
            {!loading && relatives?.length === 0 && ('renderRelative' in props ? props.renderNoRelatives??<Tag>None</Tag>:(
                <Tag>None</Tag>
            ))}
        </div>
    )
}

export default LoadRelatives;
