import React from "react"
import styles from "./CommuLinoLoading.module.css"
import logo from "../assets/commulino-white.svg";
import {ActivityIndicator} from "components/index";

const CommuLinoLoading = () => {
    return(
        <div className={styles.loadingScreen}>
            <img src={logo} className={styles.logo} alt='CommuLino Logo' style={{filter: 'invert(20%)'}} />
            <ActivityIndicator large style={styles.activeColor} />
        </div>
    )
}

export default CommuLinoLoading