import {AppThunk} from "store/store";
import {DeleteGroupImage} from "lib/media";
import {arrayRemove, deleteDoc, doc, getFirestore, updateDoc} from "firebase/firestore";
import {firebaseApp} from "lib/firebase";
import {t_Message} from "./types";
import {updateMessage as storeUpdateMsg} from "store/chat/actions";

namespace listener {
    type t_listener = () => void;

    let chat: t_listener | undefined;
    const messages = new Map<string, t_listener>();

    export const setChatListener = (l: t_listener) => {
        removeChatListener();
        chat = l;
    }

    export const removeChatListener = () => {
        if (chat) chat();
        for (const [key, l] of messages) {
            l();
        }
        messages.clear();
    }

    export const removeMessageListener = (chatId: string) => {
        const l = messages.get(chatId);
        if (l) {
            l();
            messages.delete(chatId);
        }

    }

    export const addMessageListener = (chatId: string, l: t_listener) => {
        removeMessageListener(chatId);
        messages.set(chatId, l);
    }
}

namespace chat {
    export const path = 'chats';

    export const deleteChat = async (chatId: string, hasImage?: boolean) => {
        if (hasImage)
            await DeleteGroupImage(chatId);
        await deleteDoc(doc(getFirestore(firebaseApp), path, chatId));
    }

    export const rmChatLocal = (chatId: string, serverAction: () => Promise<void>): AppThunk<Promise<void>> => {
        return async (dispatch) => {
            listener.removeMessageListener(chatId);
            await serverAction();
            dispatch({type: "MESSENGER_DELETE_CHAT", chatID: chatId})
        }
    }

    export namespace messages {
        export const path = 'messages';

        export const updateMessage = (chatId: string, id: string, msg: Partial<Omit<t_Message, 'id'>>): AppThunk<Promise<void>> => {
            return async (dispatch) => {
                await updateDoc(doc(getFirestore(), chat.path, chatId, path, id), msg);
                dispatch(storeUpdateMsg(chatId, id, msg))
            }
        }

    }
}

export const updateDocumentation = (chatId: string, id: string, documentation: boolean): AppThunk<Promise<void>> => {
    return async (dispatch) => {
        await dispatch(chat.messages.updateMessage(chatId, id, {documentation}));
    }
}

export const deleteChat = (chatId: string): AppThunk<Promise<void>> => {
    return async (dispatch, getState) => {
        await dispatch(chat.rmChatLocal(chatId, () => chat.deleteChat(chatId, !!getState().messenger.Chats[chatId]?.group?.photo)));
    }
}

export const leaveChat = (chatId: string): AppThunk<Promise<void>> => {
    return async (dispatch, getState) => {
        await dispatch(chat.rmChatLocal(chatId, () => updateDoc(doc(getFirestore(firebaseApp), 'chats', chatId), {
                uids: arrayRemove(getState().auth.uid)
            }
        )));
    }
}

export const setChatListener = listener.setChatListener;
export const removeChatListener = listener.removeChatListener;
export const addMessageListener = listener.addMessageListener;
export const removeMessageListener = listener.removeMessageListener;
