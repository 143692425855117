import {Button} from 'components/index';
import {getInvertColor} from 'lib/colors';
import React from 'react';
import {RgbStringColorPicker} from "react-colorful";
import {FiX} from 'react-icons/fi';

type t_props = {
    color: string,
    onChange: (change: string) => void,
    oldColor?: string,
    text?: string,
    CostumePreview?: React.ComponentType<{ color: string, invertColor: string }>,
}

const ColorPicker = (props: t_props) => {
    if(props.text&&props.CostumePreview) throw new Error("Invalide props of ColorPicker. Only use text or CostumePreview not both");

    const invertColor=getInvertColor(props.color);

    return (
        <div style={{display: 'flex', flexDirection: 'row',flexGrow: 1}}>
            <RgbStringColorPicker color={props.color} onChange={(c) => {
                props.onChange(c)
            }}/>

            {props.text && <div style={{
                width: 200,
                height: 200,
                borderRadius: 10,
                marginLeft: 20,
                textAlign: 'center',
                backgroundColor: props.color,
                padding: 5
            }}>
                <p style={{color: invertColor}}>{props.text}</p>
            </div>
            }
            {props.CostumePreview && <props.CostumePreview color={props.color} invertColor={invertColor} />}
            <div style={{marginLeft: 20}}>
                {props.oldColor && props.color !== props.oldColor &&
                    <Button icon={FiX} light text='Zurücksetzen' onClick={() => {
                        if (props.oldColor)
                            props.onChange(props.oldColor);
                    }}/>
                }
            </div>
        </div>
    )
}
export default ColorPicker;
