import {MessageData, MessengerPostAttachments} from "@pixel-kraft/commulino-types";
import * as media from "lib/media";
import {t_MessageData} from '../type'
import {firebaseApp} from "lib/firebase";
import {getFirestore, doc, addDoc, setDoc, collection, updateDoc} from "firebase/firestore";


const uploadImage=async (img:string,chatID:string)=>{
    const image = new Image();
    image.src = img;
    let original:string;
    let storageRef:string;
    if(img.includes("https://firebasestorage.googleapis.com")){
        // const tmp= await moveFirebaseFile(img,`messenger/${chatID}/images/`);
        // original=tmp.original;
        // storageRef=tmp.storageRef;
        throw new Error("Image forward not implement");
    } else {
        const images = await media.uploadImage(image.src, `messenger/${chatID}/images/`,image.src.startsWith('data:image/gif;')?undefined:1600,0.7);
        const tmp = images[0];
        original=tmp.original;
        storageRef=tmp.storageRef;
    }
    return {uri: original, storageRef, width: image.width, height: image.height};
}
const uploadFile=async (file:File,to:string)=>{
    const {original, storageRef} = await media.uploadDocument(
        file,
        file.name,
        `messenger/${to}/documents/`
    )
    return {name: file.name, source: original, size: file.size, storageRef}
}
const toFirebaseMsgData=(msg:t_MessageData):MessageData=>{
    delete msg.toUploadFiles;
    delete msg.toUploadImages;
    delete msg.event;
    return msg;
}

/**
 * Sendet eine Nachricht in einen Chat.
 * @param to chat ID
 * @param msg Die Nachricht
 * @param from Wenn gesetzt wird die uid in msg durch diesen Wert ersetzt.
 */
const sendToChat = async (to: string, msg:t_MessageData,from?: string,forward?:boolean) => {
    if(from!==undefined)
        msg.uid=from;
    if(msg.toUploadImages!==undefined){
        for(let i:number=0;i<msg.toUploadImages.length;++i){
            if(msg.images===undefined)
                msg.images=[await uploadImage(msg.toUploadImages[i], to)];
            else
                msg.images[i]=await uploadImage(msg.toUploadImages[i], to);
        }
    }
    if(msg.toUploadFiles!==undefined){
        for(let i:number=0;i<msg.toUploadFiles.length;++i){
            if(msg.attachments===undefined)
                msg.attachments=[await uploadFile(msg.toUploadFiles[i], to)];
            else
                msg.attachments[i]=await uploadFile(msg.toUploadFiles[i], to);
        }
    }
    if(msg.event!==undefined) {
        const eventFire = await addDoc(collection(getFirestore(firebaseApp),'events'),msg.event);
        const att: MessengerPostAttachments = {type: 'event', id: eventFire.id};
        if (!msg.postAttachments)
            msg.postAttachments = [att];
        else
            msg.postAttachments.push(att);
    }

    const sendMsg:MessageData=toFirebaseMsgData(msg);
    if(forward) sendMsg.forwarded=forward;
    const db=getFirestore(firebaseApp);
    await addDoc(collection(db,'chats',to,'messages'),sendMsg);
    await updateDoc(doc(db,'chats',to),{
        readBy: [sendMsg.uid],
        date: Date.now()
    })
    await setDoc(doc(db,'users',sendMsg.uid,'counters','chat:' + to),{
        'unread': 0
    })
}
export default sendToChat;
