import React from 'react';
import {IconType} from "react-icons";
import {ColorButton, Row} from "components";
import {getInvertColor, toRGB} from "lib/colors";

//Build after https://callstack.github.io/react-native-paper/segmented-buttons.html

type t_button<T> = {
    icon?: IconType,
    value: T,
    label: string,
    costumeTheme?: {
        activeColor: React.CSSProperties['color'],
        defaultColor: React.CSSProperties['color']
    },
    style?: React.CSSProperties
}

export type SegmentButtonsProps<T> = {
    value: T,
    onValueChange: (value: T) => void,
    options?: {
        defaultColor: 'mainColor' | 'subColor' | React.CSSProperties['color'],
        activeColor: 'mainColor' | 'subColor' | React.CSSProperties['color']
    }
    buttons: t_button<T>[],
    style?: React.CSSProperties,
    buttonStyle?: React.CSSProperties
}



const getColor = (style: { mainColor: string, subColor: string, mainTextColor: string, subTextColor: string }, active: boolean, costumeTheme?: t_button<any>['costumeTheme'], options?: SegmentButtonsProps<any>['options']) => {
    const use = active ? 'activeColor' : 'defaultColor';
    if (costumeTheme && costumeTheme[use]) {
        const color = costumeTheme[use];
        if (!color) throw Error("Color is not define")
        return ({
            back: color,
            text: getInvertColor(toRGB(color))
        })
    }
    if (options && options[use]) {
        if (options[use] === "mainColor")
            return ({
                back: style.mainColor,
                text: style.mainTextColor
            })
        if (options[use] === "subColor")
            return ({
                back: style.subColor,
                text: style.subTextColor
            })
        const color = options[use];
        if (!color) throw Error("WTF");
        return ({
            back: color,
            text: getInvertColor(toRGB(color))
        })
    }

    if (active)
        return ({
            back: style.mainColor,
            text: style.mainTextColor
        })
    return ({
        back: style.subColor,
        text: style.subTextColor
    })

}


//Stateless Component
const SegmentedButtons = <T, >({
                                   buttons,
                                   onValueChange,
                                   value,
                                   options = {activeColor: 'mainColor', defaultColor: 'subColor'},
                                   style,
                                   buttonStyle
                               }: SegmentButtonsProps<T>) => (
    <Row
        flex={1}
        style={{
            borderRadius: style?.height ?? 25,
            border: '1px solid black',
            overflow: 'hidden',
            ...style
        }}
    >
        {buttons.map(({value: buttonVal, label, costumeTheme, icon, style}, index, array) => (<ColorButton
            key={label}
            icon={icon}
            text={label}
            onClick={() => onValueChange(buttonVal)}
            style={(style) => {
                const color = getColor(style, value === buttonVal, costumeTheme, options);
                return ({
                    color: color.text,
                    backgroundColor: color.back,
                    borderRadius: 'unset',
                    borderRight: index < array.length - 1 ? '1px solid black' : undefined,
                    ...buttonStyle,
                    ...style
                })
            }}
        />))}
    </Row>
)

export default SegmentedButtons;
