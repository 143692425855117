import React, {ReactElement} from 'react';
import useWindowDimensions from "lib/useWindowDimensions";

type t_params = {
    children: ReactElement|ReactElement[],
    height?: number,
}

const Overlay=(params:t_params)=>{
    const { height } = useWindowDimensions();
    return (
        <div style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            height: height,
            backgroundColor: 'rgba(0,0,0,0.8)'
        }}
        >
            <div style={{
                zIndex: 100,
                width: 500,
                top: 0,
                bottom: 0,
                backgroundColor: 'white',
                borderRadius: 20,
                margin: 'auto',
                height: params.height?params.height:((height + 100) / 2),
                position: 'fixed',
                left: '50%'
            }}>
                {params.children}
            </div>
        </div>
    )
}
export default Overlay;