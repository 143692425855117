import React, {useEffect, useState} from "react"
import styles from "./NewCalendar.module.css"
import Tippy from "@tippyjs/react";
import {
    Button,
    ColorButton,
    ContentContainer,
    FormItem,
    Modal,
    Typo,
    GroupSelector,
    FilePicker,
    CommuLinoLoading, CheckBox
} from "components";
import {RgbStringColorPicker} from "react-colorful";
import {BsCalendarCheck, FaRegCalendarPlus, GoCalendar, IoCloseOutline} from "react-icons/all";
import { getInvertColor } from 'lib/colors';
import {useSelector} from "react-redux";
import {RootState} from "store/reducers";
import {Calendars} from "../index";
import * as api from 'lib/api'
import {Department, Location} from "@pixel-kraft/commulino-types";
import {t_eventData} from "components/Kalender/types";
import {ArrowUturnLeftIcon} from "@heroicons/react/20/solid";
import {addDoc, collection, getFirestore} from "firebase/firestore";
import {firebaseApp} from "lib/firebase";

import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import '../../../navigation/simpleBarStyle.css'

interface Props {
    calendars: Calendars[],
    setChange: (to: Partial<t_eventData>) => void,
}

interface GoogleCalendarForm {
    type: 'google',
    color: string,
    summary: string,
    userId: string | null,
    allowEventRequest: boolean,
}

interface OutlookCalendarForm {
    type: 'outlook',
    color: string,
    summary: string,
    userId: string | null,
    url: string,
    allowEventRequest: boolean,
}

type CalendarForm = GoogleCalendarForm | OutlookCalendarForm

interface Message {
    noName:boolean,
    taken:boolean,
    emptyURL:boolean,
    takenURL:boolean,
}

export const createGroupObject = (departments:string[], locations:string[], folders:boolean=false) => {
    const objectData:{departments: Department[], locations: Location[], groups?: string[]} = {
        departments: !folders && departments.length === 0 ? ["test"] : departments,
        locations: !folders && locations.length === 0 ? ["test"] : locations,
        groups:[],
    }

    const groups:any = []
    objectData.locations.forEach((a)=>objectData.departments.forEach((b)=>groups.push(a+"_"+b)))
    objectData.groups = groups

    return objectData
}

const NewCalendar = ({calendars}: Props) => {
    const [modal, setModal] = useState(false);
    const [message, setMessage] = useState<Message>({noName:false, taken:false, emptyURL:false, takenURL:false});
    const [departments, setDepartments] = useState<Department[]>([]);
    const [locations, setLocations] = useState<Location[]>([]);
    const [file, setFile] = useState<File|undefined>(undefined);
    const [modalObject, setModalObject] = useState<{type:string, created:string, failed:string}>({type:"form", created:"", failed:""});
    const [newCalendar, setNewCalendar] = useState<CalendarForm>({
        summary:"", color:"rgb(255, 85, 0)", userId: useSelector((state:RootState)=> state.auth.uid), type: 'google', allowEventRequest: false,
    })

    const clean = () => {
        setModal(false);
        setFile(undefined);
        setNewCalendar({...newCalendar, summary:"", type: "google", color:"rgb(255, 85, 0)", allowEventRequest: false},);
        setModalObject({type:"form", created:"", failed:""});
    }

    const handleSubmit = async () => {
        const _name=newCalendar.summary.trim();
        const objectData = createGroupObject(departments,locations);
        const nameAlreadyTaken = calendars.some(calendar => calendar.name === _name);

        if (_name !== "" && !nameAlreadyTaken) {
            switch (newCalendar.type) {

                case "google": // The URL is created on the server
                    setModalObject({...modalObject,type:"loading"});
                    const create = await api.put('calendars', { ...newCalendar, ...objectData })
                    if (file) {
                        const formData = new FormData()
                        formData.append("ics", file)
                        await api.upload(`calendars/${create.id}/import`,formData)// Creates a google calendar and a Firebase entry.
                            .then(()=> {
                                setModalObject({...modalObject,type:"response"})
                            })
                            .catch((e)=>{
                                setModalObject({type:"response", created:e.message.match(/"created":([0-9]*)[},"]/)[1].toString(),failed:e.message.match(/"failed":([0-9]*)[},"]/)[1].toString()})
                            })
                    } else clean();
                    break;

                case "outlook": // The URL is provided by the user.
                    const URLAlreadyTaken = calendars.some(calendar => calendar.calendarId === newCalendar.url);
                    if (newCalendar.url !== "" && !URLAlreadyTaken) {
                        setModalObject({...modalObject,type:"loading"});
                        try {
                            // To make it meaningful
                            const outlookObjectFirebase:{[key:string]:string|null|boolean,} = {...newCalendar, name:newCalendar.summary, calendarId:newCalendar.url, timeZone:"Europe/Berlin"};
                            delete outlookObjectFirebase['url'];
                            delete outlookObjectFirebase['summary'];
                            await addDoc(collection(getFirestore(firebaseApp), "calendars"), {...outlookObjectFirebase, ...objectData})
                                .then(() => clean());
                        } catch (e) {
                            console.log(e)
                        }
                    } else {
                        URLAlreadyTaken ? setMessage({...message, takenURL:true}) : setMessage({...message, emptyURL:true})
                    }
                    break;
            }
        } else {
            if (nameAlreadyTaken) setMessage({...message, taken:true});
            if (_name === "") setMessage({...message, noName:true});
        }

        const timer = setTimeout(()=>{
            setMessage({noName:false, taken:false, emptyURL:false, takenURL:false})
        },4000)
        return () => clearTimeout(timer);
    }

    const [beat, setBeat] = useState(false)

    useEffect( ()=> {
        function beating () {
            setTimeout(()=>{
                setBeat(true)
            },100)
            setTimeout(()=>{
                setBeat(false)
            },500)
        }
        if (calendars.length === 0) {
            const interval = setInterval(()=>{
                beating()
                setTimeout(()=> {
                    return clearInterval(interval)
                },5000)
            },1500)
        }

    },[])

    const modalContent = (type:string) => {
        switch (type) {
            case "form":
                return (
                    <ContentContainer
                        style={styles.container_width}
                        hideSpacer
                        contentStyle={styles.container_height}
                    >
                        {modal && (
                            <div className={styles.simpleBarContainer}>
                                <div className={styles.simpleBarWrapper}>
                                    <SimpleBar style={{height: '100%'}}>
                                        <Typo variant='h3'>
                                            Neuer Kalender
                                        </Typo>
                                        <div style={{position:"relative"}}>
                                            <FormItem
                                                className={message.noName ? styles.formInputMessage : styles.formInput}
                                                type='text'
                                                name={'Name des Kalenders'}
                                                value={newCalendar.summary}
                                                onChange={(summary) => setNewCalendar({...newCalendar, summary})}
                                                maxLength={30}
                                            />
                                            {message.noName && <div style={{position:"absolute", color: "#ff5500"}}>Bitte geben Sie einen Namen ein.</div>}
                                            {!message.noName && message.taken && <div style={{position:"absolute", color: "#ff5500"}}>Ein Kalender mit dem Namen existiert bereits.</div>}
                                            <span className={styles.number} style={newCalendar.summary.length === 30 ? {color:'#ff5500'} : {color: '#949dad'}}>{newCalendar.summary.length}/30</span>
                                        </div>
                                        <div style={{display: "flex", width: "100%"}}>
                                            <div style={{margin:"20px 0", width:"60%"}}>
                                                <div className={styles.label}>Wählen Sie die Farbe des Kalenders</div>
                                                <RgbStringColorPicker className={styles.colorPicker} color={newCalendar.color} onChange={(c)=>setNewCalendar({...newCalendar, color:c})}/>
                                                <ColorButton
                                                    style={{width:"69%",marginTop:"15px", backgroundColor:newCalendar.color, color: getInvertColor(newCalendar.color)}}
                                                    text={'Zufällig'}
                                                    onClick={() => {
                                                        const newColor = `rgb(${Math.floor(Math.random()*255)}, ${Math.floor(Math.random()*255)}, ${Math.floor(Math.random()*255)})`
                                                        setNewCalendar({...newCalendar, color:newColor})
                                                    }}
                                                />
                                            </div>
                                            <div style={{position:"relative", width: "40%"}}>
                                                <div className={styles.divExampleWrapper}>
                                                    <GoCalendar className={styles.iconExample} style={{color:newCalendar.color}}/>
                                                    <div style={{fontSize:"17px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                                                        {newCalendar.summary.trim()? newCalendar.summary : "Kalender name"}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {newCalendar.type !== 'outlook' && (
                                            <div style={{position:"relative", maxWidth:"486px"}}>
                                                {file? (
                                                    <div className={styles.icsWrapper}>
                                                        <BsCalendarCheck className={styles.icsIcon} style={{color:newCalendar.color}}/>
                                                        <div className={styles.fileName}>{file?.name.trim()}</div>
                                                        <IoCloseOutline className={styles.icsFileClose} onClick={()=>setFile(undefined)}/>
                                                    </div>
                                                ):(
                                                    <>
                                                        <ColorButton
                                                            text={"Import ICS Kalender Datei"}
                                                            onClick={()=>{ }}
                                                            style={file?{}:{backgroundColor:"rgb(211 211 211)"}}
                                                        />
                                                        <FilePicker
                                                            notOnRender={true}
                                                            onlyButton={true}
                                                            className={styles.file}
                                                            onChange={setFile}
                                                            icon={undefined}
                                                            accept={".ics"}
                                                        /></>
                                                )}
                                            </div>
                                        )}
                                        {!file && (
                                            <div>
                                                {newCalendar.type === "google"? (
                                                    <ColorButton
                                                        text={"Import ICS Kalender nach URL"}
                                                        onClick={()=> setNewCalendar({...newCalendar, type:"outlook", url:"", allowEventRequest: false})}
                                                        style={file?{}:{backgroundColor:"rgb(211 211 211)"}}
                                                    />
                                                ):(
                                                    <div style={{display:"flex", position: "relative"}}>
                                                        <FormItem
                                                            className={message.emptyURL ? styles.formInputMessage : styles.formInput}
                                                            type='text'
                                                            name={'Outlook URL'}
                                                            value={newCalendar.url}
                                                            onChange={(url) => setNewCalendar({...newCalendar, url})}
                                                            classNameInput={styles.formInputPadding}
                                                        />
                                                        <div className={styles.urlInputIconWrapper}>
                                                            <div className={styles.urlInputIconContainer}>
                                                                <ArrowUturnLeftIcon
                                                                    className={styles.urlIcon}
                                                                    onClick={()=>setNewCalendar({...newCalendar, type:"google"})}
                                                                />
                                                            </div>
                                                        </div>
                                                        {message.emptyURL && <div style={{position:"absolute", color: "#ff5500", bottom:"-20px"}}>Bitte geben Sie eine URL ein.</div>}
                                                        {message.takenURL && <div style={{position:"absolute", color: "#ff5500", bottom:"-20px"}}>Dieser Outlook-Kalender ist bereits importiert.</div>}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        <div style={{marginTop:"10px"}}>
                                            <div className={styles.label}>Kalender Einstellungen</div>
                                            <div style={newCalendar.type === "outlook" ? {opacity: "0.6"} : {opacity:"1"}}>
                                                <CheckBox
                                                    text={'Terminwunsch Eintragung über den "Für Dich" Bereich für jeden User, der den Kalender sehen kann zulassen.'}
                                                    checked={newCalendar.type === "outlook" ? false : newCalendar.allowEventRequest}
                                                    onChange={()=>newCalendar.type === 'google' && setNewCalendar(prevState => ({...prevState, allowEventRequest: !prevState.allowEventRequest}))}
                                                    style={styles.checkBox_height}
                                                    labelStyles={styles.labelStyles}
                                                />
                                            </div>
                                        </div>
                                        <div style={{width:"98%"}}>
                                            <GroupSelector
                                                selectedDepartments={departments}
                                                selectedLocations={locations}
                                                onDepartmentsSelect={setDepartments}
                                                onLocationsSelect={setLocations}
                                                showAll
                                            />
                                        </div>
                                        <div className={styles.buttons}>
                                            <Button
                                                text='Abbrechen'
                                                light
                                                style={styles.cancelButton}
                                                onClick={()=> {
                                                    clean();
                                                }}
                                            />
                                            <ColorButton
                                                style={{margin: "auto 4px"}}
                                                text="Speichern"
                                                onClick={handleSubmit}
                                            />
                                        </div>
                                    </SimpleBar>
                                </div>
                            </div>
                        )}
                    </ContentContainer>
                );
            case "loading":
                return (
                    <CommuLinoLoading/>
                )
            case "response":
                return (
                    <ContentContainer
                        hideSpacer
                    >
                        <div className={styles.responseContainer}>
                            {modalObject.failed === "" ? (
                                <div style={{marginTop: "20px", textAlignLast:"center"}}>
                                    Alle Ereignisse sind korrekt hinzugefügt worden.
                                </div>
                            ):(
                                <div>
                                    <div style={{marginBottom:"15px"}}>
                                        Fehler bei Terminimport
                                    </div>
                                    <div style={{textAlign:"justify"}}>
                                        Leider gab es einen Fehler beim übertragen der Termine. {modalObject.failed} von {modalObject.created} Terminen konnten nicht in den neu angelegten Kalender übernommen werden. Bitte prüfen Sie den Kalender und ergänzen Sie die fehlenden Termine oder starten Sie den Terminimport erneut.
                                    </div>
                                </div>

                            )}
                        </div>
                        <div className={styles.buttons}>
                            <ColorButton
                                style={{margin: "auto 4px"}}
                                text="Import abschliessen"
                                onClick={()=> {
                                    clean()
                                }}
                            />
                        </div>
                    </ContentContainer>
                )
        }
    }

    return (
        <div
            className={calendars.length === 0 ? styles.focus : styles.divWidth}
        >
            <Tippy
                content="Einen neuen Kalender erstellen"
                delay={[400,0]}
                className={styles.tippy}
                arrow={false}
            >
                <div
                    tabIndex={0}
                    className={styles.iconTextContainer}
                    onClick={()=> {
                        setModalObject({...modalObject,type:"form"})
                        setModal(true)
                    }}
                >
                    <div className={styles.iconTextWrapper}>
                        <FaRegCalendarPlus
                            className={calendars.length === 0 ? beat ? styles.iconRegBeat : styles.iconReg : styles.iconRegCalendars}
                        />
                        <div>Kalender</div>
                    </div>
                </div>
            </Tippy>
            <Modal
                isVisible={modal}
                hide={()=>null}
                modalStyle={styles.modal_width}
                contentStyle={styles.overflowHidden}
            >
                {modalContent(modalObject.type)}
            </Modal>
        </div>
    )
}

export default NewCalendar
