import {MenuPoint,Schema,i_DestinationPost, SubPointTypes} from "@pixel-kraft/commulino-types";
import {
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    getFirestore,
    orderBy,
    query,
    setDoc,
    updateDoc, where
} from "firebase/firestore";
import {firebaseApp} from "lib/firebase";
import {t_SubPointTypes} from "pages/settings/costumeMenuConfig/SubPoint/SubPointTypes";
import QueryBuilder from "lib/QueryBuilder";
import {AppThunk} from "store/store";

const subPath: string = 'config/costumeMenu/subPoints';
const mainPath: string = 'config/costumeMenu';

export const getMainPoint = async () => {
    return (await getDoc(doc(getFirestore(), mainPath))).data() as MenuPoint;

}

export const updateMainPoint = async (mainPoint: MenuPoint) => {
    return await setDoc(doc(getFirestore(), mainPath), {...mainPoint},{merge: true});
}

/**
 * Warning: For what ever reason the value of a Post SubPoint is a string for what ever reason.
 * The Object is cloned so its not a ref error.
 * TODO: Find the reason why the type of destination.content change to string by SubPoint Post
 */
export const setSubPoint = async (subPoint: t_SubPointTypes) => {
    const point={...subPoint};
    if (point.type === 'post') {
        (point.destination as any).content = JSON.stringify((point.destination as i_DestinationPost['value']).content);
        console.log(point);
    }
    return await setDoc(doc(getFirestore(), subPath, point.id), {...point})
}

/**
 * Return a firestore id so we don't need uuid4
 */
export const getId = () => {
    return doc(collection(getFirestore(firebaseApp), subPath)).id;
}

export const updateSubPointOrder = async (subPoint: t_SubPointTypes) => {
    return await updateDoc(doc(getFirestore(), subPath, subPoint.id), {'order': subPoint.order});
}

/**
 * Update SubPoint. Only values that are given will be updatet.
 * @param id Id of to update SubPoint
 * @param subPoint Contains only the members you want to update
 */
export const updateSubPoint = async (id: string, subPoint: Partial<t_SubPointTypes>) => {
    return await updateDoc(doc(getFirestore(), subPath, id), {...subPoint});
}

export const deleteSubPoint = async (subPoint: t_SubPointTypes) => {
    return await deleteDoc(doc(getFirestore(), subPath, subPoint.id));
}

const _getSubPoints = async (userMode?: 'worker'|'mediCare',forceLoadAll?: boolean)=>{
    const q = new QueryBuilder(collection(getFirestore(), subPath), orderBy('order'));
    if(!forceLoadAll)
        q.add(where('visibility','in',[userMode??[],'all'].flat()));
    const docs = await getDocs(query(q.getQuery()));
    const subPoints: t_SubPointTypes[] = [];
    docs.forEach(doc => {
        const data = {...doc.data()};
        if(data.type==='post') {
            data.destination.content = JSON.parse(data.destination.content) as Schema;
        }
        subPoints.push(data as t_SubPointTypes);
    })
    return subPoints;
}

export const getSubPoints = (forceLoadAll?: boolean):AppThunk<Promise<t_SubPointTypes[]>> => {
    return async (dispatch, getState) => {
       const {mediCare,uid} = getState().auth;
       return await _getSubPoints(uid?mediCare?"mediCare":'worker':undefined,forceLoadAll);
    }
}
