import { ROLES_REQUEST, ROLES_RECEIVE, ROLES_CREATE, ROLES_DELETE, ROLES_EDIT } from './types'
import { Role, Permissions } from '@pixel-kraft/commulino-types'
import { AppThunk } from '../store'
import { firebaseApp } from 'lib/firebase'
import {addDoc, collection, doc, getDocs, getFirestore, updateDoc} from "firebase/firestore";
import * as api from 'lib/api'

export const getRoles = (): AppThunk => {
	return async dispatch => {
		dispatch({ type: ROLES_REQUEST })

		let roles: Role[] = [];
		const snapshots = await getDocs(collection(getFirestore(firebaseApp),'roles'));
		snapshots.forEach(snapshot => {
			const role: Role = {
				id: snapshot.id,
				...(snapshot.data() as { name: string; permissions: Permissions })
			}
			roles.push(role)
		})

		dispatch({ type: ROLES_RECEIVE, roles })
	}
}

export const addRole = (role: Omit<Role, 'id'>): AppThunk => {
	return async dispatch => {
		dispatch({ type: ROLES_CREATE })
		await addDoc(collection(getFirestore(firebaseApp),'roles'),role);
		dispatch(getRoles())
	}
}

export const deleteRole = (id: string): AppThunk => {
	return async dispatch => {
		dispatch({ type: ROLES_DELETE })

		await api.delete('settings/roles', { id })

		dispatch(getRoles())
	}
}

export const editRole = (role: Role): AppThunk => {
	return async dispatch => {
		dispatch({ type: ROLES_EDIT })

		const { id, ...data } = role;
		await updateDoc(doc(getFirestore(firebaseApp),'roles',id),{...data});
		dispatch(getRoles())
	}
}
