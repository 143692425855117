import 'react-app-polyfill/ie9'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import * as Sentry from '@sentry/browser'

import Navigation from './navigation/Navigation'

import store from './store/reducers'
import { getConfig } from 'store/config/actions'
import { getRoles } from 'store/roles/actions'

store.dispatch<any>(getConfig())
store.dispatch<any>(getRoles())

if (process.env.NODE_ENV === 'production') {
	Sentry.init({ dsn: 'https://f282d9ba076e4600aeea9cbd630e8add@sentry.pixel-kraft.de/4' })
}
if('serviceWorker' in navigator) {
	const sw='firebase-messaging-sw.js'
	navigator.serviceWorker.register(process.env.PUBLIC_URL + '/'+sw).then(() => {
		console.info('Client', 'Register '+sw)
	}).catch((e) => {
		console.warn('Client', "Failed to register "+sw, e)
	})
}

const App = () => (
	<Provider store={store}>
		<BrowserRouter>
			<Navigation />
		</BrowserRouter>
	</Provider>
)

ReactDOM.render(<App />, document.getElementById('root'))
