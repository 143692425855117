import * as Ionicons from 'react-icons/io5'

export function expoIconToReactIconName(icon: string): keyof typeof Ionicons {
	const iconName = icon.charAt(4).toUpperCase() + icon.slice(5)

	const reactIconName = 'Io' + iconName.replace(/-./g, match => match[1].toUpperCase())

	return reactIconName as keyof typeof Ionicons
}

export function reactIconToExpoIconName(icon: string) {
	const iconName = icon.replace(/^Io/g, '');

	let format = []

	// eslint-disable-next-line
	for (let c of iconName) {
		if (c === c.toLowerCase()) {
			format.push(c)
		} else {
			format.push('-' + c.toLowerCase())
		}
	}

	return 'ios' + format.join('')
}
