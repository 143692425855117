import React, {lazy, Suspense, useEffect} from 'react';
import {useSelector} from "react-redux";
import {RootState} from "store/reducers";
import {ActivityIndicator} from "components";

const YouTubeEditor = lazy(() => import('./YouTube/YouTubeEdit'));
const VimeoEditor = lazy(() => import('./Vimeo/VimeoEdit'));

const VideoEditor = () => {
    const youTube = useSelector((state: RootState) => state.posts.editing.data?.video);
    const vimeo = useSelector((state:RootState)=>state.posts.editing.data?.vimeo);

    if (youTube!==undefined || vimeo)
        return (
            <Suspense fallback={<ActivityIndicator large/>}>
                {youTube!==undefined?<YouTubeEditor youtube={youTube} vimeo={vimeo}/>:<VimeoEditor vimeo={vimeo}/>}
            </Suspense>
        )
    return null;
}
export default VideoEditor;