import React from 'react'
import styles from './RoundButton.module.css'

interface IconProps {
	onClick: () => void
	className: string
	style?: React.CSSProperties
}

interface Props {
	icon: React.ComponentType<IconProps>
	onClick: () => void
	style?: string
}

const RoundButton = ({ icon: Icon, onClick, style }: Props) => {
	return <Icon onClick={onClick} className={`${styles.button} ${style}`} />
}

export default RoundButton
