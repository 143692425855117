import {t_chatTypes} from "./types";
import {t_Contact} from "@pixel-kraft/commulino-types";

export const compare=<T extends {ID: string}>(a:T,b:T)=>a.ID===b.ID;

export const compareChatUser =<T extends {uid: string},S extends {uid: string}>(a: T,b: S)=>a.uid===b.uid;


export const isRel = (a: t_Contact|t_chatTypes.t_chatUser)=>("type" in a && a.type==="relative") || "relation" in a;
export const isRes = (a: t_Contact|t_chatTypes.t_chatUser)=>("type" in a && a.type==="resident") || "residentId" in a;
export const isUser= (a: t_Contact|t_chatTypes.t_chatUser)=>("type" in a && a.type==="user") || !(isRel(a)||isRes(a));

const sortContact = (a: t_Contact,b: t_Contact,uid?: string|null) => {
    a.relatedContacts?.sort(sortContact);
    b.relatedContacts?.sort(sortContact);
    if(isUser(a) && !isUser(b)) return -1;
    if(isUser(b) && !isUser(a)) return 1;
    if(isRel(a) && !isRel(b)) return 1;
    if(isRel(b) && !isRel(a)) return -1;
    if(a.uid===uid) return -1;
    if(b.uid===uid) return 1;

    return a.name?.toUpperCase()>b.name?.toUpperCase()?1:-1
}

export const sortContacts = (contacts: t_Contact[],uid?: string|null) => contacts.sort((a,b)=>sortContact(a,b,uid));


export const search = <T extends {name: string},>(name: string,item: T) => item.name.toUpperCase().includes(name.toUpperCase());
