import {FiArchive, FiUsers} from "react-icons/fi";
import {FaClinicMedical} from "react-icons/fa";

export const users = {
    label: 'Mitarbeiter',
    icon: FiUsers
}

export const mediCare = {
    label: 'Bewohner',
    icon: FaClinicMedical
}

export const archive = {
    label: 'Archive',
    icon: FiArchive
}
