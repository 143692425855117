import React from 'react';
import {Row} from "components";

type t_props = {
    children: string | number,
    className?: string,
    style?: React.CSSProperties
}

const Bubble = ({children, style, className}: t_props) => (
        <Row
            className={className}
            justifyContent={'center'}
            style={{width: 23.8, borderRadius: '50%', backgroundColor: '#ff0000', color: 'white', ...style}}>
            {children}
        </Row>
)

export default Bubble;
