import React from 'react';

import styles from './ChatButton.module.css'

type t_props = {
    onClick: () => void,
    stopPropagation?: boolean,
    style?: React.CSSProperties,
    iconStyle?: React.CSSProperties,
    icon?: React.ComponentType<{ style?: React.CSSProperties }>,
    text?: string,
    right?: boolean,
    className?:string,
}
/**
 * Redenert einen Button bei dem der Style angepasst werden kann
 * @param right Gibt an ob das Icon Rechts under Links vom Text steht.
 */
const ChatButton = React.forwardRef<HTMLDivElement,t_props>((props,ref) => {
    return (
        <div ref={ref} className={`${styles.tk_ChatButton} ${props.className}`} style={props.style} onClick={(e)=>{
            if(props.stopPropagation)
                e.stopPropagation()
            props.onClick();
        }}>
            {props.icon && (props.right === undefined || !props.right) && <props.icon style={props.iconStyle} />}
            {props.text && props.text}
            {props.icon && (props.right !== undefined && props.right) && <props.icon style={props.iconStyle} />}
        </div>
    )
});
ChatButton.displayName='ChatButton';
export default ChatButton;
