/**
 * Diese Datei enthält alles notwendige für FMC
 */
import {logoSvg} from 'config'
import {doc, getFirestore, serverTimestamp, setDoc} from 'firebase/firestore';
import {firebaseApp} from 'lib/firebase';
import {getMessaging, getToken, onMessage} from "firebase/messaging";

onMessage(getMessaging(), (payload) => {
    console.log(payload.notification);
    createNotification(
        payload.notification?.title ?? "None",
        payload.notification?.body ?? "None",
        undefined,
        {icon: logoSvg, image: payload.notification?.image}
    );
})


const getServiceWorkerVersion = async (sw: ServiceWorker) => {
    return new Promise<string | undefined>((resolve, reject) => {
        const timer = setTimeout(() => {
            navigator.serviceWorker.removeEventListener('message', onMessage);
            reject("TimeOut");
        }, 5000);
        const onMessage = (event: MessageEvent<any>) => {
            const msg = event.data;
            console.log('Client', 'onMessage', msg);
            if (msg.type === 'VERSION') {
                navigator.serviceWorker.removeEventListener('message', onMessage);
                clearTimeout(timer);
                resolve(msg.version);
            }
        }
        navigator.serviceWorker.addEventListener('message', onMessage)

        sw.postMessage({type: 'VERSION'});

    });
}

const VERSION = '1.0.2';

if ('serviceWorker' in navigator)
    navigator.serviceWorker.ready.then(async (registration) => {
        if (registration.active) {
            try {
                const version = await getServiceWorkerVersion(registration.active);
                if (version !== VERSION) {
                    await registration.update();
                    console.info('Client', 'Update sw to ', VERSION);
                } else
                    console.info('Client', 'Sw is the latest version', VERSION);
            } catch (e) {
                console.warn("Client", "Sw cant get Version", e);
                await registration.update();
            } finally {
                registration.installing&&await waitForInstalled(registration.installing);
                if (registration.waiting) {
                    console.log('Client', 'Send Skip waiting');
                    registration.waiting.postMessage({type: 'SKIP_WAITING'});

                }
            }
        }
    })

const waitForInstalled = async (sw: ServiceWorker) => {
    return new Promise<void>((resolve) => {
        function onChange(this: ServiceWorker) {
            if (this.state === 'installed') {
                sw.removeEventListener('statechange', onChange);
                resolve();
            }
        }

        sw.addEventListener('statechange', onChange);
    })
}

/**
 * Falls der Browser Notifications unterstützt wird der serviceWorker registriert.
 * Wenn der User schon einmal die Notifications erlaubt hatte wird der FMC token abgefragt.
 * Falls der User noch nicht die Notifications erlaubt hat wird ein Lister gestartet der falls Notifications durch User
 * Actions erlaubt wird, wird das FMC token abgefragt.
 * @requires firebase-messaging-sw.js muss im Public Ordner enthalten sein kann aber auch eine lehrer Datei sein.
 */
const firebase_MSGToken = async () => {
    try {
        if ('serviceWorker' in navigator) {
            if (Notification.permission !== 'granted') {
                await Notification.requestPermission();
                const status = await navigator.permissions.query({name: 'notifications'});
                status.onchange = async () => {
                    if (status.state === 'granted') {
                        const token = await getFMVToken();
                        return token;
                    }
                }
            } else {
                const token = await getFMVToken();
                return token;
            }
        }
    } catch (e) {
        console.warn("Notification können nur in Firebase Hosting funktionieren und benötigen firebase-messaging-sw.js!")
    }
}

export const setToken = async (uid: string) => {
    const token = await firebase_MSGToken();
    if (!token) return;
    await setDoc(doc(getFirestore(firebaseApp), 'users', uid, 'fcmTokens', token), {
        token,
        lastOnline: serverTimestamp(),
        userAgent: navigator.userAgent
    })

}

export const getFMVToken = async () => {
    const token = await getToken(getMessaging());
    return token;
}

/**
 * @returns true or false
 */
export const isPushNotificationSupported = () => {
    return 'serviceWorker' in navigator && 'PushManager' in window
}

//https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration/showNotification#parameters
type t_options = {
    icon?: string
    vibrate?: number[]
    tag?: string
    image?: string
    badge?: string
    actions?: {
        action: string
        title: string
        icon: string
    }[]
}

/**
 * Zeigt eine Notification auf dem Desktop falls der benutzt dies erlaubt hat.
 * @param title Der Titel der Notification
 * @param text Test der Notification
 * @param onClick Wird ausgeführt wenn man auf die Notification clickt.
 * @param options Überschreibe standart option
 */
export const createNotification = (
    title: string,
    text: string,
    onClick?: () => void,
    options?: t_options
) => {
    let option = {
        body: text,
        icon: logoSvg,
        vibrate: [200, 100, 200],
        badge: logoSvg,
    }
    option = {...option, ...options}
    const not = new Notification(title, option)
    if (onClick) not.addEventListener('click', () => onClick())
}
