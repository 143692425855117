import React, {useEffect, useState, Suspense, lazy} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {getPostsByGroup, getNextFilteredPosts, standardPostNum_byGroup} from 'store/posts/actions'
import {RootState} from 'store/reducers'
import {ActivityIndicator, Card, ColorButton, ContentContainer, Pagination} from 'components'
import Post from '../posts/Post'
import styles from './index.module.css'
//import FilterFeed from './filterFeed'
import {FiFilter} from 'react-icons/fi'
import {setReadAllPosts} from 'lib/media'

const FilterFeed = lazy(()=>import('./filterFeed'))

const Feed = () => {
    const dispatch = useDispatch();
    const posts = useSelector((state: RootState) => state.posts.byId);
    const postsByCategory = useSelector((state: RootState) => state.posts.postsByCategory);
    const loading = useSelector((state: RootState) => state.posts.loading);
    const loadComplete = useSelector((state: RootState) => state.posts.postByGroupComplete);
    const [filter, setFilter] = useState('');
    const [page, setPage] = useState(0);
    const [filterPosts, setFilterPosts] = useState<{ id: string, date: number }[]>([]);
    const multi = useSelector((state: RootState) => state.posts.postLoadCountGroup)
    const count = 10;
    const [pages, setPages] = useState(Math.ceil(Object.keys(posts).length / count))
    const [showFilter, setShowFilter] = useState(false);
    const subColor = useSelector((state: RootState) => state.config.subColor);
    const LastPost = useSelector((state: RootState) => state.posts.lastPostID.group);
    const unreadPosts = useSelector((state: RootState) => state.posts.unread);
    const [OldUnreadPosts, setOldUnreadPosts] = useState(unreadPosts);
    const [toMark, setToMark] = useState(OldUnreadPosts);
    useEffect(() => {
        dispatch(getPostsByGroup());
        dispatch(setReadAllPosts())
    }, [dispatch])
    useEffect(() => {
        if (OldUnreadPosts !== unreadPosts)
            setToMark(OldUnreadPosts);
        setOldUnreadPosts(unreadPosts);
    }, [unreadPosts, OldUnreadPosts])

    useEffect(() => {
        if (filter !== '') {
            if ((('G_' + filter) in postsByCategory)) {
                const f = postsByCategory['G_' + filter];
                if (f.posts.length < standardPostNum_byGroup && !f.complete) {
                    dispatch(getNextFilteredPosts(filter)); //Sollte diesen UseEffect nochmal aufrufen da post sich ändert
                    return;
                }
                setFilterPosts(f.posts);
                setPages(Math.ceil(f.posts.length / count));
            } else {
                dispatch(getNextFilteredPosts(filter)); //Sollte diesen UseEffect nochmal aufrufen da post sich ändert
                return;
            }
        } else {
            const tmp: { id: string, date: number }[] = [];
            Object.keys(postsByCategory).forEach(cat => {
                if (cat.substr(0, 2) === 'G_') {
                    postsByCategory[cat].posts.forEach(p => {
                        tmp.push(p);
                    })
                }
            })
            //Bereits geladene Posts werden hier ausgeblendet damit keine Lücke entsteht.
            //Dies ist Nötig da wenn man erst nach News filtert dann nach Persönlichen Nachrichten, werden
            //die beiden hintereinander gehängen obwohl da zwischen noch Posts sind die nicht geladen sind.
            setFilterPosts(tmp.sort((a, b) => {
                if (a.date < b.date)
                    return 1;
                if (b.date < a.date)
                    return -1;
                return 0;
            }).splice(0, standardPostNum_byGroup * multi));
        }
    }, [filter, posts, count, dispatch, postsByCategory, loadComplete, multi])
    useEffect(() => {
        setPage(0);
    }, [filter])
    useEffect(() => {
        //Wenn der Filter zurückgesetzt wurde werden die Doppelte anzahl an Posts vom Start geladen damit entstanden Lücken
        //gefühlt werden und das Korrekte Datum zum nachladen benutzt wird,
        if (filter === '' && !loadComplete) {
            dispatch(getNextFilteredPosts(filter, 'category', false, false));
        }
    }, [dispatch, filter, loadComplete])
    useEffect(() => {
        setPages(Math.ceil(filterPosts.length / count));
    }, [filterPosts, count, loadComplete])

    return (
        <ContentContainer loading={loading} style={styles.page} contentStyle={styles.content}>
            {filterPosts.filter(p=>!posts[p.id].hidden).slice(page * count, page * count + count).map((post, index) => {
                return (
                    <Card style={index < toMark ? styles.card_new : styles.card} key={post.id}>
                        <ContentContainer hideSpacer contentStyle={styles.cardContent}>
                            <Post post={posts[post.id]}/>
                        </ContentContainer>
                    </Card>
                )
            })}
            {filterPosts.length===0&&!loading&&(
                <Card style={`${styles.card} ${styles.animationLong}`} key={"noPosts"} >
                    <ContentContainer hideSpacer contentStyle={styles.cardContent}>
                        {filter!==''?"In dieser Kategorie gibt es noch keine Beiträge":"Es sind noch keine Beträge erstellt worden"}
                    </ContentContainer>
                </Card>
            )}
            {(loading&&filterPosts.length===0) &&
            <div style={{width: 20, height: 20}}>
                <ActivityIndicator large/>
            </div>
            }
            {!loading && <Pagination count={pages} currentPage={page} goTo={(page) => {
                if (LastPost !== filterPosts[filterPosts.length - 1].id) {
                    dispatch(getNextFilteredPosts(filter));
                }
                setPage(page);
            }} style={styles.pagination}
                                     complete={(filterPosts.length > 0 && LastPost === filterPosts[filterPosts.length - 1].id) || (filter !== '' && postsByCategory['G_' + filter] && postsByCategory['G_' + filter].complete)}/>
            }
            <div style={{position: 'fixed', right: 50, top: 45}}>
                <div style={{width: 'max-content', position: 'absolute', right: 0}}>
                    <ColorButton icon={FiFilter} onClick={() => setShowFilter(!showFilter)}/>
                </div>
                {showFilter && (
                    <Suspense
                        fallback={<div style={{backgroundColor: subColor, padding: 10, borderRadius: 10, marginTop: 40}}><ActivityIndicator large/></div>}>
                        <FilterFeed
                            filter={filter}
                            setFilter={(f:any) => {
                                if (f === filter) setFilter('')
                                else setFilter(f)
                            }}
                            style={{backgroundColor: subColor, padding: 10, borderRadius: 10, marginTop: 40}}
                        />
                    </Suspense>
                )}
            </div>
        </ContentContainer>
    );
}

export default Feed
