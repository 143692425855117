import React, {lazy} from 'react';
import {t_props,elm} from "../type";
import styles from "./Title.module.css";
import {Typo} from "components";

const EditTitle = lazy(()=>import('./EditTitle'));

const Title=({post,edit}:t_props)=>{
    const render=(One?:elm)=>(
        <div className={`${styles.container} ${One && styles.editing}`}>
            {One ? <One post={post} /> : (
                <Typo variant='h3' style={styles.title}>
                    {post.title}
                </Typo>
            )}
        </div>
    );
    if(edit) return render(EditTitle);
    else return render();
}
export default Title;