import React, {useEffect, useState} from 'react';
import {
    Button,
    ColorButton,
    ContentContainer,
    Dropdown,
    FormItem,
    Modal,
    Row,
    Typo
} from "components";
import ExpandableTable, {i_expandableRow} from "components/ExpandableTable";
import {ResRelSDK} from "lib/sdk";
import LoadRelatives from "./LoadRelatives";
import {useAppDispatch, useAppSelector} from "store/hooks";
import {selectAreas, selectResidents} from "store/residentAndRelatives/store";
import {
    FiXCircle,
    FiCheckCircle,
    FiChevronDown,
    FiInfo,
    FiSend,
    FiUsers,
    FiCornerRightDown,
    FiCornerRightUp, FiRotateCcw
} from "react-icons/fi";
import styles from './ResidentList.module.css'
import GroupCreateDialog from "./GroupCreateDialog";
import {filterTypeChange, sortString} from "lib/helpers";
import {useHistory} from "react-router-dom";
import {getContactByResId, getContacts, selectContacts} from "store/chat/actions";
import {t_RelContact, t_ResContact} from "@pixel-kraft/commulino-types";


type t_mods = (keyof ResRelSDK.i_ResidentDirectory) | 'relatedContacts'

const ResidentList = () => {
    const residents = useAppSelector(selectResidents);
    const {uid, name, photo} = useAppSelector((state) => state.auth);
    const [loading, setLoading] = useState(true);
    const contacts = useAppSelector(selectContacts);
    const [selectedRelative, setSelectedRelative] = useState<undefined | t_RelContact & { phone?: string, email?: string }>(undefined);
    const [selectedResident, setSelectedResident] = useState<undefined | ResRelSDK.i_ResUser>(undefined);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const areas = useAppSelector(selectAreas);
    const [selectedArea, setSelectedArea] = useState<string | undefined>(undefined);
    const dispatch = useAppDispatch();
    const [sortMode, setSortMode] = useState<{ mode: t_mods, up: boolean }>({
        mode: 'nachname',
        up: false
    });
    const history = useHistory();

    useEffect(() => {
        Promise.all([dispatch(ResRelSDK.getResidentsAll()),dispatch(ResRelSDK.getAreas()),dispatch(getContacts())])
            .finally(() => setLoading(false));
    }, [dispatch])

    const selectRelative = (event: React.MouseEvent, user: ResRelSDK.i_RelUser) => {
        event.stopPropagation();
        setSelectedRelative({
            ...ResRelSDK.convertToContact(user) as t_RelContact,
            phone: (user.mediCare as any).phone,
            email: (user.mediCare as any).email
        });
    }

    const chatRelative = async (event: React.MouseEvent, relative: ResRelSDK.i_RelUser, resident: ResRelSDK.i_ResUser) => {
        event.stopPropagation();
        if (!uid || !name) throw Error('Not login');
        const old = await ResRelSDK.checkIfChatExists(resident.mediCare.id);
        const rel = dispatch(getContactByResId(resident.mediCare.id))?.relatedContacts?.find(({uid})=>uid===relative.uid);
        if(!rel) throw Error("Relative not Found in Contacts");
        if (old&&!old.uids.includes(uid)) {
            await ResRelSDK.joinChat(old);
        }
        const chatID = old ? old.id : await dispatch(ResRelSDK.createChat(
            [ResRelSDK.convertToContact({uid, name, photo}),
                ResRelSDK.convertToContact(resident),
                rel
            ],
            "relativeChat"
        ));
        history.push(`/messenger/${chatID}`);
    }

    const groupChat = async (event: React.MouseEvent, resident: ResRelSDK.i_ResUser) => {
        event.stopPropagation();
        setSelectedResident(resident);
    }

    const mapData = (residentDatas: ResRelSDK.i_ResUser[]): i_expandableRow<ResRelSDK.i_ResUser>[] => {
        return residentDatas.map((user) => ({
            key: user.uid,
            data: user,
            cells: [
                {key: 'id', minContent: ({mediCare}) => mediCare.id},
                {key: 'vorname', minContent: ({mediCare}) => mediCare.data?.vorname},
                {key: 'name', minContent: ({mediCare}) => mediCare.data?.nachname},
                {key: 'apartment', minContent: ({mediCare}) => mediCare.data?.apartment},
                {key: 'etage', minContent: ({mediCare}) => mediCare.data?.etage},
                {
                    key: 'relatives',
                    minContent: () => <div
                        style={{alignItems: 'center'}}
                        className={styles.mediRow}
                    >
                        <FiChevronDown/>
                        {!!contacts.find(({uid})=>uid===user.uid)&&<ColorButton
                            style={{marginBottom: 4, marginLeft: 2}}
                            icon={FiUsers} text={"Gruppe erstellen"}
                            onClick={(e) => groupChat(e, user)}
                        />}
                    </div>,
                    maxContent: ({mediCare}) => (
                        <LoadRelatives
                            residentId={mediCare.id}
                            inlineButtons={[
                                {
                                    key: 'infos',
                                    Icon: FiInfo,
                                    onClick: (event:any, user:any) => selectRelative(event, user),
                                    className: styles.inlineButton,
                                    light: true
                                },
                                !!contacts.find(({uid})=>uid===user.uid)?{
                                    key: 'chat',
                                    Icon: FiSend,
                                    onClick: (event: any, rel:any) => chatRelative(event, rel, user),
                                    className: styles.inlineButton,
                                    light: true
                                }:[]
                            ].flat()}
                        />
                    )
                },
                {
                    key: 'kurzzeitpflege',
                    minContent: ({mediCare}) => mediCare.data?.kurzzeitpflege ? <FiCheckCircle/> : <FiXCircle/>
                },
                {
                    key: 'deaktiviert',
                    minContent: ({mediCare}) => mediCare.data?.deaktiviert ? <FiCheckCircle/> : <FiXCircle/>
                }
            ]
        }))
    }

    const getStringVal = <T extends { [key: string]: any }, >(obj: T, key: keyof T) => {
        const val = obj[key];
        return ((val ? val.length : 0) > 0 ? val : '-') as string;
    }

    const getIcon = (mode: t_mods) => {
        if (sortMode.mode !== mode) return FiCornerRightDown;
        return sortMode.up ? FiCornerRightUp : FiCornerRightDown;
    }

    const changeSortMode = (mode: t_mods) =>
        setSortMode((old) => {
                if (old.mode === mode) return {mode: mode, up: !old.up};
                return {mode, up: false};
            }
        )

    const HeaderItem = ({
                            children,
                            mode,
                            disabled
                        }: { children: string, mode: t_mods, disabled?: boolean }) => (
        <Button
            text={children}
            transparent={sortMode.mode !== mode}
            light={sortMode.mode === mode}
            onClick={() => !disabled && changeSortMode(mode)}
            icon={disabled ? undefined : getIcon(mode)}
            cssStyle={{flexFlow: 'row-reverse'}}
        />
    )

    function isNumber(str: string) {
        //if (typeof str != "string") return false // we only process strings!
        // could also coerce to string: str = ""+str
        return /^-?[0-9]+(?:\.[0-9]+)?$/.test(str)
    }

    const sortBy = (aa: ResRelSDK.i_ResUser, bb: ResRelSDK.i_ResUser) => {
        const s = () => {
            if (sortMode.mode === "relatedContacts" || !aa.mediCare.data || !bb.mediCare.data) return 0;
            const a = aa.mediCare.data[sortMode.mode];
            const b = bb.mediCare.data[sortMode.mode];
            if (a !== undefined && b !== undefined) {
                if (typeof a === "number" && typeof b === "number") return a > b ? 1 : -1;
                if (typeof a === "boolean" && typeof b === "boolean") {
                    if (a && !b) return 1;
                    if (!a && b) return -1;
                    return 0;
                }
                if (isNumber(a as string) && isNumber(b as string)) return Number(a) > Number(b) ? 1 : -1;
                if (typeof a === "string" && typeof b === "string") return sortString(a, b);

            }

            return 0;
        }

        return (s() * (sortMode.up ? -1 : 1));
    }

    const searchString = (val: string, inVal: string) => val.toUpperCase().includes(inVal.toUpperCase());


    return (
        <ContentContainer loading={loading}>
            <h1>Bewohner</h1>
            <Row>
                <Row flex={0.3} style={{marginRight: 15}}>
                    <FormItem placeholder={"Vorname"} value={firstName} onChange={setFirstName}/>
                </Row>
                <Row flex={0.3}>
                    <FormItem placeholder={"Nachname"} value={lastName} onChange={setLastName}/>
                </Row>
                <Row flex={1} justifyContent={'end'}>
                    <Dropdown
                        name={"Bereich"}
                        options={areas.map((area) => ({name: area}))}
                        value={selectedArea}
                        onChange={setSelectedArea}
                    />
                    <Button cssStyle={{minHeight: 36, display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                            light icon={FiRotateCcw} loading={loading} onClick={() => {
                        setLoading(true);
                        dispatch(ResRelSDK.getResidentsAll(true))
                            .finally(() => setLoading(false));
                    }}/>
                </Row>
            </Row>
            <ExpandableTable
                headers={[
                    <HeaderItem mode={'id'}>Id</HeaderItem>,
                    <HeaderItem mode={'vorname'}>Vorname</HeaderItem>,
                    <HeaderItem mode={'nachname'}>Nachname</HeaderItem>,
                    <HeaderItem mode={'apartment'}>Apartment</HeaderItem>,
                    <HeaderItem mode={'etage'}>Etage</HeaderItem>,
                    <HeaderItem mode={'relatedContacts'} disabled>Kontaktperson</HeaderItem>,
                    <HeaderItem mode={'kurzzeitpflege'}>Kurzeitpflege</HeaderItem>,
                    <HeaderItem mode={'deaktiviert'}>Deaktiviert</HeaderItem>
                ]}
                rows={mapData(filterTypeChange(residents, (user) => {
                    const mediCare = user.mediCare;
                    if (mediCare.data && searchString(mediCare.data.vorname, firstName) && searchString(mediCare.data.nachname, lastName) && (!selectedArea || selectedArea === mediCare.area)) return user;
                }).sort(sortBy))}
                maxRows={25}
                loading={false}
                goToStart={[selectedArea]}
                complete
            />
            <Modal dataMustBeSet={selectedRelative} modalStyle={styles.modalContent}
                   hide={() => setSelectedRelative(undefined)}>
                {(rel) => (
                    <div style={{padding: '5px 10px'}}>
                        <Typo variant={"h3"}>{rel.name}</Typo>
                        <div className={styles.mediRow}>
                            <div>Verhältnis</div>
                            <div>{getStringVal(rel, 'relation')}</div>
                        </div>
                        <div className={styles.mediRow}>
                            <div>E-mail</div>
                            <div>{getStringVal(rel, 'email')}</div>
                        </div>
                        <div className={styles.mediRow}>
                            <div>Tel:</div>
                            <div>{getStringVal(rel, 'phone')}</div>
                        </div>
                    </div>
                )}
            </Modal>
            <GroupCreateDialog value={selectedResident?dispatch(getContactByResId(selectedResident.mediCare.id)) as t_ResContact:undefined} hide={() => setSelectedResident(undefined)}/>
        </ContentContainer>
    )
}


export default ResidentList
