import React from 'react';
import {project} from "config";
import {useSelector} from "react-redux";
import {RootState} from "store/reducers";
import {IframeLogin} from "components";

const TodoList = () => {
    const uid = useSelector((state:RootState)=>state.auth.uid);

    const link = process.env.NODE_ENV==='development'?`http://192.168.188.150:3002/login/${uid}?debugLevel=2`:`https://commulino-${project}-todolist.web.app/login/${uid}`;

    return (
        <IframeLogin
            version={'v2'}
            style={{height: '100%', display: 'flex', flex: 1, border: 'unset'}}
            src={link}
        />
    )
}

export default TodoList;
