import React from 'react';
import {ColorButton, FormItem, ImagePicker, UserImage} from "components";
import {FiImage, FiUser, FiX} from "react-icons/fi";
import Container from "./Container";
import styles from './ChatHead.module.css';
import {IconType} from "react-icons";
import ImageCropPicker from "components/ImageCropPicker/ImageCropPicker";

type t_group = {
    type: 'group'
    onImgChange: (url: string, file?: Blob) => void,
    onChatNameChange: (chatName: string) => void
    editImage: boolean,
    editName: boolean,
    extraButtons?: { icon: IconType, text?: string, onClick?: () => void }[]
}

type t_single = {
    type: 'single'
} & Partial<Omit<t_group, "type">>

type t_props = {
    imgUrl?: string,
    chatName: string

} & (t_group | t_single)

/**
 * If type is single the onChange events will not trigger. The only
 * reason why thy exist in single is, so you can change the type value on the fly
 * @param props
 * @constructor
 */
const ChatHead = (props: t_props) => (
    <Container className={styles.container} style={{
        alignItems: props.type === 'single' || (!props.editName && !props.editImage) ? 'center' : undefined
    }}>
        {props.type === 'group' && props.editImage ? (
            <ImageCropPicker
                aspect={1}
                style={{flex: 0.5, maxWidth: 150}}
                cropButton={(click, show) => (
                    <div
                        className={styles.row}
                        style={{
                            justifyContent: props.imgUrl ? 'space-between' : 'center',
                        }}>
                        <ColorButton
                            text={`Bild ${props.imgUrl ? 'ändern' : 'hinzufügen'}`}
                            icon={FiImage}
                            onClick={show}
                        />
                        {props.imgUrl &&
                            <ColorButton
                                onClick={() => props.onImgChange('')} icon={FiX}
                                style={{backgroundColor: 'white',color: 'black'}}
                            />}</div>
                )}
                addButton={(click) => (
                    <div
                        className={styles.row}
                        style={{
                            justifyContent: props.imgUrl ? 'space-between' : 'center',
                        }}>
                        <ColorButton
                            text={`Bild ${props.imgUrl ? 'ändern' : 'hinzufügen'}`}
                            icon={FiImage}
                            onClick={click}
                        />

                    </div>)}
                icon={FiUser}
                photo={props.imgUrl}
                imageStyle={{borderRadius: '25%', border: '1px solid',margin: 'auto'}}
                setImg={props.onImgChange}
            />) : (
            <UserImage
                iconStyle={{border: 'none'}}
                className={styles.userImage}
                src={props.imgUrl}
            />
        )}
        <div className={styles.formContainer}>
            {props.type === "group" && props.editName ? (<><FormItem classNameInput={styles.input} name={'Gruppenname'}
                                                                     placeholder={'Gruppenname'}
                                                                     value={props.chatName}
                                                                     onChange={props.onChatNameChange}/>
            </>) : (
                <>{props.chatName ? <h1>{props.chatName}</h1> :
                    <p>Es müssen min. 2 Gruppen Teilnehmer ausgewählt sein um einen Chat zu erstellen</p>}</>
            )}
        </div>
        {props.type === "group" && props.extraButtons &&
            <div className={styles.extraButtonContainer}>
                {props.extraButtons.map((b, index) => <ColorButton key={index} {...b} />)}
            </div>
        }
    </Container>
)
export default ChatHead;

