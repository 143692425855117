import React, {useEffect} from 'react';
import {DateTimePickerMui} from "components/MuiPickerComponents";
import {DatePickerMui} from "components/MuiPickerComponents";
import {Moment} from "moment";
import {useSelector} from "react-redux";
import {RootState} from "store/reducers";

interface Props {
    startLabel?: string,
    startValue: string | Moment | Date | undefined,
    startBorderError?: boolean,
    startOnChange: (date: Moment | string | null) => void,
    endLabel?: string,
    endValue: string | Moment | Date | undefined,
    endBorderError?: boolean,
    endOnChange: (date: Moment | string | null) => void,
    allDay: boolean,
    setDateTimeInvalid:(to:{start: boolean, end: boolean})=>void,
    dateTimeInvalid:{start: boolean, end: boolean}
}

const DateTimePickerMuiInput = ({
                                    startLabel,
                                    startValue,
                                    startBorderError,
                                    startOnChange,
                                    endLabel,
                                    endValue,
                                    endBorderError,
                                    endOnChange,
                                    allDay,
                                    setDateTimeInvalid,
                                    dateTimeInvalid
                                }:Props) => {
    const {subColor} = useSelector((state:RootState)=>state.config)
    const label = (labelValue:string|undefined) => {
        if (!labelValue) return
        return (
            <div style={{fontSize: '15px', marginBottom: '8px', fontWeight: '500', whiteSpace: "pre-wrap"}}>
                {labelValue}
            </div>
        )
    }

    const invalidDateTime = (invalidValue:boolean) => {
        if (!invalidValue) return
        return (
            <div style={{color:"#dd403e"}}>Ungültiges Datum</div>
        )
    }

    const settingInvalidStart = (some:boolean) => {
        setDateTimeInvalid({...dateTimeInvalid, start:some})
    }

    const settingInvalidEnd = (some:boolean) => {
        setDateTimeInvalid({...dateTimeInvalid, end:some})
    }

    useEffect(()=>{
        if (dateTimeInvalid.end || dateTimeInvalid.start) setDateTimeInvalid({start:false,end:false})
    },[allDay])

    const sxStyle = (borderError:boolean) => {
        return (
            {
                '.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root': {fontSize: '0.75vw', justifyContent: 'center'},
                '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {padding: '11px 12px', width: "50%"},
                '.css-161vxw-MuiOutlinedInput-notchedOutline': {right: '31px', left: '31px', borderColor: borderError ? '#ff5500': subColor},
                '.css-161vxw-MuiOutlinedInput-notchedOutline:hover':{backgroundColor: 'rgba(244, 245, 247, 0.2)', borderColor: subColor},
            }
        )
    }
    //There are two components, we could merge into one, but if we need to disable some specific date just into start calendar or end calendar like this would be easier.
    return (
        <>
            {allDay ? (
                <>
                    <div style={{display: 'flex', flexFlow: 'column', alignItems: 'center'}}>
                        {label(startLabel)}
                        <DatePickerMui
                            value={startValue}
                            onChange={startOnChange}
                            sx={sxStyle(startBorderError??false)}
                            setInvalid={settingInvalidStart}
                        />
                        {invalidDateTime(dateTimeInvalid.start)}
                    </div>
                    <div style={{display: 'flex', flexFlow: 'column', alignItems: 'center'}}>
                        {label(endLabel)}
                        <DatePickerMui
                            value={endValue}
                            onChange={endOnChange}
                            sx={sxStyle(endBorderError??false)}
                            setInvalid={settingInvalidEnd}
                        />
                        {invalidDateTime(dateTimeInvalid.end)}
                    </div>
                </>
            ):(
                <>
                    <div style={{display: 'flex', flexFlow: 'column', alignItems: 'center'}}>
                        {label(startLabel)}
                        <DateTimePickerMui
                            value={startValue}
                            onChange={startOnChange}
                            sx={sxStyle(startBorderError??false)}
                            setInvalid={settingInvalidStart}
                        />
                        {invalidDateTime(dateTimeInvalid.start)}
                    </div>
                    <div style={{display: 'flex', flexFlow: 'column', alignItems: 'center'}}>
                        {label(endLabel)}
                        <DateTimePickerMui
                            value={endValue}
                            onChange={endOnChange}
                            sx={sxStyle(endBorderError??false)}
                            setInvalid={settingInvalidEnd}
                        />
                        {invalidDateTime(dateTimeInvalid.end)}
                    </div>
                </>
            )}
        </>
    )
}

export default DateTimePickerMuiInput;
