import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from "../reducers";
import {ResRelSDK} from "lib/sdk";

type t_resAndRel = {
    res: ResRelSDK.i_ResUser[],
    rel: {
        [resId: string]: ResRelSDK.i_RelUser[]
    },
    areas: string[]
}

const initialState: t_resAndRel = {
    res: [],
    rel: {},
    areas: []
}

export const resAndRelSlice = createSlice({
    name: 'resAndRel',
    initialState,
    reducers: {
        /**
         * Add Residents to state
         * @param state state Ref
         * @param action An Array of Residents to add
         */
        addResidents: (state, action:PayloadAction<t_resAndRel['res']>) => {
            state.res.push(...action.payload);
        },
        /**
         * Sets the State to the Residents
         * @param state state Ref
         * @param action An Array of Residents to set the state too
         */
        setResidents: (state,action:PayloadAction<t_resAndRel['res']>) => {
            state.res = action.payload;
        },
        /**
         * Set the Residents data Field to the value in action
         * @param state
         * @param action An Array of the ResidentData to set
         */
        setDataForResidents: (state,action:PayloadAction<ResRelSDK.i_ResidentDirectory[]> ) => {
            const map = new Map<string,ResRelSDK.i_ResidentDirectory>();
            action.payload.forEach((res)=>map.set(res.id,res));
            state.res.forEach((old)=>{
                const val = map.get(old.mediCare.id);
                if(val) old.mediCare.data=val;
            })
        },
        /**
         * Add the Relative to the state. If the Resident don't have relatives the array is added as his new relatives
         * @param state state Ref
         * @param action An Object with residentId and a user Array to add
         */
        addRelatives: (state, action: PayloadAction<{residentId: string,users:ResRelSDK.i_RelUser[]}>) => {
            if(action.payload.residentId in state.rel)
                state.rel[action.payload.residentId].push(...action.payload.users);
            else
                state.rel[action.payload.residentId] = action.payload.users
        },
        /**
         * Sets all Relatives of all Residents. All Relatives that are not in the Array are deleted
         * By an Empty Array alle Relatives are deleted
         * @param state state Ref
         * @param action An Array of the Relatives to set rev too
         */
        setRelatives: (state,action: PayloadAction<ResRelSDK.i_RelUser[]>) => {
            const new_rel:t_resAndRel['rel'] = {};
            action.payload.forEach((user)=>{
                if(user.mediCare.id in new_rel)
                    new_rel[user.mediCare.id].push(user);
                else
                    new_rel[user.mediCare.id]=[user];
            })
            state.rel = new_rel;
        },
        setAreas: (state, action: PayloadAction<t_resAndRel['areas']>) => {
            state.areas=action.payload;
        }
    }
})

export const  {
    addResidents,
    setRelatives,
    setResidents,
    addRelatives,
    setDataForResidents,
    setAreas
} = resAndRelSlice.actions;


export const selectRelative = (state: RootState,residentId: string)=>state.resAndRel.rel[residentId];
export const selectAllRelatives = (state: RootState) => state.resAndRel.rel;
export const selectResidents = (state: RootState) => state.resAndRel.res;
export const selectResident = (state: RootState,residentId: string) => state.resAndRel.res.find(({mediCare})=>mediCare.id===residentId);
export const selectAreas = (state: RootState) => state.resAndRel.areas;

export default resAndRelSlice.reducer;
