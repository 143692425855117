import React from 'react'
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import {Bubble} from "components";

type t_props = {
    style?: React.CSSProperties,
}

const BubleUnreadChat = (props: t_props) => {
    const unread = useSelector((state: RootState) => {
        const test = state.messenger.unRead
        if (test)
            return test;
        let num = 0;
        Object.keys(state.messenger.Chats).forEach(id => {
            const h = state.messenger.Chats[id].unRead;
            if (h !== undefined) {
                num += h;
            }
        })
        return num;

    });


    if (unread <= 0) {
        return null;
    }
    return (
        <Bubble style={props.style}>{unread}</Bubble>
    )
}
export default BubleUnreadChat;
