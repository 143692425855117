import React, {useState} from 'react';
import ErrorMessageInline from './ErrorMessageInline';
import Typo from './Typo';
import styles from './FormItem.module.css';
import {ChatButton} from "components/index";
import {FiEye, FiEyeOff} from "react-icons/fi";

interface Props {
    name?: string,
    costumeNameElement?: JSX.Element,
    type?: string,
    placeholder?: string,
    value: any,
    onChange: (value: string) => void,
    showRequired?: boolean,
    requiredMessage?: string,
    style?: React.CSSProperties,
    noMarginTopLabel?: boolean,
    maxLength?: number,
    Icon?: React.ComponentType,
    onClickIcon?: () => void,
    className?: string,
    classNameInput?: string,
    minValue?:number,
    maxValue?:number,
}

const FormItem = ({
                      name,
                      type,
                      placeholder,
                      value,
                      onChange,
                      showRequired,
                      requiredMessage,
                      style,
                      noMarginTopLabel,
                      maxLength,
                      Icon,
                      onClickIcon,
                      className,
                      classNameInput,
                      costumeNameElement,
                      minValue,
                      maxValue
                  }: Props) => {

    const [showPassword, setShowPassword] = useState(false);
    if(name&&costumeNameElement) throw new Error("Use only one name or costumeNameElement!");
    return (
        <label style={{width: '100%'}}>
            {(name || costumeNameElement)&& (
                <div className={`${noMarginTopLabel ? styles.noMarginTop : styles.label} ${className}`}>
                    {costumeNameElement}
                    <Typo variant='h5'>{name}</Typo>
                    <div style={{marginLeft:"4px", marginTop: "2px", cursor: "pointer"}} onClick={onClickIcon}>
                        {Icon && <Icon />}
                    </div>
                </div>
            )}
            {type === 'textarea' ? (
                <textarea
                    value={value}
                    placeholder={placeholder}
                    onChange={event => onChange(event.target.value)}
                    className={styles.input}
                    style={{minHeight: 170,...style}}
                />
            ) : type === 'password' ? (
                    <div style={{display: 'flex', alignItems: 'center', position: 'relative'}}>
                        <input
                            type={showPassword ? 'text' : type}
                            value={value}
                            placeholder={placeholder}
                            onChange={event => onChange(event.target.value)}
                            className={styles.inputPassword}
                            style={style}
                            maxLength={maxLength}
                        />

                        <ChatButton style={{backgroundColor: 'transparent', position: 'absolute', right: 0, color: '#606e85'}}
                                    icon={showPassword ? FiEyeOff : FiEye} onClick={() => setShowPassword(!showPassword)}/>
                    </div>
                ) :
                (
                    <input
                        type={type}
                        value={value}
                        placeholder={placeholder}
                        onChange={event => onChange(event.target.value)}
                        className={`${styles.input} ${classNameInput}`}
                        style={style}
                        maxLength={maxLength}
                        min={minValue}
                        max={maxValue}
                    />
                )}
            <ErrorMessageInline visible={showRequired || false} text={requiredMessage || ''}/>
        </label>
    )
}

export default FormItem
