import React from 'react';
import styles from "components/Cloud/FolderAndFile.module.css";
import {FiFolder} from "react-icons/fi";
import {i_data} from "lib/Cloud/type";
import {FiFile} from "react-icons/all";
import {IconType} from "react-icons";

interface i_props extends i_data {
    mode: "list"|"raster",
    onClick?: (val:i_props,mode:'simple'|'double',ev: React.MouseEvent)=>void,
    className?: string,
    classNameSelected?: string
    overlay?: JSX.Element,
    isSelected?: boolean
}

interface i_content extends i_props {
    Icon: IconType,
}

const Content=(props:i_content)=>{
    return (
        <div onDoubleClick={(ev)=>{
            if(props.onClick)
                props.onClick(props,'double',ev)}} onClick={(ev)=>{
            if(props.onClick)
                props.onClick(props,'simple',ev);
        }} className={`${props.mode==="list"?styles.list_container:styles.raster_container} ${props.className} ${props.isSelected?props.classNameSelected??styles.selected:""}`}>
            <props.Icon style={{opacity: props.overlay?0:1}} className={`${props.mode==="list"?styles.list_icon:styles.raster_icon}`} />
            <div>{props.mode==="list"?props.content.name:props.content.name.length>30?props.content.name.slice(0,30)+'...':props.content.name}</div>
            {props.overlay&&<div style={{position: 'absolute',top: 0,left: 0, width: 150,height:100,padding:5 ,display: "flex",justifyContent: "center"}}>{props.overlay}</div>}
        </div>
    )
}

export const File =(props:i_props)=><Content {...props} Icon={FiFile} />
export const Folder=(props:i_props)=><Content {...props} Icon={FiFolder} />