import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setDatenschutz, setImpressum } from 'store/config/actions';
import { RootState } from 'store/reducers';

type t_props = {
    impressum: boolean,
    show: boolean,
}

const ShowText = (props:t_props) => {
    const text = useSelector((state:RootState)=> props.impressum?state.config.impressum:state.config.datenschutz);
    const dispatch=useDispatch();
    //const [text,setText]=useState(props.text);
    return (
        <div style={{display: props.show?'block':'none',width: '100%',height: '100%',borderRadius: 20,border: '1px solid black',paddingLeft: 10,paddingRight: 30,paddingTop: 5,paddingBottom: 30}}>
            <textarea 
                value={text}
                onChange={(c)=>{
                    dispatch(props.impressum?setImpressum(c.target.value):setDatenschutz(c.target.value));
                }}
                style={{
                    width: '100%',
                    resize: 'none',
                    height: '100%',
                    fontFamily: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
                    color: '#172b4d',
                    fontSize: '14px',
                    lineHeight: '1.5em',
                    padding: 10,
                    border: 'none',
                }}
            />
        </div>
    )
}
export default ShowText;