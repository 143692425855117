import React from 'react';

const StringAndNumberArraysComparison = (firstArray: string[]|number[], secondArray: string[]|number[]) => {
    // The arrays have different sizes
    if (firstArray.length !== secondArray.length) return false;

    const firstSet = new Set<string|number>(firstArray).size;
    const secondSet = new Set<string|number>(secondArray).size;

    // One of the arrays has repeated values
    if (firstSet !== secondSet) return false;

    const uniqueValues = new Set([...firstArray, ...secondArray]);
    if (uniqueValues.size === firstSet && uniqueValues.size === secondSet) return true

    //Some values are not equal
    return false
}

export default StringAndNumberArraysComparison