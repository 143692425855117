import React, {useEffect, useState} from 'react';
import {ResRelSDK} from 'lib/sdk';
import {useAppDispatch} from "store/hooks";
import {ChatAnyList} from "../";
import {
    getContacts,
} from "store/chat/actions";
import {UserProfile} from "components";
import {t_Contact} from "@pixel-kraft/commulino-types";
import {icons} from 'lib/constants';
import {FiUser} from "react-icons/fi";


type t_user = t_Contact;

type t_props = {
    users: t_user[],
    height: number,
    render: (user: t_user, index: number, array: t_user[]) => React.ReactNode
    onChange: (changes: t_user[]) => void,
    searchFilter: {
        filter: (name: string, item: t_user) => boolean,
        title?: string
    },
    group?: boolean,
    mode: 'chat' | ResRelSDK.t_ChatData['mediCare']['type'],
    resident?: t_Contact,
    edible?: boolean,
    useContactsAsRelatedContacts?: boolean
}
/**
 * The Ultimate List to display all the different ChatUsers.
 * Dislpay a List of users in Chat. This list can be manipolatitet and will use the COnatcts form the Rest Api to select from.
 * @param height MaxHeight of component.
 * @param onChange callback onChange
 * @param render How to Render the Chat user
 * @param searchFilter Callback for input field. With costume Filter.
 * @param users Users in Chat
 * @param resident Is there a resident if true which one
 * @param group is the Chat a group
 * @param mode What chat is it a Normal one or a Chat with multiple Residents or a Chat with relatives
 * @param edible Is the List edible. Will show a Button to Edit the List. On Click will Shown a ChatUser Selector
 * @param useContactsAsRelatedContacts Are all Contacts from RestApi related contacts?
 * @constructor
 */
const List = ({
                  height,
                  onChange,
                  render,
                  searchFilter,
                  users,
                  resident,
                  group,
                  mode,
                  edible,
                  useContactsAsRelatedContacts
              }: t_props) => {
    const [list, setList] = useState<t_Contact[]>([]);
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();

    useEffect(() => {
        console.log(mode, resident, list);
    }, [mode, resident, list])

    useEffect(() => {
        dispatch(getContacts()).then((contacts) => {
            let list = contacts;
            if (mode === "relativeChat") {
                if (resident) {
                    //if(useContactsAsRelatedContacts) list.push(...contacts);
                    if (resident.relatedContacts) list.push(...resident.relatedContacts);
                }

                list = list.filter((user) => !ResRelSDK.isRes(user) || resident?.uid === user.uid);
            }
            if (mode === "chat")
                list = list.filter(ResRelSDK.isUser);
            setList(list);
        }).finally(() => setLoading(false));
    }, [dispatch, resident, mode, useContactsAsRelatedContacts])

    return (
        <ChatAnyList
            list={ResRelSDK.sortContacts(list.filter((a) => !users.find((b) => ResRelSDK.compareContact(a, b))))}
            selected={users}
            renderItem={(data) => {
                if (data.pos === "center")
                    return render(data.item, data.index, data.arr);
                return null;
            }}
            maxHeight={height * 0.7}
            pickerLoading={loading}
            keyExtractor={(item) => item.uid}
            group={group}
            {...(
                edible ?
                    {
                        setSelected: onChange,
                        selectOnRowClick: true,
                        //pickerItemStyle: (item) => 'relation' in item ? {marginLeft: 40} : undefined,
                        renderPickerItem: (item) => <UserProfile
                            data={'relation' in item ? {name: item.relation + ": " + item.name} : item}/>,
                        search: {
                            filter: (name: string, item: t_user, mode: 'users' | 'res') => (mode === 'users' ? ResRelSDK.isUser(item) : mode==="res"?ResRelSDK.isRes(item):ResRelSDK.isRel(item)) && searchFilter.filter(name, item),
                            title: searchFilter.title
                        },
                        filterButtons: [
                            {
                                icon: icons.users.icon,
                                label: icons.users.label,
                                value: 'users'
                            },
                            {
                                icon: icons.mediCare.icon,
                                label: icons.mediCare.label,
                                value: 'res'
                            },
                            (mode==="relativeChat"?{
                                icon: FiUser,
                                label: 'Kontaktpersonen',
                                value: 'rel'
                            }:[])
                        ].flat(),
                        defaultFilter: 'users',
                        isSame: ResRelSDK.compareContact
                    } : {}
            )}
        />
    )

}

export default List;
