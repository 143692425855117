import React from 'react'

interface Props {
	color?: string
	style?: string
}

const Divider = ({ color, style }: Props) => (
	<div
		className={style}
		style={{
			borderTop: '1px solid',
			borderColor: color || 'rgb(244, 245, 247)',
			margin: 8,
			width: 'auto'
		}}
	/>
)

export default Divider
