import React, {useState} from 'react';
import {FiAlertTriangle, FiPlus} from "react-icons/fi";
import {ColorButton} from "components";
import Container from "../../ChatInfo/Container";
import ChatAnyPicker from "../ChatAnyPicker/ChatAnyPicker";
import {t_ChatAnyListProps} from "../types";

/**
 * Can display any User type.
 * @param props Settings
 * @constructor
 */
const ChatAnyList = <T,O,B,I,>(props:t_ChatAnyListProps<T,O,B,I>)=>{
    const [show,setShow] = useState(false);

    const pos:('before'|'center'|'after')[]=['before','center','after']

    const renderList=(pos: 'before'|'center'|'after')=>{
        if(pos==='before'&&props.addBefore)
            return props.addBefore.map((value, index, array)=>props.renderItem({pos: 'before',arr: array,item: value,index}))
        if(pos==='after'&&props.addAfter)
            return props.addAfter.map((value, index, array)=>props.renderItem({pos: 'after',arr: array,item: value,index}))
        if(pos==='center'&&props.selected)
            return props.selected.map((value, index, array)=>props.renderItem({pos: 'center',arr: array,item: value,index}))

        return null;
    }

    return (
        <Container>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <h2>{!props.group ? "Chat zwischen" : `Gruppen Teilnehmer (${props.selected.length + (props.addBefore?.length??(props.addAfter?.length ?? 0))})`}</h2>
                {props.warning && <><FiAlertTriangle style={{color: 'red', marginLeft: 5, marginRight: 2}}/>{props.warning}</>}
            </div>
            {'setSelected' in props && <ColorButton icon={FiPlus} text={"Teilenehmer hinzufügen"}
                                      onClick={() => setShow(true)}/>}
            <div style={{maxHeight: props.maxHeight, overflowY: 'auto', padding: '1px 0px', marginTop: 5}}>
                {pos.map((val)=>renderList(val))}
            </div>
            {'setSelected' in props && <ChatAnyPicker
                pickFrom={props.list}
                selected={props.selected}
                {...('defaultFilter' in props?{search: props.search,defaultFilter: props.defaultFilter,filterButtons: props.filterButtons}:{search: props.search})}
                isPicked={(item, selected)=>selected.findIndex((b)=>props.isSame?props.isSame(b,item):item===b)>-1}
                onSelect={(u) => {
                    setShow(false);
                    props.setSelected(u);
                }}
                show={show}
                onClose={() => setShow(false)}
                renderItem={props.renderPickerItem}
                selectOnRowClick={props.selectOnRowClick}
                rowStyle={props.pickerItemStyle}
                keyExtractor={props.keyExtractor}
            />
            }
        </Container>
    )
}

export default ChatAnyList;
