import React from 'react';
import Slider from "rc-slider";
import 'rc-slider/assets/index.css';
import {useSelector} from "react-redux";
import {RootState} from "store/reducers";
import {getInvertColor} from "lib/colors";
import {SliderProps} from "rc-slider/lib/Slider";


/**
 * The slider Component with the config color theme
 * @param props All Props of Slider
 */
const ColorSlider = (props: SliderProps) => {
    const {subColor, mainColor} = useSelector((state: RootState) => state.config);

    return <Slider handleStyle={{backgroundColor: getInvertColor(mainColor), borderColor: mainColor}}
                   trackStyle={{backgroundColor: subColor}} {...props} />

}
export default ColorSlider;