type t_type = 'info'|'log'|'error'|'warn';
type t_mode = 'development'|'test'|'production';

type t_params = (message?: any,...optionalParams: any[])=>void;

function log(type: t_type,mode: t_mode,message?: any,...optionalParams: any[]){
    if(mode===process.env.NODE_ENV){
        const e = new Error();
        if (!e.stack) {
            try {
                // IE requires the Error to actually be thrown or else the
                // Error's 'stack' property is undefined.
                throw e;
            } catch (e: any) {
                if (!e.stack) {
                    //return 0; // IE < 10, likely
                }
            }
        }
        const stack = e.stack?.toString().split(/\r\n|\n/);
        if(stack)
            console[type](message,optionalParams,'[' + stack[2] + ']');
        else
            console[type](message,optionalParams);
    }
}
/**
 * Logs message only in development mode
 **/
export const logDev:t_params=(message, optionalParams)=>log('log',"development",message,optionalParams);
/**
 * Info message only in development mode
 **/
export const infoDev:t_params=(message, optionalParams)=>log('info',"development",message,optionalParams);

export const logging=log;
