import React, { useEffect } from 'react'
import { FiX } from 'react-icons/fi'
import { Typo } from '.'
import styles from './Alert.module.css'

interface Props {
	title: string
	text: string
	icon: React.ComponentType<{ style: React.CSSProperties }>
	visible: boolean
	hide: (event: React.MouseEvent<SVGElement, MouseEvent>) => void
	style?: React.CSSProperties
}

const Alert = ({ title, text, icon: Icon, visible, hide, style }: Props) => {
	useEffect(() => {
		let timeoutId: number
		if (visible) timeoutId = setTimeout(hide, 5000)

		return () => {
			if (timeoutId) clearTimeout(timeoutId)
		}
	}, [visible, hide])

	return (
		<div
			className={styles.container}
			style={{ ...(!visible && { transform: 'translateX(-420px)', opacity: 0 }), ...style }}
		>
			<div className={styles.titleContainer}>
				<Icon
					style={{
						fontSize: 19,
						marginBottom: 4,
						marginRight: 8
					}}
				/>
				<Typo variant='h5' style={styles.title}>
					{title}
				</Typo>
				<FiX className={styles.hide} onClick={hide} />
			</div>
			<Typo style={styles.text}>{text}</Typo>
		</div>
	)
}

export default Alert
