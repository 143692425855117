import React, {MutableRefObject, useEffect, useState} from 'react';
import {ImageCrop, ImagePicker, Modal} from "components";
import {dataURItoBlob} from "lib/media";
import {FiUser} from "react-icons/fi";

export type t_ImageCropPickerRef = {
    showCrop: () => void,
    closeCrop: () => void,
    setImgSrc: (src: string | undefined) => void,
}

type t_props = {
    photo?: string,
    onImageSrc?: (src: string) => void,
    onImageBlob?: (blob: Blob) => void,
    setImg?: (src: string, blob: Blob) => void
    imageStyle?: React.CSSProperties,
    imageClassName?: string,
    cropButton?: (click: () => void, show: () => void) => React.ReactNode,
    addButton?: (click: () => void, show: () => void) => React.ReactNode
    style?: React.CSSProperties,
    icon?: React.ComponentType<{ style?: React.CSSProperties, className?: string }>,
    aspect?: number,
    hideImage?: boolean,
    hidePreview?: boolean
}

const ImageCropPicker = React.forwardRef<t_ImageCropPickerRef, t_props>(({
                                                                             cropButton,
                                                                             aspect,
                                                                             hideImage,
                                                                             hidePreview,
                                                                             onImageBlob,
                                                                             imageStyle,
                                                                             imageClassName,
                                                                             onImageSrc,
                                                                             addButton,
                                                                             setImg,
                                                                             style,
                                                                             icon,
                                                                             photo,
                                                                             children
                                                                         }, ref) => {
    const [imgSrc, setImgSrc] = useState(photo);
    const [show, setShow] = useState(false);
    const Ic = icon ?? FiUser;

    useEffect(() => {
        if (ref) {
            (ref as MutableRefObject<t_ImageCropPickerRef | null>).current = {
                showCrop: () => setShow(true),
                closeCrop: () => setShow(false),
                setImgSrc: (src) => setImgSrc(src)
            }
        }
    }, [ref])

    return (
        <>
            {!hideImage &&
                <div style={{display: 'flex', flexFlow: 'column', ...style}}>
                    {!photo && <Ic className={imageClassName} style={{
                        width: 100,
                        height: 100,
                        borderRadius: '50%',
                        ...imageStyle
                    }}/>}
                    {photo && <img style={{
                        objectFit: 'cover',
                        width: 100,
                        height: 100,
                        borderRadius: '50%',
                        ...imageStyle
                    }} className={imageClassName} src={photo}/>}
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <ImagePicker
                            noOverlay
                            showImage={{show: false, src: photo}}
                            setImgSrc={setImgSrc}
                            setImgFile={() => setShow(true)}
                            costumeButton={(click) => <div style={{marginTop: 5}}>
                                {photo && photo.startsWith('data:image') && cropButton && cropButton(click, () => setShow(true))}
                                {!(photo && photo.startsWith('data:image')) && addButton && addButton(click, () => setShow(true))}
                            </div>}
                        />
                    </div>
                </div>
            }
            <Modal isVisible={!!(show && imgSrc)} hide={() => setShow(false)}>
                {imgSrc && imgSrc.startsWith('data:image') &&
                    <ImageCrop
                        aspect={aspect}
                        hidePreview={hidePreview}
                        imageStyle={imageStyle}
                        imageClassName={imageClassName}
                        src={imgSrc}
                        onSuccess={(dataUri) => {
                            onImageBlob && onImageBlob(dataURItoBlob(dataUri));
                            onImageSrc && onImageSrc(dataUri);
                            setImg && setImg(dataUri, dataURItoBlob(dataUri));
                            setShow(false);
                        }}/>
                }
            </Modal>
        </>

    )
})

export default ImageCropPicker;
