import React from "react";
import {Button, ContentContainer, PageTitle} from "components";
import {FiList, FiMonitor} from "react-icons/fi";
import {useHistory} from "react-router-dom";
import styles from "./InfosystemConfig.module.css";

const InfosystemConfig = () => {
    const {push} = useHistory();

    return (
        <ContentContainer>
            <PageTitle text={"Infosystemeinstellung"}/>
            <div style={{display: "flex", alignItems: "center"}}>
                <Button style={styles.button} large light icon={FiMonitor} onClick={()=>push('/infosystem/settings/device')} text={"Geräte"}/>
                <Button style={styles.button} large light icon={FiList} onClick={()=>push('/infosystem/settings/playlists')} text={"Playlists bearbeiten"}/>
            </div>
        </ContentContainer>
    )
}
export default InfosystemConfig;