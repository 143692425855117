import {
	OnboardingActionTypes,
	ONBOARDING_EDIT_TEMPLATE,
	ONBOARDING_GET_TEMPLATES_REQUEST,
	ONBOARDING_GET_TEMPLATES_SUCCESS,
	ONBOARDING_INIT_TEMPLATE,
	ONBOARDING_MOVE_POST_DOWN,
	ONBOARDING_MOVE_POST_UP,
	ONBOARDING_REMOVE_POST,
	ONBOARDING_SAVE_POST,
	ONBOARDING_SET_EDITING_TEMPLATE
} from './types'
import { AppThunk,usersStore } from 'store/store'
import { toggleEditMode } from 'store/posts/actions'
import { OnboardingTemplate } from '@pixel-kraft/commulino-types'
import { firebaseApp } from 'lib/firebase';
import {getFirestore,collection, doc} from "firebase/firestore";
import * as api from 'lib/api'

export const getTemplates = (): AppThunk => {
	return async dispatch => {
		dispatch({ type: ONBOARDING_GET_TEMPLATES_REQUEST })
		const templates = await api.get('onboarding/templates')
		dispatch({ type: ONBOARDING_GET_TEMPLATES_SUCCESS, templates })
	}
}

export const initOnboardingTemplate = (): AppThunk => {
	return async (dispatch, getState) => {
		const {
			auth: { uid }
		} = getState()

		if (!uid) return
		const template: OnboardingTemplate = {
			createdAt: Date.now(),
			createdBy: uid,
			id: doc(collection(getFirestore(firebaseApp),'posts')).id,
			posts: [],
			title: ''
		}

		dispatch({ type: ONBOARDING_INIT_TEMPLATE, template })
	}
}

export const setEditingTemplate = (id: string): OnboardingActionTypes => {
	return {
		type: ONBOARDING_SET_EDITING_TEMPLATE,
		id
	}
}

export const editOnboardingTemplate = (
	id: string,
	data: Pick<OnboardingTemplate, 'title'>
): OnboardingActionTypes => {
	return {
		type: ONBOARDING_EDIT_TEMPLATE,
		id,
		data
	}
}

export const saveOnboardingTemplate = (): AppThunk => {
	return async (dispatch, getState) => {
		const {
			onboarding: { editing }
		} = getState()

		if (!editing?.data) return

		await api.post('onboarding/templates', editing.data)

		dispatch(getTemplates())
	}
}

export const saveOnboardingPost = (): AppThunk => {
	return async (dispatch, getState) => {
		const {
			posts: {
				editing: { data }
			}
		} = getState()

		if (!data) return

		dispatch({ type: ONBOARDING_SAVE_POST, post: data })
		dispatch(toggleEditMode(false))
	}
}

export const removeOnboardingPost = (index: number): OnboardingActionTypes => {
	return {
		type: ONBOARDING_REMOVE_POST,
		index
	}
}

export const movePostUp = (index: number): OnboardingActionTypes => {
	return {
		type: ONBOARDING_MOVE_POST_UP,
		index
	}
}

export const movePostDown = (index: number): OnboardingActionTypes => {
	return {
		type: ONBOARDING_MOVE_POST_DOWN,
		index
	}
}

export const startOnboarding = (
	template: { id: string },
	startDate: number,
	endDate: number,
	uid: string
): AppThunk => {
	return async dispatch => {
		const onboarding = await api.post('onboarding/start', { template, startDate, endDate, uid })

		dispatch(usersStore.startOnboarding({uid,onboarding}))
	}
}
