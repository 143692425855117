import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {RootState} from 'store/reducers'
import 'react-chat-elements/dist/main.css';
import {
    Message,
} from '@pixel-kraft/commulino-types';
import {
    PageTitle,
    ScrollView,
    AlertDialog,
    ActivityIndicator,
    Modal, Animations,
} from 'components/index';
import styles from './messenger.module.css';
import useWindowDimensions from 'lib/useWindowDimensions';
import {FiUser} from 'react-icons/fi';
import {AddUnreadChat, getMoreMSG, setAktiveChat, setUnread} from 'store/chat/actions';
import ReadByOverlay from './ReadByOverlay';
import ImageGallery from "react-image-gallery";
import MessengerContent from "./componets/Messenger/MessengerContent";
import {messageActionType} from "./componets/Messenger/actionTypes";
import MessageForward from "./componets/Messenger/MessageForward";
import MessengerTextBox from "./componets/Messenger/MessengerTextBox";
import MessengerChatInfo from "./componets/Messenger/MessengerChatInfo";
import {Row} from "components";
import {ChatSDK} from "lib/sdk";
import {useAppDispatch} from "store/hooks";

const TOLOADMSG = 20;

type t_params = {
    chatID: string,
    goBack: () => void,
    onAction: (type: 'leaveChat'|'deleteChat',chatId: string)=>void
}

const areEqual = (prevProps: t_params, nextProps: t_params): boolean => {
    return prevProps.chatID === nextProps.chatID;
}

const Messenger = React.memo((params: t_params) => {
    const dispatch = useAppDispatch();
    const {height, width} = useWindowDimensions();
    const user = useSelector((state: RootState) => state.auth)
    if (!user.uid) throw Error("Not Login!");
    const chat = useSelector((state: RootState) => state.messenger.Chats[params.chatID]);
    const [currentHeight, setCurrentHeight] = useState(49);
    const [replay, setReplay] = useState<Message | undefined>(undefined);
    const [showGroupInfo, setShowGroupInfo] = useState(false);
    const [showReadBy, setShowReadBY] = useState<Message | undefined>(undefined);
    const [loadMoreMsg, setLoadMoreMsg] = useState(false);
    const [alert, setAlert] = useState<React.ComponentProps<typeof AlertDialog> | undefined>(undefined)
    const [forwardMsg, setForwardMsg] = useState<Message | undefined>(undefined);
    const [ImgFullScreen, setImgFullScreen] = useState<{ original: string }[]>([]);
    const [startIndex, setStartIndex] = useState(0);


    useEffect(() => {
        if (params.chatID&&chat?.id) {
            setShowGroupInfo(false);
            dispatch(setAktiveChat(params.chatID));
            setUnread(params.chatID, 0, dispatch);
            dispatch(AddUnreadChat(params.chatID));
        }
    }, [params.chatID,chat?.id, dispatch])


    if (params.chatID === "" || params.chatID === undefined)
        return null;

    if (!chat||!chat.name) return <Row flex={1} justifyContent={'center'}><ActivityIndicator large/></Row>

    const {users, group} = chat;

    const name = group ? group.name : users?.find(({uid}) => uid !== user.uid)?.name;
    const avatar = group ? group.photo : users?.find(({uid}) => uid !== user.uid)?.photo;

    const onTop = async () => {
        if (chat && chat.messages) {
            if (chat.lastDate && chat.finishd !== true) {
                setLoadMoreMsg(true);
                await getMoreMSG(params.chatID, chat.lastDate, TOLOADMSG, dispatch);
                setLoadMoreMsg(false);
            }
        }
    }

    const onAction = async (action: messageActionType) => {
        switch (action.type) {
            case "image": {
                setImgFullScreen(action.images.map(uri => {
                    return {original: uri}
                }))
                setStartIndex(action.index);
                return;
            }
            case "alert": {
                setAlert(action.alert);
                return;
            }
            case "click": {
                setShowReadBY(action.message);
                return;
            }
            case "forward": {
                setForwardMsg(action.message);
                return;
            }
            case "replay": {
                setReplay(action.message);
                return;
            }
            case "docClick":
                await dispatch(ChatSDK.functions.updateDocumentation(params.chatID,action.message.id,!action.message.documentation));
                return;
            default: {
                throw new Error("Unknown message Action!");
            }
        }
    }

    if (!chat || !name) {
        return (
            <div className={styles.deleteContainer}>
                Die Gruppe wurde gelöscht oder Sie wurden aus ihr Entfernt.
            </div>
        )
    }

    return (
        <div className={styles.mainContainer}>
            <Animations.Switch active={showGroupInfo ? "groupInfo" : "chat"} defaultStyle={{inset: 0}} style={{height: '100vh'}} mountOnEnter timeout={300}
                               animation={"fade"}>
                {[
                    {
                        key: 'groupInfo',
                        item: (<div className={styles.chatInfoContainer}>
                            <div className={styles.flexContainer}>
                                <MessengerChatInfo onAction={(type, chatId)=>{
                                    console.log(type,chatId);
                                    params.onAction(type==="leave"?"leaveChat":"deleteChat",chatId);
                                }} src={avatar} show={showGroupInfo} chatId={params.chatID}
                                                   close={() => setShowGroupInfo(false)}/>
                            </div>
                        </div>)
                    },
                    {
                        key: 'chat',
                        item: (
                            <div>
                                <div className={styles.pageTitleContainer}
                                     onClick={() => setShowGroupInfo(!showGroupInfo)}>
                                    <PageTitle
                                        className={styles.pageTitle}
                                        avatar={avatar}
                                        avatarStyle={{objectFit: 'cover', ...chat.users.length > 2 ? {borderRadius: '25%'} : undefined}}
                                        text={name}
                                        Icon={!avatar ? FiUser : undefined}
                                        IconStyle={{height: 24, width: 24, objectFit: 'cover', borderRadius: '50%'}}
                                    />
                                    <div className={loadMoreMsg ? styles.loadContainerShow : styles.loadContainer}>
                                        <ActivityIndicator/>
                                    </div>
                                </div>
                                <div className={styles.messengerContentBox}
                                     style={{height: height - currentHeight - 80}}>
                                    <ScrollView name={params.chatID}
                                                isLoading={loadMoreMsg}
                                                onTop={onTop}
                                                style={{
                                                    height: height - currentHeight - 80,
                                                    backgroundColor: 'lightgray',
                                                    // width: '100%'
                                                }}>
                                        {chat.messages ?
                                            <MessengerContent mediCare={!!chat.mediCare && !!user.contactFor}
                                                              messages={chat.messages}
                                                              chatId={params.chatID}
                                                              width={width - 604} users={chat.users}
                                                              onAction={onAction}/> : null}
                                    </ScrollView>
                                    {alert &&
                                        <AlertDialog {...alert} />
                                    }
                                </div>
                                {/*{!showGroupInfo &&*/}
                                <div
                                    className={styles.textBoxContainer}> {/*Verhindert flackern bei höhen änderung der ScrollView*/}
                                    <MessengerTextBox
                                        setCurrentHeight={setCurrentHeight}
                                        replayMessage={replay}
                                        resetReplay={() => setReplay(undefined)}
                                        chatId={params.chatID}
                                        users={chat.users}
                                    />
                                </div>
                                {showReadBy &&
                                    <ReadByOverlay Chat={chat} msg={showReadBy}
                                                   onGoBack={() => setShowReadBY(undefined)}/>
                                }

                                {forwardMsg &&
                                    <MessageForward forwardMsg={forwardMsg} close={() => setForwardMsg(undefined)}/>
                                }
                                <Modal modalStyle={styles.biggerModal} contentStyle={styles.imageGalleryModul}
                                       isVisible={ImgFullScreen.length > 0} hide={() => setImgFullScreen([])}>
                                    <ImageGallery
                                        startIndex={startIndex}
                                        showNav={true}
                                        showThumbnails={false}
                                        showPlayButton={false}
                                        showBullets={ImgFullScreen.length > 1}
                                        showFullscreenButton={false}
                                        items={ImgFullScreen}
                                        onClick={() => setImgFullScreen([])}
                                        renderItem={item => {
                                            return (
                                                <img
                                                    src={item.original}
                                                    onClick={e => e.stopPropagation()} //Only close if not click on Image
                                                    className={styles.imageGalleryImage}
                                                    alt=''
                                                />
                                            )
                                        }}
                                    />
                                </Modal>
                            </div>
                        )
                    }
                ]}

            </Animations.Switch>
        </div>)


}, areEqual);

Messenger.displayName = 'Messenger';

export default Messenger
