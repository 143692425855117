import React from 'react';
import RenderButtons from './RenderButtons';
import UserRow from "../UserRow";
import {t_moreProps} from "./index";
import {t_ResContact} from "@pixel-kraft/commulino-types";

type t_props = {user: t_ResContact}&t_moreProps

const ResUser = (data:t_props)=>(
    <UserRow
        key={'res_' + data.user.residentId}
        user={data.user}
        me={data.me}
        buttons={data.buttons&&<RenderButtons buttons={data.buttons} />}
        nameCellStyle={{marginLeft: 5,...data.nameCellStyle}}
        rowStyle={data.rowStyle}
    />
)

export default ResUser;
