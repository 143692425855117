import React, {useLayoutEffect, useRef, useState} from 'react';
import {useDrop} from 'react-dnd'
import {ItemTypes} from "../constant";
import DragItem from "components/DragAndDrop/DragItem";

type t_props = {
    children: JSX.Element | JSX.Element[],
    index: number,
    changeIndex: (index: number) => void
}

const DropArea = ({children, index, changeIndex}: t_props) => {

    const [{isOver}, drop] = useDrop(() => ({
            accept: ItemTypes.ITEM,
            drop: (item) => changeIndex(item as number),
            collect: monitor => ({
                isOver: monitor.isOver()
            })
        })
        , [index, children]) //Children must be her. Drop don't work correct without it.

    return (
        <div ref={drop}>
            <DragItem index={index}>{children}</DragItem>
        </div>
    )
}
export default DropArea;