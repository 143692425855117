import {LoginData} from "./actions";

export const AUTH_LOGIN = 'AUTH_LOGIN'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_UPDATE_IMAGE = 'AUTH_UPDATE_IMAGE'
export const AUTH_SET_TOKEN = 'AUTH_SET_TOKEN'

export interface AuthState extends Omit<LoginData,'uid'|'name'>{
	loading: boolean
	isLoggedIn: boolean
	uid: string | null
	name: string | null
	loginToken?: {
		token: string,
		timeOut: number
	},
	loginInProcess: boolean
}

interface LoginAction extends LoginData {
	type: typeof AUTH_LOGIN
}

interface updateLocalUserImage {
	type: typeof AUTH_UPDATE_IMAGE,
	photo: string,
}

interface LogoutAction {
	type: typeof AUTH_LOGOUT
}

interface SetToken {
	type: typeof AUTH_SET_TOKEN
	token: string,
	timeOut: number
}

interface setLoginInProcess {
	type: 'SETLOGININPROCESS',
	value: boolean
}

export type AuthActionTypes = LoginAction | LogoutAction | updateLocalUserImage | SetToken | setLoginInProcess
