import {collection, getFirestore, onSnapshot, orderBy, query} from "firebase/firestore";
import {AppThunk} from "store/store";
import {setCounter, t_counter} from "store/todoList/store";


namespace counterListener {
    let listener: (() => void) | undefined = undefined;

    export const clearListener = () => {
        if (listener) listener();
        listener = undefined;
    }

    export const setListener = (l: () => void) => {
        clearListener();
        listener = l;
    }
}

/**
 * Start a Snapshot Lister for all counters with a status field (todoCounter).
 * Ads all counters to the todoList store.
 */
export const startListener = (): AppThunk => {
    return (dispatch, getState) => {
        const uid = getState().auth.uid;
        if (!uid) return;
        const snapshot = onSnapshot(query(collection(getFirestore(), 'users', uid, 'counters'), orderBy('status')), (snapshot) => {
            snapshot.forEach((doc) => {
                dispatch(setCounter({...doc.data() as t_counter}))
            })
        });
        counterListener.setListener(snapshot);
    }
}

/**
 * Stop the Snapshot Listener for todoCounters.
 */
export const stopListener = () => {
    counterListener.clearListener();
}
