import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ContentContainer, EditPermissions, PageTitle, FormItem, ColorButton } from 'components'
import { FiPlus } from 'react-icons/fi'
import { addRole } from 'store/roles/actions'
import styles from './index.module.css'

const RolesNew = () => {
	const history = useHistory()
	const dispatch = useDispatch()
	const [name, setName] = useState('')
	const [permissions, setPermissions] = useState({})
	const [error, setError] = useState(false)

	function save() {
		if (!name) return setError(true)
		dispatch(addRole({ name, permissions }))
		history.goBack()
	}

	return (
		<ContentContainer>
			<PageTitle text='Gruppe hinzufügen' />
			<FormItem
				name='Name'
				onChange={setName}
				value={name}
				showRequired={error && !name}
				requiredMessage='Bitte einen Namen angeben'
			/>
			<EditPermissions {...{ permissions, setPermissions }} />
			<div className={styles.buttonContainer}>
				<ColorButton onClick={save} text='Hinzufügen' icon={FiPlus} large />
			</div>
		</ContentContainer>
	)
}

export default RolesNew
