import {POST_UPDATE_UNREAD, POSTS_SET_UNREAD_LISTENER} from "store/posts/types"
import {AppThunk} from "store/store"
import * as api from './api';
import {firebaseApp} from "lib/firebase";
import {
    getDownloadURL,
    getStorage,
    ref,
    StorageObserver,
    uploadBytes,
    uploadBytesResumable,
    UploadMetadata,
    UploadTaskSnapshot
} from "firebase/storage"
import {addDoc, collection, doc, getFirestore, onSnapshot, setDoc, updateDoc} from "firebase/firestore";
import {getFunctions, httpsCallable} from "firebase/functions";
import {fileObject, folderObject, PathObject} from "store/folders/types";
import {database} from "pages/folders/components/database";
import {PixelCrop} from 'react-image-crop'
import {v4 as uuid4} from "uuid";


export const uriToBlob = (uri: string): Promise<Blob> => {
    return new Promise((resolve, reject) => {
        if(uri.startsWith('data:image/')) return resolve(convertBase64ToBlob(uri));
        const xhr = new XMLHttpRequest()
        xhr.onload = () => {
            resolve(xhr.response)
        }
        xhr.onerror = e => {
            reject(e)
        }
        xhr.responseType = 'blob'
        xhr.open('GET', uri, true)
        xhr.send(null)
    })
}

export const resizeDimenesion = (width: number, height: number, maxSize: number) => {
    if (width > height) return resizeByWidth(width, height, maxSize);
    return resizeByHeight(width, height, maxSize);
}

export const resizeByWidth = (width: number, height: number, maxWidth: number) => {
    if (width > maxWidth) {
        height *= maxWidth / width;
        width = maxWidth;
    }
    return {width, height};
}

export const resizeByHeight = (width: number, height: number, maxHeight: number) => {
    if (height > maxHeight) {
        width *= maxHeight / height;
        height = maxHeight;
    }
    return {width, height};
}

interface IResizeImageOptions {
    maxSize: number;
    file: File | Blob | string;
    quality?: number; //Number range in ts 4.8 possible https://stackoverflow.com/questions/69089549/typescript-template-literal-type-how-to-infer-numeric-type/69090186#69090186
}

export const dataURItoBlob = (dataURI: string) => {
    const bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ?
        atob(dataURI.split(',')[1]) :
        unescape(dataURI.split(',')[1]);
    const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const max = bytes.length;
    const ia = new Uint8Array(max);
    for (let i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
    return new Blob([ia], {type: mime});
};

/**
 * Convert BASE64 to BLOB
 * @param base64Image Pass Base64 image data to convert into the BLOB
 * https://stackoverflow.com/a/55257089/13182135
 */
const convertBase64ToBlob=(base64Image: string) => {
    // Split into two parts
    const parts = base64Image.split(';base64,');

    // Hold the content type
    const imageType = parts[0].split(':')[1];

    // Decode Base64 string
    const decodedData = window.atob(parts[1]);

    // Create UNIT8ARRAY of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length);

    // Insert all character code into uInt8Array
    for (let i = 0; i < decodedData.length; ++i) {
        uInt8Array[i] = decodedData.charCodeAt(i);
    }

    // Return BLOB image after conversion
    return new Blob([uInt8Array], {type: imageType});
}

export const resizeImage = (settings: IResizeImageOptions, toBlob: boolean = true): Promise<Blob | string> => {
    if (settings.quality && (settings.quality > 1 || settings.quality < 0)) throw new Error("Quality allowed only between 0 and 1!");
    const file = settings.file;
    const maxSize = settings.maxSize;
    const reader = new FileReader();
    const image = new Image();
    const canvas = document.createElement('canvas');

    const resize = () => {
        let width = image.width;
        let height = image.height;

        if (width > height) {
            if (width > maxSize) {
                height *= maxSize / width;
                width = maxSize;
            }
        } else {
            if (height > maxSize) {
                width *= maxSize / height;
                height = maxSize;
            }
        }
        canvas.width = width;
        canvas.height = height;
        canvas.getContext('2d')?.drawImage(image, 0, 0, width, height);
        let dataUrl = canvas.toDataURL('image/jpeg', settings.quality);
        return toBlob ? dataURItoBlob(dataUrl) : dataUrl;
    };

    return new Promise((ok, no) => {
        if(typeof file === "string"){
            if(!file.startsWith('data:image/')) no(new Error("Not File String is not Base64!"));
            image.onload = () => {
                return ok(resize())
            };
            image.src = file;
        }
        else if (!file.type.match(/image.*/)) {
            no(new Error("Not an image"));
            return;
        } else {
            reader.onload = (readerEvent) => {
                image.onload = () => {
                    return ok(resize())
                };
                image.src = readerEvent.target?.result?.toString() ?? '';
            };
            reader.readAsDataURL(file);
        }
    })
};

export const uploadGroupImage = async (file: File|Blob, chatId: string) => {
    let pic = await resizeImage({file, maxSize: 300}, false) as string;
    const picture = pic.replace('data:image/jpeg;base64', '');
    await api.post('messenger/group-picture', {chatId, picture: picture});
    return pic;
}

const fileToBase64 = (file: File|Blob) => {
    return new Promise<string>(((resolve) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
            resolve(reader.result as string);
        })
        reader.readAsDataURL(file);
    }))


}
export const uploadUserImage = async (file: File|Blob) => {
    let picture = file.type === "image/gif" ? await fileToBase64(file) : await resizeImage({
        file,
        maxSize: 512,
        quality: 0.8
    }, false) as string;
    //Weil expo base64 Stings kein 'data:image/jpeg;base64' enthalten
    picture = picture.replace(/data:image\/[a-z]{3,4};base64/gm, '');
    return await api.post('users/profile-picture', {picture}) as string;
}

export const DeleteUserImage = async () => {
    await api.delete('users/profile-picture')
}

export const DeleteGroupImage = async (chatId: string) => {
    await api.delete('messenger/group-picture', {chatId})
}

export const uploadImage = async (uri: string, path: string, size?: number, quality?: number) => {
    let blob:Blob|undefined;
    if (size) blob = await resizeImage({maxSize: size, file: uri, quality}) as Blob;
    else blob = await uriToBlob(uri);
    const mRef = ref(getStorage(), `${path}img${Date.now()}`);
    await uploadBytes(mRef, blob);
    return [
        {
            original: await getDownloadURL(mRef),
            storageRef: mRef.fullPath
        }
    ]
}
export const uploadDocument = async (file: Blob, name: string, path?: string) => {
    const mRef = ref(getStorage(), `${path}${Date.now()}${name}`);

    await uploadBytes(mRef, file)

    return {
        original: await getDownloadURL(mRef),
        storageRef: mRef.fullPath
    }
}

export function setReadAllPosts(): AppThunk {
    return async (dispatch, getState) => {
        const uid = getState().auth.uid;
        if (uid) {
            await setDoc(doc(getFirestore(firebaseApp), 'users', uid, 'counters', 'posts'), {'unread': 0})
        }
    }
}


export function attachBadgeListener(): AppThunk {
    return async (dispatch, getState) => {
        const counters: { [key: string]: number } = {};
        const uid = getState().auth.uid;
        if (uid) {
            const snap = onSnapshot(collection(getFirestore(firebaseApp), 'users', uid, 'counters'), snapshot => {
                snapshot.docChanges().forEach(({doc}) => {
                    counters[doc.id] = doc.get('unread') ?? 1;
                    if (doc.id === 'posts')
                        dispatch({type: POST_UPDATE_UNREAD, unread: counters['posts']});
                    if (doc.id === 'messages')
                        dispatch({type: 'UPDATECHATSUNREAD', unread: counters['messages']});
                })
            })

            dispatch({type: POSTS_SET_UNREAD_LISTENER, listener: snap})
        }
    }
}

export const sendMail = async (from: string, to: string, subject: string, name: string, message: string) => {
    try {
        await httpsCallable(getFunctions(), 'sendMail')({
            name,
            email: from,
            message,
            subject,
            receipient: to
        })
        return true;
    } catch (e) {
        console.log(e);
        return false;
    }
}


export const uploadVideo = async (video: File): Promise<{ id: string }> => {
    const data = new FormData();
    data.append('video', video.slice(0, video.size, 'video/mp4'), `commulino-${Date.now()}`);

    const {id} = await api.upload('posts/video', data);

    return {id};
};

export const replaceVideo = async (video: File, id: string): Promise<{ id: string }> => {
    const data = new FormData();
    data.append('video', video.slice(0, video.size, 'video/mp4'), `commulino-${Date.now()}`);
    data.append('id', id);

    const {id: _id} = await api.replace('posts/video', data);
    if (id !== _id) throw Error("Id of to replaced Video changed, that should not be possible, congrats");

    return {id};
};

/**
 * Delete a Vimeo video
 * @param videoId
 */
export const deleteVideo = async (videoId: string) => {
    return await api.delete(`posts/video/${videoId}`);
}


type t_uploadFileToCloudFolderProps = {
    to: { id: string, path: folderObject["path"] },
    file: File,
    nextOrObserver?: StorageObserver<UploadTaskSnapshot>
        | ((snapshot: UploadTaskSnapshot) => unknown)
        | null
        | undefined,
    error?: (a: any) => void,
    complete?: (newDoc: fileObject) => void
}

export const genUploadTask = (path: string, file: File, metadata?: UploadMetadata) => {
    return uploadBytesResumable(ref(getStorage(firebaseApp), path), file, metadata)
}


export const genUploadCloudTask = (to: t_uploadFileToCloudFolderProps["to"], file: t_uploadFileToCloudFolderProps["file"], metadata?: UploadMetadata) => {
    const filePath =
        (to?.path.length > 0
            ? `${(to.path.map(folder => folder.id)).join("/")}/${to.id}/${file.name}`
            : `${to.id}/${file.name}`) + uuid4()
    return {task: genUploadTask(`/files/${filePath}`, file, metadata), filePath};
}

export const genDeleteIdFromFolderPath = (destinationFolder: string, path: PathObject[]): string[] => {
    return [...path.map(path => path.id as string), destinationFolder]
}


export const uploadFileToCloud = (
    uid: string,
    rootFolderId: string,
    deleteId: string[],
    to: t_uploadFileToCloudFolderProps["to"],
    file: t_uploadFileToCloudFolderProps["file"],
    nextOrObserver: t_uploadFileToCloudFolderProps["nextOrObserver"],
    error: t_uploadFileToCloudFolderProps["error"],
    complete: t_uploadFileToCloudFolderProps["complete"],
    metadata?: UploadMetadata
) => {
    const {task: uploadTask, filePath} = genUploadCloudTask(to, file, metadata);
    uploadTask.on("state_changed", nextOrObserver, error, async () => {
        const url = await getDownloadURL(uploadTask.snapshot.ref)
        const toAdd = {
            url: url,
            name: file.name,
            createdAt: database.getCurrentTimestamp(),
            folderId: to.id,
            userId: uid,
            deleteId: deleteId,
            type: file.type,
            ref: `/files/${filePath}`
        }
        try {
            const newDoc = await addDoc(collection(getFirestore(), 'folders', rootFolderId, 'files'), toAdd)
            if (rootFolderId !== to.id) {
                await updateDoc(doc(getFirestore(), 'folders', rootFolderId, 'folders', to.id), {child: true})
            }
            if (complete) complete(database.formatDocFiles({
                id: newDoc.id, data: () => {
                    return {...toAdd}
                }
            }));
        } catch (e) {
            if (error) error(e);
        }
    })
}

export const cropImage = (file: HTMLImageElement, crop: PixelCrop) => {
    const image = file;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d')

    if (!ctx) {
        throw new Error('No 2d context')
    }
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    // devicePixelRatio slightly increases sharpness on retina devices
    // at the expense of slightly slower render times and needing to
    // size the image back down if you want to download/upload and be
    // true to the images natural size.
    const pixelRatio = window.devicePixelRatio
    // const pixelRatio = 1

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio)

    ctx.scale(pixelRatio, pixelRatio)
    ctx.imageSmoothingQuality = 'high'

    const cropX = crop.x * scaleX
    const cropY = crop.y * scaleY

    const centerX = image.naturalWidth / 2
    const centerY = image.naturalHeight / 2

    ctx.save()

    // 5) Move the crop origin to the canvas origin (0,0)
    ctx.translate(-cropX, -cropY)
    // 4) Move the origin to the center of the original position
    ctx.translate(centerX, centerY)
    // 1) Move the center of the image to the origin (0,0)
    ctx.translate(-centerX, -centerY)
    ctx.drawImage(
        image,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
    )

    ctx.restore()
    return canvas.toDataURL('image/jpeg');
}
