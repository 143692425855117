import React, {lazy, useEffect, useRef, useState} from 'react';
import styles from "./Images.module.css";
import {ActivityIndicator, Typo} from "components";
import "react-image-gallery/styles/css/image-gallery.css";
import {elm, t_props} from "../type";
import {setImageGalleryOverlay} from "store/posts/actions";
import {useDispatch} from "react-redux";

const EditImages = lazy(() => import('./EditImages'));


const Images = ({post, edit}: t_props) => {
    const Ref = useRef<HTMLDivElement>(null)
    const [myWidth, setMyWidth] = useState(0)
    const [load, setLoad] = useState(true)
    const dispatch = useDispatch();

    useEffect(() => {
        if (Ref.current && post.images && post.images.length >= 5) {
            const res = new ResizeObserver((c) => {
                setMyWidth(c[0].contentRect.width / 5 - 16)
            })
            res.observe(Ref.current);
            return () => {
                Ref.current && res.unobserve(Ref.current)
            }
        }
    }, [post.images])

    if (post.images === undefined) return null

    const render = (One?: elm) => {
        if (post.images === undefined) return null;
        const single = post.images.length === 1;
        return (
            <div ref={Ref} style={{position: 'relative'}}>
                <div className={styles.container}
                     style={{
                         height: single ? '40vh' : '30vh',
                         maxHeight: single ? '40vh' : '30vh',
                         opacity: load ? 0 : 1,
                         justifyContent: 'center'
                     }}>
                    {single &&
                        <div
                            key={`img`}
                            className={styles.imageContainer}
                            style={{width: '100%', maxHeight: '40vh'}}
                            onClick={() => {
                                if (post.images && !edit)
                                    dispatch(setImageGalleryOverlay({
                                        images: post.images,
                                        startIndex: 0,
                                    }))
                            }}
                        >
                            <img
                                src={post.images[0].medium || post.images[0].original}
                                className={styles.image}
                                style={{objectFit: 'contain'}}
                                alt=''
                                onLoad={() => {
                                    setLoad(false)
                                }}
                            />
                        </div>
                    }
                    {!single && post.images.slice(0, 5).map((image, index) => (
                        <div
                            key={`img${index}`}
                            className={styles.imageContainer}
                            style={{height: '30vh', maxWidth: (post.images?.length ?? 0) >= 5 ? myWidth : '100%'}}
                            onClick={() => {
                                if (post.images && !edit)
                                    dispatch(setImageGalleryOverlay({
                                        images: post.images,
                                        startIndex: index,
                                    }))
                            }}
                        >
                            <img
                                src={image.medium || image.original}
                                className={styles.image}
                                alt=''
                                onLoad={() => {
                                    setLoad(false)
                                }}
                            />
                            {index === 4 && (post.images?.length ?? 0) > 5 && (
                                <div className={styles.more}>
                                    <Typo style={styles.moreText}>+{(post.images?.length ?? 0) - 4}</Typo>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                {load &&
                    <div
                        style={{
                            inset: 0,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            opacity: load ? 1: 0,
                            transition: 'opacity 0.3s ease',
                            position: 'absolute'
                        }}>
                        <ActivityIndicator large/>
                    </div>
                }
                {One && <One/>}
            </div>
        );
    }

    if (edit) return render(EditImages);
    else return render();

}
export default Images;
