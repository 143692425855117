import {
	RolesState,
	RolesActionTypes,
	ROLES_REQUEST,
	ROLES_RECEIVE,
	ROLES_CREATE,
	ROLES_DELETE,
	ROLES_EDIT
} from './types'

const initialState: RolesState = {
	roles: [],
	loading: false
}

export default function roles(state = initialState, action: RolesActionTypes): RolesState {
	switch (action.type) {
		case ROLES_REQUEST:
			return {
				...state,
				loading: true
			}
		case ROLES_RECEIVE:
			return {
				...state,
				loading: false,
				roles: action.roles
			}
		case ROLES_CREATE:
			return {
				...state,
				loading: true
			}
		case ROLES_DELETE:
			return {
				...state,
				loading: true
			}
		case ROLES_EDIT:
			return {
				...state,
				loading: true
			}
		default:
			return state
	}
}
