import * as get from './get';
import * as converter from './converter';
import * as chatFunctions from './chatFunctions';
import * as types from './types';
import * as operations from './oparations'

export const getAreas = get.getAreas;
export const checkIfChatExists = chatFunctions.checkIfChatExist;
export const createChat = chatFunctions.createChat;
export const joinChat = chatFunctions.currentUserJoinChat;
export const getResidentsAll = get.getResidentsAll;
export const getRelatives = get.getRelatives;
export const searchName = operations.search

export const convertToContact = converter.convertToContact;
export const convertForChat = converter.convertForChat;


export const compareContact= operations.compareChatUser
export const sortContacts = operations.sortContacts;
export const isRes = operations.isRes;
export const isRel = operations.isRel;
export const isUser = operations.isUser;

export type i_ResidentDirectory = types.i_ResidentDirectory;
export type i_ResUser = types.i_ResUser;
export type i_RelUser = types.i_RelUser;
export type t_ChatData = types.t_chatTypes.t_ChatData
export type t_user = types.t_chatTypes.t_smallUser;

