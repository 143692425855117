import React from 'react'
import {NavLink} from 'react-router-dom'
import styles from './NavItem.module.css'
import {IconType} from "react-icons";
import {Row} from "components";

interface Props {
    text?: string,
    elm?: JSX.Element,
    icon: IconType | null,
    route?: string,
    onClick?: () => void,
    iconStyle?: any,
    children?: React.ReactNode
}

const NavItem = ({text, iconStyle, elm, icon: Icon, route, onClick, children}: Props) => {
    const Item = () => (
        <>
            {Icon && <Icon style={iconStyle ?? {fontSize: '24px', marginRight: '16px'}}/>}
            <Row flex={1} justifyContent={'space-between'}>
                <div>{elm ?? text}</div>
                <div>{children}</div>
            </Row>
        </>
    )


    if (route) {
        return (
            <NavLink to={route} className={styles.container} activeClassName={styles.active}>
               <Item />
            </NavLink>
        )
    } else {
        return (
            <div className={styles.container} onClick={onClick}>
                <Item />
            </div>
        )
    }
}

export default NavItem
