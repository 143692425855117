import React, {lazy} from 'react';

const SurveyElm=lazy(()=>import('./SurveyElm'))
const SurveyEdit=lazy(()=>import('./SurveyEdit'))

type t_props={
    edit: boolean,
    id: string,
}

const Survey=({edit,id}:t_props)=>{
    if(edit) return <SurveyEdit />;
    else return <SurveyElm id={id} />
}

export default Survey;