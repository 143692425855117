import React, {lazy} from 'react';
import {elm, t_props} from "../type";
import styles from "./Content.module.css";

const EditContent=lazy(()=>import('./EditContent'));
const ShowContent=lazy(()=>import('./ShowContent'));

const Content=({post,edit}:t_props)=>{
    const render=(One:elm)=>(
        <div className={`${styles.container}`}>
            <One post={post} />
        </div>
    );
    if(edit) return render(EditContent);
    else return render(ShowContent);

}
export default Content;