import { Chat } from '@pixel-kraft/commulino-types';

type Group = Required<Chat>['group'];
type Permissions = keyof Required<Group>['admins'][string]['permissions'];


export default function checkGroupPermissions(
  group: Group,
  uid: string,
  permission: Permissions,
): boolean {
  // Creator of group is always allowed to edit (for backwards compat) so we skip it here.
  if (group.createdBy !== uid) {
    const admin = group.admins?.[uid];

    if (!admin?.permissions[permission]) return false;
  }

  return true;
}
