import React, {useEffect, useState} from "react"
import {
    FaCalendarDay,
    FaCalendarWeek,
    FaRegCalendar,
    FcAddImage, FcImageFile, GrCircleInformation, IoIosCloseCircle,
    MdCake, MdEdit,
    MdEvent
} from "react-icons/all";
import styles from "./AddEvent.module.css"
import Tippy from "@tippyjs/react";
import {
    AlertDialog,
    Button,
    ButtonPopUp, CheckBox,
    ColorButton,
    ContentContainer,
    ErrorMessageInline,
    FormItem, ImagePicker,
    Modal
} from "components";
import Select, {OptionsType, StylesConfig} from "react-select"
import "react-datetime/css/react-datetime.css";
import {Calendars, Option} from "../index";
import * as api from 'lib/api'
import {t_eventData} from "components/Kalender/types";
import Typo from "components/Typo";
import moment, {Moment} from "moment";
import {FiTrash} from "react-icons/fi";
import  {RRule} from "rrule";
import {useSelector} from "react-redux";
import {RootState} from "store/reducers";
import {File} from "components/Cloud/Content";
import { ArrowUturnLeftIcon } from "@heroicons/react/24/solid"
import DateTimePickerMuiInput from "./dateTimePickers/DateTimePickerMuiInput";

interface Props {
    calendars?: Calendars[],
    modal: boolean,
    setModal: (to: boolean) => void,
    options: Option[],
    eventData?: Partial<t_eventData>,
    setEventData?: (to: undefined) => void,
    setChange: (to: Partial<t_eventData>|undefined) => void,
    admin:boolean
}

interface Messages {
    title: boolean,
    calendar: boolean,
    start: boolean,
    end: boolean,
    startBigger: boolean,
    frequency: boolean,
    interval:boolean,
    startAndEndSame: boolean
}

const defaultMessage: Messages = {
    title: false,
    calendar: false,
    start: false,
    end: false,
    frequency: false,
    interval: false,
    startBigger: false,
    startAndEndSame:false
}

type SelectOption<ValueType> = {
    value: ValueType;
    label: string;
    icon?: JSX.Element;
    color?: string;
};

export const getName = (ui:string|undefined) => {
    return useSelector((state:RootState) => {
        if (ui) {
            return state.userProfiles[ui]
        }
        return null;
    })
}

const AddEvent = ({
                      calendars,
                      modal,
                      setModal,
                      options,
                      eventData,
                      setChange,
                      setEventData,
                      admin
                  }: Props) => {
    const [newEventDataObject, setNewEventDataObject] = useState<Partial<t_eventData>>({});     //Object where is saved the event changes and compared with eventData
    const eventColor = options.find(({value}) => value === newEventDataObject.calendarId)?.color ?? '';
    const [eventIcon, setEventIcon] = useState<boolean>(false); //To restart the object when the user clicks on the event icon.
    const [messages, setMessages] = useState<Messages>(defaultMessage);
    const [information, setInformation] = useState(false);
    const [deleteEv, setDeleteEv] = useState(false);
    const [editAlertOptions, setEditAlertOptions] = useState(false);
    const created = getName(eventData?.createdBy);
    const updated = getName(eventData?.updatedBy);
    const [imgSrc, setImgSrc] = useState('');
    const [imgFile, setImgFile] = useState<File>();
    const {subColor} = useSelector((state:RootState)=>state.config);
    const [dateTimeInvalid, setDateTimeInvalid] = useState<{start:boolean,end:boolean}>({start:false,end:false});
    const [deleteRecurringEventDate, setDeleteRecurringEventDate] = useState(true);
    const [editRecurringEventButton, setEditRecurringEventButton] = useState(false);
    const [editRecurringEventDate, setEditRecurringEventDate] = useState(true);

    const customSingleValue = ({data}: any) => (
        <div className="input-select">
            <div className="input-select__single-value">
                {data.icon && <span style={{
                    color: data.color ? data.color : "#ff5500",
                    position: "absolute",
                    top: "10px",
                    fontSize: "17px"
                }} className="input-select__icon">{data.icon}</span>}
                <span style={{position: "absolute", top: "9px", left: "25px"}}>{data.label}</span>
            </div>
        </div>
    );

    const selectStyle = (message:boolean) => {
        const styleCalendar: StylesConfig<any, false> = {
            control: (base) => ({
                ...base,
                border: message ? "1px solid #ff5500" : `1px solid ${subColor}`,
                borderRadius: "3px",
                margin: "4px 0",
                '&:hover': {
                    backgroundColor: "rgba(244, 245, 242, 0.2)",
                    borderColor: "#414141",
                },
                boxShadow: "none"
            }),
            valueContainer: () => ({
                padding: "4px"
            })
        }
        return styleCalendar
    }

    // Freq options
    const wiederholung: OptionsType<SelectOption<number>> = [
        {value: 3, label: "Täglich", icon: <FaCalendarDay/>},
        {value: 2, label: "Wöchentlich", icon: <FaCalendarWeek/>},
        {value: 1, label: "Monatlich", icon: <FaRegCalendar/>},
        {value: 0, label: "Jährlich", icon: <MdCake/>}
    ];

    const status = [
        {value:"tentative", label:"Anfrage an"},
        {value:"confirmed", label: "Bestätigt"},
    ]

    // FUNCTIONS

    const disableSwapCalendar = () => {
        if (eventData?.recurrenceObject?.id && (eventData?.summary !== newEventDataObject.summary || eventData?.allDay !== newEventDataObject.allDay || !eventData?.end?.isSame(newEventDataObject.end) || !eventData?.start?.isSame(newEventDataObject.start) || eventData?.description !== newEventDataObject.description || eventData?.status !== newEventDataObject.status || eventData?.imageUrl !== newEventDataObject.imageUrl)) {
            if (eventData?.calendarId !== newEventDataObject.calendarId) setNewEventDataObject({...newEventDataObject, calendarId: eventData.calendarId});
            return true;
        }
        return false;
    }

    const checkEvent = () => {
        // Validation function. Triggers the warning messages as well.
        if (eventData?.allDay === newEventDataObject.allDay && eventData?.end?.isSame(newEventDataObject.end) && eventData?.start?.isSame(newEventDataObject.start) && (newEventDataObject.summary ? newEventDataObject.summary : undefined) === eventData?.summary && eventData?.status === newEventDataObject.status && newEventDataObject.description === eventData?.description && eventData?.calendarId === newEventDataObject.calendarId && eventData?.interval === newEventDataObject.interval && eventData?.freq === newEventDataObject.freq && !imgSrc && eventData?.imageUrl === newEventDataObject.imageUrl) {
            setModal(false);
            return false;
        }
        const msg: Messages = {...defaultMessage};
        if (newEventDataObject.summary === "")
            msg.title = true;
        if (newEventDataObject.calendarId === "")
            msg.calendar = true
        if (!newEventDataObject.start)
            msg.start = true;
        if (!newEventDataObject.end)
            msg.end = true;
        if (newEventDataObject.start && newEventDataObject.end && newEventDataObject.start > newEventDataObject.end)
            msg.startBigger = true;
        if (newEventDataObject.end?.isSame(newEventDataObject.start) && !newEventDataObject.allDay)
            msg.startAndEndSame = true;
        if (newEventDataObject.interval && !(typeof newEventDataObject.freq === 'number'))
            msg.frequency = true;
        if (!newEventDataObject.interval && (typeof newEventDataObject.freq === 'number') && newEventDataObject.freq >= 0)
            msg.interval = true;
        if (dateTimeInvalid.start || dateTimeInvalid.end) {
            return false
        }
        if (Object.keys(msg).filter(key => msg[key as keyof Messages]).length > 0) {
            //If there is a prop in msg that is true then do ...
            setMessages(msg);
            return false;
        }
        return true;
    }

    const deleteEvent = async () => {
        /**
         * Deletes events. Just for a regular events or the whole recurring events, not instances.
         * Admins are the only ones able to edit the events.
         * */
        if (eventData && eventData.calendarId && eventData.publicId) {
            await api.delete(`calendars/${eventData.calendarId}/events/${eventData.publicId}`)
            setChange({});
            cleanSelect()
        } else
            console.warn("Don't know what event should be delete");
    }

    function handleSubmit() {
        /**
         * Creates new events, but also edits old ones. But just for a regular events or the whole recurring events, not instances.
         * Admins are the only ones able to edit the events.
         * Everybody can create them.
         * There is no recurring events edited here. Just to create new events, or edit regular events.
         * The interval and freq can be undefined, because they must be set in the form.
         * */

        let rule = new RRule()
        if(newEventDataObject.interval && newEventDataObject.freq || newEventDataObject.freq === 0) {
            rule = new RRule({
                freq: newEventDataObject.freq,
                interval: newEventDataObject.interval,
            })
        }
        if (eventData?.publicId) {  //Just ADMIN. PublicId parameter means an already created event, therefore, update function. (Just non recurring events)
            const update = () => {
                const addImage = !!(eventData.imageUrl !== newEventDataObject.imageUrl || imgSrc);
                const eventObject = {...({
                        summary: newEventDataObject.summary,
                        description: newEventDataObject.description,
                        start:newEventDataObject.start,
                        end:newEventDataObject.end,
                        recurrence: [newEventDataObject.interval && newEventDataObject.freq || newEventDataObject.freq === 0 ? rule.toString() : {}],
                        allDay:newEventDataObject.allDay,
                        status:newEventDataObject.status,
                    }), ...(addImage && {image: imgSrc ?? null})}
                api.patch(`calendars/${newEventDataObject.calendarId}/events/${eventData.publicId}`, eventObject).then(() => {
                    cleanSelect()
                    setChange({})
                    setEventIcon(false)
                    setModal(!modal)
                })
            }
            if (!eventData) throw new Error("Event data is undefined in update!");
            if (newEventDataObject.calendarId === eventData.calendarId) {
                update()
            } else {
                // Updates the event and move it to another calendar.

                api.post(`calendars/${eventData.calendarId}/events/${eventData.publicId}/move`, {destination: newEventDataObject.calendarId})
                    .then(() => {
                        update()
                    })
            }
        } else {
            //ADMIN and USERS. Creates new events.
            const eventObject = {...({
                    summary: newEventDataObject.summary,
                    description: newEventDataObject.description,
                    start:newEventDataObject.start,
                    end:newEventDataObject.end,
                    recurrence: [newEventDataObject.interval && newEventDataObject.freq || newEventDataObject.freq === 0? rule.toString() : {}],
                    allDay:newEventDataObject.allDay,
                    status: admin ? newEventDataObject.status : "tentative",
                }), ...(imgSrc && {image:imgSrc})}

            api.put(`calendars/${newEventDataObject.calendarId}/events`, eventObject).then(async () => {
                cleanSelect()
                setEventIcon(false)
                setModal(!modal)
                setChange({})
            })
        }
    }

    const cleanSelect = () => {
        setNewEventDataObject({...newEventDataObject,
            calendarId:"",
            freq:undefined,
        })
        cleanImageFile()
    }

    useEffect(() => {
        /**
         * Stores the information in an object to create or update.
         * Depending on the user's click, it has different format.
         * Three different formats: event to create, regular event to be updated or recurrence of recurring event to be updated.
         * */

        if (modal && eventData?.publicId && !eventIcon && !editRecurringEventButton) {
            // Regular events and instances from recurring events

            setNewEventDataObject({...eventData})
        } else if (modal && eventData?.publicId && !eventIcon && editRecurringEventButton) {
            // Recurrence from recurring events

            setNewEventDataObject({
                summary:eventData.summary,
                start:eventData.recurrenceObject?.recStart,
                end:eventData.recurrenceObject?.recEnd,
                description:eventData.description,
                calendarId:eventData.calendarId,
                interval:eventData.recurrenceObject?.recInterval,
                freq:eventData.recurrenceObject?.recFreq,
                allDay:eventData.recurrenceObject?.recAllDay,
                status:eventData.status,
                imageUrl: eventData.imageUrl
            })
        } else if (modal) {
        // Sets an empty object to create an event.

            setNewEventDataObject({...newEventDataObject,
                summary:"",
                start:moment(eventData?.start ?? eventData?.start ?? Date.now()),
                end:moment(eventData?.end ?? eventData?.end ?? Date.now()+ 86400000),
                description:"",
                calendarId:"",
                interval:undefined,
                freq:undefined,
                allDay:false,
                status:"confirmed",
                imageUrl: ""
            })
            setMessages(defaultMessage);
        }
    }, [eventData, modal, editRecurringEventButton]);

    const allDayCheckboxOnChange = () => {
        // Same date is needed in start and end to create all-day events.
        // When it changes from false to true, it sets the same value. Reverse -> as normal.
        if (!newEventDataObject.allDay) {
            if (editRecurringEventButton) {
                setNewEventDataObject({...newEventDataObject,
                    start:moment(eventData?.recurrenceObject?.recStart),
                    end:eventData?.recurrenceObject?.recEnd.calendar() === eventData?.recurrenceObject?.recStart.calendar() ? moment(eventData?.recurrenceObject?.recStart).add(1,'day') : moment(eventData?.recurrenceObject?.recEnd),
                    allDay:!newEventDataObject.allDay
                })
            } else {
                setNewEventDataObject({...newEventDataObject,
                    start:moment(eventData?.start),
                    end:eventData?.end?.calendar() === eventData?.start?.calendar() ? moment(eventData?.start).add(1,'day') : moment(eventData?.end),
                    allDay:!newEventDataObject.allDay
                })
            }
        } else {
            if (editRecurringEventButton) {
                setNewEventDataObject({...newEventDataObject,
                    start:moment(eventData?.recurrenceObject?.recStart),
                    end:moment(moment(eventData?.recurrenceObject?.recStart).isSame(eventData?.recurrenceObject?.recEnd) ? moment(eventData?.recurrenceObject?.recEnd).add(1, "d") : moment(eventData?.recurrenceObject?.recEnd)),
                    allDay:!newEventDataObject.allDay
                })
            } else {
                setNewEventDataObject({...newEventDataObject,
                    start:moment(eventData?.start),
                    end:moment(moment(eventData?.start).isSame(eventData?.end) ? moment(eventData?.end).add(1, 'day') : moment(eventData?.end)),
                    allDay:!newEventDataObject.allDay
                })
            }
        }
    }

    const cleanImageFile = () => {
        setImgFile(undefined);
        setImgSrc('')
    }

    const cleanImageFileEventEdit = () => {
        // Triggers a conditional to know if the admin wants to delete an image already uploaded
        setNewEventDataObject({...newEventDataObject, imageUrl:""})
    }

    return (
        <div className={styles.iconSize}>
            <Tippy
                content="Ereignis hinzufügen"
                delay={[400, 0]}
                className={styles.tippy}
                arrow={false}
            >
                <div
                    tabIndex={0}
                    className={styles.iconTextContainer}
                    onClick={()=> {
                        setEventIcon(true);
                        setModal(true);
                        if (setEventData) {
                            setEventData(undefined);
                        }
                    }}
                >
                    <div className={styles.iconTextWrapper}>
                        <MdEvent
                            className={calendars?.length === 0 ? styles.iconDis : styles.icon}
                        />
                        <div>Termin</div>
                    </div>
                </div>
            </Tippy>
            <Modal
                isVisible={modal}
                hide={() => null}
            >
                {modal && //With this all components in this container will be rerender and lose their locale state
                    <div
                        style={eventData?.publicId ? {padding:"18px", background:eventColor, opacity:"0.85"} : {}}
                    >
                        <ContentContainer
                            hideSpacer
                            contentStyle={styles.addEventContent}
                        >
                            <div className={styles.titlesWrapper}>
                                {eventData?.recurrenceObject ? (
                                    <>
                                        <div
                                            className={eventData?.publicId ? styles.typoUpdate : styles.typo}
                                            onClick={()=>setEditRecurringEventButton(false)}
                                        >
                                            {eventData?.publicId ? "Ereignis bearbeiten" : admin ? "Ereignis hinzufügen" : "Hier können Sie einen Terminwunsch eintragen!"}
                                        </div>
                                        <div
                                            className={styles.recurrenceButton}
                                            onClick={()=>setEditRecurringEventButton(prevState => !prevState)}
                                        >
                                            {editRecurringEventButton ? (
                                                <ArrowUturnLeftIcon className={styles.returnIcon}/>
                                            ):(
                                                <div className={styles.recurrenceLabel}>Wiederholung</div>
                                                )}
                                        </div>
                                    </>
                                ):(
                                    <div
                                        className={eventData?.publicId ? styles.typoUpdate : styles.typo}
                                    >
                                        {eventData?.publicId ? "Ereignis bearbeiten" : admin ? "Ereignis hinzufügen" : "Hier können Sie einen Terminwunsch eintragen!"}
                                    </div>
                                )}
                            </div>
                            <div className={styles.modalGrid}>
                                <div className={styles.errorRow}>
                                    {admin && <div className={styles.imageNoSelect}/>}
                                    <div className={styles.titleContainer}>
                                        <ErrorMessageInline text={"Bitte geben Sie einen Title ein."}
                                                            visible={messages.title}/>
                                    </div>
                                    <div className={styles.calendarContainer}>
                                        <ErrorMessageInline text={"Bitte wählen Sie einen Kalender aus."}
                                                            visible={messages.calendar}/>
                                    </div>
                                </div>
                                {!editRecurringEventButton && (
                                    <div className={styles.row}>
                                        {admin &&
                                        <>
                                            {newEventDataObject?.imageUrl && !imgFile && !eventIcon ?
                                                <div className={styles.imageSelect} style={{ width: "calc(14% - 16px)"}}>
                                                    <div className={styles.img}>
                                                        <div
                                                            className={styles.imgBackground}
                                                            style={{borderRadius: "50%", border: "1px solid #7e7e7e", height:"100px", width: "100px", backgroundRepeat: "no-repeat", backgroundImage: `url(${newEventDataObject.imageUrl})`}}
                                                        >
                                                        </div>
                                                        <IoIosCloseCircle
                                                            className={styles.imageFileObjectTrueClose}
                                                            color={"#ff5b66"}
                                                            onClick={cleanImageFileEventEdit}
                                                            style={{fontSize:"25px", bottom: "4px"}}
                                                        />
                                                        <MdEdit
                                                            className={styles.imageFileObjectTrueEdit}
                                                            color={"#5cb25d"}
                                                            style={{fontSize:"28px", bottom: "4px"}}
                                                        />
                                                        <ImagePicker
                                                            setImgSrc={setImgSrc}
                                                            setImgFile={setImgFile}
                                                            style={{position: 'absolute', bottom: '11px', right: '2px', border: '1px solid black', height: '20px', width: '25px', cursor: 'pointer', opacity: '0',}}
                                                        />
                                                    </div>
                                                </div>
                                                :
                                                <div className={`${styles.imageNoSelect} ${styles.img} ${styles.icoBackground}`}>
                                                    {imgFile ?
                                                        <>
                                                            <FcImageFile style={{fontSize: "60px", borderRadius:"30px"}}/>
                                                            <IoIosCloseCircle
                                                                className={styles.imageFileObjectTrueClose}
                                                                color={"#ff5b66"}
                                                                onClick={cleanImageFile}
                                                            />
                                                            <MdEdit
                                                                className={styles.imageFileObjectTrueEdit}
                                                                color={"#5cb25d"}
                                                            />
                                                            <ImagePicker
                                                                setImgSrc={setImgSrc}
                                                                setImgFile={setImgFile}
                                                                style={{ position: 'absolute', bottom: '11px', right: '2px', border: '1px solid black', height: '20px', width: '25px', cursor: 'pointer', opacity: '0',}}
                                                            />
                                                            <span className={styles.spanImageSelected}>{ imgFile && imgFile.name}</span>
                                                        </>
                                                        :
                                                        <>
                                                            <FcAddImage style={{fontSize: "60px"}}/>
                                                            <ImagePicker
                                                                setImgSrc={setImgSrc}
                                                                setImgFile={setImgFile}
                                                                style={{position: 'absolute', bottom: '13px', right: '2px', border: '1px solid black', height: '20px', width: '25px', cursor: 'pointer', opacity: '0',}}
                                                            />
                                                        </>
                                                    }
                                                </div>
                                            }
                                        </>
                                        }
                                        <div className={`${styles.standardSpace} ${ admin ? styles.titleContainer : styles.titleContainerUser}`}>
                                            <FormItem
                                                noMarginTopLabel
                                                type={"text"}
                                                name={"Titel"}
                                                value={newEventDataObject.summary ?? ""}
                                                onChange={(e)=>setNewEventDataObject({...newEventDataObject, summary:e})}
                                                maxLength={70}
                                                className={styles.formInputTitle}
                                                classNameInput={`${styles.formInput} ${messages.title && styles.formErrorMessage}`}

                                            />
                                            <span className={styles.number} style={newEventDataObject.summary?.length === 70 ? {color:'#ff5500'} : {color: '#949dad'}}>{newEventDataObject.summary ? newEventDataObject.summary.length : 0}/70</span>
                                        </div>
                                        <div className={`${styles.standardSpace} ${admin ? styles.calendarContainer : styles.calendarContainerUser}`}>
                                            <div className={styles.label}>Kalender</div>
                                            <Select
                                                options={options}
                                                value={options.find(({value}) => (value === newEventDataObject.calendarId))}
                                                onChange={(e:any)=>setNewEventDataObject({...newEventDataObject,calendarId:e.value})}
                                                styles={selectStyle(messages.calendar)}
                                                components={{SingleValue: customSingleValue}}
                                                isSearchable
                                                placeholder={"Kalender auswählen..."}
                                                isDisabled={disableSwapCalendar()}
                                            />
                                        </div>
                                    </div>
                                )}
                                <div className={styles.errorRow}>
                                    <div className={styles.dateTime}>
                                        <ErrorMessageInline text={"Das Startdatum ist später als das Enddatum."}
                                                            visible={messages.startBigger}/>
                                        <ErrorMessageInline text={"Das Enddatum und die Endzeit sowie das Startdatum und die Startzeit sind identisch."}
                                                            visible={messages.startAndEndSame}/>
                                    </div>
                                    <div className={styles.repeatContainer}>
                                        <ErrorMessageInline text={"Bitte wählen Sie eine Wiederholung aus."}
                                                            visible={messages.frequency}/>
                                    </div>

                                </div>
                                <div className={styles.row}>
                                    <div className={styles.allDayContainer}>
                                        <div className={styles.label}>Ganztägig</div>
                                        <div className={styles.checkBoxAllDayContainer}>
                                            <CheckBox
                                                checked={newEventDataObject.allDay}
                                                onChange={allDayCheckboxOnChange}
                                                style={styles.checkBoxAllDay}
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.dateTime}>
                                        <div className={styles.DateTimeContainer} style={eventData?.recurrenceObject && !editRecurringEventButton ? {width:"60%"}:{width:"100%"}}>
                                            <DateTimePickerMuiInput
                                                startLabel={"Startdatum"}
                                                startValue={newEventDataObject.start}
                                                startBorderError={messages.start || messages.startAndEndSame || messages.startBigger}
                                                startOnChange={(val) => setNewEventDataObject({...newEventDataObject, start:moment(val)})}
                                                setDateTimeInvalid={setDateTimeInvalid}
                                                dateTimeInvalid={dateTimeInvalid}
                                                endLabel={"Enddatum"}
                                                endValue={newEventDataObject.end}
                                                endBorderError={messages.startAndEndSame || messages.end}
                                                endOnChange={(val)=>setNewEventDataObject({...newEventDataObject, end:moment(val)})}
                                                allDay={newEventDataObject.allDay??false}
                                            />
                                        </div>
                                    </div>
                                    {!(eventData?.recurrenceObject && !editRecurringEventButton) && (
                                        <>
                                            <div className={`${styles.intervalContainer} ${styles.standardSpace}`}>
                                                <div>
                                                    <ErrorMessageInline text={"Bitte wählen Sie eine Interval aus."}
                                                                        visible={messages.interval}
                                                                        style={styles.intervalWarning}
                                                    />
                                                    <FormItem
                                                        noMarginTopLabel={true}
                                                        type={"number"}
                                                        minValue={0}
                                                        maxValue={55}
                                                        costumeNameElement={<div
                                                            style={{display: "flex", alignItems: "center"}}>
                                                            <Typo
                                                                variant='h5'>Interval</Typo><ButtonPopUp
                                                            popUp={"top"}
                                                            popUpPos={"center"}
                                                            icon={GrCircleInformation}
                                                            popUpStyle={{maxWidth: 300}}
                                                            buttonStyle={{minHeight: 0, zIndex:0}}
                                                            popUpColor={"rgb(144, 238, 144)"}
                                                            PopUbContent={<div className={styles.information}>Sie können die
                                                                Periodizität
                                                                eines
                                                                Ereignisses*
                                                                konfigurieren. Das Intervall legt fest, wie oft ein Ereignis
                                                                wiederholt
                                                                wird, und die Wiederholung bestimmt den Zeitraum. Wenn das
                                                                Intervall
                                                                beispielsweise 1 und die Wiederholung Wöchentlich ist, wird das
                                                                Ereignis
                                                                jede
                                                                Woche wiederholt. Wenn das Intervall 2 beträgt, wird der
                                                                Vorgang
                                                                alle
                                                                zwei
                                                                Wochen wiederholt.
                                                            </div>}
                                                        /></div>}
                                                        value={newEventDataObject.interval ?? ""}
                                                        onChange={(e)=> setNewEventDataObject({...newEventDataObject, interval:parseInt(e)})}
                                                        // Icon={GrCircleInformation}
                                                        onClickIcon={() => setInformation(!information)}
                                                        placeholder={"0"}
                                                        className={styles.formInputInterval}
                                                        classNameInput={`${styles.formInput} ${messages.interval && styles.formErrorMessage}`}
                                                        style={{width:"50%"}}
                                                    />
                                                </div>
                                            </div>

                                            <div className={`${styles.standardSpace} ${styles.repeatContainer}`}>
                                                <>
                                                    <div className={styles.label}>Wiederholung</div>
                                                    <Select
                                                        options={wiederholung}
                                                        value={wiederholung.find(({value}) => value === newEventDataObject.freq)}
                                                        onChange={(e) => setNewEventDataObject({...newEventDataObject, freq: e?.value})}
                                                        styles={selectStyle(messages.frequency)}
                                                        placeholder={"Wiederholung auswählen..."}
                                                        components={{SingleValue: customSingleValue}}
                                                        isClearable
                                                        isSearchable={false}
                                                    />
                                                </>
                                            </div>
                                        </>
                                    )}
                                </div>
                                {!editRecurringEventButton && (
                                    <div className={styles.row}>
                                        <div className={styles.beschreibungContainer}>
                                            <>
                                                <FormItem
                                                    noMarginTopLabel
                                                    type={"text"}
                                                    name={"Beschreibung"}
                                                    value={newEventDataObject.description ?? ""}
                                                    onChange={(e)=>setNewEventDataObject({...newEventDataObject, description:e})}
                                                    maxLength={150}
                                                    className={styles.formInputTitle}
                                                    classNameInput={styles.formInput}
                                                />
                                                <span className={styles.number} style={newEventDataObject.description?.length === 150 ? {color:'#ff5500'} : {color: '#949dad'}}>{newEventDataObject.description ? newEventDataObject.description.length : 0}/150</span>
                                            </>
                                        </div>
                                    </div>
                                )}
                                {admin && !editRecurringEventButton && (
                                    <div className={styles.row}>
                                        <div className={`${styles.standardSpace} ${styles.repeatContainer}`}>
                                            <div className={styles.label}>Status des Ereignisses</div>
                                            <Select
                                                options={status}
                                                styles={selectStyle(false)}
                                                value={status.find(({value})=>value === newEventDataObject.status)}
                                                onChange={(e:any)=>setNewEventDataObject({...newEventDataObject, status:e.value})}
                                                isSearchable={false}
                                            />
                                        </div>
                                    </div>
                                )}
                                {!editRecurringEventButton && (
                                    <div className={styles.createdUpdatedDiv}>
                                        {created && (
                                            <div className={styles.flex}>
                                                <div className={styles.boldText}>Erstellt von:</div>
                                                <div style={{marginRight: "15px"}}>
                                                    {created.name}
                                                </div>
                                            </div>
                                        )}
                                        {updated && (
                                            <div className={styles.flex}>
                                                <div className={styles.boldText}>Aktualisiert von:</div>
                                                <div>
                                                    {updated.name}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className={styles.divButtons}>
                                {!admin && (
                                    <div className={styles.tentativeText}> </div>
                                )}
                                <div style={{flexGrow: 1}}>
                                    {eventData?.publicId &&
                                    <Button icon={FiTrash} text={"Löschen"} light onClick={() => setDeleteEv(true)}/>
                                    }
                                </div>
                                <div style={{display: "flex", height: 30}}>
                                    <Button text='Abbrechen' light style={styles.cancelButton}
                                            onClick={() => {
                                                setEventIcon(false);
                                                setModal(false);
                                                setMessages(defaultMessage);
                                                if (editRecurringEventButton) setEditRecurringEventButton(false);
                                                cleanSelect();
                                            }}/>
                                    <ColorButton text={"Speichern"} onClick={()=>{
                                        if (!checkEvent()) return; // I'd set a warning
                                        if (eventData?.recurrenceObject?.id) {
                                            setEditAlertOptions(true);
                                            setEditRecurringEventDate(true);
                                        } else {
                                            handleSubmit();
                                        }
                                    }}/>
                                </div>
                            </div>
                        </ContentContainer>
                    </div>
                }
            </Modal>
            {deleteEv &&
            <AlertDialog
                title={"Löschen"}
                text={eventData?.recurrenceObject ? "Welches Ereignis möchten Sie löschen?" : "Wollen Sie folgendes event wirklich Löschen?"}
                item={
                    <div>
                        {eventData?.recurrenceObject ? (
                            <div>
                                <CheckBox   // Deletes an event of a recurring event
                                    checked={deleteRecurringEventDate}
                                    onChange={()=>setDeleteRecurringEventDate(prevState => !prevState)}
                                    style={styles.checkBoxEdit}
                                    text={"Diese Veranstaltungt"}
                                />
                                <CheckBox   // Deletes a recurring event
                                    checked={!deleteRecurringEventDate}
                                    onChange={()=>setDeleteRecurringEventDate(prevState => !prevState)}
                                    style={styles.checkBoxEdit}
                                    text={"Alle Ereignisse"}
                                />
                            </div>
                        ) : (
                            <div style={{backgroundColor: 'lightgray',borderRadius: 4,padding: "2px 4px"}}>
                                <div>{eventData?.summary}</div>
                                <div>{eventData?.start?.format('DD.MM.YY HH:mm')} - {eventData?.end?.format('DD.MM.YY HH:mm')}</div>
                            </div>
                        )}
                    </div>
                }
                onCancel={() => {
                    setDeleteEv(false);
                    setDeleteRecurringEventDate(true);
                }}
                onSuccess={() => {
                    if (eventData?.recurrenceObject && deleteRecurringEventDate) {
                        api.patch(`calendars/${newEventDataObject.calendarId}/events/${eventData.recurrenceObject.id}`, {status:'cancelled'}).then(() => {
                            setDeleteEv(false);
                            setModal(false);
                            setChange({});
                        })
                    } else {
                        deleteEvent().then(() => {
                            setDeleteEv(false);
                            setModal(false);
                            setDeleteRecurringEventDate(true);
                        })
                    }
                }}/>
            }
            {editAlertOptions &&
            <AlertDialog
                title={"Bearbeiten"}
                text={!editRecurringEventButton && eventData?.calendarId === newEventDataObject.calendarId ? "Welches Ereignis möchten Sie bearbeiten?" : ""}
                item={
                    <div>
                        {editRecurringEventButton || eventData?.calendarId !== newEventDataObject.calendarId ? (
                            <div>
                                <div style={{marginBottom: "25px"}}>Möchten Sie die Wiederholungen aller Ereignisse bearbeiten?</div>
                            </div>
                        ) : (
                            <div>
                                <CheckBox
                                    checked={editRecurringEventDate}
                                    onChange={()=>setEditRecurringEventDate(prevState => !prevState)}
                                    style={styles.checkBoxEdit}
                                    text={"Diese Veranstaltungt"}
                                />
                                <CheckBox
                                    checked={!editRecurringEventDate}
                                    onChange={()=>setEditRecurringEventDate(prevState => !prevState)}
                                    style={styles.checkBoxEdit}
                                    text={"Alle Ereignisse"}
                                />
                            </div>
                        )}
                    </div>
                }
                onCancel={() => {
                    setEditAlertOptions(false);
                    setEditRecurringEventDate(true);
                }}
                onSuccess={async () => {

                    const editRecurringEvent = (path:string|undefined|null, objectToUpdate:{ [key: string]: any[] | string | boolean | Moment | undefined }) => {
                        if (path) {
                            api.patch(`calendars/${newEventDataObject.calendarId}/events/${path}`, objectToUpdate);
                        }
                    }

                    if (eventData?.calendarId !== newEventDataObject.calendarId) {
                        // Moving all the events to another calendar

                        await api.post(`calendars/${eventData?.calendarId}/events/${eventData?.publicId}/move`, {destination: newEventDataObject.calendarId})
                    } else if (editRecurringEventButton ) {
                        // Edit all the events

                        let rule = new RRule({
                            freq: newEventDataObject.freq,
                            interval: newEventDataObject.interval,
                        });

                        const objectToUpdateRec:{ [key: string]: any[] | string | boolean | Moment | undefined } = {
                            start:newEventDataObject.start,
                            end:newEventDataObject.end,
                            recurrence: [rule.toString()],
                        };
                        if (eventData?.allDay !== newEventDataObject.allDay) objectToUpdateRec['allDay'] = newEventDataObject.allDay;
                        await editRecurringEvent(eventData?.publicId, objectToUpdateRec)
                    } else {
                        const objectToUpdateSingle: { [key: string]: any[] | string | boolean | Moment | undefined } = {};

                        // Just updating the changed fields
                        if (eventData?.summary !== newEventDataObject.summary) objectToUpdateSingle['summary'] = newEventDataObject.summary;
                        if (eventData?.description !== newEventDataObject.description) objectToUpdateSingle['description'] = newEventDataObject.description;
                        if (!eventData?.start?.isSame(newEventDataObject.start)) objectToUpdateSingle['start'] = newEventDataObject.start;
                        if (!eventData?.end?.isSame(newEventDataObject.end)) objectToUpdateSingle['end'] = newEventDataObject.end;
                        if (eventData?.allDay !== newEventDataObject.allDay) {
                            objectToUpdateSingle['start'] = newEventDataObject.start;
                            objectToUpdateSingle['end'] = newEventDataObject.end;
                            objectToUpdateSingle['allDay'] = newEventDataObject.allDay;
                        }
                        if (eventData?.status !== newEventDataObject.status) objectToUpdateSingle['status'] = newEventDataObject.status;
                        if (!!(eventData?.imageUrl !== newEventDataObject.imageUrl || imgSrc)) objectToUpdateSingle['image'] = imgSrc;

                        if (editRecurringEventDate) {
                            await editRecurringEvent(eventData?.recurrenceObject?.id, objectToUpdateSingle);
                        } else {
                            await editRecurringEvent(eventData?.publicId, objectToUpdateSingle);
                        }
                    }
                    setEditAlertOptions(false);
                    setModal(false);
                    setMessages(defaultMessage);
                    setEditRecurringEventButton(false);
                    setEditRecurringEventDate(true);
                    setChange({});

                }}/>
            }
        </div>
    )
}

export default AddEvent
