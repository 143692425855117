import React, { useState } from 'react'
import { FiCheck } from 'react-icons/fi'
import * as api from 'lib/api'
import {
	ActivityIndicator,
	ColorButton,
	ContentContainer,
	ErrorMessageInline,
	FormItem,
	Typo
} from 'components'
import styles from './index.module.css'

import appStoreLogo from '../../assets/appstore-logo.png'
import googlePlayLogo from '../../assets/play-logo.png'

import { logo, appStoreUrl, playStoreUrl } from 'config'

const Register = () => {
	const [code, setCode] = useState('')
	const [email, setEmail] = useState('')
	const [emailVerification, setEmailVerification] = useState('')
	const [password, setPassword] = useState('')
	const [passwordCheck, setPasswordCheck] = useState('')
	const [loading, setLoading] = useState(false)
	const [success, setSuccess] = useState(false)
	const [errorMsg, setErrorMsg] = useState('')

	async function register() {
		setErrorMsg('')

		if (password.length < 6) return setErrorMsg('Passwort muss mindestens  Zeichen lang sein')
		if (password !== passwordCheck) return setErrorMsg('Die Passwörter stimmen nicht überein')
		if (email !== emailVerification) return setErrorMsg('Die E-Mail Adressen stimmen nicht überein')

		setLoading(true)

		try {
			await api.post('users/register-by-code', { code, email, password })
		} catch (err: any) {
			setLoading(false)
			setSuccess(false)
			if (err.message === 'user not found') {
				return setErrorMsg('Der angegebene Code konnte nicht gefunden werden. Bitte überprüfen Sie Ihre Eingabe.')
			}
			if (err.message === 'already registered') {
				return setErrorMsg('Mit diesem Code wurde bereits ein Account registriert.')
			}

			return setErrorMsg('Ein unbekannter Fehler ist aufgetreten.')
		}

		setLoading(false)
		setSuccess(true)
	}

	return (
		<ContentContainer style={styles.outer}>
			<div className={styles.container}>
				<img src={(logo as unknown) as string} alt='logo' className={styles.logo} />
				{loading && <ActivityIndicator />}
				{!loading && !success && (
					<React.Fragment>
						<Typo>
							Geben Sie ein Ihren Code, Ihre Email und ein Passwort ein und klicken Sie auf „Absenden“ um die Registrierung abzuschließen.
						</Typo>
						<ErrorMessageInline
								text={errorMsg}
								visible={errorMsg !== ''}
								style={styles.errorMessage}
						/>
						<FormItem name='Code' type='text' value={code} onChange={setCode} />
						<FormItem name='Email' type='text' value={email} onChange={setEmail} />
						<FormItem
								name='Email bestätigen'
								type='text'
								value={emailVerification}
								onChange={setEmailVerification}
						/>
						<FormItem name='Passwort' type='password' value={password} onChange={setPassword} />
						<FormItem
							name='Passwort bestätigen'
							type='password'
							value={passwordCheck}
							onChange={setPasswordCheck}
						/>
						<ColorButton
							onClick={register}
							text='Absenden'
							icon={FiCheck}
							style={{height: 40,marginLeft: 12}}
						/>
					</React.Fragment>
				)}
				{!loading && success && (
					<React.Fragment>
						<Typo variant='h4'>
							{`Sie erhalten eine E-Mail mit einem Bestätigungs-Link an die angegebene Adresse. Bitte öffnen Sie den Link, um die Registrierung abzuschließen. Danach können Sie sich in der App anmelden.`}
						</Typo>
					</React.Fragment>
				)}
				<Typo variant='h4' style={styles.text}>
					App noch nicht installiert?
				</Typo>
				<a href={appStoreUrl} target='_blank' rel='noopener noreferrer'>
					<img src={appStoreLogo} alt='AppStore Logo' className={styles.storeLogo} />
				</a>
				<a href={playStoreUrl} target='_blank' rel='noopener noreferrer'>
					<img src={googlePlayLogo} alt='AppStore Logo' className={styles.storeLogo} />
				</a>
			</div>
		</ContentContainer>
	)
}

export default Register
