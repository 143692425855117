import React, {lazy} from 'react';
import styles from "./Date.module.css";
import {Typo} from "components";
import moment from "moment";
import {t_props,elm} from "../type";

const EditDate=lazy(()=>import('./EditDate'));

const Date=({edit,post}:t_props)=>{
    const render=(One?:elm)=>(
        <div className={styles.container}>
            <Typo>{moment(post.date).format('DD.MM.YY')}</Typo>
            {One &&<One />}
        </div>
    )
    if(edit) return render(EditDate);
    else return render();
}
export default Date;