import React from 'react';
import styles from './DayMonthInput.module.css';


type t_val = {
    day: number,
    month: number
}

type t_props = {
    value: t_val,
    onChange: (value: t_val) => void
}

const DayMonthInput = ({value, onChange}: t_props) => {

    const changeDay = (day: number) => {
        if(day<=0||day>daysInMonth(value.month)) return;
            onChange({...value, day});
    }
    const changeMonth = (month: number) => {
        if(month<=0||month>12) return;
        const val = {...value, month};
        const max = daysInMonth(month);
        if (value.day > max)
            val.day = max;
        onChange(val);
    }

    const daysInMonth = (month: number) => Math.floor(28 + (month + (month / 8)) % 2 + 2 % month + 2 * (1 / month));

    return (
        <div style={{display: 'flex', alignItems: 'center',fontSize: '1.3em'}}>
            <div style={{marginRight: 5}}>Tag</div>
            <input name={'Day'} className={styles.numInput} type={'number'} min={1} max={daysInMonth(value.month)} value={value.day}
                   onChange={(event) => changeDay(Number(event.target.value))}/>
            <div style={{marginRight: 5}}>Monat</div>
            <input name={'Month'} className={styles.numInput} type={'number'} min={1} max={12} value={value.month}
                   onChange={(event) => changeMonth(Number(event.target.value))}/>
        </div>
    )
}

export default DayMonthInput;
