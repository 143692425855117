import React, {useEffect, useState} from 'react';
import {AlertDialog, Button, ChatButton, ColorButton, ContentContainer, FormItem, Overlay, PageTitle} from "components";
import {residentDirectory} from '@pixel-kraft/commulino-types'
import {updateBewohner, addBewohner, deleteBewohner, changeStatusBewohner} from "./action";
import AddBewohner from './AddBewohner'
import EditBewohner from "./EditBewohner";
import styles from './index.module.css';
import {FiEdit3, FiPlus, FiToggleLeft, FiToggleRight, FiTrash2, FiX} from "react-icons/fi";
import {useSelector} from "react-redux";
import {RootState} from "store/reducers";


const ResidentDirectory=()=>{
    const [bewohner,setBewohner] = useState<residentDirectory[]>([]);
    const [showAdd,setShowAdd] = useState(false);
    const [edit,setEdit] = useState<residentDirectory>();
    const [showEdit,setShowEdit] = useState(false);
    const [searchTerm,setSearchTerm] = useState("");
    const [alert, setAlert] = useState<React.ComponentProps<typeof AlertDialog> | undefined>();
    const permission= useSelector((state:RootState)=> state.auth.permissions.residentDirectory?.edit);
    const [load,setLoad]=useState(false);

    useEffect(()=>{
        updateBewohner(setBewohner,setLoad).then((sub)=>{
            return sub;
        });
    },[])

    return (
        <ContentContainer style={styles.container} loading={load}>
            <PageTitle text={"Bewohnerverzeichnis"} />
            <div style={{width: '100%',display: permission?'flex':'none',justifyContent: 'flex-end'}}>
                <ColorButton icon={FiPlus} text={"Neuer Bewohner"} onClick={()=>setShowAdd(!showAdd)} />
            </div>
            {showAdd && (
                <Overlay height={400}>
                    <div>
                        <h1 style={{marginLeft: 10}}>Hinzufügen</h1>
                        <ChatButton onClick={()=>setShowAdd(!showAdd)} style={{borderRadius: '50%',width:'max-content',position: 'absolute',top: 10,right: 10}} icon={FiX}/>
                        <AddBewohner onCancel={()=>setShowAdd(!showAdd)} onSuccess={()=>setShowAdd(!showAdd)} onAdd={addBewohner} />
                    </div>
                </Overlay>
            )

            }
            <FormItem
                type='text'
                value={searchTerm}
                placeholder='Suchen...'
                onChange={setSearchTerm}
                style={{ maxWidth: 220, borderColor: '#ccc', marginTop: 0 }}
            />
            <table className={`${styles.table}`}>
                <thead className={`${styles.thead}`}>
                    <tr className={`${styles.top}`}><td className={`${styles.td}`}>Nachname</td><td className={`${styles.td}`}>Vorname</td><td className={`${styles.td}`}>Apartment</td><td className={`${styles.td}`}>Etage</td><td className={`${styles.td}`}>Kurzzeitpflege</td><td></td></tr>
                </thead>
                <tbody>
                    {bewohner.sort((a,b)=>{
                        if(a.nachname<b.nachname)
                            return -1;
                        if(b.nachname<a.nachname)
                            return 1;
                        if(a.vorname<b.vorname)
                            return -1;
                        if(b.vorname<a.vorname)
                            return 1;
                        return 0;
                    }).filter(b=>b.nachname.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())).map((b,i)=>(
                        <tr key={b.ID} className={b.deaktiviert?`${styles.deaktiviert}`:i%2===0?`${styles.even}`:`${styles.odd}`}>
                            <td className={styles.td} >{b.nachname}</td>
                            <td className={styles.td}>{b.vorname}</td>
                            <td className={styles.td}>{b.apartment}</td>
                            <td className={styles.td}>{b.etage}</td>
                            <td className={styles.td}>{b.kurzzeitpflege?'Ja':'Nein'}</td>
                            <td className={styles.actionCell}>
                              <div className={styles.actionWrap}>
                                <Button light icon={FiEdit3} onClick={()=>{setEdit(b);setShowEdit(!showEdit)}} />
                                <Button light icon={b.deaktiviert?FiToggleLeft:FiToggleRight} onClick={()=>changeStatusBewohner(b.ID,!b.deaktiviert)} />
                                <Button light icon={FiTrash2} onClick={()=>setAlert({
                                  title:'Löschen',
                                  text:"Wollen Sie diesen Bewohner wirklich löschen?",
                                  onCancel:()=>setAlert(undefined),
                                  onSuccess:()=>{deleteBewohner(b.ID);setAlert(undefined)},
                                  item: <div>{b.nachname}, {b.vorname}</div>
                                })} />
                              </div>
                            </td>
                        </tr>
                    ))

                    }
                </tbody>
            </table>
            {showEdit&&edit&&(
                <Overlay height={400}>
                    <EditBewohner onCancel={()=>setShowEdit(!showEdit)} onSuccess={()=>setShowEdit(!showEdit)} data={{...edit}} />
                </Overlay>
            )}
            {alert &&
                <AlertDialog {...alert} />
            }
        </ContentContainer>
    )
}

export default ResidentDirectory;
