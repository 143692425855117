import React, {lazy, Suspense} from 'react';
import {useSelector} from "react-redux";
import {RootState} from "store/reducers";
import {elm} from "./type";
import {ActivityIndicator, Overlay} from "components";

const ImageGalleryOverlay=lazy(()=>import('./ImageGalleryOverlay'));
const CommentariesOverlay=lazy(()=>import('./CommentariesOverlay'));
const SurveyDetailsOverlay=lazy(()=>import('./SurveyDetailsOverlay'));

const PostOverlays=()=>{
    const showImageGallery= useSelector((state:RootState)=>state.posts.imageGalleryOverlay)
    const showCommentaries = useSelector((state:RootState)=>state.posts.commentariesOverlay)
    const showSurveyDetails = useSelector((state:RootState)=>state.posts.surveyDetails)
    const render=(One:elm,params:any)=>(
            <Suspense fallback={<Overlay><ActivityIndicator large /></Overlay>} >
                <One {...params} />
            </Suspense>
    )

    if(showImageGallery&&showImageGallery.images.length>0) return render(ImageGalleryOverlay,showImageGallery);
    else if(showCommentaries) return render(CommentariesOverlay,showCommentaries);
    else if(showSurveyDetails) return render(SurveyDetailsOverlay,showSurveyDetails);
    else return null;
}
export default PostOverlays;