import React, {lazy} from 'react';
import {Post} from "@pixel-kraft/commulino-types";

const EventShow=lazy(()=>import('./EventShow'));
const EventEdit=lazy(()=>import('./EventEdit'));

type t_props = {
    post: Post,
    edit: boolean,
}

const Event=({post,edit}:t_props)=>{
    if(edit) return <EventEdit />;
    else if(post.event) return <EventShow event={post.event} />
    else return null;
}
export default Event;
