import React, { useState, useEffect, useRef } from 'react'
import { ActivityIndicator } from '.'
import styles from './Button.module.css'

interface Props {
	onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
	text?: string
	light?: boolean
	fullWidth?: boolean
	fixedSize?: boolean,
	transparent?: boolean
	icon?: React.ComponentType<{ style: React.CSSProperties }>
	large?: boolean
	loading?: boolean
	style?: string
	cssStyle?: React.CSSProperties
}

const Button = ({
	text,
	light,
	fixedSize = true,
	fullWidth,
	transparent,
	onClick,
	icon: Icon,
	large,
	loading,
	style,
	cssStyle
}: Props) => {
	const [width, setWidth] = useState()
	const ref: any = useRef(null)
	useEffect(() => {
		setWidth(ref.current.clientWidth)
	}, [])

	return (
		<button
			className={`${styles.button} ${large && styles.large} ${light && styles.light} ${
				transparent && styles.transparent
			} ${fullWidth && styles.fullWidth} ${style}`}
			style={{ minWidth: width && fixedSize ? width : undefined,...cssStyle }}
			ref={ref}
			onClick={onClick}
		>
			{loading ? (
				<div style={{ margin: 'auto' }}>
					<ActivityIndicator light={!light} />
				</div>
			) : (
				<>
					{Icon && <Icon style={{ ...(large && { fontSize: 24 }) }} />}
					{Icon && text && <div style={{ width: 8 }} />}
					{text && text}
					{Icon && text && <div style={{ width: 4 }} />}
				</>
			)}
		</button>
	)
}

export default Button
