import React, {useRef, useState} from "react";
import {DropDownData, OutsideAlerter} from "components";
import styles from "./DropDownSelector.module.css";
import {FiChevronDown, FiChevronUp} from "react-icons/all";

type t_option = { id: any, label: string, data?: any };

type t_props = {
    value: t_option,
    options: t_option[],
    onSelect: (option: t_option) => void,
    cssStyle?: React.CSSProperties,
    className?: string

}

const DropDownSelector = ({onSelect, options, value, cssStyle, className}: t_props) => {
    const ref = useRef<HTMLDivElement>(null);
    const [isShown, setIsShown] = useState(false);

    const renderHead = (show: boolean) => (
        <div className={styles.head} style={{border: `1px solid ${show ? "rgb(0,0,0)" : "transparent"}`}}>
            <div>{value.label}</div>
            {show ? <FiChevronUp/> : <FiChevronDown/>}
        </div>);

    const renderItem = (item: t_option) => (
        <div className={styles.item} onClick={() => onSelect(item)} key={item.id}>{item.label}</div>);

    return (
        <div className={`${styles.containerWrapper} ${className}`} style={cssStyle}>
            <div className={styles.container}>
                <OutsideAlerter deactivate={!isShown} onClick={() => ref.current?.click()}>
                    <DropDownData
                        ref={ref}
                        name={renderHead}
                        items={options.filter(({id}) => id !== value.id)}
                        renderItems={renderItem}
                        closeOnClick
                        childClassName={`${styles.dropDownContent} ${isShown?styles.dropDownContentShow:styles.dropDownContentHide}`}
                        change={setIsShown}
                    />
                </OutsideAlerter>
            </div>
        </div>
    )
}
export default DropDownSelector;