import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/reducers'
import { getTemplates, startOnboarding } from 'store/onboarding/actions'
import { useHistory } from 'react-router-dom'
import { OnboardingJob, OnboardingTemplate, User } from '@pixel-kraft/commulino-types'
import {
	Button,
	ContentContainer,
	DateTimePicker,
	Dropdown,
	DynamicTable,
	Modal,
	Typo
} from 'components'
import moment from 'moment'
import { FiPlus, FiSave } from 'react-icons/fi'
import * as api from 'lib/api'
import styles from './OnboardingEdit.module.css'

interface Props {
	user?: User
}

const OnboardingEdit = ({ user }: Props) => {
	const dispatch = useDispatch()

	const ready = useSelector((state: RootState) => state.onboarding.loaded)
	const templates = useSelector((state: RootState) => state.onboarding.templates)

	const [modalVisible, setModalVisible] = useState(false)
	const [selectedTemplate, setSelectedTemplate] = useState<OnboardingTemplate>()
	const [startDate, setStartDate] = useState(Date.now())
	const [endDate, setEndDate] = useState(Date.now() + 14 * 24 * 60 * 60 * 1000)
	const [job, setJob] = useState<OnboardingJob>()

	const history = useHistory()

	useEffect(() => {
		if (!ready) {
			dispatch(getTemplates())
		}
	}, [ready, dispatch])

	useEffect(() => {
		if (user?.onboarding) {
			api.get(`onboarding/job?id=${user.onboarding.job}`).then(setJob)
		}
	}, [user?.onboarding])

	if (!user) return null

	const start = () => {
		if (!selectedTemplate) return

		if (startDate > endDate) {
			return alert('Ungültige Daten – Das Enddatum muss nach dem Startdatum liegen.')
		}

		dispatch(startOnboarding({ id: selectedTemplate.id }, startDate, endDate, user.uid))
		setModalVisible(false)
	}

	const { onboarding } = user
	const template = job?.template?.id ? templates[job.template.id] : undefined

	return (
		<div className={styles.container}>
			<Typo variant='h3'>{onboarding ? 'Aktives Onboarding' : 'Onboarding'}</Typo>
			{onboarding && onboarding.endDate > Date.now() ? (
				<div>
					<Typo strong>Template: </Typo>
					<Typo>
						{template?.title ?? 'Gelöschtes Template (laufendes Onboarding nicht betroffen)'}
					</Typo>
					<br />
					<Typo strong>Startdatum: </Typo>
					{job && <Typo>{moment(job.startDate).format('DD.MM.YY')}</Typo>}
					<br />
					<Typo strong>Enddatum: </Typo>
					{job && <Typo>{moment(job.endDate).format('DD.MM.YY')}</Typo>}
				</div>
			) : (
				<div>
					<Button
						onClick={() => setModalVisible(true)}
						text='Neues Onboarding starten'
						light
						large
					/>
				</div>
			)}
			<Modal isVisible={modalVisible} hide={() => setModalVisible(false)}>
				<ContentContainer>
					<div className={styles.row}>
						<Typo variant='h4'>Vorlage auswählen</Typo>
						<Button
							onClick={() => history.push('/onboarding')}
							text='Neue Vorlage erstellen'
							icon={FiPlus}
							light
						/>
					</div>
					<Dropdown
						name='Vorlagen'
						onChange={title =>
							setSelectedTemplate(Object.values(templates).find(t => t.title === title))
						}
						options={Object.values(templates).map(t => ({ name: t.title, id: t.id }))}
						value={selectedTemplate?.title}
						style={styles.dropdown}
					/>
					{selectedTemplate && (
						<div className={styles.template}>
							<Typo variant='h4'>{selectedTemplate.title}</Typo>
							<DynamicTable
								headers={['Reihenfolge', 'Titel']}
								rows={selectedTemplate.posts.map(post => {
									return {
										key: `${post.index}`,
										cells: [{ content: post.index + 1 }, { content: post.title }]
									}
								})}
							/>
						</div>
					)}
					<Typo>
						Die Beiträge der Onboarding-Vorlage werden über den Zeitraum zwischen Start- und
						Enddatum verteilt veröffentlicht.
					</Typo>
					<div className={styles.datePicker}>
						<div>
							<Typo variant='h5'>Startdatum</Typo>

							<DateTimePicker onChange={date => setStartDate(Number(date.valueOf()))} value={moment(startDate)} />
						</div>
						<div>
							<Typo variant='h5'>Enddatum</Typo>
							<DateTimePicker onChange={date => setEndDate(Number(date.valueOf()))} value={moment(endDate)} />
						</div>
					</div>
					<div className={styles.row}>
						<div />
						<div className={styles.row}>
							<Button onClick={() => setModalVisible(false)} text='Abbrechen' large light />
							{selectedTemplate && <Button onClick={start} text='Speichern' icon={FiSave} large />}
						</div>
					</div>
				</ContentContainer>
			</Modal>
		</div>
	)
}

export default OnboardingEdit
