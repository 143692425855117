import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/reducers'
import { Post } from '@pixel-kraft/commulino-types'
import { Card, Group, Indicator, Tag, Typo, Link } from 'components'
import styles from './PostCard.module.css'

const moment = require('moment')

interface Props {
	id?: string
	post?: Post
}

const PostCard = ({ id, post }: Props) => {
	const data =
		useSelector((state: RootState) => state.posts.posts.find(post => post.id === id)) || post

	if (!data) return null

	const {
		category,
		title,
		content,
		images,
		video,
		event,
		attachments,
		departments,
		locations,
		isPublic = false
	} = data

	return (
		<Card style={styles.container}>
			<div style={{ marginLeft: -4 }}>
				<Tag text={category?.name} />
			</div>
			<Typo variant='h4' br>
				{title}
			</Typo>
			{content && <Typo br>{content}</Typo>}
			{images && images.length > 0 && (
				<Group>
					{images.map(image => (
						<Card key={image.original}>
							<img
								src={image?.small || image.original}
								alt=''
								style={{
									margin: -8,
									marginBottom: -14,
									width: 80,
									height: 80,
									objectFit: 'cover'
								}}
							/>
						</Card>
					))}
				</Group>
			)}
			{video && (
				<Card style={styles.card}>
					<Typo variant='h5' br>
						Video
					</Typo>
					<Link href={video}>{video}</Link>
				</Card>
			)}
			{event && (
				<Card style={styles.card}>
					<Typo variant='h5' br>
						Termin
					</Typo>
					<Group margin={24}>
						<div>
							<Typo strong br>
								Beginn
							</Typo>
							<Typo br>{moment(event.startDate).format('DD.MM.YYYY HH:mm')}</Typo>
							<Typo strong br>
								Ende
							</Typo>
							<Typo br>{moment(event.endDate).format('DD.MM.YYYY HH:mm')}</Typo>
						</div>
						<div>
							<Typo strong br>
								Titel
							</Typo>
							<Typo br>{event.title}</Typo>
							<Typo strong br>
								Veranstaltungsort
							</Typo>
							<Typo br>{event.location}</Typo>
						</div>
					</Group>
				</Card>
			)}
			{attachments && attachments.length > 0 && (
				<Card style={styles.card}>
					<Typo variant='h5' br>
						Datei-Anhänge
					</Typo>
					<Group margin={0}>
						{attachments.map(attachment => (
							<Tag key={attachment.name} text={attachment.name} />
						))}
					</Group>
				</Card>
			)}
			{departments.length > 0 && (
				<div>
					<Typo variant='h5' br>
						Abteilungen
					</Typo>
					<Group margin={0}>
						{departments.map(department => (
							<Tag key={department} text={department} />
						))}
					</Group>
				</div>
			)}
			{locations.length > 0 && (
				<div>
					<Typo variant='h5' br>
						Standorte
					</Typo>
					<Group margin={0}>
						{locations.map(location => (
							<Tag key={location} text={location} />
						))}
					</Group>
				</div>
			)}
			<div style={{ display: 'flex', marginLeft: -4 }}>
				<Indicator active={isPublic} />
				{isPublic ? 'Öffentlicher Beitrag' : 'Nicht öffentlich'}
			</div>
		</Card>
	)
}

export default PostCard
