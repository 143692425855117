import {onlyMenuPlans, forMe, ipLogin, Infosystem} from 'config'

export const get_cat_ID = () => {
    const h: any = onlyMenuPlans;
    let erg: string = '';
    if (h) {
        if (h.id)
            erg = h.id;
    } else {
        const t: any = forMe;
        if (t && 'menuPlan' in t)
            erg = t.menuPlan;
    }
    return erg;
};

export const is_onlyMenuPlan = () => {
    return onlyMenuPlans !== undefined;
}
export const is_ForMe = () => {
    return forMe !== undefined;
}

export const isInfosystemActive = () => {
    return Infosystem;
}

/**
 * Gibt den Wert des members vom object züruck falls er existiert, wenn nicht @return ''
 * @param object
 * @param member
 */
const get_string_member_of = (object: any, member: string) => {
    if (object) {
        if (member in object)
            return object[member] as string;
    }
    return '';
}

export const get_PerMsg_ID = () => get_string_member_of(forMe, 'perMsg');
export const get_loginIP_URL = () => get_string_member_of(ipLogin, 'url');