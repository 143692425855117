import {
	Filter,
	LOAD_FINISH_MANUEL,
	POST_UPDATE_UNREAD,
	POSTS_ADD_COUNT_ALL,
	POSTS_ADD_COUNT_GROUP,
	POSTS_ADD_LIKE,
	POSTS_BY_GROUP_MERGE,
	POSTS_BY_GROUP_RECEIVE,
	POSTS_BY_GROUP_REQUEST,
	POSTS_BY_PERSONAL_MSG,
	POSTS_CATEGORY_COMPlETE,
	POSTS_COMPlETE,
	POSTS_DELETE,
	POSTS_EDIT,
	POSTS_EDIT_ONBOARDING_POST,
	POSTS_EDIT_REQUEST,
	POSTS_END_EDIT_MODE,
	POSTS_HIDE_VISIBLE,
	POSTS_INIT_NEW_POST,
	POSTS_INIT_NEW_POST_ONBOARDING,
	POSTS_MERGE,
	POSTS_ON_EDIT_CHANGE,
	POSTS_PUBLISH,
	POSTS_PUBLISH_REQUEST,
	POSTS_RECEIVE,
	POSTS_REMOVE_LIKE,
	POSTS_REQUEST,
	POSTS_RESET,
	POSTS_SET_FILTER,
	POSTS_SET_UNREAD_LISTENER,
	POSTS_SET_VISIBLE,
	POSTS_START,
	POSTS_START_EDIT_MODE,
	POSTS_UPDATE_SURVEY,
	PostsActionTypes,
	PostsState, RELOADPOST,
	SET_COMMENTARIESOVERLAY,
	SET_IMAGEGALLRYOVERLAY,
	SET_LAST_POST_OF,
	SET_READCONFERMATION,
	SET_SURVEYDETAILS,
	t_postBy,
	UPDATE_POST_HASCOMMENTS
} from './types'
import {Post, Survey} from '@pixel-kraft/commulino-types'
import {standardPostNum_All} from "store/posts/actions";

const initialState: PostsState = {
	posts: [],
	postComplete: false,
	postsByGroup: [],
	postByGroupComplete: false,
	byId: {},
	filter: {
		category: null,
		department: null,
		location: null
	},
	filtered: [],
	loading: false,
	visible: null,
	editing: {
		state: false,
		loading: false,
		id: null,
		data: undefined,
		mode: 'editing'
	},
	unread: 0,
	unread_listener: ()=>{},
	postsByCategory: {},
	postsByDepartment: {},
	postsByLocation: {},
	postsByPersonalMsg: {},
	postLoadCountAll: 1,
	postLoadCountGroup: 1,
	lastPostID: {
		all: '',
		group: ''
	},
	imageGalleryOverlay: undefined,
	commentariesOverlay: undefined,
	surveyDetails: undefined,
	readConfirmations: {},
	surveyMap: new Map<string, Survey>()
}

function filter(posts: Post[], filter: Filter) {
	return posts.filter(({ category, departments, locations }) => {
		if (filter.category && category?.id !== filter.category.id) {
			return false
		}
		if (filter.department && !departments.includes(filter.department)) {
			return false
		}
		return !(filter.location && !locations.includes(filter.location));

	})
}

export default function posts(state = initialState, action: PostsActionTypes): PostsState {
	const editPostsBy=(prefix:string,posts: Post[],pBC:t_postBy,get:(p:Post)=>string[])=>{
		for (let post of posts) {
			const ids=get(post);
			ids?.forEach(i=> {
				const id=prefix+i;
				if (id in pBC) {
					if (pBC[id].posts.filter(p => p.id === post.id).length === 0)
						pBC[id].posts.push({id: post.id, date: post.date});
				} else
					pBC[id] = {posts: [{id: post.id, date: post.date}], complete: false};
			}
			)
		}
		Object.keys(pBC).forEach(cat=>{
			pBC[cat].posts.sort((a,b)=>{
				if(a.date<b.date)
					return 1;
				if(b.date<a.date)
					return -1;
				return 0;
			})
		})
		return pBC;
	}
	const addNewPosts=(byId:{[id:string]:Post},tmp:Post[],posts:Post[])=>{
		for (let post of posts) {
			if(!(post.id in byId))
				tmp.push(post);
			byId[post.id] = post;

		}

		return tmp.sort((a, b) => {
			if (a.date < b.date)
				return 1;
			if (b.date < a.date)
				return -1;
			return 0;
		});
	}
	switch (action.type) {
		case POSTS_START: {
			let postsByCategory={...state.postsByCategory};
			let postsByDepartment={...state.postsByDepartment};
			let postsByLocation={...state.postsByLocation};
			let postsByPersonalMsg = {...state.postsByPersonalMsg};
			action.cat.forEach(c=>{
				postsByCategory['A_'+c]={posts:[],complete:false};
				postsByCategory['G_'+c]={posts:[],complete:false}}
			);
			action.dep.forEach(d=>{
				postsByDepartment['A_'+d]={posts:[],complete:false};
				postsByDepartment['G_'+d]={posts:[],complete:false};
			});
			action.loc.forEach(l=>{
				postsByLocation['A_'+l]={posts:[],complete:false};
				postsByLocation['G_'+l]={posts:[],complete:false};
			});
			if(action.uid===null) throw Error("User has no uid!");
			postsByPersonalMsg[action.uid]={posts:[],complete:false};
			return  {
				...state,
				postsByLocation,
				postsByDepartment,
				postsByCategory,
				postsByPersonalMsg,
			}
		}
		case POSTS_REQUEST:
			return {
				...state,
				loading: true
			}
		case POSTS_RECEIVE: {
			let byId: { [id: string]: Post } = {...state.byId};

			for (let post of action.posts) {
				byId[post.id] = post
			}
			let postsByCategory={...editPostsBy('A_',action.posts,state.postsByCategory,(p)=>[p.category.id])};
			let postsByDepartment={...editPostsBy('A_',action.posts,state.postsByDepartment,(p)=>p.departments)};
			let postsByLocation={...editPostsBy('A_',action.posts,state.postsByLocation,(p)=>p.locations)};
			return {
				...state,
				posts: action.posts,
				byId,
				postsByCategory,
				postsByDepartment,
				postsByLocation,
				filtered: filter(action.posts, state.filter),
				loading: false,
				postComplete: action.posts.length<standardPostNum_All
			}
		}
		case POSTS_MERGE: {
			const tmp = Object.keys(state.byId).map(key=>state.byId[key]);
			const byId = {...state.byId};
			const postsBy=addNewPosts(byId,tmp,action.posts);
			let postsByCategory={...editPostsBy('A_',action.posts,state.postsByCategory,(p)=>[p.category.id])};
			let postsByDepartment={...editPostsBy('A_',action.posts,state.postsByDepartment,(p)=>p.departments)};
			let postsByLocation={...editPostsBy('A_',action.posts,state.postsByLocation,(p)=>p.locations)};
			if(action.context&&action.category&&action.category!=='A_'&&action.complete_cat){
				const searchIn=action.context==='category'?postsByCategory:action.context==='departments'?postsByDepartment:postsByLocation;
				if(action.category in searchIn)
					searchIn[action.category].complete=action.complete_cat;
			}
			const setCompleteAll=(prefix:string,all:t_postBy[])=>{
				const complete=(postsBy:t_postBy)=>{
					Object.keys(postsBy).filter(k=>k.substr(0,2)===prefix).forEach(p=>postsBy[p].complete=true);
				}
				all.forEach(data=>complete(data));
			}
			const postComplete = action.complete_All === true||(action.category==='A_'&&action.posts.length>0&&action.posts[action.posts.length-1].id===state.lastPostID.all);
			if (postComplete)
				setCompleteAll('A_',[postsByLocation,postsByDepartment,postsByCategory]);
			return {
				...state,
				byId,
				posts:postsBy,
				postsByCategory,
				postsByDepartment,
				postsByLocation,
				postComplete,
				loading: false
			}
		}
		case POSTS_BY_GROUP_REQUEST:
			return {
				...state,
				loading: true
			}
		case POSTS_BY_GROUP_RECEIVE: {
			let byId: { [id: string]: Post } = {}

			for (let post of action.posts) {
				byId[post.id] = post
			}
			let postsByCategory={...editPostsBy('G_',action.posts,state.postsByCategory,(p)=>[p.category.id])};
			if(action.posts.length==0)
				postsByCategory['G_'+action.cat_ID]={posts: [],complete: false};
			if(action.cat_ID&&action.complete!==undefined)
				postsByCategory['G_'+action.cat_ID].complete=action.complete;
			return {
				...state,
				byId: {
					...state.byId,
					...byId
				},
				postsByGroup: action.posts,
				postsByCategory,
				loading: false
			}
		}
		case POSTS_BY_GROUP_MERGE: {
			const tmp: Post[] = Object.keys(state.byId).map(key=>state.byId[key]);
			const byId = {...state.byId};
			const postsByGroup=addNewPosts(byId,tmp,action.posts);
			let postsByCategory={...editPostsBy('G_',action.posts,state.postsByCategory,(p)=>[p.category.id])};
			if (action.category&&action.category!=='G_' && action.complete_cat) {
				if(postsByCategory[action.category])
					postsByCategory[action.category].complete = action.complete_cat;
				else{
					postsByCategory[action.category]={
						posts:[],
						complete: action.complete_cat
					};
				}
			}
			const postByGroupComplete = action.complete_All === true||(action.category==='G_'&&action.posts.length>0&&action.posts[action.posts.length-1].id===state.lastPostID.group);
			if (postByGroupComplete)
				Object.keys(postsByCategory).filter(k=>k.substr(0,2)==='G_').forEach(p => postsByCategory[p].complete = true);
			return {
				...state,
				byId,
				postsByGroup,
				postsByCategory,
				postByGroupComplete,
				loading: false
			}
		}
		case POSTS_SET_FILTER:
			return {
				...state,
				filter: {
					...state.filter,
					...action.filter
				},
				//filtered: filter(state.posts, { ...state.filter, ...action.filter })
			}
		case POSTS_SET_VISIBLE:
			return {
				...state,
				visible: action.id
			}
		case POSTS_HIDE_VISIBLE:
			return {
				...state,
				visible: null,
				editing: initialState.editing
			}
		case POSTS_INIT_NEW_POST:
			return {
				...state,
				posts: [...state.posts, action.post],
				byId: { ...state.byId, [action.id]: action.post },
				visible: action.id,
				editing: {
					state: true,
					loading: false,
					id: action.id,
					data: action.post,
					mode: 'new'
				}
			}
		case POSTS_START_EDIT_MODE:
			return {
				...state,
				visible: action.id,
				editing: {
					state: true,
					loading: false,
					id: action.id,
					data: state.byId[action.id],
					mode: 'editing'
				}
			}
		case POSTS_END_EDIT_MODE:
			return {
				...state,
				editing: initialState.editing
			}
		case POSTS_ON_EDIT_CHANGE:
			return {
				...state,
				editing: {
					...state.editing,
					data: {
						...state.editing.data,
						...action.change
					} as Post
				}
			}
		case POSTS_PUBLISH_REQUEST:
			return {
				...state,
				editing: {
					...state.editing,
					loading: true
				}
			}
		case POSTS_PUBLISH:
			return {
				...state,
				visible: null,
				editing: initialState.editing
			}
		case POSTS_EDIT_REQUEST:
			return {
				...state,
				editing: {
					...state.editing,
					loading: true
				}
			}
		case POSTS_EDIT: {
			const postsByCategory = {...state.postsByCategory};
			const postsByLocation = {...state.postsByLocation};
			const postsByDepartment = {...state.postsByDepartment};
			const h=Object.keys(postsByCategory);
			const manuel = (z:{id: string, date: number}[]) => {
				for (let u = 0; u < z.length; ++u) {
					if (z[u].id === state.editing.id)
						return u;
				}
				return -1;
			}
			const cat_help=(keys:string[])=>{
				for(let i=0;i<keys.length;++i){
					if (state.editing.id!==null&&keys[i].includes(state.byId[state.editing.id].category.id)) {
						postsByCategory[keys[i]].posts.splice(manuel(postsByCategory[keys[i]].posts), 1);
						if(state.editing.data){
							if(('A_'+state.editing.data.category.id) in postsByCategory)
								postsByCategory['A_'+state.editing.data.category.id].posts.push({id:state.editing.id,date:state.editing.data.date});
							else
								postsByCategory['A_'+state.editing.data.category.id]={posts:[{id:state.editing.id,date:state.editing.data.date}],complete:false};
							postsByCategory['A_'+state.editing.data.category.id].posts.sort((a,b)=>{
								if(a.date<b.date)
									return 1;
								if(b.date<a.date)
									return -1;
								return 0;
							})
						}
					}

				}
			}
			const loc_help=(postsBy:t_postBy,from:'locations'|'departments')=>{
				const keys=Object.keys(postsBy);
				for(let i=0;i<keys.length;++i){
					if(state.editing.id&&state.editing.data) {
						const tmp = state.byId[state.editing.id][from];
						for (let loc = 0; loc < tmp.length; ++loc) {
							const by=state.editing.data[from];
							if (!by.includes(tmp[loc]) && keys[i].includes(tmp[loc])) {
								postsBy[keys[i]].posts.splice(manuel(postsBy[keys[i]].posts), 1);
							}
						}

					}
				}
				if (state.editing.data) {
					state.editing.data[from].forEach(l => {
						if (state.editing.id && state.editing.data && !state.byId[state.editing.id][from].includes(l)) {
							if (('A_' + l) in postsBy) {
								postsBy['A_' + l].posts.push({
									id: state.editing.id,
									date: state.editing.data.date
								});
							} else {
								postsBy['A_' + l] = {
									posts: [{
										id: state.editing.id,
										date: state.editing.data.date
									}], complete: false
								};
							}
						}
						postsBy['A_' + l].posts.sort((a, b) => {
							if (a.date < b.date)
								return 1;
							if (b.date < a.date)
								return -1;
							return 0;
						})
					})
				}
			}
			cat_help(h);
			loc_help(postsByLocation,"locations");
			loc_help(postsByDepartment,'departments');
			return {
				...state,
				editing: initialState.editing,
				byId: {...state.byId, [state.editing.id as string]: state.editing.data as Post},
				postsByCategory,
			}
		}
		case POSTS_DELETE: {
			const posts = state.posts.filter(post => post.id !== action.id)
			const postsByGroup = state.postsByGroup.filter(post => post.id !== action.id)
			const postsByCategory = {...state.postsByCategory};
			const postsByLocation = {...state.postsByLocation};
			const postsByDepartment = {...state.postsByDepartment};
			const byId = { ...state.byId }
			const deleteBy=(postsBy:t_postBy,from:'departments'|'locations'|'category')=>{
				const manuel = (z:{id: string, date: number}[]) => {
					for (let u = 0; u < z.length; ++u) {
						if (z[u].id === action.id)
							return u;
					}
					return -1;
				}
				const tmp=from==="category"?byId[action.id][from].id:byId[action.id][from];
				Object.keys(postsBy).forEach(by=>{
					if(tmp.includes(by.substr(2))){
						postsBy[by].posts.splice(manuel(postsBy[by].posts),1);
					}
				})
			}
			deleteBy(postsByCategory,'category');
			deleteBy(postsByDepartment,'departments');
			deleteBy(postsByLocation,'locations');
			delete byId[action.id]

			return {
				...state,
				posts,
				postsByGroup,
				byId,
				postsByCategory,
				postsByLocation,
				postsByDepartment,
				filtered: filter(posts, state.filter),
				loading:false,
			}
		}
		case POSTS_ADD_LIKE:
			return {
				...state,
				byId: {
					...state.byId,
					[action.id]: {
						...state.byId[action.id],
						likes: state.byId[action.id].likes
							? [...state.byId[action.id].likes, action.uid]
							: [action.uid]
					}
				}
			}
		case POSTS_REMOVE_LIKE:
			return {
				...state,
				byId: {
					...state.byId,
					[action.id]: {
						...state.byId[action.id],
						likes: state.byId[action.id].likes.filter(id => id !== action.uid)
					}
				}
			}
		case UPDATE_POST_HASCOMMENTS: {
			return {
				...state,
				byId: {
					...state.byId,
					[action.postID]: {
						...state.byId[action.postID],
						hasComments: action.update

					}
				}
			}
		}
		case POSTS_RESET:
			state.unread_listener();
			return {
				...initialState
			}
		case POSTS_UPDATE_SURVEY: {
			state.surveyMap.set(action.survey.id,action.survey);
			return {
				...state,
				byId: {
					...state.byId,
					[action.postID]: {
						...state.byId[action.postID],
						survey: action.survey
					}
				}
			}
		}
		case POST_UPDATE_UNREAD:
			return {
				...state,
				unread: action.unread
			}
		case POSTS_INIT_NEW_POST_ONBOARDING:
			return {
				...state,
				editing: {
					id: action.id,
					loading: false,
					mode: 'onboarding',
					state: true,
					data: {
						...action.post,
						category: {
							icon: '',
							id: '',
							name: ''
						},
						createdAt: Date.now(),
						date: 0,
						departments: [],
						locations: [],
						id: action.id,
						isPersonalMessage: true,
						isPublic: false,
						likes: [],
						notificationSent: false,
						pushEnabled: true
					}
				}
			}
		case POSTS_EDIT_ONBOARDING_POST:
			return {
				...state,
				editing: {
					id: action.post.id,
					loading: false,
					mode: 'onboarding',
					state: true,
					data: action.post
				}
			}
		case LOAD_FINISH_MANUEL:
			return {
				...state,
				loading: false
			}
		case POSTS_SET_UNREAD_LISTENER:
			return {
				...state,
				unread_listener: action.listener,
			}
		case POSTS_CATEGORY_COMPlETE:
			return {
				...state,
				postsByCategory: {
					...state.postsByCategory,
					[action.category_ID]: {
						...state.postsByCategory[action.category_ID],
						complete: action.complete
					}
				}
			}
		case POSTS_COMPlETE:
			return {
				...state,
				[action.which]: action.complete
			}
		case POSTS_ADD_COUNT_GROUP: {
			return {
				...state,
				postLoadCountGroup: ++state.postLoadCountGroup
			}
		}
		case POSTS_ADD_COUNT_ALL: {
			return {
				...state,
				postLoadCountAll: ++state.postLoadCountAll
			}
		}
		case SET_LAST_POST_OF: {
			return {
				...state,
				lastPostID: {
					...state.lastPostID,
					[action.of]: action.id
				}
			}
		}
		case POSTS_BY_PERSONAL_MSG : {
			const copy = {...state.postsByPersonalMsg};
			if (!(action.uid in copy)) {
				copy[action.uid]={posts:[],complete: false};
			}
			const newByID={...state.byId};
			const newPostIDs=action.posts.map(p=>({id:p.id,post: p}));
			const newCopyPosts=copy[action.uid].posts.map(({id})=>id);
			newPostIDs.forEach((p)=>{
				newByID[p.id]=p.post;
				if(!newCopyPosts.includes(p.id)){
					copy[action.uid].posts.push({id:p.id,date: p.post.date});
				}
			});
			copy[action.uid].complete=action.complete;
			return {
				...state,
				byId: {...newByID},
				postsByPersonalMsg: {...copy}
			}
		}
		case SET_IMAGEGALLRYOVERLAY: {
			return {
				...state,
				imageGalleryOverlay: action.imageGalleryOverlay
			}
		}
		case SET_COMMENTARIESOVERLAY: {
			return {
				...state,
				commentariesOverlay: action.commentariesOverlay
			}
		}
		case SET_SURVEYDETAILS: {
			return {
				...state,
				surveyDetails: action.surveyDetails
			}
		}
		case SET_READCONFERMATION: {
			return {
				...state,
				readConfirmations: {
					...state.readConfirmations,
					[action.postID]: action.read
				}
			}
		}
		case RELOADPOST: {
			return {
				...state,
				byId: {
					...state.byId,
					[action.post.id] : action.post
				}
			}
		}
		default:
			return state
	}
}
