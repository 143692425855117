import React from 'react'
import { Route, Redirect } from 'react-router-dom'

interface Props {
	path: string
	component: React.ComponentType<any>
	auth: boolean
}

const AuthRoute = ({ path, component: Component, auth }: Props) => {
	return (
		<Route
			render={props => (auth ? <Component {...props} /> : <Redirect to='/login' />)}
			path={path}
			exact
		/>
	)
}

export default AuthRoute
