import {User} from '@pixel-kraft/commulino-types'
import {AppThunk, usersStore} from 'store//store'
import * as api from 'lib/api'
import {arrayRemove, arrayUnion, doc, getFirestore, writeBatch} from "firebase/firestore";
import {updateContactFor} from "store/chat/actions";

export const getUsers = (forceReload?: boolean): AppThunk<Promise<usersStore.t_User[]>> => {
    return async (dispatch, getState) => {
        if (!forceReload) {
            const users = usersStore.selectUsers(getState());
            if (users.length > 0) return users;
        }

        dispatch(usersStore.setLoading(true));

        const users = await api.get('users')

        dispatch(usersStore.setUsers(users))
        return users;
    }
}

const testUserPermission = (user: Omit<User, 'uid' | 'isRegistered'>) => {
    if ((user.permissions?.posts?.in_app || user.permissions?.posts?.backend) && user.permissions.posts.edit === undefined) {
        user.permissions.posts.edit = 'own';
    }
}

export const resetAlert = (): AppThunk => {
    return async dispatch => {
        dispatch(usersStore.setError(undefined));
    }
}

export const createUser = (user: Omit<User, 'uid' | 'isRegistered'>): AppThunk<Promise<void>> => {
    return async dispatch => {
        dispatch(usersStore.createUserRequest())
        testUserPermission(user);
        try {
            const data = await api.post('users', user);
            dispatch(usersStore.addUser(data));
        } catch (e) {
            dispatch(usersStore.setError({
                what: "To many users",
                where: "User Store",
                msg: "Sie haben nicht genügen Lizenzen um weitere Benutzer anzulegen"
            }))
        }


    }
}

export const editUsers = (...users: usersStore.t_User[]): AppThunk<Promise<void>> => {
    return async dispatch => {
        dispatch(usersStore.editUserRequest());
        const toEdit: (() => Promise<void>)[] = [];
        users.forEach((user) => {
            toEdit.push(() => api.put('users', user))
        });
        await Promise.all(toEdit.map((f) => f()));
        dispatch(usersStore.editUsers(users))
    }
}

const updateUserContactForFB = async (uids: string[], mode: 'add' | 'replace' | 'remove', contactFor: string[]) => {
    const batch = writeBatch(getFirestore());
    if (mode !== 'replace') {
        uids.forEach((uid) => {
            batch.update(doc(getFirestore(), 'users', uid), {
                contactFor: mode === "add" ? arrayUnion(...contactFor) : arrayRemove(...contactFor)
            })
        })
    } else {
        batch.update(doc(getFirestore(), 'users', uids[0]), {
            contactFor: arrayRemove(...contactFor)
        });
        batch.update(doc(getFirestore(), 'users', uids[1]), {
            contactFor: arrayUnion(...contactFor)
        })
    }
    await batch.commit();
}

export const updateUserContactFor = (uids: string[], mode: 'add' | 'replace' | 'remove', ...contactFor: string[]): AppThunk<Promise<void>> => {
    return async (dispatch) => {
        await updateUserContactForFB(uids, mode, contactFor);
        dispatch(updateContactFor(uids, mode, contactFor));
    }
}

export const filterUsers = usersStore.setFilter;

export type t_User = usersStore.t_User
