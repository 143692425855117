import { ColorButton, ContentContainer, PageTitle } from 'components/index';
import React from 'react'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'store/reducers';

const Datenschutz = () => {
    const datenschutz = useSelector((state: RootState) => state.config.datenschutz);
    const history = useHistory();
    const premission = useSelector((state: RootState) => state.auth.permissions.edit_settings)
    return (
        <ContentContainer>
            <PageTitle
                text="Datenschutz"
            />
            {datenschutz.length === 0 &&
                <div>
                    Sie haben kein Datenschutz angeben.
                    {premission &&
                        <ColorButton text={"Datenschutz einstellen"} onClick={() => history.push("/settings/data")} />
                    }
                </div>
            }
            {datenschutz.length > 0 &&
                <div style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: datenschutz }}>

                </div>
            }
        </ContentContainer>
    )
}
export default Datenschutz;