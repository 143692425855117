import React from 'react';
import styles from "./ChatUserSelectore.module.css";
import {CheckBox, Row} from "components";
import {ChatUser} from "pages/chat/componets";
import {t_Contact} from "@pixel-kraft/commulino-types";

type t_props = {
    checked: boolean,
    onChange: (add: boolean) => void,
    user: t_Contact,
    children?: React.ReactNode | React.ReactNode[],
    style?: React.CSSProperties
    rowStyle?: React.CSSProperties
}

/**
 * Wraps every possible ChatUser type in a selectable Row
 * @param user
 * @param checked Is selected
 * @param onChange Callback for user select
 * @param children Extra content right from the User
 * @param style Style for User Component
 * @param rowStyle Style for
 * @constructor
 */
const ChatUserItem = ({user, checked, onChange, children, style, rowStyle}: t_props) => (
    <Row flex={1} className={styles.row} style={rowStyle}>
        <CheckBox
            checked={checked}
            onChange={onChange}
        />
        <Row flex={1} onClick={(event) => {
            event.stopPropagation();
            onChange(!checked)
        }}>
            <ChatUser user={user} transparent nameCellStyle={style}/>
        </Row>
        {children&&<Row flex={1}>
            {children}
        </Row>}
    </Row>
)


export default ChatUserItem;
