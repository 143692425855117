import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { ActivityIndicator, ErrorMessageInline, Typo } from 'components'
import { appName, logo } from 'config'
import URLSearchParams from '@ungap/url-search-params'
import * as api from 'lib/api'
import styles from './index.module.css'

const EmailVerification = () => {
	const location = useLocation()
	const [done, setDone] = useState(false)
	const [error, setError] = useState(false)

	useEffect(() => {
		async function verifyEmail() {
			const params = new URLSearchParams(location.search)

			const uid = params.get('i')
			const email = params.get('e')

			if (!uid || !email) {
				setError(true)
			} else {
				try {
					await api.post('users/verify-email', { uid, email })
					setDone(true)
				} catch {
					setError(true)
				}
			}
		}

		verifyEmail()
	}, [location.search])

	return (
		<div className={styles.container}>
			<img src={(logo as unknown) as string} alt='Logo' className={styles.logo} />
			{!done && !error && <ActivityIndicator />}
			{done && (
				<>
					<Typo variant='h3'>Registrierung abgeschlossen</Typo>
					<Typo variant='h4'>
						Sie können sich jetzt über Ihre E-Mail-Adresse in der {appName}-App anmelden.
					</Typo>
				</>
			)}
			<ErrorMessageInline
				text='Fehlerhafte Anfrage. Bitte öffnen Sie den Bestätigungs-Link erneut.'
				visible={error}
			/>
		</div>
	)
}

export default EmailVerification
