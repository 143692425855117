import React, {MutableRefObject, useEffect, useRef, useState} from 'react';

export type t_MyRef = {
    open: () => void,
    close: () => void,
    status: boolean,
    height: number | undefined,
}

type t_props = {
    name: (show: boolean) => JSX.Element,
    items?: any[],
    renderItems?: (val: any, index: number) => JSX.Element,
    itemHeight?: number,
    height?: number,
    children?: React.ReactNode,
    closeOnClick?: boolean,
    className?: string,
    spaceBetween?: number,
    maxHeight?: number,
    time?: number,
    childClassName?: string,
    change?: (show: boolean) => void,
    growable?: boolean,
    noAnimation?: boolean,
    myRef?: React.ForwardedRef<t_MyRef>,
    onOpen?: () => void,
    onClose?: () => void,
    minHeight?: number,
    RenderOnEmpty?: () => JSX.Element,
    removeOnClose?: boolean,
    disable?: boolean,
}


/**
 * Dies ist kein DropDownMenu, diese Komponente soll hauptsächlich dafür dienen daten per Click unten anzufügen.
 * @param items Eine liste der zu darzustellen Items
 * @param renderItems Eine Methode zur darstellung der Items
 * @param name Die Componte auf die gecklickt werden kann um die Items anzuzeigen. Bekommt ein boolean wert der Angibt ob die Items angezeigt werden oder nicht
 * @param itemHeight Falls die Items einen größen render aufwand haben kann dies zu besseren Performance führen.
 */
const DropDownData = React.forwardRef<HTMLDivElement, t_props>(({
                                                                    items,
                                                                    renderItems,
                                                                    name,
                                                                    itemHeight,
                                                                    height,
                                                                    children,
                                                                    closeOnClick,
                                                                    className,
                                                                    spaceBetween,
                                                                    maxHeight,
                                                                    time,
                                                                    childClassName,
                                                                    change,
                                                                    growable,
                                                                    noAnimation,
                                                                    myRef,
                                                                    onOpen,
                                                                    onClose,
                                                                    minHeight,
                                                                    RenderOnEmpty,
                                                                    removeOnClose,
                                                                    disable
                                                                }, ref) => {
    if ((items !== undefined && renderItems !== undefined) == (children !== undefined)) throw new Error("Bad props!") //!(item!==undefiniert&&renderItems!==undefiniert xor children!==undefiniert)

    const [showItems, setShowItems] = useState(false);
    const itemsRef = useRef<HTMLDivElement>(null);
    const [_height, setHeight] = useState<number | undefined>();
    const [toRender, setToRender] = useState(!removeOnClose);

    useEffect(() => {
        if (myRef) {
            (myRef as MutableRefObject<t_MyRef | null>).current = {
                open: () => show(true),
                close: () => show(false),
                status: showItems,
                height: _height
            }
        }
    }, [myRef, showItems, _height])


    /**
     * Es kann dazu kommen das wenn man z.B. eine Liste von Bilder rendert will diese noch nicht fertig geladen sind
     * wenn der User auf die Name Compente clickt, dadurch könnte es passieren das die Liste nachträglich immer
     * größer wird um dies zu verhindert sollte man die höhe der Bilder festlegen und in ItemsHeight übergeben.
     */

    const show = (s: boolean) => {
        if (s == showItems) return;
        setShowItems(s);
        if (s && onOpen) onOpen();
        if (!s && onClose) onClose();
        if (change) change(s);
    }

    useEffect(() => {
        if (growable) {
            const observer = new ResizeObserver(entries => {
                entries.forEach((entry) => {
                    if (entry.contentBoxSize) {
                        const contentBoxSize = Array.isArray(entry.contentBoxSize) ? entry.contentBoxSize[0] : entry.contentBoxSize;
                        setHeight(contentBoxSize.blockSize);
                    }
                })
            })
            if (itemsRef.current) {
                observer.observe(itemsRef.current);
                return () => observer.disconnect();
            }
        }
    }, [growable])

    useEffect(() => {
        let val = items && itemHeight ? itemHeight * items.length : height ?? itemsRef.current?.getBoundingClientRect().height;
        if (val && minHeight && val < minHeight) val = minHeight;
        setHeight(val);
    }, [showItems, items, itemHeight, height, minHeight])

    useEffect(() => {
        if (removeOnClose) {
            if (!showItems) {
                const t = setTimeout(() => setToRender(false), (time ?? 0.3) * 1000);
                return () => {
                    clearTimeout(t);
                }
            } else setToRender(true);
        }
    }, [showItems, time, removeOnClose])

    return (
        <div className={className}>
            <div ref={ref} onClick={() => show(!showItems)}>{name(showItems)}</div>
            <div style={{
                height: showItems && !disable ? _height : 0,
                overflow: "hidden",
                transition: noAnimation ? undefined : `height ${time ?? 0.3}s`
            }}>
                <div className={childClassName}
                     style={{paddingTop: spaceBetween, minHeight, maxHeight, overflowY: maxHeight?'auto':'visible'}}
                     onClick={closeOnClick ? () => show(false) : undefined}
                     ref={itemsRef}>
                    {toRender &&
                        <>
                            {renderItems && items?.map(renderItems)}
                            {children}
                            {RenderOnEmpty && !children && (!items || items.length === 0) && <RenderOnEmpty/>}
                        </>
                    }
                </div>
            </div>
        </div>
    )
})
DropDownData.displayName = "DropDown";
export default DropDownData;
