import React, {useEffect, useRef, useState} from 'react';
import ChatButton from "components/ChatButton";
import styles from "./ButtonPopUp.module.css";
import {OutsideAlerter} from "components/index";
import {Transition,TransitionStatus} from "react-transition-group";

type t_props = {
    popUp: 'top' | 'bottom' | 'left' | 'right',
    popUpPos: 'center' | 'left' | 'right',
    text?: string,
    icon?: React.ComponentType<{ style?: React.CSSProperties }>,
    PopUbContent?: JSX.Element,
    buttonStyle?: React.CSSProperties,
    popUpStyle?: React.CSSProperties,
    toClose?: React.DependencyList,
    popUpColor?: string,
    iconStyle?: React.CSSProperties,
}

type t_PopUpElementProps = {
    popUp: 'top' | 'bottom' | 'left' | 'right',
    popUpPos: 'center' | 'left' | 'right',
    PopUbContent?: JSX.Element,
    showPopUp: boolean,
    height?: number,
    width?: number,
    popUpStyle?: React.CSSProperties,
    popUpColor?: string
}

const PopUpElement = ({
                          popUp,
                          height,
                          width,
                          showPopUp,
                          PopUbContent,
                          popUpPos,
                          popUpStyle,
                          popUpColor
                      }: t_PopUpElementProps) => {
    const [style, setStyle] = useState<React.CSSProperties>({});
    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const getStyle = (smaller?: boolean): React.CSSProperties => {
            const getPos = (): React.CSSProperties => {
                switch (popUpPos) {
                    case "center": {
                        if (smaller) {
                            return {left: width && ref.current?.offsetWidth ? -((ref.current?.offsetWidth - (width)) / 2) : 'unset'};
                        }
                        return {margin: 'auto', left: 0, right: 0}
                    }
                    case 'left': {
                        return {right: 0}
                    }
                    case "right": {
                        return {left: 0}
                    }
                    default:
                        return {}
                }
            }
            switch (popUp) {
                case "top":
                    return {bottom: height ? height : 'unset', ...getPos()}
                case "bottom":
                    return {top: height ? height : 'unset', ...getPos()}
                case "left":
                    return {right: width, display: "flex", alignItems: "center"}
                case "right":
                    return {left: width, display: "flex", alignItems: "center"}
                default :
                    return {}
            }
        }
        if (width && ref.current?.offsetWidth && width < ref.current?.offsetWidth)
            setStyle(getStyle(true));
        else
            setStyle(getStyle())
    }, [height, width, ref.current?.offsetWidth, popUp, popUpPos])
    const WithD = ({className}: { className: string }) => {
        return (
            <div style={{
                ...(popUp === 'bottom' ? {borderBottomColor: popUpColor} : {borderTopColor: popUpColor}),
                ...{
                    margin: popUpPos === 'center' ?
                        "auto" : 'unset',
                    right: popUpPos === 'center' ?
                        'unset' : 0,
                    left: popUpPos === 'right' ?
                        width ?
                            width / 2 - 6.5 : 0
                        : popUpPos === 'left' ?
                            width ?
                                ref.current?.offsetWidth ? ref.current?.offsetWidth - width / 2 - 6.5 : 'unset' : 'unset' : 'unset'
                }
            }} className={className}/>
        )
    }

    const transitionStyles:{[key in TransitionStatus]:React.CSSProperties} = {
        entering: { opacity: 1},
        entered:  { opacity: 1 },
        exiting:  { opacity: 0 },
        exited:  { opacity: 0 },
        unmounted: {opacity: 0}
    };

    return (
        <Transition nodeRef={ref} in={showPopUp} timeout={300}>
            {state=> (
            <div ref={ref}
                 style={{
                     width: "max-content",
                     position: 'absolute',
                     ...style, ...popUpStyle,
                     opacity: 0,
                     transition: 'all 300ms ease',
                     ...transitionStyles[state]
                 }}>
                {popUp === 'bottom' &&
                    <WithD className={styles.dreieckBottom}/>
                }
                {popUp === 'right' &&
                    <div style={{borderRightColor: popUpColor}} className={styles.dreieckLeft}/>
                }
                <div style={{backgroundColor: popUpColor}} className={styles.popUpContent}>
                    {PopUbContent}
                </div>
                {popUp === 'left' &&
                    <div style={{borderLeftColor: popUpColor}} className={styles.dreieckRight}/>
                }
                {popUp === 'top' &&
                    <WithD className={styles.dreieckTop}/>
                }

            </div>)}
        </Transition>
    )
}
/**
 *
 * @param buttonStyle Der Style des Buttons
 * @param popUpPos Bestimmt wo genau von der Mitte aus das PopUp Positioniert werden soll.
 * @param popUp bestimmt wo das PopUp gerendert wird
 * @param PopUbContent Der Inhalt des PopUps
 * @param popUpStyle Style des Divs für PopUp
 * @param icon Icon des Buttons
 * @param text Text des Buttons
 * @param toClose Wenn sich der Wert ändert wird das PopUp Fenstern geschlossen.
 */
const ButtonPopUp = (
    {
        buttonStyle, popUpPos, popUp, PopUbContent, popUpStyle, icon, text, toClose, popUpColor, iconStyle
    }
        : t_props) => {
    const [showPopUp, setShowPopUp] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);
    useEffect(() => {
        if (ref.current) {
            setHeight(ref.current?.offsetHeight);
            setWidth(ref.current?.offsetWidth);
        }
    }, [ref])
    useEffect(() => {
        setShowPopUp(false);
    }, [toClose])
    return (
        <OutsideAlerter onClick={() => setShowPopUp(false)} deactivate={!showPopUp}>
            <div style={{
                position: 'relative',
                width: 'max-content',
                display: "flex",
                alignItems: popUpPos === "right" ? 'top' : popUpPos === 'left' ? 'end' : "center",
                overflow: showPopUp?'visible':'hidden'
            }}>
                {popUp !== "right" &&
                    <PopUpElement
                        popUp={popUp}
                        popUpPos={popUpPos}
                        showPopUp={showPopUp}
                        PopUbContent={PopUbContent}
                        popUpStyle={popUpStyle}
                        width={width}
                        height={height}
                        popUpColor={popUpColor}
                    />
                }
                <ChatButton ref={ref} text={text} icon={icon} iconStyle={iconStyle}
                            onClick={() => setShowPopUp(!showPopUp)}
                            style={{...{width: 'min-content', zIndex: 5}, ...buttonStyle}}/>
                {popUp === "right" &&
                    <PopUpElement
                        popUp={popUp}
                        popUpPos={popUpPos}
                        showPopUp={showPopUp}
                        PopUbContent={PopUbContent}
                        popUpStyle={popUpStyle}
                        width={width}
                        height={height}
                        popUpColor={popUpColor}
                    />
                }
            </div>
        </OutsideAlerter>
    )
}

export default ButtonPopUp;
