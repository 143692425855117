import React from 'react';
import User from "./ChatUser";
import RelUser from "./RelUser";
import ResUser from "./ResUser";
import {ResRelSDK} from "lib/sdk";
import {buttonsType} from './RenderButtons';
import {t_Contact, t_RelContact, t_ResContact} from "@pixel-kraft/commulino-types";

export type t_moreProps = {
    me?: boolean,
    transparent?: boolean,
    nameCellStyle?: React.CSSProperties,
    rowStyle?: React.CSSProperties,

}&buttonsType

type t_props = {
    user: t_Contact
}&t_moreProps

/**
 * Display every Possible Chat user type.
 * @param data
 * @constructor
 */
const ChatUser = (data: t_props)=>{
    if(data.user.uid){
        if(ResRelSDK.isRes(data.user)) return <ResUser {...data} user={data.user as t_ResContact} />
        if(ResRelSDK.isRel(data.user)) return <RelUser {...data} user={data.user as t_RelContact} />
        return <User {...data.user} me={data.me} buttons={data.buttons} transparent={data.transparent} />
    } else if(ResRelSDK.isRes(data.user)) return <ResUser {...data} user={data.user as t_ResContact} />

    return null
}

export default ChatUser;
