import { ShowPostOfCategory } from 'components/index';
import React from 'react'
import { get_cat_ID } from '../../lib/config_help'

const MenuPlans = () => {
    return (
        <ShowPostOfCategory cat_ID={get_cat_ID()} />
    )
}

export default MenuPlans;