import React, {useEffect, useRef, useState} from "react";
import {i_content, i_FolderAndFile} from "@pixel-kraft/commulino-types/src/menuPoint";
import useSingleAndDoubleClick from "lib/useSingleAndDoubleClick";
import Button from "components/Button";
import {FiArrowLeft} from "react-icons/all";
import {FiAlertCircle, FiSave, FiUpload} from "react-icons/fi";
import styles from './FolderAndFilePicker.module.css';
import {PathObject} from "store/folders/types";
import {Alert, AlertDialog, FilePicker} from "components";
import {genDeleteIdFromFolderPath, uploadFileToCloud} from "lib/media";
import {useSelector} from "react-redux";
import {RootState} from "store/reducers";
import {i_data} from "lib/Cloud/type";
import {getCloudFiles, getCloudFolderInfo, getCloudFolders, getCloudParentInfo} from "lib/Cloud/CloadApi";
import {UploadMetadata} from "firebase/storage";

const compareContent = (contentA: i_content, contentB: i_content) =>
    contentA.id === contentB.id &&
    contentB.name === contentB.name

const compareData = (dataA: i_data, dataB: i_data) =>
    dataA.rootFolder === dataB.rootFolder &&
    dataA.url === dataB.url &&
    dataA.parentId === dataB.parentId &&
    compareContent(dataA.content, dataB.content)

const areEqual = (prev: Props, next: Props) =>
    compareContent(prev.rootFolder, next.rootFolder) && (
        (prev.value !== undefined &&
            next.value !== undefined &&
            compareData(prev.value, next.value)
        ) ||
        (prev.value === undefined && next.value === undefined)
    )

interface Props {
    value?: i_data,
    only?: "files" | "folders" //setValue will only trigger if is type is same
    setValue: (value?: i_data) => void
    rootFolder: i_content,
    folderBuilder: (folder: i_data, isSelected: boolean) => JSX.Element,
    fileBuilder: (file: i_data, isSelected: boolean) => JSX.Element,
    accept?: string, //Currently, only support one mime type.
    noParent?: () => void,
    folderContainer?: string,
    fileContainer?: string,
    classNameHeadRow?: string,
    metadata?: UploadMetadata
}

// TODO: Look if their is a way to reduce firebase request. Its pretty easy to make a lot of request with the current state of the component
/**
 * WARNING!!! Use with care you can pretty easy make a lot of read request to firebase.
 * A lot of probe's trigger a firebase request and most of them are Objects so react can't compare them easily.
 * (useEffect use the reference of Objects to compare them and the reference of state Objects changes everytime you change the state!)
 */
const FolderAndFilePicker = React.memo(React.forwardRef<HTMLDivElement, Props>(({
                                                                                    value,
                                                                                    setValue,
                                                                                    only,
                                                                                    rootFolder,
                                                                                    folderBuilder,
                                                                                    fileBuilder,
                                                                                    accept,
                                                                                    noParent,
                                                                                    fileContainer,
                                                                                    folderContainer,
                                                                                    classNameHeadRow,
                                                                                    metadata
                                                                                }, ref) => {
    const [prevFolder, setPrevFolder] = useState<i_FolderAndFile[]>([]);//An array to use it as a go back.
    const [folders, setFolders] = useState<i_data[] | undefined>(undefined);
    const [files, setFiles] = useState<i_data[] | undefined>(undefined);
    const [select, setSelect] = useState<i_data | undefined>(undefined);
    const [_value, _setValue] = useState<i_data | undefined>(value);
    const [selectFile, setSelectFile] = useState<File | undefined>(undefined);
    const [loadingUpload, setLoadingUpload] = useState(false);
    const [progress, setProgress] = useState(0);
    const changeRef = useRef<HTMLDivElement>(null);
    const uid = useSelector((state: RootState) => state.auth.uid);
    const [error, setError] = useState(false);
    const cancel: { stop: boolean } = {stop: false};
    const inputRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
            if (value) { //Load parent Info folder
                getCloudParentInfo(value.content.id, value.rootFolder, value.type).then(folder => {
                    if (cancel.stop) return;
                    setSelect({
                        content: {id: folder.id, name: folder.name},
                        rootFolder: value.rootFolder,
                        type: "folders",
                        path: folder.path,
                        parentId: folder.parentId
                    })

                }).catch((e) => {
                    console.log(e);
                })
                return () => {
                    cancel.stop = true;
                }
            }
        }
        ,
        [value]
    )

    const _setSelect = (content: i_data) => {
        if (select) setPrevFolder([...prevFolder, select]);
        _setValue(content);
        setSelect(content);
    }

    useEffect(() => {
        if (rootFolder?.id) {
            const getContent = async (type: i_FolderAndFile["type"], setContent: (content: i_data[]) => void): Promise<void> => {
                const content = type === "files" ? await getCloudFiles(rootFolder, select?.content, accept) :
                    await getCloudFolders(rootFolder, select?.content);
                if (cancel.stop) return;
                setContent(content);

            }
            Promise.all([getContent("folders", setFolders), getContent("files", setFiles)]).then(() => {
                if (changeRef.current) changeRef.current.dispatchEvent(new Event('change', {bubbles: true})); //Trigger on Change
            })

        }
        return () => {
            cancel.stop = true;
        }
    }, [select, rootFolder])

    if (!uid) return null;

    const goBack = async () => {
        if (select && select.parentId) {
            const data = await getCloudFolderInfo(select.parentId, rootFolder.id);
            setSelect({
                content: {id: data.id, name: data.name},
                parentId: data.parentId,
                path: data.path,
                rootFolder: rootFolder.id,
                type: "folders"
            })
        } else {
            setSelect(undefined);
            if (noParent) {
                noParent();
            }
        }
    }

    const uploadFile = async () => {
        if (!selectFile) return;
        const to: { id: string, path: PathObject[] } = select ? {
            id: select.content.id,
            path: select.path ?? []
        } : {id: rootFolder.id, path: []}
        setLoadingUpload(true);
        uploadFileToCloud(uid, rootFolder.id, genDeleteIdFromFolderPath(to.id, to.path), to, selectFile, snapshot => {
                setProgress(Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100));
            },
            (e) => {
                setLoadingUpload(false);
                setSelectFile(undefined);
                console.error(e);
                setError(true);
            }
            ,
            (doc) => {
                setLoadingUpload(false);
                setSelectFile(undefined);
                setValue({
                    type: "files",
                    rootFolder: rootFolder.id,
                    content: {id: doc.id, name: doc.name},
                    url: doc.url
                })
            },
            metadata
        );
    }

    const FolderElement = (folder: i_data) => {
        const click = useSingleAndDoubleClick(() => _setValue(folder), () => _setSelect(folder))
        return (
            <div onClick={click}>{folderBuilder(folder, _value?.content.id === folder.content.id)}</div>
        )
    }

    const FileElement = (file: i_data) => {
        const click = useSingleAndDoubleClick(() => _setValue(file), () => updateValue(file))
        return (
            <div onClick={click}>{fileBuilder(file, _value?.content.id === file.content.id)}</div>
        )
    }

    const updateValue = (val: i_data) => {
        if (only && only !== val.type) return;
        setValue(val);
    }

    return (
        <div ref={ref} className={styles.container}>
            <div ref={changeRef}/>
            {/*To trigger change event. The events of this elements bubble up*/}
            <div className={`${styles.headRow} ${classNameHeadRow ?? ''}`}>
                <Button light icon={FiArrowLeft} onClick={goBack}/>
                <div>{_value?.content.name}</div>
                <div className={styles.row}>
                    <Button style={styles.spaceRight} light icon={FiUpload}
                            onClick={() => {
                                if (inputRef.current)
                                    inputRef.current.click();
                            }}/>
                    <Button light icon={FiSave} onClick={() => {
                        if (_value) updateValue(_value)
                    }}/>
                </div>
            </div>
            <FilePicker ref={inputRef} notOnRender accept={accept} inBackground onChange={setSelectFile}/>
            <div onClick={(e) => e.stopPropagation()}>
                <div className={folderContainer ?? ""}>{folders?.map((folder) => <FolderElement
                    key={folder.content.id} {...folder} />)}</div>
                <div className={fileContainer ?? ""}>{files?.map((file) => <FileElement
                    key={file.content.id} {...file} />)}</div>
                {(!folders || folders.length === 0) && (!files || files.length === 0) &&
                    <div>Keine Dateien oder Ordner</div>}
            </div>
            {!!selectFile &&
                <AlertDialog
                    title={"Datei Hochladen"}
                    text={`Möchten Sie Folgende Datei nach ${select?.content.name ?? rootFolder.name} hochladen?`}
                    item={<div style={{display: "flex", justifyContent: "space-between"}}>
                        <div>{selectFile.name}</div>
                        <div>{loadingUpload ? progress + "%" : ""}</div>
                    </div>}
                    onCancel={() => setSelectFile(undefined)}
                    onSuccess={() => uploadFile()}
                    loading={loadingUpload}
                />
            }
            <Alert
                title={"Hochladen gescheitert"}
                text={"Es gab ein fehler bei dem hochladen der Datei."}
                icon={FiAlertCircle}
                visible={error}
                hide={() => setError(false)}
            />
        </div>
    )
}), areEqual);
FolderAndFilePicker.displayName = "FolderAndFilePicker";
export default FolderAndFilePicker;
