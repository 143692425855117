import {Config_Posts, Permissions} from "@pixel-kraft/commulino-types";
import data from "pages/settings/data";

export function chunkArray<A>(arr: A[], size: number): A[][] {
    return Array.from({length: Math.ceil(arr.length / size)}, (_, i) =>
        arr.slice(i * size, i * size + size)
    );
}

/**
 * Normal filter map : O 2n this should be O n
 * @param arr
 * @param filter
 * @param map
 */
export const filterAndMap = <T,M>(arr: T[],filter: (f:T,index: number,arr: T[])=>boolean,map: (m: T,index: number,arr: T[])=>M):M[]=> {
    const erg:M[] = [];
    arr.forEach((elm,index,array)=>filter(elm,index,array)&&erg.push(map(elm,index,array)));
    return erg;
}

export const filterTypeChange = <T,M>(arr: T[],filter: (f:T,index: number,arr: T[])=>M|undefined):M[]=> {
    const erg:M[] = [];
    arr.forEach((elm,index,array)=>{
        const f=filter(elm,index,array)
        f&&erg.push(f)
    });
    return erg;
}

export const splitBy = <T,M>(arr: (T|M)[],split: (elm: T|M)=>boolean):[T[],M[]]=>{
    const a:T[] = [];
    const b:M[] = [];
    arr.forEach((elm)=>(split(elm)?a:b).push(elm as any));
    return [a,b];
}

export const splitByAndConvert = <A,B,C>(arr: A[],split: (elm:A)=>boolean,convert: (elm: A)=>B|C):[B[],C[]]=>{
    const a:B[]=[];
    const b:C[]=[];
    arr.forEach((elm)=>{
        if(split(elm)){
            a.push(convert(elm) as B)
        } else
            b.push(convert(elm) as C)
    })
    return [a,b];
}

/**
 * TODO: use findIndex
 * Search in array for an element where func is true
 * @param arr Array to search in
 * @param func If func return true the index of elemente is return
 * @return Returns Index of first element where func is true else return -1
 */
export const getIndexOf = <T,>(arr: T[],func: (data: T)=>boolean)=>{
    for(let i=0;i<arr.length;++i)
        if(func(arr[i])) return i;
    return -1;
}

//TODO: use find
export const getItemWidth = <T,>(arr: T[],func: (data: T)=>boolean) => arr[getIndexOf(arr,func)];
//TODO: use find
export const includesWhere=<T,>(arr: T[],func: (data: T)=>boolean)=>getIndexOf(arr,func)>-1;

//https://stackoverflow.com/questions/8572826/generic-deep-diff-between-two-objects
export const deepDiffMapper = function () {
    return {
        VALUE_CREATED: 'created',
        VALUE_UPDATED: 'updated',
        VALUE_DELETED: 'deleted',
        VALUE_UNCHANGED: 'unchanged',
        map: function (obj1: { [x: string]: any; } | undefined, obj2: { [x: string]: any; }) {
            let key;
            if (this.isFunction(obj1) || this.isFunction(obj2)) {
                throw Error('Invalid argument. Function given, object expected.');
            }
            if (this.isValue(obj1) || this.isValue(obj2)) {
                return {
                    type: this.compareValues(obj1, obj2),
                    data: obj1 === undefined ? obj2 : obj1
                };
            }

            let diff: { [key: string]: any } = {};
            for (key in obj1) {
                if (this.isFunction(!(obj1) || obj1[key])) {
                    continue;
                }

                let value2 = undefined;
                if (obj2[key] !== undefined) {
                    value2 = obj2[key];
                }

                // @ts-ignore
                diff[key] = this.map(obj1[key], value2);
            }
            for (key in obj2) {
                // @ts-ignore
                if (this.isFunction(obj2[key]) || diff[key] !== undefined) {
                    continue;
                }

                // @ts-ignore
                diff[key] = this.map(undefined, obj2[key]);
            }

            return diff;

        },
        compareValues: function (value1: any | undefined, value2: any | undefined) {
            if (value1 === value2) {
                return this.VALUE_UNCHANGED;
            }
            if (this.isDate(value1) && this.isDate(value2) && value1.getTime() === value2.getTime()) {
                return this.VALUE_UNCHANGED;
            }
            if (value1 === undefined) {
                return this.VALUE_CREATED;
            }
            if (value2 === undefined) {
                return this.VALUE_DELETED;
            }
            return this.VALUE_UPDATED;
        },
        isFunction: function (x: any) {
            return Object.prototype.toString.call(x) === '[object Function]';
        },
        isArray: function (x: any) {
            return Object.prototype.toString.call(x) === '[object Array]';
        },
        isDate: function (x: any) {
            return Object.prototype.toString.call(x) === '[object Date]';
        },
        isObject: function (x: any) {
            return Object.prototype.toString.call(x) === '[object Object]';
        },
        isValue: function (x: any) {
            return !this.isObject(x) && !this.isArray(x);
        }
    }
}();

export const getErgOfCheck = (arr: any[], erg: { [p: string]: any }): boolean => {
    if (!erg) return true;
    for (let i = 0; i < arr.length; ++i) {
        if ('type' in erg[arr[i]]) {
            if ((erg[arr[i]].type === "created") || (erg[arr[i]].type !== "unchanged" && 'data' in erg[arr[i]] && erg[arr[i]].data === false))
                return false;
        } else {
            if (!getErgOfCheck(Object.keys(erg[arr[i]]), erg[arr[i]]))
                return false;
        }
    }
    return true;
}
/**
 * Checkt ob 2 Permissions objects unterschiedlich sind. Solle auch noch gehen wenn der Permissions type geändert wird.
 * @param a
 * @param b
 */
export const permissionCheck = (a: Permissions, b: Permissions) => {
    const erg = deepDiffMapper.map(a, b);
    return getErgOfCheck(Object.keys(erg), erg);
}

export const checkPost = (posts: Config_Posts, oldPosts: Config_Posts) => posts.job &&
    ((posts.job.email && posts.job.email !== oldPosts.job.email) || (posts.job.uid && posts.job.uid !== oldPosts.job.uid))

export const resizeComponents = (width: number, height: number, maxWidth: number, maxHeight: number) => {
    if (width > height) {
        if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
        }
    } else {
        if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
        }
    }
    return {height, width};
}

const youtubeRegex = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=|shorts\/))((\w|-){11})(?:\S+)?$/;
export const isValidYouTubeLink = (link: string)=>youtubeRegex.test(link);

const mailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const isValidEmail = (email: string) => {
    return mailRegex.test(email.toLowerCase())
}

const linkRegex = /^(?:https?:\/\/)?(?=.{1,253}\.?(?:\/.{0,32768})?$)(?:[A-Za-z0-9-_]{1,63}(?:\.)){1,127}[a-z]{2,10}(\/.{0,32768})?/;
export const isLink = (link: string) => {
    return linkRegex.test(link);
}

export const makeLink = (link: string) => {
    if (link.startsWith("http://") || link.startsWith("https://")) return link;

    return `https://${link}`;
}

export const findLinks = (text: string) => {
    const data = text.split(" ");
    //console.log(data);
    const getStart = (list: string[], index: number) => {
        let x = 0;
        for (let i = 0; i < index; ++i) {
            x += list[i].length + 1;
        }
        return x;
    }
    //console.log(text.match(linkRegex))
    const result: { start: number, end: number }[] = []
    data.forEach((val, index) => {
        if (isLink(val)) {
            const start = getStart(data, index);
            result.push({start, end: start + val.length})
        }
    })
    return result;
}

export const compareObject = (a: any, b: any) => {
    if(a===undefined&&b===undefined) return true;
    if(a===undefined&&b) return false;
    if(b===undefined&&a) return false;
    const key = Object.keys(a);
    if (key.length !== Object.keys(b).length) return false;
    for (let i = 0; i < key.length; ++i)
        if (a[key[i]] !== b[key[i]])
            return false;
    return true;
}

export const compareListOfObjects = (a: any[], b: any[]) => {
    if(a.length!==b.length) return false;
    for (let i = 0; i < a.length; ++i)
        if (!compareObject(a[i], b[i]))
            return false;
    return true;
}

export const sortString = (a:string,b:string)=>{
    const c=a.toUpperCase();
    const d=b.toUpperCase();
    if(c>d) return 1;
    if(d>c) return -1;
    return 0;
}

export const sortStringArray = (a:string[],b:string[])=>{
    for (let i = 0; i < a.length; ++i) {
        if (i >= b.length) return 1;
        const s = sortString(a[i], b[i]);
        if (s !== 0) return s;
    }
    return 0;
}

export const sortSortedStringArray = (a:string[],b:string[])=>{
    if(a.length>b.length) return -1;
    if(b.length>a.length) return 1;
    return sortStringArray(a,b);
}

//https://stackoverflow.com/a/57103940/13182135
export type DistributiveOmit<T, K extends keyof any> = T extends any
    ? Omit<T, K>
    : never;

//https://stackoverflow.com/a/56253298/13182135
export function flattenObj(obj: any, parent?: any, res: any={}){
    for(let key in obj){
        let propName = parent ? parent + '.' + key : key;
        if(typeof obj[key] == 'object'){
            flattenObj(obj[key], propName, res);
        } else {
            res[propName] = obj[key];
        }
    }
    return res;
}


export const getObjectKeys =<T,>(obj: T)=>Object.keys(obj) as Array<keyof T>;

export const removeFromArray =<T,>(arr: T[],func:(item:T)=>boolean)=> {
    const index = arr.findIndex(func);
    if(index<0) return arr;
    arr.splice(index,1);
    return arr;
}

/**
 * Adds toAdd to an array. If the array is undefined the array will be [toAdd]
 * @param obj Object with the Array
 * @param property Name of the Property
 * @param toAdd Value to add
 */
export const addToArrayProperty = <T extends {[key: string]:any},V, >(obj: T,property: keyof T, toAdd: V) => {
    if(!obj[property]) {
        // @ts-ignore
        obj[property] = [toAdd]
    }else {
        (obj[property] as any).push(toAdd);
    }
}

/**
 * Search if array includes other any value from other array
 * @param arr array to search in
 * @param items Items for search for in arr
 */
export const arrayIncludesAny = <T>(arr: T[],items: T[]) => arr.reduce((prev,cur)=>prev||items.includes(cur),false as boolean)


export const isDev=process.env.NODE_ENV==="development";
