import React from 'react';
import styles from "./MessageFileElm.module.css";
import {FiDownloadCloud, FiFile} from "react-icons/all";
import {ActivityIndicator} from "components";


type t_props= {
    reply: boolean,
    onDownload?: (uri:string) => void,
    getCursor: (func:any)=> React.CSSProperties,
    uri:string,
    loading: boolean,
    text: string
}

const MessageFileElm=({reply,onDownload,getCursor,loading,uri,text}:t_props)=>{
    return (
        <div className={reply?styles.reply:styles.file} style={getCursor(onDownload)} onClick={(e)=>{
            if(onDownload&&!reply&&!loading) {
                e.stopPropagation();
                onDownload(uri);
            }
        }}>
            <FiFile className={reply?`${styles.smallIcon}`:`${styles.bigIcon}`}/>
            <div style={{marginLeft: 15,marginRight: 15}}>
                {text}
            </div>
            {!reply&&
                <div>
                    {!loading &&<FiDownloadCloud className={`${styles.bigIcon}`}/>}
                    {loading &&<ActivityIndicator />}
                </div>
            }

        </div>
    )
}

export default MessageFileElm;