import React, {CSSProperties, useState} from 'react';
import {FiUser} from "react-icons/fi";

type t_props = {
    src?:string,
    style?: CSSProperties,
    iconStyle?: CSSProperties,
    className?: string,
    loadedBefore?: boolean
}

const UserImage=(props:t_props)=>{
    const [loading,setLoading]=useState(props.loadedBefore?false:true);
    return (
        <div className={props.className} style={{position: 'relative',...props.style}}>
            <FiUser className={props.className} style={{...props.style,...props.iconStyle}}/>
            {props.src && props.src !== ""&&
                <div style={{position: 'absolute',top:0,transition: 'opacity 0.3s',opacity: loading?0:1}}>
                    <img
                        alt={""}
                        src={props.src}
                        style={{objectFit: 'cover', ...props.style}}
                        className={props.className}
                        onLoadStart={()=>setLoading(true)}
                        onLoad={()=>setLoading(false)}
                        loading={"lazy"}
                    />
                </div>
            }
        </div>
    );
}

export default UserImage;
