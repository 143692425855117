import React, {Key} from 'react';
import {Transition, TransitionGroup, TransitionStatus} from "react-transition-group";

type t_props = {
    children: React.ReactNode,
    timeout: number,
    animation: keyof typeof animations,
    in?: boolean //Must be named in to work with TransitionGroup!!!
    defaultStyle?: React.CSSProperties,
    unmountOnExit?: boolean,
    mountOnEnter?: boolean,
    transitionKey?: React.Key
}

type t_style = {
    animation: { [key in TransitionStatus]: React.CSSProperties },
    defaultStyle: React.CSSProperties
}

//Classic fade animation
const fadeStyle: t_style = {
    animation: {
        entering: {opacity: 0},
        entered: {opacity: 1},
        exiting: {opacity: 0},
        exited: {opacity: 0},
        unmounted: {opacity: 0}
    },
    defaultStyle: {transition: `opacity &timeoutms ease-in-out`}
}



/**
 * Will replace &timeout in transition style or create it
 * @param style
 * @param timeout
 */
const genTransition = (style: React.CSSProperties, timeout: number) => {
    return {
        ...style,
        transition: style.transition?.replace("&timeout", timeout.toString()) ?? `all ${timeout}ms ease-in-out`
    }
}

/**
 * Object of possible animations
 * [name of Animation]: animation props
 */
const animations = {
    fade: fadeStyle
}

/**
 * Will animate the Child element
 * @param children
 * @param timeout Time in ms the animation is played
 * @param animation Animation to Play
 * @param defaultStyle Style of component (can override the Animation)
 * @param unmountOnExit Remove elemente after show is set ti false and animation was played
 * @param mountOnEnter Only Mount elemente if show is True will only Unmount if unmountOnExit is true
 * @param show Show or hide the Elemente (will trigger animation)
 */
export const View = ({children, timeout, animation, defaultStyle, unmountOnExit, mountOnEnter, in: show,transitionKey}: t_props) => {
    const nodeRef = React.useRef(null);
    return (
        <Transition key={transitionKey} nodeRef={nodeRef} unmountOnExit={unmountOnExit} mountOnEnter={mountOnEnter} in={show}
                    timeout={timeout}>
            {(state) =>
                <div
                    ref={nodeRef}
                    style={{
                        ...genTransition(animations[animation].defaultStyle, timeout),
                        ...defaultStyle,
                        ...animations[animation].animation[state]
                    }}>
                    {children}
                </div>
            }
        </Transition>
    )
}


type t_switchProps<T> = Omit<t_props, 'children' | 'show'> & {
    children: { key: T, item: React.ReactNode }[],
    active: T,
    style?: React.CSSProperties,
}

/**
 * Will show the children with the active key. On Change will play Animation of both elements (old active key and current active key)
 * Same props as View
 * @param children An array of Elements with key
 * @param active Active key
 */
export const Switch = <T extends Key, >({
                                            children,
                                            timeout,
                                            animation,
                                            active,
                                            unmountOnExit,
                                            mountOnEnter,
                                            defaultStyle,
                                            style
                                        }: t_switchProps<T>) => {

    return (
        <div style={{position: 'relative',...style}}>
            <TransitionGroup>
                {children.filter(({key}) => key === active).map((child) => (
                    <View defaultStyle={{position: 'absolute', left: 0, right: 0, ...defaultStyle}} key={child.key}
                          timeout={timeout} animation={animation}
                          mountOnEnter={mountOnEnter}
                          unmountOnExit={unmountOnExit}>
                        {child.item}
                    </View>
                ))
                }
            </TransitionGroup>
        </div>
    )
}

export const Group = TransitionGroup;

