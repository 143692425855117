import {
    ChatData,
    Message,
    isMessage,
    User,
    isSystemMessage,
} from '@pixel-kraft/commulino-types';
import {
    getFirestore,
    collection,
    query,
    getDoc,
    getDocs,
    doc,
    addDoc,
    deleteDoc,
    updateDoc,
    arrayUnion,
    deleteField,
    onSnapshot,
    orderBy,
    limit,
    setDoc,
    startAfter,
    where
} from "firebase/firestore"
import {getStorage, ref, deleteObject} from "firebase/storage"
import {firebaseApp} from "lib/firebase"
import {Action} from "redux";
import {ThunkDispatch} from "redux-thunk";
import {AppThunk} from "store/store";
import * as api from "lib/api";
import {createNotification} from "lib/push_notification";
import {t_WebChat} from "store/chat/types";
import {t_Contact} from "@pixel-kraft/commulino-types";
import store, {RootState} from '../reducers';
import {getMessageString} from 'pages/chat/SystemMessage';
import {filterTypeChange} from "lib/helpers";
import {ChatSDK} from 'lib/sdk';

const createChat = async (me: Pick<User, "name" | "uid" | "photo">, users: Pick<User, "name" | "uid" | "photo">[], chats: [string, t_WebChat][]) => {
    const checkIFChatExists = (s: Pick<User, "name" | "uid" | "photo">[]) => {
        const chat = chats
            .filter(chat => chat[1].uids.length === 2)
            .filter(chat => chat[1].uids.includes(s[0].uid))

        if (chat.length) {
            return chat[0][0];
        }
        return null;
    }
    const exist = checkIFChatExists(users);
    if (exist !== null) return exist;
    const chat: ChatData = {
        users: users,
        uids: users.map(({uid}) => uid),
        readBy: [me.uid],
        date: Date.now(),
    }
    const {id} = await addDoc(collection(getFirestore(firebaseApp), "chats"), chat);
    return id;

}

export const createNewChat = async (msg: string, to: Pick<User, "name" | "uid" | "photo">, testPermission: boolean = true) => {
    const {uid, name, permissions} = store.getState().auth;
    if (uid === null || name === null) throw new Error("User is not login");
    // console.log("Allowd to start Chat? "+permissions?.messenger?.start_chat);
    if (testPermission && !permissions?.messenger?.start_chat) throw new Error("User hast no permission to start a chat!");
    const text = msg.trim();
    // console.log("Mgs: "+text);
    if (text.length === 0) throw new Error("Msg is empty");
    const chatID = await createChat({name, uid}, [to, {name, uid}], Object.entries(store.getState().messenger.Chats));
    const message = {
        text,
        uid,
        date: Date.now(),
        readBy: [uid]
    }

    await addDoc(collection(getFirestore(firebaseApp), "chats", chatID, "messages"), message);
    return chatID;

}

const setMessageListner = (chatID: string): AppThunk => {
    return async (dispatch, getState) => {
        const uid = getState().auth.uid;
        if (uid === null) return;
        // firestore.collection('chats').doc(chatID).collection('messages').get().then(docs => {
        // 	dispatch({ type: 'SETALLCHATMSG', chatID, allChatMsg: docs.size });
        // })
        let load = false;
        /**
         * Lädt die Nachricht mit dem neusten Datum aus dem Firbase Chat
         */
        const db = getFirestore(firebaseApp);
        const lastMSG = onSnapshot(
            query(
                collection(db, "chats", chatID, "messages"), orderBy('date', 'desc'), limit(1)
            ),
            snapshot => {
                const changes = snapshot.docChanges();
                const messages: NonNullable<t_WebChat["messages"]> = [];
                for (const change of changes) {
                    if (change.type === 'added') {
                        const tmp = change.doc.data() as (typeof messages[0]);
                        messages.push({
                            ...tmp,
                            id: change.doc.id,
                            ...(isSystemMessage(tmp) ? {
                                text: getMessageString(tmp.action, tmp.target, tmp.uid),
                            } : {}),
                        });
                    }
                }
                if (messages.length > 0) {
                    dispatch({type: 'MESSENGER_UPDATE_MESSAGE', chatId: chatID, message: messages})
                    const isMessages = filterTypeChange(messages, (msg) => {
                        if (isMessage(msg)) return msg;
                    });
                    if (uid !== null && isMessages.length > 0) {
                        const notReadied = isMessages.filter(({readBy}) => !readBy.includes(uid))
                        if (notReadied.length > 0 && getState().messenger.aktiveChat !== chatID) {
                            dispatch({type: 'ADDUNREAD', chatID, unRead: notReadied.length});
                            createNotification("Eine neue Nachricht", "Jemand hat dir im Chat eine Nachricht geschickt");
                        } else {
                            dispatch({type: 'SETUNREAD', chatID, unRead: 0});
                            updateDoc(doc(db, "chats", chatID), {readBy: arrayUnion(uid)})
                            setDoc(doc(db, "users", uid, "counters", "chat:" + chatID), {unread: 0});
                        }
                    }
                    if (!load) {
                        load = true;
                        dispatch({
                            type: 'SETLASTDATE',
                            chatID,
                            lastDate: snapshot.docs[snapshot.size - 1].data().date
                        });
                        //getMoreMSG(chatID, snapshot.docs[snapshot.size - 1].data().date, 20, dispatch);
                    }
                    dispatch({type: 'ADDALLCHATMSG', chatID})
                }
            });

        const unread = await getDoc(doc(db, "users", uid, "counters", 'chat:' + chatID));
        const d = unread.data();
        if (d && d.unread > 0) {
            dispatch({type: 'SETUNREAD', chatID, unRead: d.unread});
        }

        ChatSDK.functions.addMessageListener(chatID, lastMSG);
        // const remove = () => {
        //     lastMSG();
        //     // unread();
        // }
        // dispatch({type: 'MESSENGER_SET_MESSAGE_LISTNER', chatID, listner: remove});
    }
}
export const getMoreMSG = async (chatID: string, lastDate: number, lenght: number, dispatch: ThunkDispatch<any, unknown, Action<string>>) => {

    const msgs = await getDocs(query(
        collection(getFirestore(firebaseApp), 'chats', chatID, 'messages'),
        orderBy('date', 'desc'), startAfter(lastDate), limit(lenght)
    ));
    let help = 0;
    const message: NonNullable<t_WebChat["messages"]> = [];
    msgs.forEach(doc => {
        const tmp = doc.data() as NonNullable<t_WebChat["messages"]>[0];
        message.push({
            ...tmp,
            id: doc.id
        })
        help = tmp.date;
    })
    dispatch({type: 'MESSENGER_UPDATE_MESSAGE', chatId: chatID, message});
    // console.log(msgs.size+"\t"+lenght+"\t"+lastDate);
    dispatch({type: 'ADDALLCHATMSG', chatID, add: msgs.size})
    if (help === 0 || msgs.size < lenght) {
        dispatch({type: 'SETFINISHCHAT', chatID, finishd: true});
        return;
    }
    dispatch({type: 'SETLASTDATE', chatID, lastDate: help});
}
export const setUnread = (chatID: string, num: number, dispatch: ThunkDispatch<any, unknown, Action<string>>) => {
    dispatch({type: 'SETUNREAD', chatID, unRead: num});
}

export const setAktiveChat = (chatID: string): AppThunk => {
    return (dispatch, getState) => {
        if (chatID && chatID in getState().messenger.Chats) {
            const {unRead, messages} = getState().messenger.Chats[chatID];
            const uid = getState().auth.uid;
            if (messages && unRead && uid) {
                const msgs = messages.slice(messages.length - unRead).flatMap((msg) => {
                    if (isMessage(msg) && !(msg as Message).readBy.includes(uid)) {
                        return [{
                            ...(msg as Message),
                            readBy: [...(msg as Message).readBy, uid]
                        }]
                    }
                    return [];
                })
                if (msgs.length > 0) dispatch({type: 'MESSENGER_UPDATE_MESSAGE', chatId: chatID, message: msgs})
            }
        }
        dispatch({type: 'SETAKTIVECHAT', chatID});
    }


}
// export const setContacs = async (dispatch: ThunkDispatch<any, unknown, Action<string>>) => {
//     const erg = await api.get('messenger/contacts')
//     const contacs = erg.users as User[];
//     dispatch({type: 'SETCONTACS', contacs: contacs.sort((a, b) => a.name < b.name ? -1 : 1)});
// }
/**
 * Löscht eine Nachricht für alle entweder soft oder hard (Setzt Delete auf true oder Löscht die Nachricht aus firebase)
 * @param chatID
 * @param msgID
 */
export const deleteMsg = (chatID: string, msgID: string): AppThunk => {
    return async (dispatch: ThunkDispatch<any, unknown, Action<string>>, getState) => {
        try {
            const erg = await getDoc(doc(getFirestore(firebaseApp), 'chats', chatID, 'messages', msgID));
            const d = erg.data() as Message;
            const storage = getStorage();
            if (d.images)
                await deleteObject(ref(storage, d.images[0].storageRef));
            if (d.attachments)
                await deleteObject(ref(storage, d.attachments[0].storageRef));
            if (d.readBy.filter(u => u !== getState().auth.uid).length === 0) {
                await deleteDoc(doc(getFirestore(firebaseApp), 'chats', chatID, 'messages', msgID));
                dispatch({type: 'DELETEMSG', chatID, msgID})
            } else {
                dispatch(softDeleteMsg(chatID, msgID, d.images !== undefined, d.attachments !== undefined));
            }
        } catch (e) {
            throw Error("Konnte " + msgID + " in " + chatID + " nicht Löschen!");
        }
    }
}
const softDeleteMsg = (chatID: string, msgID: string, image: boolean, file: boolean): AppThunk => {
    return async (dispatch: ThunkDispatch<any, unknown, Action<string>>) => {
        try {
            // console.log("Soft Delete")
            await updateDoc(doc(getFirestore(firebaseApp), 'chats', chatID, 'messages', msgID), {
                'text': deleteField(),
                'attachments': deleteField(),
                'images': deleteField()
            });
            dispatch({type: 'SOFTDELETEMSG', chatID, msgID})
        } catch (e) {
            throw Error("Konnte " + msgID + " in " + chatID + " nicht soft Löschen!");
        }
    }
}

export const deleteFor = (chatID: string, msgID: string, uid: string): AppThunk => {
    return async (dispatch) => {
        try {
            await updateDoc(doc(getFirestore(firebaseApp), 'chats', chatID, 'messages', msgID), {
                "deleteFor": arrayUnion(uid)
            })
            dispatch({type: 'DELETEMSGFOR', chatID, msgID, uid});
        } catch (e) {
            console.log(e);
            throw Error("Konnte " + msgID + " in " + chatID + " für " + uid + " nicht Löschen!");
        }
    }
}


export const AddUnreadChat = (chatID: string): AppThunk => {
    return async (dispatch, getState) => {
        const uid = getState().auth.uid;
        if (uid === null)
            return;
        if (getState().messenger.Chats[chatID]?.readBy && !getState().messenger.Chats[chatID].readBy.includes(uid)) {
            dispatch({type: 'ADDREADBYCHAT', chatID, uid});
            const db = getFirestore(firebaseApp);
            updateDoc(doc(db, 'chats', chatID), {
                readBy: arrayUnion(uid)
            }).then(() =>
                setDoc(doc(db, 'users', uid, 'counters', 'chat:' + chatID), {'unread': 0})
            );
        }
    }
}
export const removeAllListner = (): AppThunk => {
    return async (dispatch, getState) => {
        ChatSDK.functions.removeChatListener();
        const allChats = getState().messenger.Chats;
        Object.keys(allChats).forEach(chatID => {
            dispatch({type: 'MESSENGER_DELETE_CHAT', chatID})
        })
    }
}
export const setChatsListner = (): AppThunk => {
    return async (dispatch, getState) => {
        const uid = getState().auth.uid;
        const listner = onSnapshot(query(collection(getFirestore(firebaseApp), 'chats'), where('uids', 'array-contains', getState().auth.uid)), snapshot => {
            const changes = snapshot.docChanges(); //Enthält alle änderung seit dem Letzten Snapshot.
            for (const change of changes) {
                const {id} = change.doc;
                console.log(id,change.type);
                const chat = {...change.doc.data() as t_WebChat, id}
                chat.name = (chat.group ? chat.group.name : chat.users?.find((user) => user.uid !== uid)?.name) ?? '';
                chat.avatar = (chat.group ? chat.group.photo : chat.users?.find((user) => user.uid !== uid)?.photo) ?? '';
                switch (change.type) {
                    case 'removed': {
                        ChatSDK.functions.removeMessageListener(id);
                        dispatch({type: 'MESSENGER_DELETE_CHAT', chatID: id});
                        break;
                    }
                    case "added": {
                        dispatch({type: 'MESSENGER_UPDATE_CHAT', chatID: id, chat})
                        dispatch(setMessageListner(id));
                        break;
                    }
                    case "modified":{
                        dispatch({type: 'MESSENGER_UPDATE_CHAT', chatID: id, chat})
                        break;
                    }
                }
            }
        })
        //dispatch({type: 'SET_CHATS_LISTNER', listner})
        ChatSDK.functions.setChatListener(listner);
    }

}


export const getContactByResId = (resId: string): AppThunk<t_Contact | undefined> => {
    return (dispatch, getState) => {
        const contacts = [...selectContacts(getState())];
        console.log(resId, contacts);
        return contacts.find((con) => con.type === "resident" && con.residentId === resId);
    }
}

export const selectContacts = (state: RootState) => state.messenger.contacts;


const setContacts = (contacts: t_Contact[]): AppThunk => {
    return (dispatch) => {
        dispatch({
            type: 'SETCONTACTS',
            contacts
        })
    }
}

/**
 * Update the ContactFor the specific areas.
 * @param uids uids that are effected. By replace [old,new]
 * @param mode Add alle users | remove all users | replace old with new
 * @param areas Areas to change. By replace only [area]
 */
export const updateContactFor = (uids: string[], mode: 'add' | 'remove' | 'replace', areas: string[]): AppThunk => {
    return (dispatch, getState) => {
        const contacts = [...selectContacts(getState())]; //Dont forget to use '...' if select is used in Functions!!!
        const toUpdate: number[] = [];
        for (let i = 0; i < contacts.length; ++i) {
            if (uids.includes(contacts[i].uid))
                toUpdate.push(i);
        }
        if (toUpdate.length !== uids.length) throw Error("Contact not exist!");

        const add = (index: number, areas: string[]) => {
            if (contacts[index].areas) contacts[index].areas?.push(...areas)
            else contacts[index].areas = areas;
        }

        const remove = (index: number, areas: string[]) => {
            areas.forEach((area) => {
                if (contacts[index].areas) {
                    const _index = contacts[index].areas?.indexOf(area);
                    if (_index !== undefined && _index > -1) contacts[index].areas?.splice(_index, 1);
                }
            })
        }

        const replace = (toReplace: number, replacement: number, areas: string[]) => {
            remove(toReplace, areas);
            add(replacement, areas);
        }

        switch (mode) {
            case "add": {
                toUpdate.forEach((index) => {
                    add(index, areas);
                })
                break;
            }
            case "remove": {
                toUpdate.forEach((index) => {
                    remove(index, areas);
                })
                break;
            }
            case "replace": {
                replace(toUpdate[0], toUpdate[1], areas);
                break;
            }
        }

        dispatch(setContacts(contacts));
    }
}

/**
 * Load Contacts from Rest Api if not previously loaded.
 * @param forceReload Load Contacts from RestApi even if Contacts was loaded before.
 */
export const getContacts = (forceReload?: boolean): AppThunk<Promise<t_Contact[]>> => {
    return async (dispatch, getState) => {
        if (!forceReload) {
            const c = [...selectContacts(getState())]
            if (c.length > 0) return c;
        }
        const erg = await api.get('messenger/contacts')
        const contacts = erg.users as t_Contact[];
        dispatch(setContacts(contacts));
        return [...contacts];
    }
}

export const toDefault = (): AppThunk => {
    return (dispatch) => {
        dispatch({type: 'TODEFAULT'});
    }
}

export const updateMessage = (chatId: string, id: string, msg: Partial<Omit<ChatSDK.types.t_Message, 'id'>>): AppThunk => {
    return (dispatch) => {
        dispatch({type: 'updateMessage', chatId, id, message: msg})
    }
}

export const selectActiveChat = (state: RootState) => state.messenger.aktiveChat;
