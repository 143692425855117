import React from 'react'
import Modal from './Modal'
import Button from './Button'
import Group from './Group'
import Typo from './Typo'
import styles from './ActionModal.module.css'

interface Props {
	visible: boolean
	hide: () => void
	title: string
	text: string
	button: React.ReactNode
}

const ActionModal = ({ visible, hide, title, text, button }: Props) => (
	<Modal isVisible={visible} hide={hide} flexWidth>
		<div className={styles.container}>
			<Typo variant='h5' br>
				{title}
			</Typo>
			<Typo>{text}</Typo>
			<Group>
				<Button text='Abbrechen' onClick={hide} light />
				{button}
			</Group>
		</div>
	</Modal>
)

export default ActionModal
