import React from 'react'
import styles from './Card.module.css'

interface Props {
	children: React.ReactNode
	style?: string,
	cssStyle?: React.CSSProperties,
}

const Card = ({ children, style,cssStyle }: Props) => (
	<div style={cssStyle} className={`${styles.card} ${style}`}>{children}</div>
)

export default Card
