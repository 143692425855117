import React, {lazy, Suspense, useEffect, useRef, useState} from 'react';
import TopElem from "../../TopElem";
import {ActivityIndicator, ButtonPopUp, ChatButton, TextBox} from "components";
import {FiImage, FiPlus, FiSend, FiSmile} from "react-icons/fi";
import {FiFile} from "react-icons/all";
import {IEmojiData} from "emoji-picker-react";
import {buildMessage, getTitele, sendToChat} from "../../functions";
import {Event, isMessage, Message, MessengerImage, User} from "@pixel-kraft/commulino-types";
import {useSelector} from "react-redux";
import {RootState} from "store/reducers";
import styles from "./MessengerTextBox.module.css"
import {t_MessageData} from "pages/chat/type";

const Picker = lazy(() => import('emoji-picker-react'));

type t_props = {
    setCurrentHeight: (currentHeight: number) => void,
    replayMessage?: Message,
    chatId: string,
    resetReplay: () => void,
    users: Pick<User, "name" | "uid" | "photo">[],
    demo?: {
        onSend: (msg: t_MessageData) => void,
        loading: boolean
    }
}


const MessengerTextBox = ({setCurrentHeight, replayMessage, chatId, resetReplay, users, demo}: t_props) => {
    const uid = useSelector((state: RootState) => state.auth.uid);
    const [img, setImg] = useState<string[] | undefined>(undefined);
    const [file, setFile] = useState<File | undefined>(undefined);
    const [insertInText, setInsertInText] = useState('');
    const [text, setText] = useState(''); //Fürs testen
    const [load, setLoad] = useState(false);
    const [showAttachmentMenu, setShowAttachmentMenu] = useState<'image' | 'file' | undefined>(undefined);
    const [event, setEvent] = useState<Event | undefined>(undefined);
    const textFieldRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (file !== undefined || img !== undefined)
            setText('');
    }, [file, img])

    useEffect(() => {
        resetAll(showAttachmentMenu);
    }, [showAttachmentMenu])

    useEffect(() => {
        if (demo?.loading !== undefined)
            setLoad(demo.loading);
    }, [demo?.loading])

    useEffect(() => {
        resetAll(replayMessage ? 'replay' : undefined);
    }, [chatId, replayMessage])

    const keyEvent = async (ev: React.KeyboardEvent<HTMLDivElement>) => {
        if (ev.key === "Enter") {
            ev.preventDefault();
            if (ev.ctrlKey || ev.shiftKey) setInsertInText('\n');
            else {
                await tryToSend();
                textFieldRef?.current?.focus();
            }
        }
    }

    if (!uid) return null;

    const renderReplay = (msg?: Message) => {
        if (!msg) return null;
        let other: JSX.Element | undefined = undefined;
        if (isMessage(msg)) {
            let text: string = '';
            let url: MessengerImage[] = [];
            if (msg.text)
                text = msg.text;
            if (msg.images)
                url = msg.images;
            else if (msg.attachments)
                text = msg.attachments[0].name;
            else if (msg.postAttachments) {
                for (const att of msg.postAttachments) {
                    switch (att.type) {
                        case 'event': {
                            if (att.data) {
                                other = (
                                    <div style={{backgroundColor: 'lightgray', padding: 5, borderRadius: 20}}>
                                        Termin
                                    </div>
                                )
                            }
                        }
                    }
                }
            }
            return (
                <div className={styles.replayContainer}>
                    <div className={styles.replayTitleText}>{getTitele(msg, uid, users)}</div>
                    {other &&
                        other
                    }
                    <div className={styles.replayText}>{text}</div>
                    <div style={{display: msg.images ? 'block' : 'none', paddingRight: 50, overflowX: "hidden"}}>
                        {msg.images?.map((img, index) => <img key={index} className={styles.replayImage} src={img.uri}
                                                              alt='404'/>)}
                    </div>
                </div>
            )
        }
        return null;
    }


    const onEmojiClick = (event: React.MouseEvent<Element, MouseEvent>, data: IEmojiData) => {
        setInsertInText(data.emoji);
    }


    const resetAll = (except?: 'file' | 'image' | 'event' | 'replay') => {
        const close = [
            () => setFile(undefined),
            () => setImg(undefined),
            () => setEvent(undefined),
            () => resetReplay(),
            () => setShowAttachmentMenu(undefined), //Muss am Ende stehen
        ];
        if (except) {
            switch (except) {
                case "file": {
                    close.splice(0, 1);
                    break;
                }
                case "image": {
                    close.splice(1, 1);
                    break;
                }
                case "event": {
                    close.splice(2, 1);
                    break;
                }
                case "replay": {
                    close.splice(3, 1);
                    break;
                }
            }
            close.splice(close.length - 1, 1);
        }
        close.forEach(f => f());
    }


    const send = async () => {
        if(demo){
            demo.onSend(buildMessage(uid, text, replayMessage?.id, img ? img : undefined, file ? [file] : undefined, event));
            return;
        }
        try {
            setLoad(true);
            await sendToChat(chatId, buildMessage(uid, text, replayMessage?.id, img ? img : undefined, file ? [file] : undefined, event));
        } finally {
            setText('');
            setLoad(false);
        }

    }

    const tryToSend = async () => {
        if (!load && (
            (text.trim().length > 0) ||
            (replayMessage !== undefined && text.trim().length > 0) ||
            (img !== undefined && img !== null && img.length > 0) ||
            file !== undefined || event !== undefined
        )) {
            await send();
            resetAll();
        }
    }

    return (
        <TextBox
            ref={textFieldRef}
            onKeyDown={keyEvent}
            disabled={load || file !== undefined}
            text={text}
            insertInTextAtCurrentPostions={insertInText}
            insertInTextAtCurrentPostionsReset={() => setInsertInText('')}
            showTop={showAttachmentMenu !== undefined || replayMessage !== undefined}
            TopElem={(replayMessage === undefined && showAttachmentMenu ? showAttachmentMenu === "image" ?
                    <TopElem type={"image"} onChange={setImg}/> : <TopElem type={"file"} onChange={setFile}/>
                : renderReplay(replayMessage))}
            closeTop={resetAll}
            updateText={setText}
            updateHeight={setCurrentHeight}
            buttons={[
                <ButtonPopUp
                    key={0}
                    popUp={"top"}
                    popUpPos={"left"}
                    icon={FiSmile}
                    PopUbContent={<Suspense fallback={<ActivityIndicator/>}><Picker
                        onEmojiClick={onEmojiClick}/></Suspense>}
                    buttonStyle={{backgroundColor: 'white', borderRadius: '50%'}}
                    toClose={[text]}
                />,
                <ButtonPopUp
                    key={1}
                    popUp={"top"}
                    popUpPos={"center"}
                    icon={FiPlus}
                    PopUbContent={
                        <div>
                            <ChatButton icon={FiFile} onClick={() => setShowAttachmentMenu('file')}
                                        style={{backgroundColor: "transparent", fontSize: '1.3em'}}/>
                            <ChatButton icon={FiImage} onClick={() => setShowAttachmentMenu('image')}
                                        style={{backgroundColor: "transparent", fontSize: '1.3em'}}/>
                        </div>
                    }
                    buttonStyle={{marginLeft: 5, backgroundColor: 'white', borderRadius: '50%'}}
                    toClose={[text, file, img]}
                />,
                load ? <div key={2} className={styles.sendLoadContainer}><ActivityIndicator/></div> : {
                    icon: FiSend,
                    onClick: tryToSend,
                    style: {marginLeft: 5, backgroundColor: 'white', borderRadius: '50%'}
                }
            ]}
        />
    )
}
export default MessengerTextBox;
