import React, {Suspense, lazy} from 'react';
import {i_props} from "./VideoTypes";

const Youtube = lazy(() => import('./YouTube/YouTubeShow'));
const Vimeo = lazy(() => import('./Vimeo/VimeoShow'));

const VideoShow = (post: i_props['post']) => {

    if (post.video)
        return <Youtube url={post.video}/>
    if (post.vimeo)
        return <Vimeo vimeo={post.vimeo}/>
    return null;
}
export default VideoShow;