import moment from 'moment'
import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from 'store/reducers'
import './ChatMessage.css'
import {deleteFor, deleteMsg} from "store/chat/actions";
import {AlertDialog, MessageBox} from 'components/index';
import {FiCornerUpLeft, FiCornerUpRight, FiInfo, FiMessageSquare, FiTrash2,HiDocumentSearch} from "react-icons/all";
import {firebaseApp} from "lib/firebase";
import {arrayUnion, doc, getFirestore, updateDoc} from "firebase/firestore";
import {ChatSDK} from 'lib/sdk';

type t_props = {
    id: string,
    msg: ChatSDK.types.t_Message,
    title: string,
    reply?: any,
    chatID: string,
    onReplay: (n: string) => void,
    onClick?: (id: string) => void,
    onInfoClick: (id: string) =>void,
    onDocClick?: (id: string) => void,
    imgOnClick?: (index: number) => void,
    onForward: (msg: ChatSDK.types.t_Message) => void,
    deleteFor?: string[],
    setAlert: (alert?: React.ComponentProps<typeof AlertDialog>) => void,
    delete: boolean,
    maxWidth?: number,
    mediCare?: boolean
}

const arrCompare = (a?: any[], b?: any[]) => {
    if (a && b) {
        for (let i = 0; i < a.length && b.length; ++i) {
            if (a[i] !== b[i])
                return false;
        }
        return true;
    }

    return !a && !b;
}

const areEqual = (prevProps: t_props, nextProps: t_props) => {
    return prevProps.id === nextProps.id &&
        prevProps.title === nextProps.title &&
        prevProps.reply === nextProps.reply &&
        arrCompare(prevProps.deleteFor, nextProps.deleteFor) &&
        prevProps.delete === nextProps.delete &&
        prevProps.msg.documentation === nextProps.msg.documentation &&
        arrCompare(prevProps.msg.readBy,nextProps.msg.readBy)
}

/**
 * Rednert die Chat Nachricht
 * @param onReplay fürt die Function mit der id aus
 * @param onClick fürt die Function mit der id aus
 */
const ChatMessage = React.memo((props: t_props) => {
    const dispatch = useDispatch();
    const uid = useSelector((state: RootState) => state.auth.uid)
    if (uid === null)
        return null;
    const deleteForMe = props.deleteFor?.includes(uid) === true;
    const deleted = deleteForMe || (props.msg.text === undefined && props.msg.images === undefined && props.msg.attachments === undefined);
    const m_position = props.msg.uid === uid ? 'right' : 'left';
    const type = deleted ? 'text' : props.msg.images !== undefined ? 'photo' : props.msg.attachments !== undefined ? 'file' : 'text';
    const text = deleted ? deleteForMe ? "Sie haben diese Nachricht gelöscht" : "Diese Nachricht wurde gelöscht" : props.msg.attachments !== undefined ? props.msg.attachments[0].name : props.msg.text;

    const extractAttachment = () => {
        if (deleted) return undefined;
        if (type === 'photo') return props.msg.images;
        if (type === 'file') return {
            uri: props.msg.attachments === undefined ? '404' : props.msg.attachments[0].source,
            status: {
                click: false,
                loading: 0,
            }
        }
        return undefined;
    }

    const data = extractAttachment();



    if (!props.msg.readBy.includes(uid)) {
        setMessageRead(props.chatID, props.id, uid)
    }
    return (
        <MessageBox
            position={m_position}
            maxWidth={props.maxWidth}
            type={type}
            text={text}
            highlight={props.msg.documentation?'var(--secondaryColor,#000)':undefined}
            id={props.id}
            key={props.id}
            chatID={props.chatID}
            dateString={moment(props.msg.date).format('HH:mm')}
            title={props.title}
            onClick={props.onClick}
            imgOnClick={props.imgOnClick}
            forwarded={props.msg.forwarded}
            data={data}
            onDownload={async (url) => {
                if (props.msg.attachments) {
                    const link = document.createElement('a');
                    link.download = text === undefined ? '404' : text;
                    link.target = '_blank';
                    link.href = url;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }}
            reply={props.reply}
            buttons={deleted ? undefined : [
                {
                    icon: FiInfo,
                    onClick: () => props.onInfoClick(props.id)
                },
                {
                    icon: FiMessageSquare,
                    onClick: () => props.onReplay(props.id)
                },
                {
                    icon: m_position === "right" ? FiCornerUpLeft : FiCornerUpRight,
                    onClick: () =>/*props.msg.images?console.warn("Image forwarding not supported"):*/props.onForward(props.msg)
                },
                props.mediCare?{
                    icon: HiDocumentSearch,
                    onClick: ()=>{
                        props.onDocClick&&props.onDocClick(props.id)
                    }
                }:[],
                {
                    icon: FiTrash2,
                    onClick: () => {
                        if (props.msg.uid === uid && Date.now() - props.msg.date < 3600000) //3600000ms=1h
                            props.setAlert({
                                title: "Löschen",
                                text: "Wollen Sie die Nachricht wirklich für alle Löschen?",
                                item: <div/>,
                                onCancel: () => props.setAlert(undefined),
                                onSuccess: () => {
                                    dispatch(deleteMsg(props.chatID, props.id));
                                    props.setAlert(undefined)
                                }
                            });
                        else
                            props.setAlert({
                                title: "Löschen",
                                text: "Wollen Sie die Nachricht wirklich nur für Sie Löschen?",
                                item: <div/>,
                                onCancel: () => props.setAlert(undefined),
                                onSuccess: () => {
                                    dispatch(deleteFor(props.chatID, props.id, uid));
                                    props.setAlert(undefined)
                                }
                            })
                    }
                }
            ].flat()}
        />
    )

}, areEqual);

const setMessageRead = (chatId: string, id: string, uid: string) => {
    updateDoc(doc(getFirestore(firebaseApp), 'chats', chatId, 'messages', id), {
        readBy: arrayUnion(uid)
    }).catch(()=>console.warn("Already updated!",{chatId,id,uid})); // TODO: Test if fix in action setAktiveChat works
    /**
     * The Error pop up if a new Message is in a not aktive chat. So the bubble count gos up. If the Chat
     * with the new Msg changed to activate the new message will be updatet so readBy includes uid. But the updatet function
     * is called more than once.
     */
}

export default ChatMessage
