import React, {lazy,Suspense} from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/reducers'
import { Permissions } from '@pixel-kraft/commulino-types'
import {ActivityIndicator, Dropdown, Typo} from 'components'
import styles from './RoleSelect.module.css'
const EditPermissions=lazy(()=>import('components/EditPermissions'));

interface Props {
	permissions?: Permissions,
	setPermissions: (permissions: Permissions) => void,
	role?: string,
	setRole: (role?: string) => void
}

const RoleSelect = ({ permissions, setPermissions, role, setRole }: Props) => {
	const roles = useSelector((state: RootState) => state.roles.roles)

	return (
		<div className={styles.container}>
			<Typo variant='h4' style={styles.title}>
				Berechtigungen
			</Typo>
			<Dropdown
				name='Benutzer-Gruppe wählen'
				options={roles}
				value={roles.find(r => r.id === role)?.name}
				onChange={value => {
					const id = roles.find(r => r.name === value)?.id

					if (value === null) {
						setRole(undefined)
					} else if (id) {
						setRole(id)
						setPermissions({})
					}
				}}
			/>
			{!role &&
			<Suspense fallback={<ActivityIndicator large />}>
				<EditPermissions {...{ permissions, setPermissions }} />
			</Suspense>}
		</div>
	)
}

export default RoleSelect
