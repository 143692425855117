import {AUTH_LOGIN, AUTH_LOGOUT, AUTH_UPDATE_IMAGE, AuthActionTypes} from './types'
import {Permissions, User} from '@pixel-kraft/commulino-types'
import {RootState} from "store/reducers";
import {AppThunk} from "store/store";

export interface LoginData {
	uid: string
	permissions: Permissions
	name: string
	groups: string[],
	email?: string,
	photo?: string,
	contactFor?: User['contactFor'],
	mediCare?: User['mediCare'],
	personnelId?: string
}

export const login = (data: LoginData): AuthActionTypes => {
	return {
		type: AUTH_LOGIN,
		...data
	}
}

export const updateLocalUserImage=(photo:string):AuthActionTypes => {
	return {
		type: AUTH_UPDATE_IMAGE,
		photo
	}
}

export const logout = (): AuthActionTypes => {
	return {
		type: AUTH_LOGOUT
	}
}

export const setLoginToken  = (token: string,timeOut: number):AuthActionTypes=> ({
	type: 'AUTH_SET_TOKEN',
	token,
	timeOut
})


export const selectLoginInProcess = (state: RootState)=>state.auth.loginInProcess;

export const setLoginProcess = (value: boolean):AppThunk => {
	return (dispatch) => {
		dispatch({
			type: 'SETLOGININPROCESS',
			value
		})
	}
}
