import React, {useState, useEffect, useLayoutEffect, useRef, lazy, Suspense} from 'react'
import {useHistory} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from 'store/reducers'
import {
    FiFileText,
    FiUser,
    FiMenu,
    FiLogOut,
    FiLock,
    FiInfo,
    FiMonitor,
} from 'react-icons/fi'
import {ActivityIndicator, Bubble, Divider, RoundButton} from 'components'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import './simpleBarStyle.css'
import styles from './Menu.module.css'

import NavItem from './NavItem'

import config from 'config'
import {getInvertColor} from 'lib/colors';
import {is_ForMe, is_onlyMenuPlan} from 'lib/config_help';
import {getPostsByGroup} from "store/posts/actions";
import {setReadAllPosts} from "lib/media";
import {createNotification} from "lib/push_notification";
import PostOverlays from "../pages/posts/componets/PostOverlays";
import useWindowDimensions from "lib/useWindowDimensions";
import {icons} from 'lib/constants';
//import {isValidEmail} from "lib/helpers";

// const PostOverlays = lazy(()=>import('../pages/posts/componets/PostOverlays'))
const Speiseplan = lazy(() => import('./MenuComponents/Speiseplan'));
// const PersonaleMsg=lazy(()=>import('./MenuComponents/PersonaleMsg'));
const Messanger = lazy(() => import('./MenuComponents/Messenger'));
const FuerDich = lazy(() => import('./MenuComponents/FuerDich'));
const Folders = lazy(() => import('./MenuComponents/Folders'));
const Kalender = lazy(() => import('./MenuComponents/Kalender'));
const PostsEdit = lazy(() => import('./MenuComponents/PostsEdit'));
const WorkerEdit = lazy(() => import('./MenuComponents/WorkerEdit'));
const Onboarding = lazy(() => import('./MenuComponents/Onboarding'));
const Bewohnerverzeichnis = lazy(() => import('./MenuComponents/Bewohnerverzeichnis'));
const Einstellungen = lazy(() => import('./MenuComponents/Einstellungen'));
const CostumeMenu = lazy(() => import("./MenuComponents/CostumeMenu"));
const InfoTouch = lazy(() => import('./MenuComponents/InfoTouch'));
const TodoList = lazy(() => import('./MenuComponents/TodoList'));


interface Props {
    logout: () => void
}

const MenuItems = () => {
    const permissions = useSelector((state: RootState) => state.auth.permissions);
    const categories = useSelector((state: RootState) => state.config.categories);
    const menuPoint = useSelector((state: RootState) => state.config.costumeMenu?.menuPoint);
    const onlyMenuPlans = is_onlyMenuPlan();
    const forMe = is_ForMe();

    return (
        <Suspense fallback={<ActivityIndicator large/>}>
            {permissions.messenger && (
                <Messanger/>
            )}
            {onlyMenuPlans && (
                <>
                    <Speiseplan categories={categories}/>
                </>
            )}
            {!onlyMenuPlans && (forMe || config.calendar || config.Infotouch) && (
                <FuerDich categories={categories}
                          showFiles={permissions.files?.user?.read || permissions.files?.user?.write || permissions.files?.group.read || permissions.files?.group.write || permissions.files?.write_special}/>
            )}
            {menuPoint && <CostumeMenu/>}
            {permissions.posts?.backend && (
                <PostsEdit/>
            )}
            {permissions.manage_users && (
                <WorkerEdit/>
            )}
            {config.onboardingEnabled && permissions.manage_users && (
                <Onboarding/>
            )}
            {config.BewohnerVerzeichnis && permissions.residentDirectory && (
                <Bewohnerverzeichnis/>
            )}
            {permissions.edit_settings && (
                <Einstellungen/>
            )}
            {config.files && permissions.files?.group.write &&
                <Folders/>
            }
            {config.Infosystem && permissions.infosystem &&
                <NavItem text='Infosystem' icon={FiMonitor} route='/infosystem/settings'/>
            }
            {config.calendar && permissions.calender &&
                <Kalender/>
            }
            {config.Infotouch && permissions.infotouch?.write &&
                <InfoTouch/>
            }
            {(config as any).todoList && permissions.todoList && <TodoList/>}
            {(config as any).residents && !!(permissions as any)?.residentList &&
                <NavItem icon={icons['mediCare'].icon} text={icons['mediCare'].label} route={'/residentAndRelatives'}/>}
        </Suspense>
    )
}

const Menu = ({logout}: Props) => {
    const history = useHistory()
    const [mobileMenuOpen, setMobileMenuOpen] = useState(true)
    const [mobileMenuHeight, setMobileMenuHeight] = useState(9999)
    const mobileMenu = useRef<HTMLDivElement>(null)
    const mainColor = useSelector((state: RootState) => state.config.mainColor)
    const unreadPost = useSelector((state: RootState) => state.posts.unread)
    const dispatch = useDispatch();
    const {height} = useWindowDimensions();
    const imgRef = useRef<HTMLDivElement>(null);
    const [menuHeight, setMenuHeight] = useState<number | string>("auto");
    useEffect(() => {
        if (unreadPost > 0)
            createNotification("Ein neuer Post", "Es gibt neue Beiträge für Sie.")
    }, [unreadPost])

    useEffect(() => {
        const remove = history.listen(() => setMobileMenuOpen(false))
        return () => remove()
    }, [history])

    useLayoutEffect(() => {
        setMobileMenuHeight(mobileMenu.current?.clientHeight || 9999)
    }, [])

    const syncMenuHeight = (height: number) => {
        if (imgRef.current?.clientHeight) setMenuHeight(height - imgRef.current.clientHeight - 40);
    }

    useEffect(() => {
        syncMenuHeight(height);
    }, [height, imgRef.current?.clientHeight])

    return (
        <>
            <div className={styles.menu}>
                <div className={styles.leftBar} style={{backgroundColor: mainColor}}/>

                <div className={`${styles.navigationContainer} ${styles.menuContainer}`}>

                    <div ref={imgRef} className={styles.iconContainer} onClick={() => history.push('/')}>
                        <img onLoad={() => syncMenuHeight(height)} src={config.logo} alt='CommuLino Icon'
                             className={styles.icon} width={200}/>
                    </div>
                    <div className={styles.simpleBarContainer}>
                        <div className={styles.simpleBarWrapper}>
                            <SimpleBar autoHide={false} style={{height: '100%', minWidth: 195, maxWidth: 231}}>
                                <div className={styles.navigationContainer}
                                     style={{minHeight: menuHeight, height: "unset"}}>
                                    <div>

                                        <div style={{position: 'relative'}} onClick={() => {
                                            if (unreadPost > 0) {
                                                dispatch(getPostsByGroup());
                                                dispatch(setReadAllPosts());
                                            }
                                        }}>
                                            <NavItem text='Beiträge' icon={FiFileText} route='/feed'>
                                                {unreadPost > 0 && (
                                                    <Bubble>
                                                        {unreadPost.toString()}
                                                    </Bubble>
                                                )}
                                            </NavItem>
                                        </div>
                                        <MenuItems/>
                                    </div>
                                    <div>
                                        <Divider color='rgba(9, 30, 66, 0.04)'/>
                                        <NavItem text={'Benutzerdaten'} icon={FiUser} route={'/usersettings'}/>
                                        <NavItem text='Impressum' icon={FiInfo} route='/impressum'/>
                                        <NavItem text='Datenschutz' icon={FiLock} route='/datenschutz'/>
                                    </div>
                                    <div>
                                        <Divider color='rgba(9, 30, 66, 0.04)'/>
                                        <NavItem text='Abmelden' icon={FiLogOut} onClick={logout}/>
                                    </div>
                                </div>
                            </SimpleBar>
                        </div>
                    </div>
                </div>

            </div>
            <div className={styles.mobileMenu}>
                <div
                    className={styles.mobileNavBar}
                    style={{backgroundColor: mainColor, color: getInvertColor(mainColor)}}
                >
                    <RoundButton icon={FiMenu} onClick={() => setMobileMenuOpen(open => !open)}/>
                    <img src={config.logoLightSvg} alt='CommuLino Icon' className={styles.iconMobile} width={100}/>
                    <div style={{flex: 1}}/>
                </div>
                <div
                    style={{
                        transition: '0.3s',
                        maxHeight: mobileMenuOpen ? mobileMenuHeight : 0,
                        overflow: 'hidden'
                    }}
                    ref={mobileMenu}
                >
                    <div className={styles.navigationContainer}>
                        <MenuItems/>
                        <Divider color='rgba(9, 30, 66, 0.04)'/>
                        <NavItem text='Abmelden' icon={FiLogOut} onClick={logout}/>
                    </div>
                </div>
            </div>

            <PostOverlays/>

        </>
    )
}

export default Menu;
