const {default: logo} = require('./logo.png');
const {default: logoSvg} = require('./logo.svg');
const {default: logoLightSvg} = require('./logo-light.svg');

const config = {
	firebaseConfig: {
		projectId: "commulino-medicare-test",
		appId: "1:698427314323:web:49f34adc8860ddc199fd66",
		storageBucket: "commulino-medicare-test.appspot.com",
		apiKey: "AIzaSyDBra5NfT1Vv5_3AeF9UvPN1YLdylPSPz8",
		authDomain: "commulino-medicare-test.firebaseapp.com",
		messagingSenderId: "698427314323"
	},
	project: 'medicare-test',
	appName: 'MediCare Test',
	appStoreUrl: '',
	playStoreUrl: 'https://play.google.com/store/apps/details?id=de.commulino.data-schafhausen',
	logo,
	logoSvg,
	logoLightSvg,
	onlyMenuPlans: undefined,
	forMe: undefined,
	onboardingEnabled: false,
	ipLogin: undefined,
	BewohnerVerzeichnis: true,
	Infosystem: true,
	calendar: true,
	Infotouch: true,
	files: true,
	todoList: true
}

module.exports = config
