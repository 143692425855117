import React, {Suspense,lazy} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'store/reducers'
import { toggleEditMode, setPostVisible } from 'store/posts/actions'
import {Modal, ContentContainer, ActivityIndicator} from 'components'
import styles from './Details.module.css'
import {POSTS_DELETE} from "store/posts/types";
const AddButton =lazy(()=>import('./AddButton'));
const Post = lazy(()=>import('./Post'))
const DetailsSidebar = lazy(()=>import('./DetailsSidebar'))

interface Props {
	postId?: string
}

const Details = ({ postId }: Props) => {
	const dispatch = useDispatch()
	const id = useSelector((state: RootState) => postId ?? state.posts.visible)
	const isEditing = useSelector((state: RootState) => state.posts.editing.state)
	const post = useSelector((state: RootState) =>
		isEditing ? state.posts.editing.data : id && state.posts.byId[id]
	)
	const editMode = useSelector((state:RootState)=> state.posts.editing.mode);
	const cancel = () => {
		dispatch(toggleEditMode(false))
		dispatch(setPostVisible(false))
		if(editMode === 'new'){
			dispatch({type: POSTS_DELETE, id})
		}
	}

	return !post ? null : (
		<Suspense fallback={<ActivityIndicator />}>
		<Modal isVisible={!!id || isEditing} hide={()=>null} fullscreen>
			<div className={styles.container}>
				<ContentContainer style={styles.details} contentStyle={styles.content} hideSpacer>
					<Post post={post} />
					{isEditing &&
					<Suspense fallback={<ActivityIndicator large />} >
						<AddButton />
					</Suspense>
					}
				</ContentContainer>
				<DetailsSidebar post={post} hide={cancel} />
			</div>
		</Modal>
		</Suspense>
	)
}

export default Details
