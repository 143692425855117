import React from "react";
import {Modal} from "components";
import styles from "./CloudPopUpPicker.module.css";
import FolderAndFilePicker from "components/Cloud/CloadPicker/FolderAndFilePicker";
import {File, Folder} from "components/Cloud/Content";
import {i_data} from "lib/Cloud/type";
import {i_content} from "@pixel-kraft/commulino-types/dist/menuPoint";
import {UploadMetadata} from "firebase/storage";

type t_props = {
    value?: i_data,
    onChange: (value?: i_data) => void,
    rootFolder: i_content,
    show: boolean,
    hide: () => void,
    accept: string,
    metadata?: UploadMetadata
}

const CloudPopUpPicker = ({value, onChange, show, rootFolder, hide, accept, metadata}: t_props) => {
    return (
        <Modal contentStyle={styles.modalContent} modalStyle={styles.modal} isVisible={show} hide={hide}>
            {show && <FolderAndFilePicker
                classNameHeadRow={styles.pickerHead}
                metadata={metadata}
                value={value}
                only={"files"}
                setValue={onChange}
                folderContainer={styles.row}
                fileContainer={styles.row}
                rootFolder={rootFolder}
                accept={accept}
                folderBuilder={(folder, isSelected) => <Folder isSelected={isSelected}
                                                               mode={"raster"} {...folder} />}
                fileBuilder={(file, isSelected) => <File
                    overlay={<img className={styles.pickerImage} src={file.url}/>}
                    isSelected={isSelected} mode={"raster"} {...file} />}
            />
            }
        </Modal>
    )
}
export default CloudPopUpPicker;
