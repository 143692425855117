import React, {useEffect, useRef, useState} from 'react';
import './ScrollView.css'

type t_props = {
    children: JSX.Element | null
    style?: React.CSSProperties,
    name?: string,
    onTop?: () => void,
    isLoading: boolean,
    className?: string
}
/**
 * Rendert ein Scrollable div
 * @param children
 * @param isLoading wenn sich der Wert ändert wird zu der Letzten postion gescrollt falst children größer wird
 */
const ScrollView = (props: t_props) => {
    const ScrollRef = useRef<HTMLDivElement>(null);
    const [scroll, setScroll] = useState(true);
    const topRef = useRef<HTMLDivElement>(null);
    const bottomRef = useRef<HTMLDivElement>(null);
    const [oldscrollHeight, setOldscrollHeight] = useState(-1);
    const [help, setHelp] = useState(0);

    useEffect(() => {
        if (ScrollRef.current && scroll) {
            if (oldscrollHeight === ScrollRef.current?.scrollHeight) {
                ScrollRef.current.scrollBy(0, 100);
                //console.log(ScrollRef.current?.style.height);
            } else {
                ScrollRef.current.scrollTop = ScrollRef.current.scrollHeight;
            }
        }
    }, [ScrollRef.current?.scrollHeight, ScrollRef.current?.style.height, props.style, oldscrollHeight, scroll]) //Scroll darf nicht im Array sein da sonst beim nach unten Scrollen beim Letzten Element ein Sprung gemacht wird!

    useEffect(() => {
        if (ScrollRef.current)
            ScrollRef.current.scrollTop = ScrollRef.current.scrollHeight;
        setScroll(true);
        setHelp(0);
    }, [props.name])
    useEffect(() => {
        if (help === 0) {
            //console.log("Load------");
            if (isInViewport() && props.onTop)
                props.onTop();
            setHelp(1);
        }
    }, [props, help])

    const isInViewport = (offset = 0) => {
        if (topRef === null) return false;
        else {
            if (topRef.current !== null) {
                const top = topRef.current.getBoundingClientRect().top;
                return (top + offset) >= 0 && (top - offset) <= window.innerHeight;
            }
        }
    }

    useEffect(() => {
        if (props.children) {
            if (ScrollRef.current && (!props.isLoading)) {
                if (oldscrollHeight !== ScrollRef.current.scrollHeight) {
                    ScrollRef.current.scrollBy(0, ScrollRef.current.scrollHeight - oldscrollHeight);
                    setOldscrollHeight(ScrollRef.current.scrollHeight);
                }
            }
        }
    }, [props.children, ScrollRef, oldscrollHeight, props.isLoading]) //Wenn Load drin ist wird zuspät gescrollt
    return (
        <div style={{position: 'relative', width: '100%'}}>
            <div ref={ScrollRef}
                 onScroll={() => {
                     if (ScrollRef.current && ScrollRef.current?.scrollTop < (ScrollRef.current.scrollHeight - ScrollRef.current.offsetHeight - 200)) {
                         setScroll(false);
                     } else {
                         setScroll(true);
                     }
                     if (ScrollRef.current && ScrollRef.current.scrollTop <= 20) {
                         if (isInViewport()) {
                             if (props.onTop) {
                                 setOldscrollHeight(ScrollRef.current.scrollHeight);
                                 //console.log("Load|-|-|-|-|-|-")
                                 props.onTop();
                             }
                         }
                     }
                 }}
                 style={{...props.style, overflowY: 'scroll'}}
                className={props.className}
            >
                <div ref={topRef}/>
                {props.children}
                <div ref={bottomRef} id="tk_anchor" />
            </div>
        </div>
    )
}

export default ScrollView;
