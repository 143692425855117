import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from "../reducers";

type t_status = 'complete'|'requestComplete'|'todo';

export type t_counter = {
    role: 'creator'|'worker',
    status: t_status,
    todoId?: string,
    unread: number
}

type t_counters = {
    [key in t_status]?: t_counter
}

type t_userType = 'creator'|'worker';

type t_todoList = {
    counters: {
        [key in t_userType]: t_counters
    }
}

const initialState: t_todoList = {
    counters:{
        creator: {},
        worker: {}
    }
}

export const todoListSlice = createSlice({
    name: 'todoList',
    initialState,
    reducers: {
        setCounter: (state,action: PayloadAction<t_counter>) => {
            state.counters[action.payload.role][action.payload.status] = action.payload;
        },
    }
})


export const {setCounter} = todoListSlice.actions;

/**
 * Select the sum of the counters relevant for the current user.
 */
export const selectCounter= (state: RootState)=>{
    if(state.auth.permissions.todoList){
        return (state.todoList.counters.creator.requestComplete?.unread??0)+(state.todoList.counters.worker.todo?.unread??0);
    }
    return state.todoList.counters.worker.todo?.unread??0;
}

export default todoListSlice.reducer;
