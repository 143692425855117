import {Category as CategoryType} from "@pixel-kraft/commulino-types/dist/config";
import styles from "./Category.module.css";
import {Icon} from "components";
import React from "react";

const Category = (cat: CategoryType) => {
    return (
        <div className={styles.row} key={cat.id}>
            <Icon name={cat.icon}/>
            <div style={{marginLeft: 5}}>{cat.name}</div>
        </div>
    )
}
export default Category;