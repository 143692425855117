import { OnboardingTemplate } from '@pixel-kraft/commulino-types'
import {
	OnboardingActionTypes,
	OnboardingState,
	ONBOARDING_EDIT_TEMPLATE,
	ONBOARDING_GET_TEMPLATES_REQUEST,
	ONBOARDING_GET_TEMPLATES_SUCCESS,
	ONBOARDING_INIT_TEMPLATE,
	ONBOARDING_MOVE_POST_DOWN,
	ONBOARDING_MOVE_POST_UP,
	ONBOARDING_REMOVE_POST,
	ONBOARDING_SAVE_POST,
	ONBOARDING_SET_EDITING_TEMPLATE
} from './types'

const initialState: OnboardingState = {
	templates: {},
	loading: false,
	loaded: false
}

export default function onboarding(
	state = initialState,
	action: OnboardingActionTypes
): OnboardingState {
	switch (action.type) {
		case ONBOARDING_GET_TEMPLATES_REQUEST:
			return {
				...state,
				loading: true
			}
		case ONBOARDING_GET_TEMPLATES_SUCCESS:
			let templates: { [id: string]: OnboardingTemplate } = {}

			action.templates.forEach(template => {
				templates[template.id] = template
			})

			return {
				...state,
				loading: false,
				loaded: true,
				templates
			}
		case ONBOARDING_INIT_TEMPLATE:
			return {
				...state,
				editing: {
					state: true,
					data: action.template
				}
			}
		case ONBOARDING_EDIT_TEMPLATE:
			if (!state.editing?.data) return state

			return {
				...state,
				editing: {
					...state.editing,
					data: {
						...state.editing.data,
						...action.data
					}
				}
			}
		case ONBOARDING_SET_EDITING_TEMPLATE:
			return {
				...state,
				editing: {
					state: true,
					data: state.templates[action.id]
				}
			}
		case ONBOARDING_SAVE_POST:
			if (!state.editing?.data) return state

			return {
				...state,
				editing: {
					...state.editing,
					data: {
						...state.editing.data,
						posts: [
							...state.editing.data.posts.filter(post => post.index !== action.post.index),
							action.post
						]
					}
				}
			}
		case ONBOARDING_REMOVE_POST:
			if (!state.editing?.data) return state

			return {
				...state,
				editing: {
					...state.editing,
					data: {
						...state.editing.data,
						posts: state.editing.data.posts
							.filter(post => post.index !== action.index)
							.map(post => ({
								...post,
								index: post.index > action.index ? post.index - 1 : post.index
							}))
					}
				}
			}
		case ONBOARDING_MOVE_POST_UP:
			if (!state.editing?.data) return state

			if (action.index === state.editing.data.posts.length - 1) return state

			return {
				...state,
				editing: {
					...state.editing,
					data: {
						...state.editing.data,
						posts: state.editing.data.posts.map(post => {
							if (post.index === action.index) {
								return {
									...post,
									index: post.index + 1
								}
							} else if (post.index === action.index + 1) {
								return {
									...post,
									index: post.index - 1
								}
							}
							return post
						})
					}
				}
			}
		case ONBOARDING_MOVE_POST_DOWN:
			if (!state.editing?.data || action.index === 0) return state

			return {
				...state,
				editing: {
					...state.editing,
					data: {
						...state.editing.data,
						posts: state.editing.data.posts.map(post => {
							if (post.index === action.index) {
								return {
									...post,
									index: post.index - 1
								}
							} else if (post.index === action.index - 1) {
								return {
									...post,
									index: post.index + 1
								}
							}
							return post
						})
					}
				}
			}
		default:
			return state
	}
}
