import { project } from 'config'
import {getAuth} from "firebase/auth";
import {firebaseApp} from "lib/firebase";

// Change to api adresse (https://api.commulino.de)
const baseUrl = `https://api.commulino.de/${project}`

const request = async (path: string, method: string, body?: string) => {
	const token = await getAuth(firebaseApp).currentUser?.getIdToken();

	const headers: RequestInit['headers'] = {}

	if (token) {
		headers.token = token
	}

	if (body) {
		headers['Content-Type'] = 'application/json'
	}
	const res = await fetch(`${baseUrl}/${path}`, { method, headers, body })

	if (!res.ok) {
		throw Error(await res.text())
	}

	try {
		const json = await res.json()
		return json
	} catch (err) {
		return true
	}
}

export const get = (path: string) => {
	return request(path, 'GET')
}

export const post = (path: string, data: object) => {
	return request(path, 'POST', JSON.stringify(data))
}

export const patch = (path: string, data: object) => {
	return request(path, 'PATCH', JSON.stringify(data))
}

export const put = (path: string, data: object) => {
	return request(path, 'PUT', JSON.stringify(data))
}

const _delete = (path: string, data?: object) => {
	return request(path, 'DELETE', JSON.stringify(data))
}

export const upload = async (path: string, body: RequestInit['body']): Promise<any> => {
	const token = await getAuth(firebaseApp).currentUser?.getIdToken();

	const headers: RequestInit['headers'] = {};

	if (token) {
		headers.token = token;
	}

	const res = await fetch(`${baseUrl}/${path}`, { method: 'POST', headers, body });

	if (!res.ok) {
		throw Error(await res.text());
	}

	try {
		return res.json();
	} catch {
		return true;
	}
};

export const replace = async (path: string, body: RequestInit['body']): Promise<any> => {
	const token = await getAuth(firebaseApp).currentUser?.getIdToken();

	const headers: RequestInit['headers'] = {};

	if (token) {
		headers.token = token;
	}

	const res = await fetch(`${baseUrl}/${path}`, { method: 'PATCH', headers, body });

	if (!res.ok) {
		throw Error(await res.text());
	}

	try {
		return res.json();
	} catch {
		return true;
	}
};

export const getCalendarUrl=(calendarId:string)=>{
	return `${baseUrl}/calendars/${calendarId}`
}


export { _delete as delete }
