import React, {useState} from 'react';
import {useAppDispatch, useAppSelector} from "store/hooks";
import {ResRelSDK} from 'lib/sdk';
import {FiSettings, FiX} from "react-icons/fi";
import {arrayRemove, doc, getFirestore, updateDoc,arrayUnion} from "firebase/firestore";
import {firebaseApp} from "lib/firebase";
import {ChatUser} from '..';
import {AlertDialog} from "components";
import ChatUserPermission from "pages/chat/ChatUserPermission";
import UltimateList from "../ChatList";
import {getContactByResId} from "store/chat/actions";
import checkGroupPermissions from "lib/checkGroupPermissions";

type t_props = {
    chatId: string,
    height: number,
}

type t_pre = { admins: boolean, members: boolean, photo: boolean, name: boolean };

const ChatUserListManipulate = ({chatId, height}: t_props) => {
    const chat = useAppSelector((state) => state.messenger.Chats[chatId] as (ResRelSDK.t_ChatData | undefined));
    const {uid} = useAppSelector((state) => state.auth);
    const [permissionMenu, setPermissionMenu] = useState<{ userName: string, userID: string, permission?: t_pre } | undefined>();
    const [alert, setAlert] = useState<React.ComponentProps<typeof AlertDialog> | undefined>();
    const dispatch = useAppDispatch();

    const getPermissionData = React.useCallback(() => {
        if (chat?.group && uid && chat.group.admins && chat.group.admins[uid]) {
            return chat.group.admins[uid].permissions;
        }
        return {admins: false, name: false, members: false, photo: false};

    }, [uid, chat?.group])


    if (!chat || !uid) return null;
    const creator = chat?.group?.createdBy === uid;
    const mode = chat.mediCare?.type??'chat'

    const getUsers = React.useCallback(() => chat.uids.map((uid) => chat.users.filter(({uid: _uid}) => uid === _uid)[0]), [chat.users, chat.uids])
    const myPer = getPermissionData();

    return (
        <>
            <UltimateList
                users={ResRelSDK.sortContacts(getUsers(),uid)}
                height={height}
                searchFilter={{
                    filter: (name,item) =>item.name.toUpperCase().includes(name.toUpperCase())
                }}
                resident={mode==="relativeChat" ? dispatch(getContactByResId(chat.mediCare.residents[0])):undefined}
                mode={mode}
                edible={chat.group&&checkGroupPermissions(chat.group,uid,'members')}
                group={!!chat.group}
                onChange={async (users)=>{
                    if(chat.group && (myPer.members || creator)){
                        const uids:string[]=[];
                        for(const user of users) {
                            if (!user.photo) delete user.photo;
                            uids.push(user.uid);
                        }
                        await updateDoc(doc(getFirestore(firebaseApp),'chats',chatId),{
                            users: arrayUnion(...users),
                            uids: arrayUnion(...uids)
                        })
                    }
                }}
                render={(user, index) => {
                    const per = user.uid && chat.group?.admins ? chat.group.admins[user.uid] : undefined;
                    return (
                        <ChatUser me={uid === user.uid} key={user.uid ?? index} user={user} buttons={chat?.group?.createdBy===user.uid||per?.grantedBy === user.uid?undefined:[
                            (uid !== user.uid && ResRelSDK.isUser(user) && (myPer.members || myPer.admins || creator)) ? [{
                                icon: FiSettings,
                                onClick: per?.grantedBy === user.uid ? () => null : () => user.uid && setPermissionMenu({
                                    userID: user.uid,
                                    userName: user.name ?? "",
                                    permission: per?.permissions
                                })
                            }] : [],
                            (myPer.members || myPer.admins || creator) ? [{
                                icon: FiX,
                                onClick: () => setAlert({
                                    title: 'Gruppen Mitglied entfernen?',
                                    text: 'Willst du folgendes Gruppen Mitglied wirklich aus der Gruppe entfernen?',
                                    item: (<div>{user.name}</div>),
                                    onCancel: () => setAlert(undefined),
                                    onSuccess: async () => {
                                        setAlert(undefined);
                                        //setLoad(true);
                                        await updateDoc(doc(getFirestore(firebaseApp), 'chats', chatId), {
                                            uids: arrayRemove(user.uid),
                                        })
                                        //setLoad(false);
                                    },
                                })
                            }] : []
                        ].flat()}/>)
                }}

            />
            {alert && <AlertDialog {...alert} />}
            {permissionMenu &&
                <ChatUserPermission
                    chatID={chatId}
                    myID={uid}
                    finish={() => setPermissionMenu(undefined)}
                    {...permissionMenu}
                />}
        </>
    )
}

export default ChatUserListManipulate;
