import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref: React.MutableRefObject<null>,onClick:()=>void,deactivate?:boolean) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: { target: any; }) {
            if(ref.current!==null&&deactivate!==true){
                // @ts-ignore
                if (ref.current && !ref.current.contains(event.target)) {
                    onClick();
                }
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref,deactivate,onClick]);
}

/**
 * Component that alerts if you click outside of it
 */
function OutsideAlerter(props: { children: React.ReactNode,onClick:()=>void,deactivate?:boolean }) {
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef,props.onClick,props.deactivate);

    return <div /*style={{width: 'min-content'}}*/ ref={wrapperRef}>{props.children}</div>;
}

OutsideAlerter.propTypes = {
    children: PropTypes.element.isRequired
};

export default OutsideAlerter;