import React from 'react'
import styles from './Group.module.css'

interface Props {
	children: React.ReactNodeArray
	flex?: boolean
	style?: string
	contentStyle?: string
	margin?: number
}

const Group = ({ children, flex, style, contentStyle, margin }: Props) => (
	<div className={`${styles.container} ${style}`} style={{ margin: margin ? -margin : undefined }}>
		{children.map(
			(item, index) =>
				item && (
					<div
						key={index}
						className={`${styles.item} ${contentStyle}`}
						style={{
							flex: flex ? 1 : undefined,
							margin: margin !== undefined ? margin : undefined
						}}
					>
						{item}
					</div>
				)
		)}
	</div>
)

export default Group
