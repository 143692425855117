import React from 'react';
import {ResRelSDK} from 'lib/sdk'
import {Row, UserImage} from "components";
import RenderButtons from "./RenderButtons";
import {t_moreProps} from "./index";

type t_props = ResRelSDK.t_user & t_moreProps

const ChatUser = React.memo((user: t_props) => (
    <Row flex={1} style={{marginBottom: 5}} justifyContent={"space-between"}>
        <Row flex={0.5} style={{
            backgroundColor: user.transparent?"transparent":"white",
            paddingLeft: 5,
            borderRadius: 5
        }}>
            <UserImage
                src={user.photo}
                style={{
                    borderRadius: '50%',
                    height: 40,
                    width: 40,
                    marginRight: 10
                }}
            />
            <p>{user.me ? 'Du' : user.name}</p>
        </Row>
        {!user.me && user.buttons && <RenderButtons buttons={user.buttons}/>}
    </Row>
), (a, b) => a.uid === b.uid && a.name === b.name && a.photo === b.photo);

export default ChatUser;
