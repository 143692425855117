import React, {useEffect, useRef} from 'react';
import {t_postTodoListMessage} from "@pixel-kraft/commulino-types";
import getLoginToken from "lib/getLoginToken";

const sendMessageTo = (iFrame: Window, msg: any, link: string) => iFrame.postMessage(msg, link);


const IframeLogin = (props: React.HTMLProps<HTMLIFrameElement> & { version: 'v1' | 'v2' }) => {
    const iframe = useRef<HTMLIFrameElement>(null);

    useEffect(() => {
        if (iframe.current && props.version === "v2") {
            const win = iframe.current.contentWindow;
            if (win && props.src) {
                const onMsg = async (ev: MessageEvent) => {
                    try {
                        const data = JSON.parse(ev.data);
                        if (data.type === "requestToken") {
                            const token = await getLoginToken();
                            sendMessageTo(win, {type: 'sendToken', token}, props.src ?? '');
                        }
                    } catch (e) {
                        console.log("Ignore Error", e);
                    }
                }
                window.onmessage = onMsg;
                return () => {
                    window.removeEventListener('message', onMsg);
                }
            }
        }
    }, [iframe.current, props.src, props.version])

    return (
        <iframe
            ref={iframe}
            onLoad={props.version === "v1" ? async (ref) => {
                const iframe = ref.currentTarget.contentWindow;
                if (!iframe || !props.src) return;
                sendMessageTo(iframe, {type: 'loadToken'}, props.src);
                const token = await getLoginToken();
                sendMessageTo(iframe, {type: 'token', token}, props.src)
            } : undefined}
            {...props}
        />
    )
}

export default IframeLogin;
