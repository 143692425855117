import React, {useEffect, useRef, useState} from 'react';
import ReactCrop, {centerCrop, Crop, makeAspectCrop, convertToPixelCrop} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import {Button} from "components";
import {cropImage} from "lib/media";

type t_props = {
    src: string,
    onSuccess: (url: string) => void,
    imageStyle?: React.CSSProperties,
    imageClassName?: string,
    aspect?: number,
    hidePreview?: boolean
}

const ImageCrop = ({src, onSuccess, imageStyle, imageClassName,aspect,hidePreview}: t_props) => {
    const ref = useRef<HTMLImageElement>(null);
    const [crop, setCrop] = useState<Crop>();
    const [image, setImage] = useState<string | undefined>(undefined);


    return (
        <div style={{display: "flex", flexFlow: 'column', maxWidth: 1080}}>
            <div style={{padding: 5}}>
                <h1>Ausschnitt wählen</h1>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div>{image && !hidePreview &&
                        <img height={200} style={{borderRadius: '50%', ...imageStyle}} className={imageClassName}
                             width={200} src={image}/>}</div>
                    <Button text={"Zuschneiden"}
                            onClick={() => ref.current && crop && onSuccess(cropImage(ref.current, convertToPixelCrop(crop, ref.current.width, ref.current.height)))}/>
                </div>
                <div style={{margin: 'auto', width: 'min-content'}}>
                    <ReactCrop
                        onDragEnd={() => !hidePreview && ref.current && crop && setImage(cropImage(ref.current, convertToPixelCrop(crop, ref.current.width, ref.current.height)))}
                        aspect={aspect} keepSelection crop={crop} onChange={setCrop}>
                        <img
                            onLoad={(r) => {
                                const crop = aspect?centerCrop(
                                    makeAspectCrop({
                                            unit: 'px',
                                            height: 200
                                        },
                                        aspect,
                                        r.currentTarget.width,
                                        r.currentTarget.height
                                    ),
                                    r.currentTarget.width,
                                    r.currentTarget.height
                                ):centerCrop(
                                    {
                                      unit: 'px',
                                      height: r.currentTarget.height*0.8,
                                      width: r.currentTarget.width*0.8
                                    },
                                    r.currentTarget.width,
                                    r.currentTarget.height
                                )
                                setCrop(crop)
                                crop && setImage(cropImage(r.currentTarget, convertToPixelCrop(crop, r.currentTarget.width, r.currentTarget.height)))
                            }}
                            ref={ref}
                            style={{
                                objectFit: 'contain',
                                maxHeight: '50vh',
                                maxWidth: 1070,
                                minWidth: 300,
                                minHeight: 300,
                            }} src={src}/>
                    </ReactCrop>
                </div>
            </div>
        </div>
    )
}

export default ImageCrop;
