import React, {useRef} from 'react';

type t_props = {
    children: JSX.Element | JSX.Element[],
    show: boolean,
    inBox?: React.CSSProperties,
}

const AnimatedView = ({children, show, inBox}: t_props) => {
    const ref = useRef<HTMLDivElement>(null);

    return (
        <div style={{
            height: show ? ref.current?.clientHeight : 0,
            opacity: show ? 1 : 0,
            position: "relative",
            zIndex: show ? 1 : -1,
            transition: 'all 0.3s ease'
        }}>
            <div ref={ref} style={inBox}>
                {children}
            </div>
        </div>
    )
}

export default AnimatedView;