import React, {MutableRefObject, useEffect, useState} from "react";
import {i_data} from "lib/Cloud/type";
import {i_content} from "@pixel-kraft/commulino-types/dist/menuPoint";
import {CloudPopUpPicker, ColorButton} from "components";
import {FiImage, FiTrash2} from "react-icons/fi";
import styles from "./CloudPopUpImagePicker.module.css";


export type CloudPopUpImagePickerRef = {
    open: () => void,
    close: () => void
}

type t_props = {
    value?: i_data,
    onChange: (value?: i_data) => void,
    rootFolder: i_content,
    accept: string,
    ref?: React.ForwardedRef<CloudPopUpImagePickerRef>,
    imgClass?: string,
    imgStyle?: React.CSSProperties,
    buttonClass?: string,
    buttonStyle?: React.CSSProperties,
    className?: string,
    style?: React.CSSProperties,
}

/**
 * Warning if a lot of firebase Request are made it's properly because you put a state that change a lot in value.
 * Something like data:{img: i_data,text: string} and then <CloudPopUpImagePicker value={data.img} /> the problem is
 * that every time text is change the CloudPopUpImagePicker will rerender again and make a firebase request.
 */
const CloudPopUpImagePicker = ({ref, rootFolder, onChange, value, accept, imgClass, imgStyle,style,className,buttonStyle,buttonClass}: t_props) => {
    const [show, setShow] = useState(false);
    useEffect(() => {
        if (ref) {
            (ref as MutableRefObject<CloudPopUpImagePickerRef | null>).current = {
                open: () => setShow(true),
                close: () => setShow(false),
            }
        }
    }, [ref])

    const change: React.ComponentProps<typeof CloudPopUpPicker>["onChange"] = (img) => {
        setShow(false);
        onChange(img);
    }

    return (
        <div className={`${styles.container} ${className??''}`} style={style}>
            {value && value.url &&
                <>
                    <img className={`${styles.img} ${imgClass ?? ''}`} style={imgStyle} src={value.url}
                         onClick={() => setShow(!show)}/>
                    <ColorButton icon={FiTrash2} onClick={()=>onChange(undefined)} style={{position: "absolute",top: 4,right: 4, borderRadius: "50%",backgroundColor: "rgba(0,0,0,0.2)"}} />
                </>
            }
            {!value && <ColorButton className={`${buttonClass??''}`} style={buttonStyle} icon={FiImage} onClick={() => setShow(!show)}/>}
            <CloudPopUpPicker
                value={value}
                onChange={change}
                rootFolder={rootFolder}
                show={show}
                hide={() => setShow(false)}
                accept={accept}
            />
        </div>
    )
}
export default CloudPopUpImagePicker;
