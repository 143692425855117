import React from 'react';
import {Bubble, Row, UserImage} from "components";
import styles from './ChatItem.module.css'

type t_props = {
    title: string,
    src?: string,
    dateString: string,
    subtitle: string,
    id: string,
    unread?: number,
    group?: boolean,
    onClick: () => void,
    active?: boolean
}

const ChatItem = (props: t_props) => {
    return (
        <Row key={props.id} justifyContent={'space-between'}
             className={`${styles.container} ${props.active ? styles.active : ''}`}
             onClick={props.onClick}>
            <Row>
                <UserImage src={props.src} className={`${props.group ? styles.groupImage : styles.userImage}`}/>
                <div style={{marginLeft: 5}}>
                    <div className={`${styles.title}`}>{props.title}</div>
                    <div className={`${styles.subtitle}`}>{props.subtitle}</div>
                </div>
            </Row>
            <Row flexFlow={'column'} alignItems={'end'}>
                <div className={`${styles.date}`}>{props.dateString}</div>
                {props.unread !== undefined && props.unread > 0 &&
                    <Bubble>{props.unread}</Bubble>
                }
            </Row>
        </Row>
    );
}

export default ChatItem;
