import React from 'react';
import styles from "pages/fuerDichCalendar/index.module.css";
import {BsCardText, BsPencilSquare, FcOvertime, IoMdClose} from "react-icons/all";
import {FiUser} from "react-icons/fi";
import {Modal} from "components";
import {Option} from "pages/Kalender";
import {t_eventData} from "components/Kalender/types";
import {getName} from "pages/Kalender/components/AddEvent";

interface Props {
    modal: boolean,
    options: Option[],
    eventData: Partial<t_eventData> | undefined,
    setModal: (to:boolean) => void,
}

const EventInfoModal = ({modal, options, eventData, setModal}:Props) => {
    const eventColor = options.find(({value}) => value === eventData?.calendarId)?.color ?? '';
    const created = getName(eventData?.createdBy);
    const updated = getName(eventData?.updatedBy);

    return (
        <Modal
            isVisible={modal}
            hide={() => null}
            contentStyle={styles.modalContent}
            modalStyle={styles.maxWidth}
        >
            <div style={{padding:"8px", backgroundColor:eventColor}} className={eventData?.status === "tentative"? styles.backgroundLinear : ""}>
                <div style={{position:"relative", backgroundColor:"white", borderRadius:"5px", padding:"20px"}}>
                    <IoMdClose
                        className={styles.closeIcon}
                        onClick={()=>setModal(false)}
                    />
                    <div className={styles.title}>{eventData?.summary ? eventData.summary : "Beschreibung der Veranstaltung"}</div>
                    <div style={{display:"flex"}}>
                        <div style={{display:"grid", marginTop:"10px", width: "100%"}}>
                            {eventData?.start && !eventData.allDay && (
                                <div className={styles.iconDiv}>
                                    <div>
                                        <FcOvertime className={styles.iconSize}/>
                                    </div>
                                    <div className={`${styles.date} ${styles.bold}`}>Startdatum:</div>
                                    <div className={styles.date}>
                                        {eventData.start.format("dddd, MMMM D YYYY, H:mm")}
                                    </div>
                                </div>
                            )}
                            {eventData?.end && !eventData.allDay && (
                                <div className={styles.iconDiv}>
                                    <div>
                                        <FcOvertime className={styles.iconSize}/>
                                    </div>
                                    <div className={`${styles.date} ${styles.bold}`}>Enddatum:</div>
                                    <div className={styles.date}>
                                        {eventData.end.format("dddd, MMMM D YYYY, H:mm")}
                                    </div>
                                </div>
                            )}
                            {eventData?.allDay && eventData?.start && (
                                <div className={styles.iconDiv}>
                                    <div>
                                        <FcOvertime className={styles.iconSize}/>
                                    </div>
                                    <div className={`${styles.date} ${styles.bold}`}>Ganztägig:</div>
                                    <div className={styles.date}>
                                        {eventData.start.format("dddd, MMMM D YYYY")}
                                    </div>
                                </div>
                            )}
                            {created && (
                                <div className={styles.iconDiv}>
                                    <div>
                                        <FiUser className={styles.iconSizeDescription}/>
                                    </div>
                                    <div className={`${styles.date} ${styles.bold}`}>Erstellt von:</div>
                                    <div className={styles.date}>{created.name}</div>
                                </div>
                            )}
                            {updated && (
                                <div className={styles.iconDiv}>
                                    <div>
                                        <BsPencilSquare className={styles.iconSizeDescription}/>
                                    </div>
                                    <div className={`${styles.date} ${styles.bold}`}>Aktualisiert von:</div>
                                    <div className={styles.date}>{updated.name}</div>
                                </div>
                            )}
                            <div className={styles.iconDiv}>
                                <div>
                                    <BsCardText className={`${styles.iconSizeDescription} `}/>
                                </div>
                                <div className={`${styles.date} ${styles.bold}`}>
                                    Beschreibung:
                                </div>
                                {eventData?.description ? (
                                    <div className={styles.description}>{eventData?.description}</div>
                                ):(
                                    <div className={styles.noDescription}>Keine Beschreibung</div>
                                )}
                            </div>
                        </div>
                        <div>
                            {eventData?.imageUrl && <div
                                style={{
                                    width: "130px",
                                    height: "130px",
                                    backgroundColor: "blue",
                                    marginLeft: "30px",
                                    backgroundImage: `url(${eventData.imageUrl})`,
                                    backgroundPosition: "center",
                                    backgroundSize: "cover",
                                    borderRadius:"50%",
                                    border: "1px solid #7e7e7e",
                                }}
                            > </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default EventInfoModal;
