import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {UsersSDK} from 'lib/sdk'
import {useHistory} from 'react-router-dom'
import {FiAlertCircle, FiUserPlus, FiChevronRight} from 'react-icons/fi'
import {
    Alert,
    CheckBox,
    ColorButton,
    ContentContainer, DayMonthInput,
    ErrorMessageInline,
    FormItem,
    GroupSelector,
    Modal,
    PageTitle,
    Typo
} from 'components'
import CodePdfGen from '../CodePdfGen'
import styles from './index.module.css'
import RoleSelect from '../RoleSelect'
import {Permissions, Department, Location, User} from '@pixel-kraft/commulino-types'
import {getInvertColor} from 'lib/colors'
import {RootState} from 'store/reducers'
import {customAlphabet} from 'nanoid';
import {set,format} from 'date-fns'

const nanoId = customAlphabet('123456789ABCDEFGHIJKLMNPQRSTUVWXYZÄÖÜ', 8);

const alertNull = {
    title: '',
    text: '',
    icon: FiAlertCircle,
}

const UsersAdd = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const [personnelId, setPersonnelId] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [birthday,setBirthday] = useState<{day: number,month: number}>({day: 1,month: 1});
    const [birthdayMsg,setBirthdayMsg] = useState(false);
    const [code, setCode] = useState('')
    const [codeRegistration, setCodeRegistration] = useState(false)
    const [departments, setDepartments] = useState<Department[]>([])
    const [locations, setLocations] = useState<Location[]>([])
    const [permissions, setPermissions] = useState<Permissions>({})
    const [role, setRole] = useState<string>()
    const [incomplete, setIncomplete] = useState(false)
    const [alertVisible, setAlertVisible] = useState<Omit<React.ComponentProps<typeof Alert>, "visible" | "hide">>(alertNull)
    const subColor = useSelector((state: RootState) => state.config.subColor);
    const [moduleVisible, setModuleVisible] = useState(false);
    const [isCreateUser, setIsCreateUser] = useState(false);
    const {loading, creatingUser, error} = useSelector((state: RootState) => state.users);
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        if(error===undefined)
            setShowAlert(false);

        if (isCreateUser && !loading && !creatingUser) {
            setIsCreateUser(false);
            if (error)
                setShowAlert(true);
            else
                history.replace('/users');
        }
    }, [loading, creatingUser, error, isCreateUser, history])

    function isValidEmail(email: string) {
        // eslint-disable-next-line
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return regex.test(String(email).toLowerCase())
    }

    function checkEntry() {
        if (!name || (!codeRegistration && !email) || (!codeRegistration && !isValidEmail(email))) {
            setIncomplete(true)
            return false
        }

        if (!departments.length || !locations.length) {
            setAlertVisible({
                title: 'Keine Berechtigungen ausgewählt',
                text: 'Jeder Mitarbeiter muss mindestens einer Abteilung und einem Standort zugewiesen werden.',
                icon: FiAlertCircle,
                style: {backgroundColor: subColor, color: getInvertColor(subColor)}
            })
            return false
        }

        return true
    }

    async function generateCode() {
        if (!checkEntry()) return

        setCode(nanoId())
    }

    async function addUser() {
        if (!checkEntry()) return
        const user:Omit<User, 'uid'|'isRegistered'> = {personnelId, name, email, departments, locations, role, permissions, code};
        if(birthdayMsg){
            user.birthdayPost=birthdayMsg;
            user.birthday=format(set(new Date(),{date: birthday.day,month: birthday.month-1}),'dd-MM');
        }
        dispatch(UsersSDK.createUser(user))
        setIsCreateUser(true);
    }

    useEffect(() => {
        if (code) setModuleVisible(true);
    }, [code])

    return (
        <ContentContainer>
            <div className={styles.container}>
                <PageTitle text='Mitarbeiter hinzufügen'/>
                <Typo>
                    {`Wenn Sie einen neuen Mitarbeiter hinzufügen erhält dieser eine E-Mail mit einem Registrierungs-Link.

Mitarbeiter, die ohne E-Mail hinzugefügt werden, können sich direkt in der App über den generierten Code registrieren und dabei eigenständig eine E-Mail-Adresse angeben.

`}
                </Typo>
                <FormItem
                    type='text'
                    name='Personal-Nr. (optional)'
                    value={personnelId}
                    onChange={setPersonnelId}
                />
                <FormItem
                    type='text'
                    name='Name'
                    value={name}
                    onChange={setName}
                    requiredMessage='Bitte einen Namen angeben'
                    showRequired={incomplete && !name}
                />
                <div className={styles.emailInput}>
                    {!codeRegistration && (
                        <>
                            <FormItem
                                type='text'
                                name='E-Mail'
                                value={email}
                                onChange={setEmail}
                                requiredMessage='Bitte eine E-Mail-Adresse angeben'
                                showRequired={incomplete && !email}
                            />
                            <ErrorMessageInline
                                text='Keine gültige E-Mail-Adresse'
                                visible={incomplete && !!email && !isValidEmail(email)}
                            />
                        </>
                    )}
                    <CheckBox
                        checked={codeRegistration}
                        onChange={state => setCodeRegistration(state)}
                        text='Mitarbeiter ohne E-Mail registrieren und stattdessen Registrierungs-Code erzeugen'
                        style={styles.checkBox}
                    />
                </div>
                <div>
                    <CheckBox checked={birthdayMsg} onChange={setBirthdayMsg} text={"Geburtstag Nachricht"} />
                    {birthdayMsg && <div style={{marginTop: 5,display: 'flex',alignItems: 'center'}}>
                        <div style={{fontSize: '1.3em',marginRight: 5}}>Geburtstag: </div><DayMonthInput value={birthday} onChange={setBirthday}/>
                    </div>}
                </div>
                <RoleSelect
                    permissions={permissions}
                    setPermissions={setPermissions}
                    role={role}
                    setRole={setRole}
                />
                <GroupSelector
                    selectedDepartments={departments}
                    selectedLocations={locations}
                    onDepartmentsSelect={setDepartments}
                    onLocationsSelect={setLocations}
                    showAll
                />
                <div className={styles.buttonContainer}>
                    <ColorButton
                        onClick={codeRegistration ? (code ? async () => setModuleVisible(true) : generateCode) : addUser}
                        text={!codeRegistration ? 'Hinzufügen' : 'Code generieren'}
                        icon={codeRegistration ? FiChevronRight : FiUserPlus}
                        large
                    />
                </div>
            </div>
            <Alert
                {...alertVisible}
                visible={alertVisible.title !== ''}
                hide={() => setAlertVisible(alertNull)}
            />
            <Modal isVisible={moduleVisible} flexWidth hide={() => setModuleVisible(false)}>
                <div style={{padding: 24}}>
                    <Typo br>Der Registrierungs-Code lautet:</Typo>
                    <Typo variant='h3' br style={styles.code}>
                        {code}
                    </Typo>
                    <Typo>
                        Teilen Sie diesen Code Ihrem Mitarbeiter {name} mit, damit er sich in der App
                        registrieren kann. Sie können außerdem direkt eine PDF erzeugen, auf der alle Infos zur
                        Registrierung gesammelt sind zum Weitergeben an den Mitarbeiter.
                    </Typo>
                    <CodePdfGen addText={" & Mitarbeiter hinzufügen"} onClick={addUser} {...{
                        code,
                        name,
                        personnelId
                    }} />
                    <ColorButton
                        text='Mitarbeiter hinzufügen'
                        onClick={addUser}
                        style={{marginTop: 20}}
                        icon={FiUserPlus}
                    />
                </div>
            </Modal>
            <Alert title={"Zu viele Benutzer"} text={"Sie haben nicht genügen Lizenzen um weitere Benutzer anzulegen"}
                   icon={FiAlertCircle} visible={showAlert} hide={() => dispatch(UsersSDK.resetAlert())}/>
        </ContentContainer>
    )
}

export default UsersAdd
