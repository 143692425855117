import React from 'react';
import {User} from "@pixel-kraft/commulino-types";
import {Row, UserImage} from "components";
import styles from "pages/chat/GroupScreen.module.css";


type t_props<T> = {
    user: T,
    me?: boolean,
    nameCellClassName?: string,
    nameCellStyle?: React.CSSProperties,
    rowClassName?: string,
    rowStyle?: React.CSSProperties,
    children?: React.ReactNode[] | React.ReactNode
    //removeUser?: (event: React.MouseEvent) => void,
    reverse?: boolean,
    buttons?: React.ReactNode
}
const UserRow = <T extends Pick<User, 'name' | 'photo'>>(props: t_props<T>) => (
    <Row flex={1} style={{marginBottom: 5}}>
        <Row flex={1} className={props.rowClassName} style={props.rowStyle}>
            <UserImage
                style={{
                    borderRadius: '50%',
                    height: 40,
                    width: 40,
                    marginRight: 10
                }}
                src={props.user.photo}
            />
            <Row flex={1} flexFlow={props.reverse ? 'row-reverse' : undefined}>
                <div className={`${styles.nameCell} ${props.nameCellClassName ?? ''}`}
                     style={props.nameCellStyle}>{props.me ? "Du" : props.user.name}</div>
                {props.children}
            </Row>
        </Row>
        {!props.me&&props.buttons}
    </Row>
)

export default UserRow;
