import {AppThunk} from "store/store";

const check=<T extends {uid: string}>(users: T[]):AppThunk<string|false>=>{
    return (dispatch,getState) => {
        const chat = Object.entries(getState().messenger.Chats)
            .filter(chat => (!chat[1].group) && chat[1].uids.length === 2&& chat[1].uids.includes(users[0].uid))

        if (chat.length) {
            const c = chat[0];
            return c[0]
        }

        return false;
    }
}

export default check;
