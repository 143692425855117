import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from 'store/reducers'
import {UsersSDK} from 'lib/sdk'
import {Department, Location, Permissions} from '@pixel-kraft/commulino-types'
import {useLocation, useHistory} from 'react-router-dom'
import {FiAlertCircle, FiSave, FiX} from 'react-icons/fi'
import {
    Alert, AlertDialog,
    Button, CheckBox,
    ColorButton,
    ContentContainer, DayMonthInput,
    EditItem,
    ErrorMessageInline,
    GroupSelector,
    PageTitle,
    Typo
} from 'components'
import RoleSelect from '../RoleSelect'
import OnboardingEdit from '../OnboardingEdit'
import styles from './index.module.css'
import {getInvertColor} from 'lib/colors'
import {onboardingEnabled} from 'config'
import {permissionCheck} from "lib/helpers";
import {set,format,parse} from 'date-fns';
import {usersStore} from 'store/store';

const UsersEdit = () => {
    const history = useHistory()
    const location: any = useLocation()
    const dispatch = useDispatch()
    const user = useSelector((state: RootState) =>
        state.users.users.find(user => user.uid === location.state.uid)
    )
    const uid = useSelector((state: RootState) => state.auth.uid);
    /**
     * Date month start by zero!!! And so dos date-fns
     * https://stackoverflow.com/a/41992352/13182135
     * @param date as string in format dd-MM
     */
    const getBirthday = (date: string)=>{
        const d=parse(date,'dd-MM',new Date());
        return {
            day: d.getDate(),
            month: d.getMonth()+1
        }
    }
    const [birthday, setBirthday] = useState<{ day: number, month: number }>(user?.birthdayPost && user.birthday ? getBirthday(user.birthday) : {day: 1, month: 1});
    const [birthdayMsg, setBirthdayMsg] = useState(!!user?.birthdayPost);
    const roles = useSelector((state: RootState) => state.roles.roles);
    const [rolPer, setRolPer] = useState<Permissions>({});
    const [personnelId, setPersonnelId] = useState(user?.personnelId || '')
    const [name, setName] = useState(user?.name || '')
    const [role, setRole] = useState<string | undefined>(user?.role)
    const [permissions, setPermissions] = useState(user?.permissions || {})
    const [departments, setDepartments] = useState<Department[]>(user?.departments || [])
    const [locations, setLocations] = useState<Location[]>(user?.locations || [])
    const [error, setError] = useState<boolean | string>(false)
    const subColor = useSelector((state: RootState) => state.config.subColor)
    const [alert, setAlert] = useState<React.ComponentProps<typeof AlertDialog> | undefined>(undefined);

    useEffect(() => {
        if (!user) history.push('/users')
    })
    useEffect(() => {
        const tmp = roles.find(r => r.id === role)?.permissions;
        setRolPer(tmp ? tmp : {});
    }, [role, roles])

    function save() {
        if (!user) return
        if (!RegExp(/[a-zA-Z]/).test(name)) return setError('name')
        if (departments.length === 0 || locations.length === 0) return setError('groups')
        const per = JSON.stringify(user.permissions) === "{}" ? roles.find(r => r.id === user.role)?.permissions : user.permissions;
        const newUser:usersStore.t_User  = {...user, personnelId, name, departments, locations, permissions, role};
        if (birthdayMsg) {
            newUser.birthdayPost = birthdayMsg;
            newUser.birthday = format(set(new Date(),{date: birthday.day,month: birthday.month-1}),'dd-MM');
        } else {
            newUser.birthdayPost = false;
            newUser.birthday='';
        }
        if (user.uid === uid && user.permissions && !permissionCheck(JSON.stringify(permissions) === "{}" ? rolPer : permissions, per ? per : {})) {
            setAlert({
                title: "Rechte entfernen",
                text: "Sie sind dabei ihre eigenen Rechte zu einzuschränken!",
                item: <div/>,
                onCancel: () => setAlert(undefined),
                onSuccess: () => {
                    setAlert(undefined);
                    dispatch(UsersSDK.editUsers(newUser));
                    history.push('/users')
                }
            })
        } else {
            dispatch(UsersSDK.editUsers(newUser))

            history.push('/users')
        }
    }

    if (!user) return null

    return (
        <ContentContainer>
            <PageTitle text='Mitarbeiter bearbeiten'/>
            <Typo variant='h5' br>
                E-Mail
            </Typo>
            <Typo br style={styles.userEmail}>
                {user.email || '–'}
            </Typo>
            <EditItem name='Personal-Nr.' value={personnelId} onChange={setPersonnelId}/>
            <EditItem name='Name' value={name} onChange={setName}/>
            <div>
                <CheckBox checked={birthdayMsg} onChange={setBirthdayMsg} text={"Geburtstag Nachricht"}/>
                {birthdayMsg && <div style={{marginTop: 5,display: 'flex',alignItems: 'center'}}>
                    <div style={{fontSize: '1.3em',marginRight: 5}}>Geburtstag: </div><DayMonthInput value={birthday} onChange={setBirthday}/>
                </div>}
            </div>
            {onboardingEnabled && <OnboardingEdit user={user}/>}
            <ErrorMessageInline text='Bitte einen gültigen Namen angeben!' visible={error === 'name'}/>
            <RoleSelect {...{role, setRole, permissions, setPermissions}} />
            <GroupSelector
                selectedDepartments={departments}
                selectedLocations={locations}
                onDepartmentsSelect={setDepartments}
                onLocationsSelect={setLocations}
                showAll
            />
            <div className={styles.buttonContainer}>
                <Button onClick={history.goBack} text='Abbrechen' icon={FiX} light transparent large/>
                <ColorButton
                    onClick={save}
                    text='Speichern'
                    icon={FiSave}
                    large
                    style={{marginLeft: 12}}
                />
            </div>
            <Alert
                title='Bitte mindestens eine Abteilung/Standort auswählen'
                text='Ein Mitarbeiter muss mindestens einem Standort und einer Abteilung zugeordnet sein.'
                icon={FiAlertCircle}
                visible={error === 'groups'}
                hide={() => setError(false)}
                style={{backgroundColor: subColor, color: getInvertColor(subColor)}}
            />
            {alert &&
                <AlertDialog {...alert} />
            }
        </ContentContainer>
    )
}

export default UsersEdit
