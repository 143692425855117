import React from 'react'
import {useSelector} from 'react-redux'
import {RootState} from 'store/reducers'
import {Department, Location} from '@pixel-kraft/commulino-types'
import {FiCheck, FiPlus, FiMinus} from 'react-icons/fi'
import {Button, ColorButton, Group, Typo} from 'components'
import styles from './GroupSelector.module.css'
import {ConfigState} from "store/config/types";

interface Props {
    selectedDepartments: Department[],
    selectedLocations: Location[],
    onDepartmentsSelect: (departments: Department[]) => void,
    onLocationsSelect: (locations: Location[]) => void,
    showAll?: boolean
}

const GroupSelector = ({
                           selectedDepartments,
                           selectedLocations,
                           onDepartmentsSelect,
                           onLocationsSelect,
                           showAll
                       }: Props) => {

    const getArray=(from: 'departments'|'locations',edit: "all" | "group" | "own" | undefined,config: ConfigState,groups: string[])=>{
        if (showAll || edit === "all") {
            return config[from];
        } else{
            return config[from].filter(d => groups.filter(t => t.includes(d)).length > 0);
        }
        // throw Error("Es würde nicht festgelegt welche Posts dieser Benutzer bearbeiten darf!");
    }

    const departments = useSelector((state: RootState) => {
        return getArray('departments',state.auth.permissions.posts?.edit,state.config,state.auth.groups);
        }
    )
    const locations = useSelector((state: RootState) => {
        return getArray('locations',state.auth.permissions.posts?.edit,state.config,state.auth.groups);
    })

    return (
        <div>
            <div className={styles.row}>
                <Typo strong style={styles.text}>
                    Abteilungen
                </Typo>
                <Button
                    text={departments.length === selectedDepartments.length ? 'Alle abwählen' : 'Alle auswählen'}
                    onClick={() => {
                        if (departments.length === selectedDepartments.length) {
                            onDepartmentsSelect([])
                        } else {
                            onDepartmentsSelect(departments)
                        }
                    }}
                    icon={departments.length === selectedDepartments.length ? FiMinus : FiPlus}
                    transparent
                />
            </div>
            <Group style={styles.group} margin={4}>
                {departments.map(department => {
                    const selected = selectedDepartments.includes(department)
                    return (
                        <ColorButton
                            key={department}
                            text={department}
                            onClick={() =>
                                selected
                                    ? onDepartmentsSelect(selectedDepartments.filter(d => d !== department))
                                    : onDepartmentsSelect([...selectedDepartments, department])
                            }
                            icon={selected ? FiCheck : FiPlus}
                            aktive={selected}
                            disableStyle={{backgroundColor: 'lightgray', color: 'black'}}
                        />
                    )
                })}
            </Group>
            <div className={styles.row}>
                <Typo strong style={styles.text}>
                    Standorte
                </Typo>
                <Button
                    text={locations.length === selectedLocations.length ? 'Alle abwählen' : 'Alle auswählen'}
                    onClick={() => {
                        if (locations.length === selectedLocations.length) {
                            onLocationsSelect([])
                        } else {
                            onLocationsSelect(locations)
                        }
                    }}
                    icon={locations.length === selectedLocations.length ? FiMinus : FiPlus}
                    transparent
                />
            </div>
            <Group style={styles.group} margin={4}>
                {locations.map(location => {
                    const selected = selectedLocations.includes(location)
                    return (
                        <ColorButton
                            key={location}
                            text={location}
                            onClick={() =>
                                selected
                                    ? onLocationsSelect(selectedLocations.filter(l => l !== location))
                                    : onLocationsSelect([...selectedLocations, location])
                            }
                            icon={selected ? FiCheck : FiPlus}
                            aktive={selected}
                            disableStyle={{backgroundColor: 'lightgray', color: 'black'}}
                        />
                    )
                })}
            </Group>
        </div>
    )
}

export default GroupSelector
