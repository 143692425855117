import React, { useState } from 'react'
import { FiCheck, FiEdit2 } from 'react-icons/fi'
import FormItem from './FormItem'
import Button from './Button'
import Typo from './Typo'
import styles from './EditItem.module.css'

interface Props {
	value: any
	name: string
	type?: string
	onChange: (value: string) => void
}

const EditItem = ({ value, name, type, onChange }: Props) => {
	const [edit, setEditMode] = useState(false)

	return (
		<div className={styles.container}>
			<div className={styles.name}>
				<Typo variant='h5'>{name}</Typo>
				<Button
					onClick={() => setEditMode(!edit)}
					icon={edit ? FiCheck : FiEdit2}
					light
					transparent
				/>
			</div>
			{edit ? (
				<FormItem type={type || 'text'} value={value} onChange={onChange} />
			) : (
				<Typo>{value}</Typo>
			)}
		</div>
	)
}

export default EditItem
