import React, {ReactElement} from "react";
import styles from "./DropDownHead.module.css";
import {FiChevronDown, FiChevronUp} from "react-icons/fi";


type t_DropDownHead = {
    Item: ReactElement,
    show: boolean,
}

const DropDownHead = ({Item, show}: t_DropDownHead) => {
    return (
        <div className={`${styles.lastRow} ${styles.dropDownHead}`}>
            {Item}
            <div className={styles.row}>{show ? <FiChevronUp/> : <FiChevronDown/>}</div>
        </div>
    )
}

export default DropDownHead;