import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'store/reducers'
import {
    getPosts,
    setPostVisible,
    initNewPost,
    standardPostNum_All,
    getNextFilteredPosts,
    getPostsByGroup
} from 'store/posts/actions'
import { FiPlus } from 'react-icons/fi'
import moment from 'moment'
import { ColorButton, ContentContainer, DynamicTable, Indicator, PageTitle, Tag } from 'components'
import PostsFilter from './PostsFilter'
import Details from './Details'
import {get_PerMsg_ID} from "lib/config_help";
import {t_filterFor, t_postBy} from "store/posts/types";
import {Post as t_Post} from "@pixel-kraft/commulino-types"

const Posts = () => {
    const dispatch = useDispatch();
    const edit = useSelector((state: RootState) => state.auth.permissions.posts?.edit?state.auth.permissions.posts?.edit:'group');
    const posts = useSelector((state: RootState) => state.posts.byId);
    const filter = useSelector((state: RootState) => state.posts.filter);
    const loading = useSelector((state: RootState) => state.posts.loading);
    const complete = useSelector((state: RootState) => state.auth.permissions.posts?.edit !== 'group' ? state.posts.postComplete : state.posts.postByGroupComplete);
    const postsByCategory = useSelector((state: RootState) => state.posts.postsByCategory);
    const postsByLocation = useSelector((state: RootState) => state.posts.postsByLocation);
    const postsByDepartment = useSelector((state: RootState) => state.posts.postsByDepartment);
    const [filterPosts, setFilterPosts] = useState<{ id: string, date: number }[]>([]);
    const [searchTerm, setSearchTerm] = useState('')
    const multi = useSelector((state: RootState) => state.posts.postLoadCountAll)
    const [filterID, setFilterID] = useState('');
    const [filterBy, setFilterBy] = useState<t_filterFor>('category');
    const hasPosts=useSelector((state:RootState)=>Object.keys(state.posts.byId).length>0);
    const uid=useSelector((state:RootState)=> state.auth.uid);


    useEffect(() => {
        const loadNext = (data: t_postBy, filter: string, filterFor: t_filterFor) => {
            if (filter in data) {
                const tmp = data[filter];
                if (tmp.posts.length < standardPostNum_All && !tmp.complete) {
                    dispatch(getNextFilteredPosts(filter.substr(2), filterFor, edit !== 'group')); //Sollte diesen UseEffect nochmal aufrufen da post sich ändert
                    return;
                }
                setFilterPosts(tmp.posts)
            } else {
                dispatch(getNextFilteredPosts(filter.substr(2), filterFor, edit !== 'group')); //Sollte diesen UseEffect nochmal aufrufen da post sich ändert
                return;
            }
        }
        const setFilterPosts_help = (costumePost: { [id: string]: t_Post }, filterFunc: (id: { id: string, date: number }) => boolean) => {
            const tmp: { id: string, date: number }[] = [];
            Object.keys(costumePost).forEach(post_id=>tmp.push({id: post_id,date: posts[post_id].date}))

            setFilterPosts(tmp.filter(filterFunc).sort((a, b) => {
                if (a.date < b.date)
                    return 1;
                if (b.date < a.date)
                    return -1;
                return 0;
            }).splice(0, standardPostNum_All * multi));
        }

        if (edit === "all") {
            if (filter.category) {
                loadNext(postsByCategory, 'A_' + filter.category.id, 'category');
                setFilterID(filter.category.id);
                setFilterBy('category');
            } else if (filter.location) {
                loadNext(postsByLocation, 'A_' + filter.location, 'locations');
                setFilterID(filter.location);
                setFilterBy('locations');
            } else if (filter.department) {
                loadNext(postsByDepartment, 'A_' + filter.department, "departments");
                setFilterID(filter.department);
                setFilterBy('departments');
            } else {
                setFilterID('');
                setFilterBy('category');
                const perID = get_PerMsg_ID();
                setFilterPosts_help(posts, id => posts[id.id].category.id !== perID);
            }
        } else {
            const perID = get_PerMsg_ID();
            const buildFunc=(id:{id: string, date: number})=>posts[id.id].author.uid===uid;
            if (filter.category)
                setFilterPosts_help(posts, id => posts[id.id].category.id === filter.category?.id&&(edit==="own"?buildFunc(id):true));
            else if (filter.location)
                setFilterPosts_help(posts, id => posts[id.id].locations.includes(filter.location ? filter.location : '') && posts[id.id].category.id !== perID&&(edit==="own"?buildFunc(id):true));
            else if (filter.department)
                setFilterPosts_help(posts, id => posts[id.id].departments.includes(filter.department ? filter.department : '') && posts[id.id].category.id !== perID&&(edit==="own"?buildFunc(id):true));
            else
                setFilterPosts_help(posts, id => posts[id.id].category.id !== perID&&(edit==="own"?buildFunc(id):true));
        }
    }, [postsByCategory, postsByDepartment, postsByLocation, posts, filter, dispatch, multi, edit,uid])

    useEffect(() => {
        dispatch(edit === "group" ? getPostsByGroup(undefined, undefined, undefined, standardPostNum_All) : getPosts());
    }, [dispatch, edit])

    const filtered = searchTerm ?
        filterPosts.filter((p) =>
            posts[p.id] ? posts[p.id].title.toLowerCase().includes(searchTerm.toLowerCase())
                : false)
        : filterPosts
    return (
        <ContentContainer fullHeight loading={loading}>
            <PageTitle
                text='Beiträge'
                button={
                    <ColorButton
                        text='Neuer Beitrag'
                        icon={FiPlus}
                        onClick={() => dispatch(initNewPost())}
                        large
                    />
                }
            />
			{!hasPosts && !loading &&
				<div>
					<h3>{edit==="own"?"Sie haben noch keinen Post erstellt":edit==="group"?"In ihrer Gruppe wurde noch kein Post erstellt":"Es wurde noch kein Posts erstellt"}</h3>
				</div>
			}
            {(hasPosts||loading) &&
            <div>
                <PostsFilter {...{searchTerm, setSearchTerm}} />
                <DynamicTable
                    headers={['Titel', 'Kategorie', 'Abteilungen', 'Standorte', 'Datum', 'Öffentlich']}
                    rows={filtered.filter(p => posts[p.id] !== undefined).map(
                        (post_small) => {
                            return ({
                                key: post_small.id,
                                cells: [
                                    {key: 'title', content: posts[post_small.id].title},
                                    {
                                        key: 'category',
                                        content: <Tag
                                            text={posts[post_small.id].category?.name || 'Kategorie gelöscht'}/>
                                    },
                                    {
                                        key: 'departments',
                                        content: posts[post_small.id].departments?.map(department => <Tag
                                            text={department} key={department}/>)
                                    },
                                    {
                                        key: 'locations',
                                        content: posts[post_small.id].locations?.map(location => <Tag text={location}
                                                                                                      key={location}/>)
                                    },
                                    {key: 'date', content: moment(post_small.date).format('DD.MM.YY')},
                                    {
                                        key: 'public',
                                        content: (
                                            <div style={{display: 'flex'}}>
                                                <Indicator active={posts[post_small.id].isPublic || false}/>
                                                {posts[post_small.id].isPublic ? 'Ja' : 'Nein'}
                                            </div>
                                        )
                                    }
                                ]
                            })
                        }
                    )}
                    maxRows={25}
                    onRowClick={row => dispatch(setPostVisible(true, row.key))}
                    complete={complete ||
                    (filterBy === 'category' && filterID !== '' && postsByCategory['A_' + filterID] && postsByCategory['A_' + filterID].complete) ||
                    (filterBy === 'departments' && filterID !== '' && postsByDepartment['A_' + filterID] && postsByDepartment['A_' + filterID].complete) ||
                    (filterBy === 'locations' && filterID !== '' && postsByLocation['A_' + filterID] && postsByLocation['A_' + filterID].complete)}
                    load_next={() => dispatch(getNextFilteredPosts(filterID, filterBy, edit !== 'group', undefined, standardPostNum_All))}
                    gotoStartOn={filter}
                />
                <Details/>
            </div>
            }
        </ContentContainer>
    )
}

export default Posts
