import React, {useState} from 'react';
import {CheckBox, ColorButton, FormItem, Typo} from "components";
import {residentDirectoryData} from '@pixel-kraft/commulino-types'
import styles from './AddBewohner.module.css'


type t_params = {
    onAdd: (data:residentDirectoryData)=>void,
    onSuccess?:()=>void,
    onCancel?:()=>void,
    data?: residentDirectoryData,
    addButtonText?: string,
    cancelButtonText?:string,
}

const AddBewohner=(params:t_params)=>{
    const [vorname,setVorname]=useState(params.data?params.data.vorname:"");
    const [nachname,setNachname] = useState(params.data?params.data.nachname:"");
    const [apartment,setApartment] = useState(params.data?params.data.apartment:"");
    const [etage,setEtage] = useState(params.data?params.data.etage:"");
    const [kurzzeitpflege,setKurzzeitpflege] = useState(params.data?params.data.kurzzeitpflege:false);
    const [msg,setMsg] = useState("");
    const reset=()=>{
        setVorname("");
        setNachname("");
        setApartment("");
        setEtage("");
        setKurzzeitpflege(false);
    }
    const test=()=>{
       if(vorname.trim().length===0||nachname.trim().length===0||apartment.trim().length===0||etage.trim().length===0){
           setMsg("Sie haben nicht alle Felder aus gefühlt!");
           return false;
       }
       return true;
    }

    return (
        <div>
            <Typo style={`${styles.msg}`}>{msg}</Typo>
            <table style={{marginLeft: 10}}>
                <tbody>
                <tr>
                    <td>Vorname:</td>
                    <td><FormItem value={vorname} onChange={setVorname}/></td>
                </tr>
                <tr>
                    <td>Nachname:</td>
                    <td><FormItem value={nachname} onChange={setNachname}/></td>
                </tr>

                <tr>
                    <td>Apartment:</td>
                    <td><FormItem value={apartment} onChange={setApartment}/></td>
                </tr>
                <tr>
                    <td>Etage:</td>
                    <td><FormItem value={etage} onChange={setEtage}/></td>
                </tr>
                <tr>
                    <td>Kurzzeitpflege:</td>
                    <td><CheckBox checked={kurzzeitpflege} onChange={setKurzzeitpflege}/></td>
                </tr>
                </tbody>
            </table>
            <div className={`${styles.ButtonContainer}`}>
                <ColorButton text={params.addButtonText ? params.addButtonText : 'Hinzufügen'}
                             onClick={() => {
                                 if(test()) {
                                     params.onAdd({
                                         vorname: vorname.trim(),
                                         nachname: nachname.trim(),
                                         apartment: apartment.trim(),
                                         etage: etage.trim(),
                                         kurzzeitpflege,
                                         deaktiviert: false
                                     });
                                     reset();
                                     if (params.onSuccess)
                                         params.onSuccess();
                                 }
                             }}/>
                <ColorButton style={{position: 'absolute',right: 20}} text={params.cancelButtonText ? params.cancelButtonText : 'Abbrechen'} onClick={() => {
                    reset();
                    if (params.onCancel)
                        params.onCancel();
                }}/>
            </div>

        </div>
    );
}

export default AddBewohner;
