import React, {useEffect, useRef, useState} from "react";
import {ChatButton} from "components/index";
import {FiFileText} from "react-icons/fi";
import styles from './FilePicker.module.css';

type t_FilePicker = {
    onChange: (f: File) => void,
    disable?: boolean,
    icon?: React.ComponentType<{ style?: React.CSSProperties }>,
    className?: string,
    notOnRender?: boolean,
    onlyButton?: boolean,
    inBackground?: boolean,
    accept?: string,
}
const FilePicker = React.forwardRef<HTMLDivElement, t_FilePicker>(({disable,inBackground,accept, onChange,icon,onlyButton,className,notOnRender}, ref) => {
    const FILERef = useRef<HTMLInputElement | null>()
    const [file, setFile] = useState<File | undefined>(undefined);

    useEffect(() => {
            // console.log("Make a click on Render "+(notOnRender===true?"ney":"yey"));
        if(!notOnRender) {
            FILERef.current?.click();
        }
    }, [notOnRender])

    useEffect(() => {
        if (file)
            onChange(file);
    }, [file])

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {target} = event;
        const {files} = target;
        if (files && files[0]) {
            let reader = new FileReader();


            reader.onload = event => {
                if (event.target) {
                    setFile(files[0]);
                }
            };

            reader.readAsDataURL(files[0]);
        }
    }
    return (
        <div ref={ref} onClick={() => {
            if (!disable && FILERef.current !== null) {
                FILERef.current?.click();
            }
        }} style={{
            width: 'max-content',
            display: inBackground?"none":"flex",
            backgroundColor: "white",
            padding: "0px 10px",
            marginBottom: 5,
            borderRadius: '5px',
            alignItems: 'center'
        }}>
            <input ref={(ref) => FILERef.current = ref} onClick={(event => event.stopPropagation())} style={{display: 'none'}}
                   id="tk_filePicker"
                   type="file"
                   accept={accept??"*"}
                   capture="user"
                   onChange={(e) => handleFileChange(e)}
            />
            {!inBackground && (<>
                {!onlyButton && file &&
                    <p>{file.name}</p>}
                <ChatButton className={`${disable?styles.buttonDis:styles.button} ${className}`} icon={icon?icon:FiFileText} onClick={() => {}}/>
            </>)}
        </div>
    )
})
FilePicker.displayName = 'FilePicker';
export default FilePicker;
