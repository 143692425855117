import {useEditor, EditorContent} from '@tiptap/react';
import {Schema} from '@pixel-kraft/commulino-types/dist/Content/Schema'
import styles from './Editor.module.css';
import './Prosemirror.css';
import EditorToolbar from "./EditorToolbar";
import {useRef} from 'react';
import Image from '@tiptap/extension-image';
import Heading from '@tiptap/extension-heading';
import {minimumExtension} from "lib/configEditor";
import Embeds from "lib/tipTapExensions/Embeds";

export type t_config = {
    image?: (setImage: (url: string) => void, show: boolean) => void
    headline?: (1 | 2 | 3 | 4 | 5 | 6)[],
    embeds?: boolean
}

type Props = {
    content: string | Schema;
    onChange: (content: Schema) => void;
    config?: t_config
}

const ContentEditor = ({content, onChange, config}: Props) => {
    const wrapperRef = useRef<HTMLDivElement | null>(null);

    const configFunc = (config?: t_config)=>{
        if(!config) return [];
        const arr:any[]=[];
        if(config.image)
            arr.push(Heading.configure({
            levels: config.headline,
        }))

        if(config.headline)
            arr.push(Image.configure({
                HTMLAttributes: {
                    class: styles.image,
                }
            }))

        if(config.embeds)
            arr.push(Embeds.configure({
                HTMLAttributes: {
                    class: styles.embed
                }
            }))

        return arr;
    }

    const editor = useEditor({
        onUpdate(event) {
            onChange(event.editor.getJSON() as Schema)
        },
        extensions: [
            minimumExtension,
            configFunc(config)
        ].flat(),
        content,
    });
    return (
        <div ref={wrapperRef} style={{position: 'relative'}}>
            <div className={[styles.editor, editor?.isFocused ? styles.editorFocused : ''].join(' ')}>
                <EditorToolbar config={config} editor={editor} wrapper={wrapperRef}/>
                <EditorContent className={styles.editor__content} editor={editor}/>
            </div>
        </div>
    );
}

export default ContentEditor;
