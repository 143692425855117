import {Message} from '@pixel-kraft/commulino-types';
import {ActivityIndicator, ChatButton, Modal} from 'components/index';
// import {firestore} from 'lib/firebase';
import useWindowDimensions from 'lib/useWindowDimensions';
import React, {useEffect, useState} from 'react'
import {FiX} from 'react-icons/fi';
import {t_WebChat} from 'store/chat/types';
import {useSelector} from "react-redux";
import {RootState} from "store/reducers";
import {getInvertColor} from "lib/colors";
import {firebaseApp} from "lib/firebase";
import {doc, getDoc, getFirestore} from "firebase/firestore";

type t_props = {
    Chat: t_WebChat,
    msg: Message | undefined,
    onGoBack: () => void,
}
/**
 * Render eine Overlay in dem steht wer die Nachricht gelesen hat und wer nicht.
 */
const ReadByOverlay = (props: t_props) => {
    const {height} = useWindowDimensions();
    const [isLoading, setIsLoading] = useState(true);
    const [readBY, setReadBY] = useState<string[]>([]);
    const color = useSelector((state: RootState) => state.config.mainColor)
    useEffect(() => {
        setIsLoading(true);
        const get = async () => {
            if(props.msg?.id) {
                const r = await getDoc(doc(getFirestore(firebaseApp), 'chats', props.Chat.id, 'messages', props.msg?.id));
                const result = r.data() as Message;
                setReadBY(result.readBy);
            }
            setIsLoading(false);
        }
        get();

    }, [props.Chat.id, props.msg?.id])
    const getUserNameBYuserID = (id: string) => {
        for (let i = 0; i < props.Chat.users.length; ++i) {
            if (props.Chat.users[i].uid === id)
                return props.Chat.users[i].name;
        }
        return undefined;
    }
    const readNames = () => {
        return readBY.filter(val => {
            return val !== props.msg?.uid;

        }).map((val) => {
            return getUserNameBYuserID(val) as string;
        });
    }
    /**
     *   Gibt die Namen der User aus die die Nachricht nicht Gelesen haben!
     *   Wenn readBY undefiniert ist werden alle Usernamen in der Gruppe ausgeben.
     */
    const notReadNames = () => {
        return (readBY !== undefined ? props.Chat.uids.filter(val => {
            return !readBY.includes(val);
        }) : props.Chat.uids).map(val => {
            return getUserNameBYuserID(val) as string;
        });
    }
    const rendersName = (names: string[]) => {
        if (names.length === 0) {
            return (
                <div key={"leer"}>-</div>
            )
        }
        return (
            names.map((val, i) => {
                return (<div key={i}>{val}</div>)
            })
        )
    }
    return (
        <Modal hide={props.onGoBack} isVisible={true} flexWidth>
            <div style={{
                padding: 10,
                overflowY: 'auto',
                height: ((height + 100) / 2) - 40,
                marginTop: 10,
                minWidth: 300,
                position: "relative"
            }}>
                <ChatButton style={{
                    backgroundColor: color,
                    color: getInvertColor(color, true),
                    top: 0,
                    width: 30,
                    borderRadius: '50%',
                    position: 'absolute',
                    right: 10
                }} icon={FiX} onClick={() => props.onGoBack()}/>
                <div style={{fontWeight: 'bolder'}}>Gelesen von</div>
                {isLoading && <ActivityIndicator/>}
                {!isLoading && rendersName(readNames())}
                <div style={{fontWeight: 'bolder'}}>Nicht Gelesen von</div>
                {isLoading && <ActivityIndicator/>}
                {!isLoading && rendersName(notReadNames())}
            </div>
        </Modal>
    )
}
export default ReadByOverlay;
