import React, {MutableRefObject, useEffect, useRef} from "react";
import {t_MyRef} from "components/DropDownData";
import DropDownData from "./DropDownData";

export type DropDownDataWrapperRef={
    open: ()=>void,
    close: ()=>void
}

const DropDownDataWrapper=(props:Omit<React.ComponentProps<typeof DropDownData>,"myRef"|"ref">&{show:boolean,wrapperRef?: React.ForwardedRef<DropDownDataWrapperRef>})=>{
    const ref=useRef<t_MyRef>(null);

    useEffect(()=>{
        if(ref.current) ref.current[(props.show?"open":"close")]();
    },[props.show,ref])

    useEffect(()=>{
        if(props.wrapperRef){
            (props.wrapperRef as MutableRefObject<DropDownDataWrapperRef | null>).current = {
                open: () => ref.current?.open(),
                close: () => ref.current?.close(),
            }
        }
    },[props.wrapperRef,ref])


    return (
        <DropDownData
            myRef={ref}
            {...props}
        />
    )


}
export default DropDownDataWrapper;
