import React, { useState, useEffect } from 'react'
import { FiCheck } from 'react-icons/fi'
import URLSearchParams from '@ungap/url-search-params'
import { useLocation } from 'react-router-dom'
import * as api from 'lib/api'
import {
	ActivityIndicator,
	ColorButton,
	ContentContainer,
	ErrorMessageInline,
	FormItem,
	Typo
} from 'components'
import styles from './index.module.css'

import appStoreLogo from '../../assets/appstore-logo.png'
import googlePlayLogo from '../../assets/play-logo.png'

import { logo, appName, appStoreUrl, playStoreUrl } from 'config'

const Register = () => {
	const location = useLocation()
	const [uid, setUid] = useState('')
	const [email, setEmail] = useState('')
	const [name, setName] = useState('')
	const [password, setPassword] = useState('')
	const [passwordCheck, setPasswordCheck] = useState('')
	const [loading, setLoading] = useState(true)
	const [passwordTooShort, setPasswordTooShort] = useState(false)
	const [passwordNotIdentical, setPasswordNotIdentical] = useState(false)
	const [missingParams, setMissingParams] = useState(false)
	const [success, setSuccess] = useState(false)

	async function register() {
		setPasswordNotIdentical(false)

		if (password.length < 6) return setPasswordTooShort(true)
		if (password !== passwordCheck) return setPasswordNotIdentical(true)

		setLoading(true)

		try {
			await api.post('users/register', { uid, email, name, password })
		} catch (err) {
			console.error(err)
		}

		setLoading(false)
		setSuccess(true)
	}

	useEffect(() => {
		const params = new URLSearchParams(location.search)

		const email = params.get('e')
		const name = params.get('n')
		const uid = params.get('i')

		if (!email || !name || !uid) {
			return setMissingParams(true)
		}

		setEmail(email)
		setName(name)
		setUid(uid)
		setLoading(false)
	}, [location.search])

	return (
		<ContentContainer style={styles.outer}>
			<div className={styles.container}>
				<img src={(logo as unknown) as string} alt='logo' className={styles.logo} />
				{loading && !missingParams && <ActivityIndicator />}
				{!loading && !success && (
					<React.Fragment>
						<Typo variant='h4' br>
							{'Hallo ' + name + ','}
						</Typo>
						<Typo>
							geben Sie ein Passwort ein und klicken Sie auf „Absenden“ um die Registrierung
							abzuschließen.
						</Typo>
						<FormItem name='Passwort' type='password' value={password} onChange={setPassword} />
						<FormItem
							name='Passwort bestätigen'
							type='password'
							value={passwordCheck}
							onChange={setPasswordCheck}
						/>
						<ErrorMessageInline
							text='Passwörter stimmen nicht überein.'
							visible={passwordNotIdentical}
							style={styles.errorMessage}
						/>
						<ColorButton
							onClick={register}
							text='Absenden'
							icon={FiCheck}
							style={{height: 40,marginLeft: 12}}
						/>
						<ErrorMessageInline
							text='Das Passwort muss aus mindestens 6 Zeichen bestehen.'
							visible={passwordTooShort && password.length < 6}
							style={styles.errorMessage}
						/>
					</React.Fragment>
				)}
				{!loading && success && (
					<React.Fragment>
						<Typo variant='h4'>
							{`Registrierung abgeschlossen! Sie können sich jetzt in der ${appName}-App anmelden.`}
						</Typo>
					</React.Fragment>
				)}
				<ErrorMessageInline
					text='Fehlerhafte Anfrage. Bitte öffnen Sie den Link aus der Registrierungs-Email erneut.'
					visible={missingParams}
				/>
				<Typo variant='h4' style={styles.text}>
					App noch nicht installiert?
				</Typo>
				<a href={appStoreUrl} target='_blank' rel='noopener noreferrer'>
					<img src={appStoreLogo} alt='AppStore Logo' className={styles.storeLogo} />
				</a>
				<a href={playStoreUrl} target='_blank' rel='noopener noreferrer'>
					<img src={googlePlayLogo} alt='AppStore Logo' className={styles.storeLogo} />
				</a>
			</div>
		</ContentContainer>
	)
}

export default Register
