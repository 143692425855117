import React, {useEffect, Suspense, lazy} from 'react'
import {useHistory, Route, BrowserRouter as Router} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {ActivityIndicator} from 'components'
import styles from './Navigation.module.css'

import AuthRoute from './AuthRoute'
import Menu from './Menu'

import {RootState} from 'store/reducers'
import {removeAllListner, setChatsListner, toDefault} from 'store/chat/actions'
import {stopAllListener as stopFolders} from 'store/folders/actions'
import {Posts_start, resetPosts} from 'store/posts/actions'
import {attachBadgeListener} from 'lib/media'

import config, {logoLightSvg as logo} from 'config'
import {UsersSDK, AuthSDK, TodoListSDK} from "lib/sdk";
import {isPushNotificationSupported, setToken} from 'lib/push_notification'
import {getUserProfiles, stopListener} from "store/userProfiles/actions";
import {onAuthStateChanged} from "firebase/auth";
import {doc, getDoc} from "firebase/firestore";

import {auth, firestore} from "lib/firebase";
import {useAppDispatch} from "store/hooks";
import {clearMenu, syncCostumenMenu} from "store/config/actions";
import {MediCareAuth, Login} from "pages/index";

const MyChat = lazy(() => import('../pages/chat'));
const Posts = lazy(() => import('../pages/posts/index'));
const Users = lazy(() => import('../pages/users/index'));
const Settings = lazy(() => import('../pages/settings/index'));
const UserSettings = lazy(() => import('../pages/UserSettings/index'));
const ResidentDirectory = lazy(() => import('../pages/residentDirectory/index'));
const PersonalMsg = lazy(() => import('../pages/personalMSG/index'));
const FuerDichCalendar = lazy(() => import('../pages/fuerDichCalendar/index'))
const FuerDichPinnwand = lazy(() => import('../pages/fuerDichPinnwand/index'));
const FuerDichFolders = lazy(() => import('../pages/fuerDichFolders/index'));
const MenuPlans = lazy(() => import('../pages/menuplans/index'));
const Impressum = lazy(() => import('../pages/impressum/index'));
const Datenschutz = lazy(() => import('../pages/datenschutz/index'));
const Data = lazy(() => import('../pages/settings/data/index'));
const Layout = lazy(() => import('../pages/settings/layout/index'));
const RolesEdit = lazy(() => import('../pages/settings/roles/edit/index'));
const RolesNew = lazy(() => import('../pages/settings/roles/new/index'));
const Roles = lazy(() => import('../pages/settings/roles/index'));
const ResAndRelSettings = lazy(() => import('../pages/settings/resAndRel/index'));
const UsersEdit = lazy(() => import('../pages/users/edit/index'));
const UsersAdd = lazy(() => import('../pages/users/add/index'));
const Onboarding = lazy(() => import('../pages/onboarding/index'));
const OnboardingEdit = lazy(() => import('../pages/onboarding/edit/index'));
const RegisterByCode = lazy(() => import('../pages/register-by-code/index'));
const Register = lazy(() => import('../pages/register/index'));
const Feed = lazy(() => import('../pages/feed/index'));
const Folders = lazy(() => import('../pages/folders/index'));
const Kalender = lazy(() => import('../pages/Kalender/index'));
const ForgotPassword = lazy(() => import('../pages/forgot-password/index'));
const EmailVerification = lazy(() => import('../pages/verify/index'))
const Dashboard = lazy(() => import('../pages/folders/components/Dashboard'));
const costumeMenuConfig = lazy(() => import('../pages/settings/costumeMenuConfig/index'));
const CostumeMenu = lazy(() => import('pages/costumeMenu/costumeMenu'));
const Pinnwand = lazy(() => import('../pages/infotouch/pinnwand/index'));
const InfosystemSettings = lazy(() => import('pages/infosystem/infoSystemConfig/InfosystemConfig'));
const PlayListsEditor = lazy(() => import('pages/infosystem/infoSystemConfig/PlayListsEditor'));
const DeviceSettings = lazy(() => import('pages/infosystem/infoSystemConfig/DeviceSettings'))
const TodoList = lazy(() => import('pages/todoList/todoList'));
const ResidentList = lazy(() => import('pages/residentAndRelatives/index'));

const Navigation = () => {
    const history = useHistory()
    const dispatch = useAppDispatch()
    const {loading, isLoggedIn, permissions, loginInProcess} = useSelector((state: RootState) => state.auth)
    const loadConfig = useSelector((state: RootState) => state.config.loading)
    const costumeMenu = useSelector((state: RootState) => state.config.costumeMenu);
    useEffect(() => {
        AuthSDK.functions.afterLogin(
            () => dispatch(setChatsListner()),
            () => dispatch(attachBadgeListener()),
            () => dispatch(Posts_start()),
            () => dispatch(getUserProfiles()),
            () => dispatch(syncCostumenMenu()),
            () => dispatch(TodoListSDK.functions.startListener()),
        );
        if (isPushNotificationSupported()) AuthSDK.functions.afterLogin((uid) => setToken(uid));

        AuthSDK.functions.beforeLogout(
            () => dispatch(removeAllListner()),
            () => dispatch(resetPosts()),
            () => dispatch(stopListener()),
            () => dispatch(stopFolders()),
            () => dispatch(clearMenu()),
            () => dispatch(toDefault()),
            () => TodoListSDK.functions.stopListener()
        )

        const unsubscribe = onAuthStateChanged(auth, async user => {
            if (user) {
                const {uid} = user
                const db = firestore;

                let {
                    name,
                    role,
                    permissions = {},
                    groups,
                    email,
                    photo,
                    contactFor,
                    mediCare,
                    personnelId
                } = await getDoc(doc(db, 'users', uid))
                    .then(snapshot => snapshot.data() as UsersSDK.t_User);

                if (role) {
                    const snapshot = await getDoc(doc(db, 'roles', role));
                    permissions = snapshot.get('permissions');

                }

                return dispatch(AuthSDK.functions.login({
                    uid,
                    permissions,
                    name,
                    groups,
                    email,
                    photo,
                    contactFor,
                    mediCare,
                    personnelId
                }))
            } else
                dispatch(AuthSDK.functions.logout())
        })

        return () => unsubscribe()

    }, [dispatch])

    useEffect(() => {
        if (history.location.pathname === '/login' && isLoggedIn && !loginInProcess) {
            history.replace('/feed')
        }
    }, [history.location.pathname, isLoggedIn, loginInProcess])


    if (loading || loadConfig || loginInProcess)
        return (<div className={styles.loadingScreen}>
            <img width={200} height={33} src={logo} className={styles.logo} alt='CommuLino Logo'
                 style={{filter: 'invert(20%)'}}/>
            <ActivityIndicator large style={styles.activeColor}/>
        </div>)

    const accessCalendar = config.calendar && !!(permissions?.calender?.write || permissions?.calender?.read);
    const seeFolder = !!(permissions.files?.user?.write || permissions.files?.user?.read)
    return (
        <Router>
            <div className={styles.app}>
                {isLoggedIn && <Menu logout={async () => {
                    await dispatch(AuthSDK.functions.logout());
                    history.replace('/login');
                }}/>}
                <Suspense
                    fallback={<div style={{position: "absolute", margin: "auto"}}><ActivityIndicator large/></div>}>
                    <Route path='/login' exact render={() => <Login/>}/>
                    <Route path='/mediCareAuth' exact render={() => <MediCareAuth/>}/>
                    <Route path='/register' component={Register}/>
                    <Route path='/register-by-code' component={RegisterByCode}/>
                    <Route path='/verify' component={EmailVerification}/>
                    <Route path='/forgot-password' component={ForgotPassword}/>
                    <AuthRoute path='/' component={Feed} auth={isLoggedIn}/>
                    <AuthRoute path='/feed' component={Feed} auth={isLoggedIn}/>
                    <AuthRoute path={'/usersettings'} component={UserSettings} auth={isLoggedIn}/>
                    <AuthRoute path='/posts' component={Posts} auth={!!permissions.posts?.backend}/>

                    <Route path='/messenger'>
                        {/* Must be render if permission because its needs to load the sub nav */}
                        <MyChat/>
                    </Route>

                    <AuthRoute path='/datenschutz' component={Datenschutz} auth={isLoggedIn}/>
                    <AuthRoute path='/impressum' component={Impressum} auth={isLoggedIn}/>
                    <AuthRoute path='/menuplan' component={MenuPlans} auth={isLoggedIn}/>
                    <AuthRoute path='/personalmessages' component={PersonalMsg} auth={isLoggedIn}/>
                    <AuthRoute path='/fuerDichKalender' component={FuerDichCalendar} auth={isLoggedIn}/>
                    <AuthRoute path='/fuerDichPinnwand' component={FuerDichPinnwand} auth={isLoggedIn}/>
                    <AuthRoute path='/users' component={Users} auth={!!permissions.manage_users}/>
                    <AuthRoute path='/users/add' component={UsersAdd} auth={!!permissions.manage_users}/>
                    <AuthRoute path='/users/edit' component={UsersEdit} auth={!!permissions.manage_users}/>
                    {config.files &&
                        <>
                            <AuthRoute path='/dateien' component={FuerDichFolders}
                                       auth={!!permissions.files?.user}/>
                            <AuthRoute path='/dateien/dash' component={Dashboard} auth={!!permissions.files?.user}/>
                            <AuthRoute path='/dateien/dash/:rootFolderId' component={Dashboard}
                                       auth={!!permissions.files?.user}/>
                            <AuthRoute path='/dateien/dash/:rootFolderId/:folderId' component={Dashboard}
                                       auth={!!permissions.files?.user}/>

                            <AuthRoute path='/dateiverwaltung' component={Folders}
                                       auth={!!permissions.files?.group.write}/>
                            <AuthRoute path='/dateiverwaltung/dash' component={Dashboard}
                                       auth={!!permissions.files?.group.write}/>
                            <AuthRoute path='/dateiverwaltung/dash/:rootFolderId' component={Dashboard}
                                       auth={!!permissions.files?.group.write}/>
                            <AuthRoute path='/dateiverwaltung/dash/:rootFolderId/:folderId' component={Dashboard}
                                       auth={!!permissions.files?.group.write}/>
                        </>
                    }
                    {/*{Infosystem &&
                <>

                    <AuthRoute path='/infosystem' component={InfoSystem} auth={!!permissions.infosystem}/>
                    <AuthRoute path='/infosystem/galerie' component={Galerie} auth={!!permissions.infosystem}/>
                    <AuthRoute path='/infosystem/menu' component={InfoMenu} auth={!!permissions.infosystem}/>

                </>
                }*/}
                    {accessCalendar &&
                        <AuthRoute path='/kalender' component={Kalender} auth={accessCalendar}/>
                    }
                    {config.onboardingEnabled && (
                        <>
                            <AuthRoute path='/onboarding' component={Onboarding} auth={!!permissions.manage_users}/>
                            <AuthRoute
                                path='/onboarding/edit'
                                component={OnboardingEdit}
                                auth={!!permissions.manage_users}
                            />
                        </>
                    )}
                    {config.BewohnerVerzeichnis && (
                        <AuthRoute path={'/residentDirectory'} component={ResidentDirectory}
                                   auth={!!permissions.residentDirectory}/>
                    )}
                    <AuthRoute path='/settings' component={Settings} auth={!!permissions.edit_settings}/>
                    <AuthRoute path='/settings/roles' component={Roles} auth={!!permissions.edit_settings}/>
                    <AuthRoute
                        path='/settings/roles/new'
                        component={RolesNew}
                        auth={!!permissions.edit_settings}
                    />
                    <AuthRoute
                        path='/settings/roles/edit'
                        component={RolesEdit}
                        auth={!!permissions.edit_settings}
                    />
                    <AuthRoute path='/settings/layout' component={Layout} auth={!!permissions.edit_settings}/>
                    <AuthRoute path='/settings/data' component={Data} auth={!!permissions.edit_settings}/>
                    <AuthRoute path='/settings/data/:type' component={Data} auth={!!permissions.edit_settings}/>
                    {(config as any).residents && <>
                        <AuthRoute path={'/settings/resAndRel'} component={ResAndRelSettings} auth={isLoggedIn}/>
                    </>}
                    {config.Infotouch &&
                        <AuthRoute path={'/infotouch/pinnwand'} component={Pinnwand}
                                   auth={!!permissions.infotouch?.write}/>
                    }
                    <AuthRoute path={'/settings/costumeMenu'} component={costumeMenuConfig}
                               auth={!!permissions.edit_settings}/>
                    {costumeMenu &&
                        <>
                            <AuthRoute path={'/costumeMenu/:pointId'} component={CostumeMenu} auth={isLoggedIn}/>
                        </>
                    }
                    {config.Infosystem &&
                        <>
                            <AuthRoute path={'/infosystem/settings'} component={InfosystemSettings}
                                       auth={!!permissions.infosystem}/>
                            <AuthRoute path={'/infosystem/settings/device'} component={DeviceSettings}
                                       auth={!!permissions.infosystem}/>
                            <AuthRoute path={'/infosystem/settings/playlists'} component={PlayListsEditor}
                                       auth={!!permissions.infosystem}/>
                        </>
                    }

                    {(config as any).residents &&
                        <AuthRoute path={'/residentAndRelatives'} component={ResidentList}
                                   auth={!!(permissions as any)?.residentList}/>}
                    {(config as any).todoList &&
                        <AuthRoute path={'/todolist'} component={TodoList} auth={isLoggedIn}/>
                    }
                </Suspense>
            </div>
        </Router>
    )
}

export default Navigation
