import React from "react";
import styles from "./ProgressRing.module.css";

type t_props = {
    radius: number,
    stroke: number,
    progress: number,
    color?: string,
    fill?: string,
}

const ProgressRing = ({progress, stroke, radius,color,fill}: t_props) => {
    const normalizedRadius = radius - stroke * 2;
    const circumference = normalizedRadius * 2 * Math.PI;
    const strokeDashoffset = circumference - progress / 100 * circumference;

    return (
        <svg
            height={radius * 2}
            width={radius * 2}
            className={styles.ring}
        >
            <circle
                stroke={color??"gray"}
                fill={fill??"transparent"}
                strokeWidth={stroke}
                strokeDasharray={circumference + ' ' + circumference}
                style={{strokeDashoffset}}
                r={normalizedRadius}
                cx={radius}
                cy={radius}
            />
        </svg>
    );

}
export default ProgressRing;
