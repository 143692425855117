import {Message} from "@pixel-kraft/commulino-types";
import {firebaseApp} from "lib/firebase";
import {
    getFirestore,
    addDoc,
    setDoc,
    doc,
    updateDoc,
    collection
} from "firebase/firestore";


const forwardToChat=async (to: string,msg:Message,uid:string)=>{
    delete msg.replyTo;
    msg.forwarded=true;
    msg.date=Date.now();
    msg.uid=uid;
    msg.readBy=[uid];
    const db=getFirestore(firebaseApp);
    await addDoc(collection(db,'chats',to,'messages'),msg);
    await updateDoc(doc(db,'chats',to),{
        readBy: [uid],
        date: Date.now()
    })
    setDoc(doc(db,'users',uid,'counters','chat:' + to),{
        'unread': 0
    })
}

export default forwardToChat;
