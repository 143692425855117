import React from 'react';
import AddBewohner from "./AddBewohner";
import {residentDirectory} from "@pixel-kraft/commulino-types";
import {editBewohner} from "./action";
import {ChatButton} from "components";
import {FiX} from "react-icons/fi";

type t_params = {
    data: residentDirectory,
    onSuccess?:()=>void,
    onCancel?:()=>void,
}

const EditBewohner=(params:t_params)=>{
    return (
        <div>
            <h1 style={{marginLeft: 10}}>Bearbeiten</h1>
            <ChatButton onClick={()=>{if(params.onCancel) params.onCancel()}} style={{borderRadius: '50%',width:'max-content',position: 'absolute',top: 10,right: 10}} icon={FiX}/>
            <AddBewohner
                onCancel={params.onCancel}
                onSuccess={params.onSuccess}
                onAdd={(d) => editBewohner(params.data.ID, {...d})}
                data={{...params.data}}
                addButtonText={"Speichern"}
            />
        </div>
    );
}

export default EditBewohner;
