import React from 'react'
import Dropzone from 'react-dropzone'
import { FiX } from 'react-icons/fi'
import { Button } from '.'
import styles from './DragDropZone.module.css'

interface Props {
	text: string
	files: File[]
	accept?: string | string[]
	style?: string
	onChange: (files: File[]) => void
}

const DragDropZone = ({ text, files, accept, style, onChange }: Props) => {
	const onFileChange = (newFiles: File[]) => {
		const change = newFiles.filter(
			newFile => files.filter(file => file.name === newFile.name).length === 0
		)
		onChange([...files, ...change])
	}

	const removeFile = (fileName: string) => {
		const update = files.filter(file => file.name !== fileName)
		onChange(update)
	}

	return (
		<Dropzone onDrop={onFileChange} accept={accept}>
			{({ getRootProps, getInputProps }) => (
				<div className={style}>
					<div {...getRootProps({ className: 'dropzone' })} className={styles.dropzone}>
						<input {...getInputProps()} />
						<div>{text}</div>
					</div>
					<div className={styles.filesContainer}>
						{files.map((file: File) => (
							<div key={file.name} className={styles.file}>
								<Button onClick={() => removeFile(file.name)} icon={FiX} light />
								<div className={styles.fileName}>{file.name}</div>
							</div>
						))}
					</div>
				</div>
			)}
		</Dropzone>
	)
}

export default DragDropZone
