import {getFunctions, httpsCallable} from "firebase/functions";
import {firebaseApp} from "lib/firebase";
import store from 'store/reducers'
import {setLoginToken} from "store/auth/actions";
import {addMinutes} from "date-fns";

export const getLoginTokenFromServer = async ()=> {
    const {data: token} = await httpsCallable<unknown,string>(getFunctions(firebaseApp,'europe-west1'),'genLoginToken')();
    return token;
}

const getLoginToken = async () => {
    const lToken= store.getState().auth.loginToken;
    if(lToken&&lToken.timeOut>Date.now()) return lToken.token;
    const newToken = await getLoginTokenFromServer();
    //A token expire after a hour so 45min should be safe
    store.dispatch(setLoginToken(newToken,addMinutes(Date.now(),45).valueOf()));
    return newToken;
}



export default getLoginToken;
