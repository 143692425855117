import React from 'react'
import { Moment } from 'moment'
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
require('moment/locale/de')
import "pages/Kalender/components/dateTimePickers/datetime.css"

interface Props {
	value: string | Moment | Date | undefined,
	onChange: (date: Moment | string) => void,
	isValidDate?: boolean,
	style?: string,
	dateFormat?: boolean| string,
	timeFormat?: boolean|string,
	id?:string,
	setShow?:(to:boolean)=>void
}

const DateTimePickerComp = ({ value, isValidDate, onChange, style,dateFormat,timeFormat, id, setShow}: Props) => {
	return (
		<Datetime
			value={value}
			className={`${style}`}
			onChange={onChange}
			isValidDate={() => {
				if (isValidDate === undefined) return true;
				return isValidDate
			}}
			input={id === "kalendar"}
			locale={'de'}
			dateFormat={dateFormat}
			timeFormat={timeFormat}
			onOpen={()=>{
				setShow && setShow(true)
			}}
			onClose={()=>{
				setShow && setShow(false)
			}}
		/>
	)
}

export default DateTimePickerComp;

