import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getTemplates } from 'store/onboarding/actions'
import { RootState } from 'store/reducers'
import { useHistory } from 'react-router-dom'
import { FiPlus } from 'react-icons/fi'
import moment from 'moment'
import { Button, ContentContainer, DynamicTable, PageTitle } from 'components'

const Onboarding = () => {
	const history = useHistory()

	const dispatch = useDispatch()
	const loading = useSelector((state: RootState) => state.onboarding.loading)
	const templates = useSelector((state: RootState) => state.onboarding.templates)

	useEffect(() => {
		dispatch(getTemplates())
		// eslint-disable-next-line
	}, [])

	return (
		<ContentContainer loading={loading}>
			<PageTitle
				text='Onboarding'
				button={
					<Button
						onClick={() => history.push('/onboarding/edit')}
						text='Vorlage hinzufügen'
						icon={FiPlus}
						large
					/>
				}
			/>
			<DynamicTable
				headers={['Titel', 'Beiträge', 'Erstellt am']}
				rows={
					Object.values(templates).map(template => ({
						key: template.id,
						cells: [
							{ content: template.title },
							{ content: template.posts.length },
							{ content: moment(template.createdAt).format('DD.MM.YYYY') }
						]
					})) ?? []
				}
				onRowClick={row => history.push('/onboarding/edit', { id: row.key })}
			/>
		</ContentContainer>
	)
}

export default Onboarding
