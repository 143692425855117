import React, {lazy} from 'react';
import MessageFileElm from "components/MessageFile/MessageFileElm";

const MessageForwardFile=lazy(() => import('./MessageForwardFile'))

type t_props = {
    id?: string,
    chatID?: string,
    text: string,
    file: string,
    forwarded: boolean,
    onDownload?: (url:string)=>void,
    reply?: boolean,
    getCursor: (fun:any)=>React.CSSProperties
}

const MessageFile=({file,chatID,id,onDownload,reply,forwarded,text,getCursor}:t_props)=>{

    if(reply!==true&&id&&chatID&&forwarded&&!file.includes(chatID)) return (<MessageForwardFile file={file} chatID={chatID} id={id} text={text} onDownload={onDownload} getCursor={getCursor} />);
    else return (<MessageFileElm reply={reply===true} getCursor={getCursor} uri={file} loading={false} text={text} onDownload={onDownload} />);
}

export default MessageFile;