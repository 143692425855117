import {ConfigState, ConfigActionTypes, CONFIG_REQUEST, CONFIG_RECEIVE} from './types'
import {getInvertColor} from "lib/colors";

const initialState: ConfigState = {
    categories: [],
    departments: [],
    locations: [],
    loading: false,
    mainColor: "rgba(179,179,179,1)",
    mainTextColor: 'rgba(0,0,0,1)',
    subColor: "rgba(0,82,204,1)",
    subTextColor: "rgba(255,255,255,1)",
    impressum: "",
    datenschutz: "",
    stellenanzeige: "",
    verbesserungsvorschlag: "",
    posts: {
        job: {
            email: "",
            uid: "",
        }
    }
}

export default function config(state = initialState, action: ConfigActionTypes): ConfigState {
    switch (action.type) {
        case CONFIG_REQUEST:
            return {
                ...state,
                loading: true
            }
        case CONFIG_RECEIVE:
            const {type: _, ...config} = action
            return {
                ...state,
                ...config,
                loading: false
            }
        case "CLEARMENU":
            return {
				...state,
                costumeMenu: undefined
			};
        case "SETMAINCOLOR":
            return {
                ...state,
                mainColor: action.color,
                mainTextColor: getInvertColor(action.color)
            }
        case "SETSUBCOLOR":
            return {
                ...state,
                subColor: action.color,
                subTextColor: getInvertColor(action.color)
            }
        case "SETDATENSCHUTZ":
            return {
                ...state,
                datenschutz: action.datenschutz
            }
        case "SETIMPRESSUM":
            return {
                ...state,
                impressum: action.impressum
            }
        case "SETJOBEMAIL":
            return {
                ...state,
                posts: {
                    ...state.posts,
                    job: {
                        uid: "",
                        email: action.email
                    }
                }
            }
        case "SETJOBUID":
            return {
                ...state,
                posts: {
                    ...state.posts,
                    job: {
                        email: "",
                        uid: action.uid
                    }
                }
            }
        case "SETPOSTSCONFIG":
            return {
                ...state,
                posts: {...action.posts}
            }
        case "SETCOSTUMENMENU":
            return {
                ...state,
                costumeMenu: action.costumeMenu
            }
        case "SETMENUPOINT":
            return {
                ...state,
                costumeMenu: {
                    ...state.costumeMenu,
                    menuPoint: action.menuPoint
                }
            }
        case "SETSUBPOINTS":
            return {
                ...state,
                costumeMenu: {
                    ...state.costumeMenu,
                    subPoints: action.subPoints
                }
            }
        default:
            return state
    }
}
