import {FoldersActionTypes, FoldersState, RootFolder} from "store/folders/types";

export const initialState: FoldersState = {
    folderId: null,
    folder: null,
    childFolders:[],
    childFiles:[],
    rootFolderId: "",
    listener: {
        childFiles: ()=>null,
        childFolders: ()=>null,
    }
}

export const ROOT_FOLDER: RootFolder = {
    name: "User",
    id: null,
    path: [],
    deleteId: [],
    child: false,
    departments: [],
    groups: [],
    locations: []
}

export default function folders (state = initialState, action: FoldersActionTypes): FoldersState {
    switch (action.type) {
        case "SELECT_FOLDER":
            return {
                ...state,
                folderId: action.folderId,
                folder:  action.folder,
            }
        case "UPDATE-FOLDER": {
            return {
                ...state,
                folder: {...action.folder},
            }
        }
        case "SET-CHILD-FOLDERS":
            return {
                ...state,
                childFolders: [...action.childFolders],
            }
        case "SET-CHILD-FILES":
            return {
                ...state,
                childFiles: [...action.childFiles],
            }
        case "SET_FOLDER_ID":
            return {
                ...state,
                folderId: action.folderId,
            }
        case "SET_CHILD_EMPTY":
            return {
                ...state,
                childFolders: action.childFolders,
            }
        case "SET_ROOTFOLDERID":
            return {
                ...state,
                rootFolderId: action.rootFolderId
            }
        case "SET_CHILD_FILES_LISTENER": {
            state.listener.childFiles(); //Cancel old snapshot
            return {
                ...state,
                listener: {
                    ...state.listener,
                    childFiles: action.listener
                }
            }
        }
        case "SET_CHILD_FOLDERS_LISTENER":{
            state.listener.childFiles(); //Cancel old snapshot
            return {
                ...state,
                listener: {
                    ...state.listener,
                    childFolders: action.listener
                }
            }
        }
        case "STOP_ALL_LISTENER":{
            Object.keys(state.listener).forEach((l)=>state.listener[l as keyof typeof state.listener]());
            return {
                ...state,
                listener: initialState.listener
            }
        }
        default:
            return state
    }
}
