import {AuthState, AuthActionTypes, AUTH_LOGIN, AUTH_LOGOUT, AUTH_UPDATE_IMAGE} from './types'

const initialState: AuthState = {
	loading: true,
	isLoggedIn: false,
	uid: null,
	name: null,
	permissions: {
		edit_settings: false,
		manage_users: false,
		messenger: {
			enabled: false,
			start_chat: false
		},
		posts: {
			backend: false,
			categories: [],
			in_app: false,
		},
		infosystem: false
	},
	groups: [],
	email: "",
	photo: "",
	loginInProcess: false
}

export default function auth(state = initialState, action: AuthActionTypes): AuthState {
	switch (action.type) {
		case AUTH_LOGIN:
			return {
				...state,
				loading: false,
				isLoggedIn: true,
				uid: action.uid,
				name: action.name,
				permissions: action.permissions,
				groups: action.groups,
				email: action.email,
				photo: action.photo,
				contactFor: action.contactFor,
				mediCare: action.mediCare,
				personnelId: action.personnelId
			}
		case AUTH_LOGOUT:
			return {
				...initialState,
				loading: false
			}
		case AUTH_UPDATE_IMAGE:
			return {
				...state,
				photo: action.photo
			}
		case "AUTH_SET_TOKEN":
			return {
				...state,
				loginToken: {
					token: action.token,
					timeOut: action.timeOut
				}
			}
		case "SETLOGININPROCESS":
			return {
				...state,
				loginInProcess: action.value
			}
		default:
			return state
	}
}
