import React from 'react'
import { Post } from '@pixel-kraft/commulino-types'

type t_props = {
	post: Post
}

const Author = (props: t_props) => {
	return <div>{props.post.author?.name}</div> //Im Onboarding bei Demo ist der Author nicht immer gesetzt (/onboarding-templates/JGFGMtRqStxN5Trotkq4 ab index 1)
}
export default Author
