import React, {useEffect, useState} from 'react';
import {Button, ColorButton, Modal, Row, Tag, Typo} from "components";
import {ResRelSDK} from 'lib/sdk';
import LoadRelatives from "./LoadRelatives";
import {FiCheck, FiUserMinus, FiUserPlus, FiX} from "react-icons/fi";
import {useAppDispatch, useAppSelector} from "store/hooks";
import {filterTypeChange, getIndexOf} from "lib/helpers";
import styles from './GroupCreateDialog.module.css';
import {useHistory} from "react-router-dom";
import {t_RelContact, t_ResContact} from "@pixel-kraft/commulino-types";

type t_props = {
    value?: t_ResContact,
    hide: () => void
}

const GroupCreateDialog = ({value, hide}: t_props) => {

    const [selectedRelatives, setSelectedRelatives] = useState<t_RelContact[]>([]);
    const [includeRes, setIncludeRes] = useState(true);
    const {name, uid, photo} = useAppSelector((state) => state.auth);
    const [groupUsers, setGroupUsers] = useState(1);
    const dispatch = useAppDispatch();
    const history = useHistory();

    useEffect(() => {
        if (value?.relatedContacts) {
            setSelectedRelatives(filterTypeChange(value.relatedContacts,(rel)=>{
                if(rel.type==="relative") return rel;
            }));
        }
    }, [value])

    useEffect(() => {
        setGroupUsers(selectedRelatives.length + (includeRes ? 1 : 0) + 1);
    }, [includeRes, selectedRelatives])

    const isSelected = (uid: string) => getIndexOf(selectedRelatives, ({uid: _uid}) => _uid === uid) > -1;
    const removeRelative = (uid: string) => {
        const index = getIndexOf(selectedRelatives, ({uid: _uid}) => _uid === uid);
        if (index < 0) return;
        const tmp = [...selectedRelatives];
        tmp.splice(index, 1);
        setSelectedRelatives(tmp);
    }

    const addRelative = (user: t_RelContact) => {
        const tmp = [...selectedRelatives];
        tmp.push(user);
        setSelectedRelatives(tmp);
    }

    const createChat = async () => {
        if (!value || !uid || !name) return;
        const old = await ResRelSDK.checkIfChatExists(value.residentId);
        if (old&&!old.uids.includes(uid)) {
            await ResRelSDK.joinChat(old);
        }
        const id = old ? old.id : await dispatch(ResRelSDK.createChat(
            [value, ...selectedRelatives, ResRelSDK.convertToContact({
                name,
                uid,
                photo
            })],
            'relativeChat',
            {name: value.name},
            includeRes
        ));
        history.push(`/messenger/${id}`);
    }

    return (
        <Modal dataMustBeSet={value} hide={hide} modalStyle={styles.modalContent}>
            {(user) =>
                <div style={{padding: 10}}>
                    <Typo variant={'h3'}>{groupUsers > 2 ? "Neue Gruppe" : "Chat"} erstellen</Typo>
                    <Row justifyContent={'space-between'}>
                        <div>Gruppenmitglieder:</div>
                        <div style={{width: 32, textAlign: "center"}}>{groupUsers}</div>
                    </Row>

                    <Row>
                        <Tag className={styles.tag}>Du</Tag>
                        <Tag className={styles.tag}>{name}</Tag>
                    </Row>
                    <Row justifyContent={'space-between'}>
                        <Row flex={0.8}>
                            <Tag className={styles.tag}>Bewohner</Tag> <Tag
                            className={styles.tag}>{user.name}</Tag>
                        </Row>
                        <Row flex={0.2} justifyContent={'end'}>
                            <Button
                                icon={includeRes ? FiUserMinus : FiUserPlus}
                                onClick={() => setIncludeRes((old) => !old)}
                                light={!includeRes}
                            />
                        </Row>
                    </Row>
                    <LoadRelatives
                        residentId={user.residentId}
                        tagClassName={styles.tag}
                        inlineButtonsJustifyContent={'end'}
                        inlineButtons={(user) => [
                            {
                                key: 'inGroup',
                                ...(isSelected(user.uid) ? {
                                    Icon: FiUserMinus,
                                    onClick: () => removeRelative(user.uid)
                                } : {
                                    Icon: FiUserPlus,
                                    onClick: () => addRelative(ResRelSDK.convertToContact(user) as t_RelContact),
                                    light: true
                                })

                            }
                        ]}
                    />
                    <Row justifyContent={'end'}>
                        <Row flex={0.3} justifyContent={'space-between'}>
                            <Button
                                light
                                text={"Abbrechen"}
                                onClick={hide}
                            />
                            <ColorButton
                                icon={groupUsers > 1 ? FiCheck : FiX}
                                aktive={groupUsers > 1}
                                disableStyle={{
                                    backgroundColor: '#dd403e',
                                    color: 'white'
                                }}
                                onClick={createChat}
                            />
                        </Row>
                    </Row>
                </div>
            }
        </Modal>
    )
}

export default GroupCreateDialog;
