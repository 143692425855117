import React from 'react'
import * as Ionicons from 'react-icons/io5'
import { expoIconToReactIconName } from 'lib/icon'

interface Props {
	name: string
	size?: number
	color?: string
}

export const IconConstructor = ({ name, size, color }: Props) => {
	if (!name) return null

	const reactIcon = expoIconToReactIconName(name)

	return Ionicons[reactIcon];
}

const Icon = ({ name, size, color }: Props) => {
	const I = IconConstructor({name, size, color});

	if (!I) return null;

	return <I {...{ size, color }} />
}

export default Icon
