import {i_FolderAndFile} from "@pixel-kraft/commulino-types/src/menuPoint";
import {i_data, i_dataUsedIn} from "lib/Cloud/type";
import QueryBuilder from "lib/QueryBuilder";
import {
    arrayRemove,
    arrayUnion,
    collection,
    doc,
    getDoc,
    getDocs,
    getFirestore,
    updateDoc,
    where
} from "firebase/firestore";
import {i_content} from "@pixel-kraft/commulino-types/dist/menuPoint";
import {fileObject, folderObject} from "store/folders/types";
import {database} from "pages/folders/components/database";

/**
 * A Function to load Folder and File Data.
 * @param type Specifics what should be loaded
 * @param rootFolder The rootFolder for example all,pinnwand
 * @param parent The parent folder. If not given rootFolder will be used instand
 * @param accept Only for files. image/* is equal to image/png,image/gif,image/jpeg
 */
export const getCloudContent = async (type: i_FolderAndFile["type"], rootFolder: i_content, parent?: i_content, accept?: string,usedIn?: i_dataUsedIn): Promise<i_data[]> => {
    const q = new QueryBuilder(collection(getFirestore(), 'folders', rootFolder.id, type));
    const isIn = parent ? parent.id : rootFolder.id;
    if (type === "files") {
        q.add(where("folderId", "==", isIn))
        if (accept) {
            if (accept.includes(',')||accept.includes('image/*')) {
                const mediaString=accept.replace('image/*','image/png,image/gif,image/jpeg')
                const mediaTypes = mediaString.split(',');
                if (mediaTypes.length > 10) throw Error('Currently only 10 different types are supportet!');
                q.add(where("type", "in", mediaTypes))
            } else
                q.add(where("type", "==", accept))
        }
        if(usedIn)
            q.add(where("usedIn","array-contains",usedIn))
    } else
        q.add(where("parentId", "==", isIn))

    const snapshot = await getDocs(q.getQuery())
    const content: i_data[] = [];
    snapshot.forEach((doc) => {
        const data = doc.data();

        content.push({
            content: {id: doc.id, name: data.name},
            rootFolder: rootFolder.id,
            type,
            parentId: ("parentId" in data) ? data.parentId : data.folderId,
            path: data.path ?? undefined,
            mediaType: type === "files" ? data.type : undefined,
            url: type === "files" ? data.url : undefined,
            usedIn: data.usedIn,
        });
    })

    return content;
}

export const getCloudFilesUsedIn= async (rootFolder: i_content,usedIn: i_dataUsedIn) =>{
    return await getCloudContent("files",rootFolder,undefined,undefined,usedIn);
}

export const getCloudFiles = async (rootFolder: i_content, parent?: i_content, accept?: string) => {
    return await getCloudContent("files", rootFolder, parent, accept);
}

export const getCloudFolders = async (rootFolder: i_content, parent?: i_content) => {
    return await getCloudContent("folders", rootFolder, parent);
}

export const getCloudFolderInfo = async (id: string, rootFolderId: string) => {
    const docData = await getDoc(doc(getFirestore(), "folders", rootFolderId, "folders", id))
    return {id: docData.id, ...docData.data()} as folderObject;
}


export const getCloudFileInfo = async (id: string, rootFolderId: string) => {
    const docData = await getDoc(doc(getFirestore(), "folders", rootFolderId, "files", id))
    return {id: docData.id, ...docData.data()} as fileObject;
}

export const getCloudParentInfo = async (id: string, rootFolderId: string, type: i_FolderAndFile["type"]) => {
    const _doc = await getDoc(doc(getFirestore(), "folders", rootFolderId, type, id))
    const folderId = type === "files" ? database.formatDocFiles(_doc).folderId : database.formatDocFolder(_doc).parentId;
    console.log({id, rootFolderId, type, folderId})
    return await getCloudFolderInfo(folderId, rootFolderId);

}

const useFileIn=async (fileId: string,rootFolderId: string,usedIn: i_dataUsedIn,add:boolean=true) => {
    return await updateDoc(doc(getFirestore(),"folders",rootFolderId,fileId),{usedIn: (add?arrayUnion:arrayRemove)(usedIn)})
}

export const addExistingFileToInfosystemSlider=async (fileId: string,rootFolderId: string,infosystemSliderId: string)=>{
    return await useFileIn(fileId,rootFolderId,({id: infosystemSliderId,type:"infoSystemSlider"} as i_dataUsedIn));
}

export const removeExistingFileFromInfosystemSlider=async (fileId: string,rootFolderId: string,infosystemSliderId: string)=>{
    return await useFileIn(fileId,rootFolderId,({id: infosystemSliderId,type:"infoSystemSlider"} as i_dataUsedIn),false);
}