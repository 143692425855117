import React from 'react';
import { SystemMessage as MessageType } from '@pixel-kraft/commulino-types';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers'
import styles from './SystemMessage.module.css'

interface Props {
  message: MessageType;
  chatId: string;
}

export function getMessageString(action: MessageType['action'], target?: string, user?: string): string {
  const texts: {[index in typeof action]: string} = {
    add: user ? `${user} hat ${target} hinzugefügt.` : `${target} wurde hinzugefügt.`,
    remove: user ? `${user} hat ${target} entfernt.` : `${target} wurde entfernt.`,
    leave: `${target} hat die Gruppe verlassen.`,
    enter: `${target} ist der Gruppe beigetreten.`,
  };

  return texts[action];
}

const SystemMessage = ({ message, chatId }: Props): JSX.Element => {
  const { users } = useSelector((state: RootState) => state.messenger.Chats[chatId]);
  const { action, uid: user, target } = message;

  const { name: targetName } = users.find(({ uid }) => uid === target) ?? {};
  const { name: userName } = users.find(({ uid }) => uid === user) ?? {};

  const text = getMessageString(action, targetName, userName);

  return (
    <div className={styles.SystemMessage}>
      { text }
    </div>
  );
};

export default SystemMessage;
