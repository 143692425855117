import {Event} from "@pixel-kraft/commulino-types";
import {t_MessageData} from "../type"

/**
 * Baut ein Übergangsobjekt das alle Daten zum senden der Nachricht bereit stellt und eine Liste von Daten die noch
 * zusätzlich hochgeladene werden müsse(Bilder,Dateien,...). Diesen Object muss vor dem Senden umgewandelt werden! (Dies
 * übernimmt die sendToChat methode)
 * @param uid Der sender
 * @param text Text der Nachricht
 * @param replay Msg ID des zu zitierenden Nachricht
 * @param img Ein array von Bild urls. Wird dann von der sendToChat Methode hochgeladen.
 * @param file Ein array von Dateien. Wird dann von der sendToChat Methode hochgeladen.
 * @param event Ein event das in ein ChatEvent umgewandelt wird und von der sendToChat Methode hochgeladen wird.
 */
const buildMessage=(uid: string,text:string,replay?:string,img?:string[],file?:File[],event?: Event)=>{
    if (!uid) throw new Error('Not authenticated');

    const preparedText = text.trim();

    const message: t_MessageData = {
        ...(preparedText ? {
            text: preparedText,
        } : {}),
        uid,
        date: Date.now(),
        readBy: [uid]
    }

    if (replay !== undefined && replay.length>0)
        message.replyTo = replay;
    if (img !== undefined && img.length>0) {
        message.toUploadImages=img;
    }
    if (file !== undefined&&file.length>0) {
        message.toUploadFiles=file;
    }
    if (event) {
        message.event={
            ...event,
            authorID: uid
        };
    }
    return message;
}

export default buildMessage;
