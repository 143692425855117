import { OnboardingPost, OnboardingTemplate } from '@pixel-kraft/commulino-types'

export interface OnboardingState {
	templates: {
		[id: string]: OnboardingTemplate
	}
	loading: boolean
	loaded: boolean
	editing?: {
		state: boolean
		data: OnboardingTemplate
	}
}

export const ONBOARDING_GET_TEMPLATES_REQUEST = 'ONBOARDING_GET_REQUEST'
export const ONBOARDING_GET_TEMPLATES_SUCCESS = 'ONBOARDING_GET_SUCCESS'
export const ONBOARDING_INIT_TEMPLATE = 'ONBOARDING_INIT_TEMPLATE'
export const ONBOARDING_EDIT_TEMPLATE = 'ONBOARDING_EDIT_TEMPLATE'
export const ONBOARDING_SET_EDITING_TEMPLATE = 'ONBOARDING_SET_EDITING_TEMPLATE'
export const ONBOARDING_SAVE_POST = 'ONBOARDING_SAVE_POST'
export const ONBOARDING_REMOVE_POST = 'ONBOARDING_REMOVE_POST'
export const ONBOARDING_MOVE_POST_UP = 'ONBOARDING_MOVE_POST_UP'
export const ONBOARDING_MOVE_POST_DOWN = 'ONBOARDING_MOVE_POST_DOWN'

interface GetRequestAction {
	type: typeof ONBOARDING_GET_TEMPLATES_REQUEST
}

interface GetSuccessAction {
	type: typeof ONBOARDING_GET_TEMPLATES_SUCCESS
	templates: OnboardingTemplate[]
}

interface InitTemplateAction {
	type: typeof ONBOARDING_INIT_TEMPLATE
	template: OnboardingTemplate
}

interface EditTemplateAction {
	type: typeof ONBOARDING_EDIT_TEMPLATE
	id: string
	data: Pick<OnboardingTemplate, 'title'>
}

interface SetEditingTemplateAction {
	type: typeof ONBOARDING_SET_EDITING_TEMPLATE
	id: string
}

interface SavePostAction {
	type: typeof ONBOARDING_SAVE_POST
	post: OnboardingPost
}

interface RemovePostAction {
	type: typeof ONBOARDING_REMOVE_POST
	index: number
}

interface MovePostUpAction {
	type: typeof ONBOARDING_MOVE_POST_UP
	index: number
}

interface MovePostDownAction {
	type: typeof ONBOARDING_MOVE_POST_DOWN
	index: number
}

export type OnboardingActionTypes =
	| GetRequestAction
	| GetSuccessAction
	| InitTemplateAction
	| EditTemplateAction
	| SetEditingTemplateAction
	| SavePostAction
	| RemovePostAction
	| MovePostUpAction
	| MovePostDownAction
