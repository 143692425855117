import React, {lazy, Suspense, useEffect, useState} from 'react';
import {ActivityIndicator, ColorButton, Typo} from "components";
import {FiUserPlus, FiUserX} from "react-icons/fi";
import {isValidEmail} from "lib/helpers";
import styles from "./JobLeft.module.css"
import {t_User} from "../type";

const UserProfile = lazy(() => import('components/UserProfile'));

type t_props = {
    mode: 'email' | 'user',
    email?: string,
    user?: t_User,
    onEmailChange: (value: string) => void,
    onModeChange: (mode: 'email' | 'user') => void,
}

const JobLeft = ({onEmailChange, onModeChange, mode, user, email}: t_props) => {
    const [warn, setWarn] = useState(false);

    useEffect(() => {
        email && setWarn(isValidEmail(email));
    }, [email])


    return (
        <div>
            <Typo style={styles.info}>
                Legen Sie fest, wer die Benachrichtigung über eine Bewerbung erhalten soll. Sie können entweder einen
                Benutzer auswählen, der dann über den Chat eine Nachricht erhält oder alternativ wenn Sie den Benutzer
                aus dem Feld entfernen dort eine gültige E-Mail Adresse angeben. In diesem Fall wird dann eine E-Mail
                versendet.
            </Typo>
            {mode === 'email' &&
                <div>
                    {!warn && <div style={{color: 'red'}}>Bitte geben sie eine gültige E-mail ein</div>}
                    <div style={{display: 'flex'}}>
                        <input
                            type={"text"}
                            name={"Email"}
                            placeholder={"test.test@test.de"}
                            value={email}
                            onChange={(e) => onEmailChange(e.currentTarget.value)}
                            className={styles.input}
                        />
                        <ColorButton
                            icon={FiUserPlus}
                            onClick={() => {
                                onModeChange('user');
                            }}
                            style={{marginLeft: 'auto', marginRight: '50%'}}
                        />
                    </div>
                </div>
            }
            {mode === 'user' &&
                <Suspense fallback={<ActivityIndicator/>}>
                    <div style={{display: 'flex'}}>
                        {user ?<UserProfile data={{uid: user.uid}}/>:<div>None</div>}
                        <ColorButton style={{
                            height: 30,
                            width: 30,
                            marginTop: 'auto',
                            marginBottom: 'auto',
                            marginLeft: 'auto',
                            marginRight: '50%'
                        }} icon={FiUserX} onClick={() => {
                            onModeChange('email');
                        }}/>
                    </div>
                </Suspense>
            }</div>
    )
}

export default JobLeft;
