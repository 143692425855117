import { mergeAttributes, Node } from '@tiptap/core'
import {getEmbedURLFromYoutubeURL, isValidYoutubeUrl} from "lib/tipTapExensions/Embeds/utils";


export interface EmbedsOptions {
    addPasteHandler: boolean;
    allowFullscreen: boolean;
    controls: boolean;
    height: number;
    HTMLAttributes: Record<string, any>,
    inline: boolean;
    nocookie: boolean;
    width: number;
}

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        embeds: {
            /**
             * Insert a youtube video
             */
            setEmbeds: (options: { src: string, width?: number|string, height?: number|string, start?: number }) => ReturnType,
        }
    }
}

export const Embeds = Node.create<EmbedsOptions>({
    name: 'embeds',

    addOptions() {
        return {
            addPasteHandler: true,
            allowFullscreen: false,
            controls: true,
            height: 480,
            HTMLAttributes: {},
            inline: false,
            nocookie: false,
            width: 640,
        }
    },

    inline() {
        return this.options.inline
    },

    group() {
        return this.options.inline ? 'inline' : 'block'
    },

    draggable: true,

    addAttributes() {
        return {
            src: {
                default: null,
            },
            start: {
                default: 0,
            },
            width: {
                default: this.options.width,
            },
            height: {
                default: this.options.height,
            },
        }
    },

    parseHTML() {
        return [
            {
                tag: 'div[data-youtube-video] iframe',
            },
        ]
    },

    addCommands() {
        return {
            setEmbeds: options => ({ commands }) => {

                return commands.insertContent({
                    type: this.name,
                    attrs: options,
                })
            },
        }
    },


    renderHTML({ HTMLAttributes }) {
        if(isValidYoutubeUrl(HTMLAttributes.src)!==null) {
            const embedUrl = getEmbedURLFromYoutubeURL({
                url: HTMLAttributes.src,
                controls: this.options.controls,
                nocookie: this.options.nocookie,
                startAt: HTMLAttributes.start || 0,
            })

            HTMLAttributes.src = embedUrl
        }

        return [
            'div',
            { 'data-youtube-video': '' },
            [
                'iframe',
                mergeAttributes(
                    this.options.HTMLAttributes,
                    {
                        width: this.options.width,
                        height: this.options.height,
                        allowfullscreen: this.options.allowFullscreen,
                    },
                    HTMLAttributes,
                ),
            ],
        ]
    },
})
