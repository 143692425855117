import {residentDirectory, residentDirectoryData} from '@pixel-kraft/commulino-types'
import {firebaseApp} from "lib/firebase";
import {getFirestore, collection, onSnapshot, addDoc, doc,deleteDoc,updateDoc} from "firebase/firestore";

export const updateBewohner= async (OnUpdate:(data:residentDirectory[])=>void,setLoad:(load:boolean)=>void)=>{
    return onSnapshot(collection(getFirestore(firebaseApp),'residentDirectory'),data=>{
        setLoad(true);
        const bewohnerList:residentDirectory[]=[];
        data.forEach((bewohner=>{
            const d=bewohner.data() as residentDirectoryData;
            bewohnerList.push({
                ID:bewohner.id,
                nachname: d.nachname,
                vorname:d.vorname,
                apartment:d.apartment,
                etage:d.etage,
                kurzzeitpflege:d.kurzzeitpflege,
                deaktiviert: d.deaktiviert
            });
        }))
        OnUpdate(bewohnerList);
        setLoad(false);
    })
}

export const addBewohner=async (bewohner:residentDirectoryData)=>{
    await addDoc(collection(getFirestore(firebaseApp),'residentDirectory'),{...bewohner});
}

export const deleteBewohner=async (ID:string) =>{
    await deleteDoc(doc(getFirestore(firebaseApp),'residentDirectory',ID));
}

export const editBewohner=async  (ID:string,bewohner:residentDirectoryData)=>{
    await updateDoc(doc(getFirestore(firebaseApp),'residentDirectory',ID),{...bewohner});
}

export const changeStatusBewohner=async (ID:string,deaktiviert:boolean) => {
    await updateDoc(doc(getFirestore(firebaseApp),'residentDirectory',ID),{
        "deaktiviert": deaktiviert
    });
}
