import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "store/reducers";
import {ColorButton, ContentContainer, PageTitle,ImageCropPicker} from "components";
import {DeleteUserImage, uploadUserImage} from "lib/media";
import {updateLocalUserImage} from "store/auth/actions";
import {FiImage, FiCrop, FiLogIn} from "react-icons/fi";
import FormItem from "components/FormItem";
import styles from "pages/login/index.module.css";

import { getAuth, reauthenticateWithCredential, updatePassword, EmailAuthProvider } from "firebase/auth";

const PasswordChangeForm = () => {
    const [passwordOld, setPasswordOld] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [loading, setLoading] = useState(false);

    const btnHandle = async () => {
        setLoading(true);
        const passwdTrim = password.trim();
        const passwdConfirmTrim = passwordConfirm.trim();
        const passwordOldTrim = passwordOld.trim();
        if (passwdTrim.length === 0) {
            window.alert('Das Passwort ist Leer');
            setLoading(false);
            return;
        }

        if (passwordOldTrim.length === 0) {
            window.alert('Das Alte Passwort ist Leer');
            setLoading(false);
            return;
        }

        if (passwdTrim !== passwdConfirmTrim) {
            window.alert('Die Passwörter stimmen nicht überein');
            setLoading(false);
            return;
        }

        const user = getAuth().currentUser;

        if (!user) return null;

        const credential = EmailAuthProvider.credential(user.email!, passwordOldTrim);
        try {
            await reauthenticateWithCredential(user, credential);
        } catch {
            setLoading(false);
            window.alert('Problem beim wieder anmelden');
            return;
        }



        updatePassword(user, password).then(async () => {
            setLoading(false);
            setPasswordOld('');
            setPassword('');
            setPasswordConfirm('');
            window.alert('Passwort wurde geändert');
        });
    };

    return (
        <form
            className={styles.form}
            onSubmit={event => {
                event.preventDefault()
                btnHandle()
                return false
            }}
        >
            <h3>Passwort ändern</h3>
            <FormItem name='Altes Passwort' type='password' value={passwordOld} onChange={setPasswordOld}/>
            <FormItem name='Neues Passwort' type='password' value={password} onChange={setPassword}/>
            <FormItem name='Passwort bestätigen' type='password' value={passwordConfirm} onChange={setPasswordConfirm}/>

            <ColorButton
                text='Speichern'
                icon={FiLogIn}
                fullWidth
                large
                loading={loading}
                style={{justifyContent: 'center', marginTop: 16}}
            />
        </form>
    )
}

const UserSettings=()=> {
    const {name, email, photo, uid} = useSelector((state: RootState) => state.auth);
    const [imgSrc, setImgSrc] = useState(photo);
    const [imgFile, setImgFile] = useState<Blob>();
    const [load, setLoad] = useState(false);
    const [loadDelete, setLoadDelete] = useState(false);
    const dispatch = useDispatch();

    return (
        <ContentContainer loading={load || loadDelete}>
            <PageTitle text={"Benutzerdaten"}/>
            <div style={{display: 'flex'}}>

                <div style={{width: 100, height: 100}}>
                    <div style={{width: 100, display: 'flex', justifyContent: 'center'}}>
                        <ImageCropPicker
                            photo={imgSrc}
                            aspect={1}
                            onImageSrc={setImgSrc}
                            onImageBlob={setImgFile}
                            addButton={(click)=>(
                                <ColorButton icon={FiImage} onClick={click} />
                            )}
                            cropButton={(click, show)=>(
                                <ColorButton icon={FiCrop} onClick={show} />
                            )}
                        />
                    </div>
                </div>
                <div style={{marginLeft: 20}}>
                    <p>Name: {name}</p>
                    <p>Email: {email}</p>
                </div>
            </div>
            <div style={{marginTop: 40}}>
                {photo !== imgSrc && imgFile && uid &&
                <div style={{display: 'flex'}}>
                    <ColorButton loading={load} text={"Speichern"} onClick={async () => {
                        setLoad(true);
                        const erg = await uploadUserImage(imgFile);
                        dispatch(updateLocalUserImage(erg));
                        setImgSrc(erg);
                        setImgFile(undefined);
                        setLoad(false);
                    }
                    }/>
                    {!load && !loadDelete &&
                    <ColorButton style={{marginLeft: 10}} text={"Zurücksetzen"} onClick={() => setImgSrc(photo)}/>
                    }
                </div>
                }
                {!load && photo &&
                <ColorButton text={"Profilbild Löschen"} style={{marginTop: 10}} loading={loadDelete} onClick={async () => {
                    setLoadDelete(true);
                    if (uid) {
                        await DeleteUserImage();
                        dispatch(updateLocalUserImage(""));
                        setImgSrc(undefined);
                        setImgFile(undefined);
                    } else
                        throw Error("Der Benutzer ist nicht eingeloggt!");
                    setLoadDelete(false);
                }}/>
                }
            </div>
            <PasswordChangeForm />
        </ContentContainer>
    );
}
export default UserSettings;
