import React from 'react'
import styles from './Indicator.module.css'

interface Props {
	active: boolean
	style?: string
}

const Indicator = ({ active, style }: Props) => (
	<div className={`${styles.indicator} ${active && styles.active} ${style}`} />
)

export default Indicator
