import React from 'react';
import {ActivityIndicator, Animations, CheckBox, Row} from "components";
import styles from './ChatAnySelect.module.css';
import {getIndexOf} from "lib/helpers";
import {t_ChatAnySelectProps} from "../types";
import SimpleBar from 'simplebar-react';


const ChatAnySelect = <T, >(props: t_ChatAnySelectProps<T>) => {

    const changeSelected = (item: T, add: boolean) => {
        if (props.single) {
            props.setSelected(add ? [item] : []);
            return;
        }

        if (add) {
            props.setSelected([...props.selected, item]);
            return;
        }
        const tmp = [...props.selected];
        const index = getIndexOf(tmp, (data) => props.isSame ? props.isSame(item, data) : item === data)
        tmp.splice(index, 1);
        props.setSelected(tmp);
    }


    return (
        <div style={{position: 'relative', display: 'flex', flexFlow: 'column'}}>
            <div style={{width: '100%', paddingTop: 5, ...props.listContainerStyle}}
                 className={props.listContainerClassName}>
                <SimpleBar style={{height: props.height}}>
                    <Animations.Group>
                        {!props.loading && props.items.map((item, index) => {
                            const isSel = props.isSelected(item, props.selected);
                            return (<Animations.View unmountOnExit mountOnEnter key={props.keyExtractor?props.keyExtractor(item):index} timeout={150} animation={'fade'}><Row
                                className={styles.row}
                                style={props.rowStyle && props.rowStyle(item, index, isSel)}
                                onClick={props.changeOnClick ? () => changeSelected(item, !isSel) : undefined}>
                                <CheckBox
                                    checked={isSel}
                                    onChange={(checked) => changeSelected(item, checked)}
                                />
                                {props.render(item, index, isSel)}
                            </Row> </Animations.View>)
                        })}
                        {props.loading && <div
                            style={{
                                height: '100%',
                                display: "flex",
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                            <ActivityIndicator large/></div>}

                    </Animations.Group>
                </SimpleBar>
            </div>
        </div>
    )
}

export default ChatAnySelect;
