import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'store/reducers'
import { useHistory } from 'react-router-dom'
import { Card, ContentContainer, PageTitle, Button, Typo, ActionModal, ColorButton } from 'components'
import { FiEdit2, FiPlus, FiX, FiTrash2 } from 'react-icons/fi'
import { deleteRole } from 'store/roles/actions'
import styles from './index.module.css'

const Roles = () => {
	const history = useHistory()
	const dispatch = useDispatch()
	const loading = useSelector((state: RootState) => state.roles.loading)
	const roles = useSelector((state: RootState) => state.roles.roles)
	const [deleteId, setDeleteId] = useState<string | null>(null)

	return (
		<ContentContainer loading={loading}>
			<PageTitle
				text='Benutzer-Gruppen'
				button={
					<ColorButton
						onClick={() => history.push('/settings/roles/new')}
						text='Gruppe hinzufügen'
						icon={FiPlus}
						large
					/>
				}
			/>
			{roles.map(({ name, id }) => (
				<Card key={id}>
					<Typo variant='h4'>{name}</Typo>
					<div className={styles.row}>
						<Button
							onClick={() => history.push({ pathname: '/settings/roles/edit', state: { id } })}
							text='Bearbeiten'
							icon={FiEdit2}
							light
							style={styles.button}
						/>
						<Button
							onClick={() => {
								if (id) setDeleteId(id)
							}}
							text='Entfernen'
							icon={FiX}
							light
							style={styles.button}
						/>
					</div>
				</Card>
			))}
			<ActionModal
				title='Gruppe löschen'
				text='Wollen Sie die Gruppe wirklich entfernen? Diese Änderung betrifft alle Mitarbeiter, die dieser Gruppe zugewiesen sind.'
				button={
					<ColorButton
						onClick={() => {
							if (deleteId) {
								dispatch(deleteRole(deleteId))
								setDeleteId(null)
							}
						}}
						text='Entfernen'
						icon={FiTrash2}
					/>
				}
				visible={!!deleteId}
				hide={() => setDeleteId(null)}
			/>
		</ContentContainer>
	)
}

export default Roles
