import { ColorButton, Typo } from 'components/index';
import React from 'react';
import { FiThumbsUp } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { likePost, removeLike } from 'store/posts/actions';
import { RootState } from 'store/reducers';

type t_props = {
    id: string
}


/**
 * Rendert ein Like Button.
 * Falls im Post kein likes Feld existiert wird null zurückgegeben.
 * @param id Post ID
 */
const LikeButton = (props: t_props) => {
    const uid = useSelector((state: RootState) => state.auth.uid);
    const like = useSelector((state: RootState) => state.posts.byId[props.id]?.likes)
    const isEdit = useSelector((state: RootState) => state.posts.editing.id === props.id)
    const dispatch = useDispatch();

    if (!like || uid === null) return null;

    const liked = like.includes(uid);
    return (
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            {like.length !== 0 && <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}><Typo>Gefällt {like.length} {like.length === 1 ? 'Person' : 'Personen'}</Typo></div>}
            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                <ColorButton style={{transition: '0.3s'}} icon={FiThumbsUp} aktive={liked} disableStyle={{color: 'black',backgroundColor: 'lightgray'}} text={!liked?"Gefällt mir":""} onClick={() => { if (!isEdit) dispatch(liked ? removeLike(props.id) : likePost(props.id)) }} />
            </div>
        </div>
    )
}
export default LikeButton;
