import React from 'react';

type t_props = {
    justifyContent?: React.CSSProperties['justifyContent'],
    flex?: React.CSSProperties['flex'],
    alignItems?: React.CSSProperties['alignItems'],
    flexFlow?: React.CSSProperties['flexFlow']
    flexWrap?: React.CSSProperties['flexWrap']
    children?: React.ReactNode,
    style?: React.CSSProperties,
    className?: string,
    onClick?:(event: React.MouseEvent<HTMLDivElement>)=>void
}


const Row = React.forwardRef<HTMLDivElement, t_props>((props, ref) => (
    <div
        style={{
            display: 'flex',
            justifyContent: props.justifyContent,
            flex: props.flex,
            alignItems: props.alignItems??'center',
            flexFlow: props.flexFlow,
            flexWrap: props.flexWrap,
            ...props.style
        }}
        className={props.className}
        ref={ref}
        onClick={props.onClick}
    >
        {props.children}
    </div>
))

export default Row;
