import React, {
    useEffect,
    useState,
} from 'react';
import styles from './EditingCalendars.module.css';
import {Button, CheckBox, ColorButton, FormItem, GroupSelector, Typo} from 'components';
import {GoCalendar, MdClose, TbEdit, FiMonitor} from 'react-icons/all';
import {getInvertColor} from 'lib/colors';
import {firebaseApp} from 'lib/firebase';
import {
    deleteField, doc, getFirestore, updateDoc,
} from 'firebase/firestore';
import ColorPicker from "pages/settings/layout/ColorPicker";
import {Department, Location, t_infoCalenderTypes} from "@pixel-kraft/commulino-types";
import InfoSystemType from "pages/Kalender/components/InfoSystemType";
import {createGroupObject} from "pages/Kalender/components/NewCalendar";
import StringAndNumberArraysComparison from "components/StringAndNumberArraysComparison";
import { ArrowUturnLeftIcon } from "@heroicons/react/24/solid"
import {Calendars} from "pages/Kalender";
import {FiTrash2} from "react-icons/fi";
import {useSelector} from "react-redux";
import {RootState} from "store/reducers";

import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import '../../../navigation/simpleBarStyle.css'

interface Props {
    setDropDownEditColor: (to: boolean) => void,
    oldCalendar: Calendars,
    calendars: Calendars[],
    setModalMakeSureToDelete:(to:boolean) => void,
}

const EditingCalendars = ({setDropDownEditColor, oldCalendar, calendars, setModalMakeSureToDelete}: Props) => {
    const [calendarDataToUpdate, setCalendarDataToUpdate] = useState<Calendars>({...oldCalendar});   // To compare with the old calendar and check if it's necessary an update
    const [updateInput, setUpdateInput] = useState<{name:boolean, outlookURL:boolean}>({name:false, outlookURL:false})
    const [showInfoSettings,setShowInfoSettings] = useState(oldCalendar.infoSystemType!==undefined);
    const [infoType,setTypeInfo] = useState<t_infoCalenderTypes|undefined>(oldCalendar.infoSystemType);
    const [warning, setWarning] = useState<{ emptyName:boolean, alreadyTakenName:boolean, noChanges:boolean, emptyURL:boolean, alreadyTakenURL:boolean}>({emptyName:false, alreadyTakenName:false, noChanges:false, emptyURL:false, alreadyTakenURL:false})
    const subColor = useSelector((state:RootState) => state.config.subColor);

    useEffect(() => {
        setCalendarDataToUpdate({...oldCalendar})
        setUpdateInput({name:false, outlookURL:false}) // Just is triggered if the user selects a different calendar selected previously
        setShowInfoSettings(!!oldCalendar.infoSystemType)
        setTypeInfo(oldCalendar.infoSystemType);
    }, [oldCalendar])

    useEffect(()=>{
        if(!showInfoSettings) setTypeInfo(undefined);
    },[showInfoSettings])

    useEffect(() => {   // Shows the warnings 2 and a half seconds
        if (warning.emptyURL || warning.alreadyTakenName  || warning.noChanges || warning.emptyName || warning.alreadyTakenURL) {
            const timerID = setTimeout(() => {
                setWarning({emptyName:false, alreadyTakenName:false, noChanges:false, emptyURL:false, alreadyTakenURL:false})
            }, 2500);

            return () => {clearTimeout(timerID)}
        }
    }, [warning])

    const handleSubmit = () => {
        if (calendarDataToUpdate) {
            const nameAlreadyTaken = calendars.some(calendar => calendar.name === calendarDataToUpdate.name.trim());
            const URLAlreadyTaken = calendars.some(calendar => calendar.calendarId === calendarDataToUpdate.calendarId);

            // Checking, setting warnings and if it's okay updating
            if ((nameAlreadyTaken && oldCalendar.name.trim()!==calendarDataToUpdate.name.trim()) || calendarDataToUpdate.name === '') {
                nameAlreadyTaken ? setWarning({...warning, alreadyTakenName: true}) : setWarning({...warning, emptyName: true})
            }
            else if (updateInput.outlookURL && (calendarDataToUpdate.calendarId === '' || URLAlreadyTaken)) {
                URLAlreadyTaken ? setWarning({...warning, alreadyTakenURL: true}) : setWarning({...warning, emptyURL: true})
            }
            else if (oldCalendar.color!==calendarDataToUpdate?.color ||
                oldCalendar.infoSystemType!==infoType ||
                oldCalendar.name.trim()!==calendarDataToUpdate.name.trim() ||
                !StringAndNumberArraysComparison(oldCalendar.departments ?? [], calendarDataToUpdate.departments ?? []) ||
                !StringAndNumberArraysComparison(oldCalendar.locations ?? [], calendarDataToUpdate.locations ?? []) ||
                oldCalendar.calendarId !== calendarDataToUpdate.calendarId ||
                oldCalendar.allowEventRequest !== calendarDataToUpdate.allowEventRequest
            ) {

                const objectDepartmentsLocationsGroups = createGroupObject(calendarDataToUpdate.departments ?? [], calendarDataToUpdate.locations ?? [])
                const objUP = {...calendarDataToUpdate, ...{groups:objectDepartmentsLocationsGroups.groups, infoSystemType: infoType??deleteField()}}
                updateDoc(doc(getFirestore(firebaseApp), 'calendars', oldCalendar.id), objUP)
                    .then(() => setDropDownEditColor(false))
                    .catch((error) => {
                        // The document probably doesn't exist.
                        console.error("Error updating document: ", error);
                    });
            } else setWarning({...warning, noChanges: true});
        }
    }

    const Preview = ({color, invertColor}: { color: string, invertColor: string }) => {
        return (<div style={{margin: "auto", borderRadius: 5, backgroundColor: invertColor, padding: 20}}>
            <GoCalendar style={{color: color}} className={styles.icon}/>
        </div>)
    }

    return (
        <>
            <div className={styles.dropDownWrapper}>
                <div className={styles.simpleBarContainer}>
                    <div className={styles.simpleBarWrapper}>
                        <SimpleBar style={{height: '100%'}}>
                            <div className={styles.editCalendarNameWrapper}>
                                {updateInput.name ? (
                                    <div className={styles.formWrapper}>
                                        <ArrowUturnLeftIcon
                                            className={styles.returnIcon}
                                            onClick={()=>{
                                                setUpdateInput(prevState => ({...updateInput, name:!prevState.name}))
                                                setCalendarDataToUpdate({...calendarDataToUpdate, name: oldCalendar.name})
                                            }}
                                        />
                                        <FormItem
                                            value={calendarDataToUpdate.name}
                                            onChange={(e)=>setCalendarDataToUpdate({...calendarDataToUpdate, name:e})}
                                            maxLength={30}
                                            classNameInput={styles.paddingRight}
                                        />
                                        {warning.alreadyTakenName && <div style={{position:"absolute", color: "#ff5500", fontSize: '11px'}}>Ein Kalender mit dem Namen existiert bereits.</div>}
                                        {warning.emptyName && <div style={{position:"absolute", color: "#ff5500", fontSize: '11px'}}>Bitte geben Sie einen Namen ein.</div>}
                                        <span className={styles.number} style={calendarDataToUpdate.name.length === 30 ? {color:'#ff5500'} : {color: '#949dad'}}>{calendarDataToUpdate.name.length}/30</span>
                                    </div>
                                ):(
                                    <>
                                        <div className={styles.calendarName} style={{color:calendarDataToUpdate.color}}>{oldCalendar.name}</div>
                                        <TbEdit className={styles.editIcon} onClick={()=>{
                                            setUpdateInput(prevState => ({...updateInput, name:!prevState.name}))
                                            setCalendarDataToUpdate({...calendarDataToUpdate, name: oldCalendar.name})
                                        }}/>
                                    </>
                                )}
                                <MdClose className={styles.closeButton} onClick={()=> { // All reset, because it will probably be triggered on purpose
                                    setDropDownEditColor(false);
                                    setUpdateInput({name:false, outlookURL:false});
                                }}/>
                            </div>
                            <div style={{width:"95%", margin:"auto"}}>
                                <div className={styles.title}>Wähle eine Farbe für den Kalender</div>
                                <ColorPicker color={calendarDataToUpdate.color} onChange={(c) => setCalendarDataToUpdate({...calendarDataToUpdate, color:c})} CostumePreview={Preview}/>
                                <div className={styles.randomButtonTitleWrapper}>
                                    <ColorButton
                                        style={{marginTop: '10px', width: '100%', backgroundColor: calendarDataToUpdate.color, color: getInvertColor(calendarDataToUpdate.color)}}
                                        text={'Zufällig'}
                                        onClick={() => {
                                            const newColor = `rgb(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)})`
                                            setCalendarDataToUpdate({...calendarDataToUpdate, color: newColor})
                                        }}
                                    />
                                </div>
                            </div>
                            {oldCalendar.type === 'outlook' && (
                                <div  style={{width:"95%", margin:"0 auto 10px auto"}}>
                                    <div className={styles.row}>
                                        <Typo strong style={styles.text}>
                                            Outlook URL
                                        </Typo>
                                    </div>
                                    { updateInput.outlookURL ? (
                                        <div>
                                            <FormItem
                                                value={calendarDataToUpdate.calendarId}
                                                onChange={(e)=>setCalendarDataToUpdate({...calendarDataToUpdate, calendarId:e})}
                                                classNameInput={styles.paddingRight}
                                            />
                                            {warning.alreadyTakenURL && <div style={{position:"absolute", color: "#ff5500", fontSize: '11px'}}>Dieser Outlook-Kalender ist bereits importiert.</div>}
                                            {warning.emptyURL && <div style={{position:"absolute", color: "#ff5500", fontSize: '11px'}}>Bitte geben Sie eine Outlook-URL ein.</div>}
                                        </div>
                                    ) : (
                                        <ColorButton
                                            text={'Bearbeiten Sie die Outlook-URL'}
                                            onClick={()=> setUpdateInput(prevState => ({...updateInput, outlookURL:!prevState.outlookURL}))}
                                        />
                                    )}
                                </div>
                            )}
                            <div style={{marginTop:"10px"}}>
                                <div className={styles.label}>Kalender Einstellungen</div>
                                <div style={oldCalendar.type === "outlook" ? {opacity: "0.6"} : {opacity:"1"}}>
                                    <CheckBox
                                        text={'Terminwunsch Eintragung über den "Für Dich" Bereich für jeden User, der den Kalender sehen kann zulassen.'}
                                        checked={oldCalendar.type === "outlook" ? false : calendarDataToUpdate.allowEventRequest}
                                        onChange={()=>oldCalendar.type === 'google' && setCalendarDataToUpdate(prevState => ({...prevState, allowEventRequest: !prevState.allowEventRequest}))}
                                        style={styles.checkBox_height}
                                        labelStyles={styles.labelStyles}
                                    />
                                </div>
                            </div>
                            <div style={{width:"95%", margin:"auto"}}>
                                <GroupSelector
                                    selectedDepartments={calendarDataToUpdate.departments ?? []}
                                    selectedLocations={calendarDataToUpdate.locations ?? []}
                                    onDepartmentsSelect={(e: Department[])=>setCalendarDataToUpdate({...calendarDataToUpdate, departments:e})}
                                    onLocationsSelect={(e: Location[])=>setCalendarDataToUpdate({...calendarDataToUpdate, locations:e})}
                                />
                            </div>
                            {showInfoSettings && (<div style={{marginBottom:'5px'}}><InfoSystemType value={infoType} onChange={setTypeInfo} /></div>)}
                            <div style={{width:"95%", margin:"0 auto 15px auto"}}>
                                {!showInfoSettings && (
                                    <div className={styles.row}>
                                        <Typo strong style={styles.text}>
                                            Infosystem
                                        </Typo>
                                    </div>
                                )}
                                <ColorButton
                                    style={{backgroundColor: showInfoSettings ? subColor : '#d3d3d3', color: showInfoSettings ? getInvertColor(subColor) : 'black'}}
                                    icon={FiMonitor}
                                    onClick={()=>setShowInfoSettings(!showInfoSettings)}
                                    text={"Infosystemkalender"}
                                />
                            </div>
                            <div className={styles.buttonWrapper} style={{width:"95%", margin:"auto"}}>
                                <Button
                                    style={styles.deleteButton}
                                    icon={FiTrash2}
                                    text={"Kalender löschen"}
                                    onClick={() => {                    // Hides edit modal, shows delete modal.
                                        setDropDownEditColor(false);
                                        setModalMakeSureToDelete(true);
                                    }}
                                />
                                <ColorButton
                                    style={{height: "30px"}}
                                    text="Speichern"
                                    onClick={handleSubmit}
                                />
                                {warning.noChanges &&
                                <div style={{position:"absolute", color: "#ff5500", fontSize: '11px', bottom: 0, right: 0, marginRight: '10px'}}>Es gibt keine Änderungen.</div>}
                            </div>
                        </SimpleBar>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditingCalendars;
