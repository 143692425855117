import React from 'react'
import styles from './Tag.module.css'

type t_style = {
	color?: string,
	textColor?: string
	style?: React.CSSProperties,
	className?: string
}

type t_textProps = t_style&{
	text: string
}

type t_childrenProps = t_style&{
	children: React.ReactNode
}

type t_props = t_textProps | t_childrenProps

const Tag = (props: t_props) => (
	<div className={`${[styles.tag,props.className??[]].flat().join(' ')}`} style={{ backgroundColor: props.color,color: props.textColor, ...props.style }}>
		{'text' in props&&props.text}
		{'children' in props&&props.children}
	</div>
)

export default Tag
