import React from 'react'
import styles from './PageTitle.module.css'
import {IconType} from "react-icons";

interface Props {
	text: string,
	button?: React.ReactNode,
	style?: React.CSSProperties,
	className?: string,
	avatar?: string,
	Icon?: IconType,
	IconStyle?: React.CSSProperties,
	avatarStyle?: React.CSSProperties,
	onClick?: () => void,
}

const PageTitle = ({ text, button, style,className, avatar, Icon, IconStyle, avatarStyle, onClick }: Props) => (
	<div className={`${styles.container} ${className}`} style={style}>
		{avatar!==undefined&&
			<img className={styles.avatar} style={avatarStyle} alt='404' src={avatar}/>
		}
		{Icon&&
			<Icon style={IconStyle} />
		}
		<div className={styles.text} onClick={onClick}>{text}</div>
		{button && button}
	</div>
)

export default PageTitle
