import React, {useRef} from "react";
import styles from "./CalendarFrame.module.css";
import FullCalendar from '@fullcalendar/react';
import iCalendarPlugin from '@fullcalendar/icalendar'
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from '@fullcalendar/list';
import rrulePlugin from "@fullcalendar/rrule";
import {Calendars, Option} from "../index";
import "./calendarStyle.css";
import {t_eventData} from "components/Kalender/types";
import {EventClickArg, DateSelectArg} from "@fullcalendar/core";
import deLocale from '@fullcalendar/core/locales/de';
import moment from "moment";
import {getCalendarUrl} from "lib/api";
import * as api from 'lib/api'


interface Props {
    calendars: Calendars[],
    setEventDetailsModal: (to: boolean) => void,    // Modal to show event details. All users event's selected and admins when it's an outlook event
    setEventRequestModal:(to:boolean)=>void,        // Modal to create events for users, and to create and update for admins
    options?: Option[],
    setEventData: (to: Partial<t_eventData>) => void,
    change?: string,
    admin?:boolean,
    setAvoidAlertAfterDelete?:(to:boolean) => void,
    avoidAlertAfterDelete?: boolean,
}

/**
 * Compare two Props objects.
 * For better performance only use change.
 * @param prev Prevision Props
 * @param next The new Props
 */
const areEqual = (prev: Props, next: Props) => {
    return prev.change === next.change //&& compareListOfObjects(prev.options,next.options) && compareListOfObjects(prev.calendars,next.calendars);
}

const CalendarFrame = React.memo(({
                                      calendars,
                                      setEventDetailsModal,
                                      setEventRequestModal,
                                      setEventData,
                                      admin,
                                      setAvoidAlertAfterDelete,
                                      avoidAlertAfterDelete,
                                  }: Props) => {

    const ref=useRef<FullCalendar>(null);
    const proxyURL: string = "https://cors.pixelserver.info/";

    // To create and event

    const handleDateSelect = (dateData: DateSelectArg) => {
        setEventData({
            start: moment(dateData.start),
            end: moment(dateData.end),
        })
        setEventRequestModal(true)
    }

    // To edit an event or show the details

    const handleEventClick = async (clickInfo: EventClickArg) => {
        const outlookEvent = !clickInfo.event.extendedProps["calendarId"];  // Checks if it's an outlook event

        let startSet = false;
        let endSet = false;
        let recurrenceObject;

        const returnFreqByString = (freqString: string) => {
            const map = new Map();
            map.set("DAILY",3);
            map.set("WEEKLY",2);
            map.set("MONTHLY",1);
            map.set("YEARLY",0);

            return map.get(freqString);
        }

        if (clickInfo.event._def.extendedProps.recurringEventId) {  // If it's a recurring event, retrieves the freq and interval
            const event = await api.get(`calendars/${clickInfo.event.extendedProps["calendarId"]}/events/${clickInfo.event._def.extendedProps.recurringEventId}`)
            if (event.rrule) {
                recurrenceObject = {
                    id: clickInfo.event._def.publicId?.split("_")[1] ? clickInfo.event._def.publicId : null,  // SingleEvent ID to edit/cancel an event form a recurring event
                    recFreq: returnFreqByString(event.rrule.match('.*FREQ=(.*);INTERVAL=(.*)')[1]),
                    recInterval: parseInt(event.rrule.match('.*FREQ=(.*);INTERVAL=(.*)')[2]),
                    recStart: moment(event.start),
                    recEnd: moment(event.end),
                    recAllDay: event.allDay,
                }
            }
        }

        if (clickInfo.event.start)
            startSet = true;
        else
            console.warn("No start date use now!");
        if (clickInfo.event.end)
            endSet = true;
        else
            console.warn("No end date use tomorrow!");

        setEventData({
            summary: clickInfo.event.title,
            start: moment(startSet ? clickInfo.event.start : Date.now()),
            end: moment(endSet ? clickInfo.event.end : Date.now() + 86400000),
            description: clickInfo.event.extendedProps["description"] === '\n' ? '' : clickInfo.event.extendedProps["description"],
            calendarId: clickInfo.event.extendedProps["calendarId"],
            allDay: clickInfo.event.allDay,
            publicId: clickInfo.event._def.publicId.split("_")[0],  // Parent event ID (allows to edit/delete a recurring event)
            status:clickInfo.event._def.extendedProps.status,
            createdBy:clickInfo.event._def.extendedProps.createdBy ?? '',
            updatedBy:clickInfo.event._def.extendedProps.updatedBy ?? '',
            imageUrl:clickInfo.event._def.extendedProps.image ?? '',
            recurrenceObject: recurrenceObject ? recurrenceObject : undefined,
        });

        if ( admin && !outlookEvent) {      // All admins' events that are not outlook
            setEventRequestModal(true)
        } else {                            // Admins' events that are outlook and all user's events
            setEventDetailsModal(true)
        }
    }

    return (
        <div className={styles.calendarFrameWrapper}>
            <FullCalendar
                ref={ref}
                plugins={[dayGridPlugin, interactionPlugin, listPlugin, rrulePlugin, iCalendarPlugin]}
                initialView="dayGridMonth"
                locales={[deLocale]}
                locale={'de'}
                weekends={true}
                displayEventEnd={true}
                views={{dayGridMonth: {eventLimit: 2}}}
                customButtons={{
                    myButton: {
                        text: 'Heute',
                        click: async function () {
                            const today = document.getElementsByClassName('fc-day-today')[0]
                            if (!today) {
                                await ref.current?.getApi().today();
                                setTimeout(()=>{document.getElementsByClassName('fc-day-today')[0]?.scrollIntoView({behavior: 'smooth', block:'start'})},1000)
                                return
                            }
                            today.scrollIntoView({behavior: 'smooth', block:'start'})
                        }
                    }
                }}

                headerToolbar={{
                    left: 'title prev,next myButton',
                    right: 'dayGridMonth,listWeek'
                }}

                eventSources={
                    calendars.filter(calendar => calendar.selected).map(({calendarId, color, type, name}) => {
                        if (type === 'outlook') {
                            return {
                                url: proxyURL+calendarId,
                                format: 'ics',
                                display: "block",
                                color: color,
                                failure: function() {
                                    alert(`Beim Abrufen von Ereignissen aus dem ${name}-Kalender ist ein Fehler aufgetreten. Stellen Sie sicher, dass die URL des Outlook-Kalenders richtig eingestellt ist.`);
                                }
                            }
                        } else { // google
                            return {
                                url: getCalendarUrl(calendarId),
                                display: "block",
                                color: color,
                                failure: function () {
                                    if (!avoidAlertAfterDelete) {
                                        alert(`Beim Abrufen von Ereignissen aus dem ${name}-Kalender ist ein Fehler aufgetreten.!`);
                                    } else {
                                        setAvoidAlertAfterDelete && setAvoidAlertAfterDelete(false);
                                    }
                                }
                            }
                        }
                    })
                }

                eventTimeFormat={{
                    hour: '2-digit',
                    minute: '2-digit',
                    meridiem: false,
                    hour12: false
                }}
                editable={true}
                selectable={true}
                select={handleDateSelect}
                eventClick={handleEventClick}
            />
        </div>
    )
}, areEqual);

export default CalendarFrame
