import React, {useEffect, useState} from 'react';
import {ColorButton, FormItem, SegmentedButtons} from "components";
import useWindowDimensions from "lib/useWindowDimensions";
import ChatAnySelect from "../ChatAnySelect/ChatAnySelect";
import {t_ChatAnyPickerProps} from "../types";

const ChatAnyPicker=<T,S,>(props:t_ChatAnyPickerProps<T,S>)=>{
    const [selected,setSelected] = useState(props.selected);
    const {height} = useWindowDimensions();
    const [searchName,setSearchName] = useState('');
    const [orderBy,setOrderBy] = useState<S|false>("defaultFilter" in props&&props.defaultFilter);

    useEffect(()=>{
        setSelected(props.selected);
    },[props.selected])


    return (
        <div style={{
            position: 'absolute',
            inset: 0,
            height,
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: "flex",
            flexFlow: 'column',
            justifyContent: "center",
            alignItems: "center",
            opacity: props.show ? 1 : 0,
            transition: 'opacity 0.3s ease',
            zIndex: props.show ? 5 : -5
        }}>
            <div style={{
                backgroundColor: 'white',
                padding: 5,
                borderRadius: 5,
                minWidth: 300,
                maxWidth: 600,
                width: '80%',
                position: "relative"
            }}>
                <h1>{props.title ?? "Teilenehmer Auswählen"}</h1>
                {props.search&&<FormItem value={searchName} onChange={setSearchName} placeholder={props.search.title??"Benutzername"}/>}
                {"filterButtons" in props&&props.filterButtons&&<SegmentedButtons options={{activeColor: 'mainColor',defaultColor: '#edeff2'}} value={orderBy} onValueChange={setOrderBy} buttonStyle={{flex: 1}} buttons={props.filterButtons}/>}
                <div style={{padding: 5}}>
                    <ChatAnySelect
                        items={props.pickFrom.filter((item)=>{
                            if("defaultFilter" in props)
                                return orderBy?props.search?.filter(searchName,item,orderBy):true;
                            else
                                return props.search?.filter(searchName,item)
                        })}
                        selected={selected}
                        setSelected={setSelected}
                        isSelected={props.isPicked}
                        render={props.renderItem}
                        isSame={props.isSame}
                        height={0.5 * height}
                        changeOnClick={props.selectOnRowClick}
                        listContainerStyle={{backgroundColor: 'rgb(237, 239, 242)', borderRadius: 4, marginBottom: 10}}
                        rowStyle={props.rowStyle}
                        single={props.single}
                        //keyExtractor={props.keyExtractor}
                    />
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}>
                        <ColorButton
                            onClick={props.onClose}
                            text={"Abbrechen"}
                            style={{paddingLeft: 8, paddingRight: 8}}
                        />
                        <ColorButton
                            onClick={() => {
                                props.onSelect(selected);
                                props.closeOnSelect&&props.onClose();
                            }} text={"Auswählen"}
                            style={{paddingLeft: 8, paddingRight: 8}}
                        />
                    </div>
                </div>
            </div>
        </div>
    )

}

export default ChatAnyPicker;
