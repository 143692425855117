import React from 'react';
import moment from "moment";
import {
    isMessage,
    isSystemMessage,
    Message,
    SystemMessage as SystemMessageType,
    User
} from "@pixel-kraft/commulino-types";
import SystemMessage from "../../SystemMessage";
import {getReply, getTitele} from "../../functions";
import ChatMessage from "../../ChatMessage";
import {messageActionType} from "./actionTypes";
import {useAppSelector} from "store/hooks";


type t_props = {
    messages: (Message | SystemMessageType)[],
    chatId: string,
    width: number,
    users: Pick<User, "name" | "uid" | "photo">[],
    onAction: (action: messageActionType) => void,
    mediCare?: boolean

}

const MessengerContent = ({messages, chatId, onAction, users, width, mediCare}: t_props) => {
    const uid = useAppSelector((state) => state.auth.uid);
    let prevDate = 0;

    if (!uid) return null;

    return (
        <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
            {messages.map((m, i, arr) => {
                const sameDate = prevDate !== 0 && (moment(m.date).format('D. MMMM YYYY') === moment(prevDate).format('D. MMMM YYYY'));
                const lastMessage = arr[i - 1] as typeof arr[number] | undefined;
                prevDate = m.date;
                if (isSystemMessage(m)) {
                    return (
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <div>{!sameDate && (
                                <div style={{textAlign: 'center'}}>
                                    {moment(m.date).format('D. MMMM YYYY')}
                                </div>
                            )
                            }</div>
                            <SystemMessage message={m} chatId={chatId}/>
                        </div>
                    );
                }
                const title = (lastMessage && isMessage(lastMessage) && lastMessage.uid === m.uid && sameDate) ? '' : getTitele(m, uid, users);
                return (
                    <div key={m.id} data-title={title}>
                        <div>{!sameDate && (
                            <div style={{textAlign: 'center'}}>
                                {moment(m.date).format('D. MMMM YYYY')}
                            </div>
                        )
                        }</div>
                        <ChatMessage
                            id={m.id}
                            key={m.id}
                            title={title}
                            msg={m}
                            deleteFor={m.deleteFor}
                            reply={getReply(m, uid, arr, users)}
                            chatID={chatId}
                            onReplay={() => {
                                onAction({type: 'replay', message: m});
                            }}
                            onInfoClick={() => {
                                onAction({type: 'click', message: m})
                            }}
                            imgOnClick={(index) => {
                                if (m.images) {
                                    onAction({type: 'image', index, images: m.images.map(({uri}) => uri)})
                                }
                            }
                            }
                            onDocClick={()=>onAction({type: 'docClick',message: m})}
                            mediCare={mediCare}
                            setAlert={(alert) => onAction({type: 'alert', alert})}
                            maxWidth={width} //Side menu 304px ChatList 300px
                            onForward={(message) => onAction({type: 'forward', message})}
                            delete={m.text === undefined && m.attachments === undefined && m.images === undefined} //Nötig um das MSG object neu zu rendern
                        />
                    </div>
                )
            })
            }
        </div>
    )

}
export default MessengerContent;
