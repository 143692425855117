import React, {useEffect} from 'react'
import {
    ContentContainer,
    PageTitle
} from 'components/index'
import 'tippy.js/dist/tippy.css';
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {FiFolder} from "react-icons/fi";
import styles from './index.module.css'
import {RootState} from "store/reducers";
import {setChildFiles, setFolderId, setRootFolderId} from "store/folders/actions";
import {stopAllListener} from "store/folders/actions";
import {FcFolder} from "react-icons/all";

const Folders = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const {name: userName, uid, permissions} = useSelector((state: RootState) => state.auth)

    useEffect(() => {
        return () => {
            dispatch(stopAllListener());
        }
    }, [dispatch])

    if (uid === null) throw new Error("No login user found!");

//State is pass in history.push to save some important value to run the Dashboard component. Depending on the values inside the state, the Dashboard
//components is gonna run in one way or other.
    return (
        <ContentContainer>
            <PageTitle
                text='Dateiverwaltung'
            />
            <div className={styles.foldersContainer}>
                {/*{permissions.files?.user !== undefined &&*/}
                {/*    <div*/}
                {/*        id={'UserFolder'}*/}
                {/*        className={styles.folders}*/}
                {/*        onClick={() => {*/}
                {/*            //dispatch(setRootFolderId(uid))*/}
                {/*            history.push(`/dateiverwaltung/${uid}`)*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        <div className={styles.div_icon_folders_user}>*/}
                {/*            <FiFolder className={styles.icons}/>*/}
                {/*            <p className={styles.labels}>{userName}</p>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*}*/}
                <div id={'all'} className={styles.folders}
                     onClick={() => {
                         dispatch(setFolderId(null));
                         dispatch(setRootFolderId(""));
                         // dispatch(setRootFolderId("all"))
                         history.push({
                             pathname: `/dateiverwaltung/dash/all`,
                             state: {section:"dateiverwaltung", folderType: "all"}
                         })
                     }}
                >
                    <div className={styles.div_icon_folders}>
                        <FcFolder className={styles.icons}/>
                        <div className={styles.labels}>Alle</div>
                    </div>
                </div>
                <div id={'pinnwand'} className={styles.folders}
                     onClick={() => {
                         dispatch(setFolderId(null));
                         dispatch(setRootFolderId(""));
                         // dispatch(setRootFolderId("pinnwand"))
                         history.push({
                             pathname: `/dateiverwaltung/dash/pinnwand`,
                             state: {section:"dateiverwaltung", folderType: "pinnwand"}
                         })
                     }}
                >
                    <div className={styles.div_icon_folders_infotouch}>
                        <FcFolder className={styles.icons}/>
                        <div className={styles.labels}>Pinnwand</div>
                    </div>
                </div>
                <div id={'infosystemFiles'} className={styles.folders}
                     onClick={() => {
                         dispatch(setFolderId(null));
                         dispatch(setRootFolderId(""));
                         // dispatch(setRootFolderId("pinnwand"))
                         history.push({
                             pathname: `/dateiverwaltung/dash/infosystemFiles`,
                             state: {section:"dateien", folderType: "all"}
                         })
                     }}
                >
                    <div className={styles.div_icon_folders_infotouch}>
                        <FcFolder className={styles.icons}/>
                        <div className={styles.labels}>Infosystem Dateien</div>
                    </div>
                </div>
            </div>
        </ContentContainer>
    )
}

export default Folders

