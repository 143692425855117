import React from 'react'
import Select from 'react-dropdown-select'
import { FiX } from 'react-icons/fi'
import { Button } from '.'
import styles from './Dropdown.module.css'

interface Props {
	name: string,
	options: object[],
	value: any,
	labelField: string,
  	valueField: string,
	onChange: (value: any) => void,
	style?: string,
	disable?: boolean,
	cleanButton?:boolean,
}

function getValue(options: any, value: any, valueField: string, labelField: string) {
	const selectedOption = options.find((option: any) => option[valueField] === value);

	return selectedOption?.[labelField] ?? value;
}

const Dropdown = ({ name, options, value, onChange, style, valueField, labelField, disable, cleanButton = true}: Props) => (
	<div className={`${styles.container} ${style}`}>
		<Select
			disabled={disable}
			options={options}
			values={value ? [value] : []}
			contentRenderer={() =>
				value ? (
					<div className={styles.selected}>
						{cleanButton && <Button
							onClick={event => {
								onChange(null)
								event.stopPropagation()
							}}
							icon={FiX}
							style={styles.button}
							transparent
							light
						/>}
						<div
							style={{ paddingLeft: 4 }}>{getValue(options, value, valueField, labelField)}</div>
					</div>
				) : (
					<div className={styles.selected} style={{ paddingLeft: 4 }}>
						{name}
					</div>
				)
			}
			itemRenderer={({ item, methods }: any) => (
				<div key={item.name} className={styles.item} onClick={() => methods.addItem(item)}>
					{item.name}
				</div>
			)}
			valueField={valueField}
			labelField={labelField}
			onChange={values => onChange(values[0] ? values[0][valueField] : null)}
		/>
	</div>
)

Dropdown.defaultProps = {
	labelField: 'name',
	valueField: 'name',
}

export default Dropdown
