import { CollectionReference, DocumentData, query, QueryConstraint } from "firebase/firestore";

type t_path = CollectionReference<DocumentData>;
type t_conditions=QueryConstraint[];

/**
 * A class to add new conditions to a query
 * @constructor Needs collection of the wanted Documents.
 * @add Added new condition to query
 * @getQuery Creat the query
 */
class QueryBuilder {
    /**
     * In Firebase v8 is it possible to write request.where().limit.usw... this is in Firebase v9 not possible because of the new quere function.
     * So you must now all conditions before you can create your query
     * This class fix that problem you can add every condition you want with the add function;
     */
    private readonly path: t_path;
    private readonly conditions: t_conditions;

    constructor(path:t_path,conditions?:t_conditions|QueryConstraint){
        this.path=path;
        if(conditions) this.conditions=Array.isArray(conditions)?conditions:[conditions];
        else this.conditions=[];
    }

    /**
     * Add conditions
     * @param q to added conditions
     * @return a reference of the QueryBuilder object
     */
    add(...q: QueryConstraint[]){
        this.conditions.push(...q)
        return this;
    }

    /**
     * Add conditions
     * @param q to added conditions
     * @return a new QueryBuilder with the old and to added conditions
     */
    addToNew(...q: QueryConstraint[]){
        return new QueryBuilder(this.path,this.conditions.concat(q));
    }



    getQuery(){
        return query(this.path,...this.conditions);
    }
}

export default QueryBuilder;
