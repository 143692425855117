import React, {Suspense} from 'react';
import ChatButton from "components/ChatButton";
import styles from './MessageBox.module.css'
import {FiImage} from "react-icons/fi";
import {ActivityIndicator, MessageImage, Row} from "components/index";
import MessageFile from "components/MessageFile/MessageFile";
import {isLink, makeLink} from "lib/helpers";
import {t_data, t_file} from "components/MessageImage/type";
import {MessengerImage} from "@pixel-kraft/commulino-types";

type t_reply = {
    title: string,
    message: string | undefined,
    photoURL?: string,
    dataURL?: string
}
type t_button = {
    style?: React.CSSProperties,
    icon: React.ComponentType<{ style?: React.CSSProperties, className?: string }>,
    onClick: () => void,
}
type t_MessageBoxProps = {
    position: 'left' | 'right',
    type: "photo" | "file" | "text",
    text?: string,
    textOnClick?: (id: string) => void,
    textBoxStyle?: React.CSSProperties,
    id?: string,
    dateString: string,
    title?: string,
    titleBoxStyle?: React.CSSProperties,
    titleOnClick?: (id: string) => void,
    data?: t_data
    onDownload?: (url: string) => void,
    reply?: t_reply,
    buttons?: t_button[],
    imgStyle?: React.CSSProperties,
    imgOnClick?: (index: number) => void,
    onClick?: (id: string) => void,
    chatID: string,
    forwarded?: boolean,
    maxWidth?: number,
    highlight?: React.CSSProperties['backgroundColor']
}

/**
 * Rendert eine Msg Komponente. Falls ein XXXXonClick definiert ist wird nur diese function ausgeführt wenn auf die entsprechende Komponente geklickt wird, nicht aber onClick!
 * @param position Legt fest wo die Komponente gerendert werden soll
 * @param imgStyle Wird zum Bild hinzugefügt
 * @param buttons Ein Array von Buttons wird wenn postion=left in der übergeben Reihenfolge angezeigt bei right umgekehrt
 * @param data Kann ein Bild oder eine Datei enthalten
 * @param onClick Gilt für das ganze Message Object
 * @param dateString Das Datum
 * @param id wird bei onClick zurück gegeben
 * @param onDownload Beim click auf data
 * @param reply wenn definiert wird ein reply gerendert
 * @param text der Text der Nachricht
 * @param textBoxStyle Style für das div in dem der Text steht
 * @param title Der Title der Nachricht (Absender)
 * @param titleBoxStyle Der Style für das div was den Title umgibt
 * @param type Ob es ein normale Nachricht ist oder mit extras
 * @param imgOnClick beim Click auf ein Bild
 * @param textOnClick beim Click auf Text
 * @param titleOnClick beim Click auf Title
 */
const MessageBox = ({
                        position,
                        imgStyle,
                        buttons,
                        data,
                        onClick,
                        dateString,
                        id,
                        onDownload,
                        reply,
                        text,
                        textBoxStyle,
                        title,
                        titleBoxStyle,
                        type,
                        imgOnClick,
                        textOnClick,
                        titleOnClick,
                        chatID,
                        forwarded,
                        maxWidth,
                        highlight
                    }: t_MessageBoxProps) => {

    /**
     * Render ein Array von Buttons
     */
    const RenderButtons = (props: { buttons: t_button[] }) => {
        return (
            <div className={`${styles.buttonsContainer}`} style={{justifyContent: position}}>
                <div style={{flex: 1, maxWidth: 200, justifyContent: "space-around", display: 'flex'}}>
                    {props.buttons.map((b, i) => {
                        return (
                            <ChatButton key={i} icon={b.icon} style={{
                                ...b.style
                            }} onClick={b.onClick} className={styles.renderButtons}/>
                        )
                    })
                    }
                </div>
            </div>
        )
    }
    /**
     * Rendert eine Reply object
     */
    const RenderReply = (reply: t_reply) => {
        return (
            <div className={styles.reply}>
                <div className={styles.replayHead}>
                    <div className={styles.replayTitle}>{reply.title}</div>
                    {reply.photoURL &&
                        <img alt={'404'} src={reply.photoURL} className={styles.replayImage}/>
                    }
                </div>
                {reply.dataURL && reply.message &&
                    <div>
                        <MessageFile text={reply.message} file={reply.dataURL} forwarded={false} getCursor={getCursor}
                                     reply={true}/>
                    </div>
                }
                {!reply.dataURL &&
                    <div className={styles.row}>
                        {reply.photoURL &&
                            <div className={`${styles.replayText} ${styles.row}`}><FiImage/></div>
                        }
                        {reply.message && <div style={{width: '100%'}} className={styles.text}>{reply.message}</div>}
                    </div>
                }

            </div>
        );
    }

    /**
     * Testet ob die entsprechende Komponente ein Onclick event hat on wenn ja wird der Courser hier auf pointer gesetzt.
     */
    const getCursor = (func: any): React.CSSProperties => {
        return {
            cursor: func || onClick ? 'pointer' : 'default'
        }
    }

    const RenderText = (text: string) => {
        const words = text.trim().split(' ');

        return (
            <>
                {
                    words.map((word, index) => {
                        const spacer = (index === words.length - 1) ? '' : ' ';
                        if (isLink(word)) {
                            return (
                                <span key={index} onClick={(event) => event.stopPropagation()}>
                          <a rel="noreferrer noopener" target="_blank" href={makeLink(word)}>{word}</a>{spacer}
                        </span>
                            )
                        }

                        return word + spacer;
                    })
                }
            </>
        )
    }

    return (
        <div style={{display: 'block', position: 'relative'}}>
            <div style={{
                position: 'absolute',
                inset: 0,
                backgroundColor: highlight ?? 'rgba(0,0,0,0)',
                opacity: highlight ? 0.3 : 0,
                transition: 'all 0.3s ease'
            }}/>
            <div className={`${styles.container}`}
                 style={{justifyContent: position === 'left' ? 'flex-start' : 'flex-end'}}>
                {position === 'left' &&
                    <div className={styles.dreieckLeft}/>
                }
                {position === 'right' && buttons &&
                    <RenderButtons buttons={buttons.reverse()}/>
                }
                <div
                    className={`${styles.msgBox}`}
                    onClick={() => {
                        if (onClick && id)
                            onClick(id);
                    }}
                    style={{...(position === 'left' ? {borderTopRightRadius: 10} : {borderTopLeftRadius: 10}), ...getCursor(false)}}
                >
                    <div className={`${styles.title}`} style={{...titleBoxStyle, ...getCursor(titleOnClick)}}
                         onClick={(e) => {
                             if (titleOnClick && id) {
                                 e.stopPropagation();
                                 titleOnClick(id);
                             }
                         }
                         }>
                        {title}
                    </div>
                    <>
                        {type === "photo" && data && id &&
                            <Suspense fallback={<ActivityIndicator/>}>
                                <MessageImage data={data as MessengerImage[]} chatID={chatID} id={id}
                                              forwarded={forwarded === true}
                                              getCursor={getCursor} imgOnClick={imgOnClick}
                                              imgStyle={imgStyle}
                                              maxWidth={maxWidth}
                                />
                            </Suspense>
                        }
                        {type === "file" && data && text && id &&
                            <Suspense fallback={<ActivityIndicator/>}>
                                <MessageFile id={id} chatID={chatID} text={text} file={(data as t_file).uri}
                                             forwarded={forwarded === true} getCursor={getCursor}
                                             onDownload={onDownload}/>
                            </Suspense>
                        }
                        {reply &&
                            <RenderReply {...reply} />
                        }
                        {type !== "file" && text &&
                            <div className={`${styles.text}`} style={{...textBoxStyle, ...getCursor(textOnClick)}}
                                 onClick={(e) => {
                                     if (textOnClick && id) {
                                         e.stopPropagation();
                                         textOnClick(id);
                                     }
                                 }}>
                                {RenderText(text)}
                            </div>
                        }
                    </>
                    <div className={`${styles.date}`}>
                        {dateString}
                    </div>
                </div>
                {position === 'right' &&
                    <div className={styles.dreieckRight}/>
                }
                {position === 'left' && buttons &&
                    <RenderButtons buttons={buttons}/>
                }
            </div>
        </div>
    );
}

export default MessageBox;
