import { useState, useEffect } from 'react';

type t_click=(event:React.MouseEventHandler<HTMLDivElement> | undefined)=>void;

function useSingleAndDoubleClick(actionSimpleClick:t_click, actionDoubleClick:t_click, delay = 250,simpleClickOnClick= false,event?:React.MouseEventHandler<HTMLDivElement>) {
    const [click, setClick] = useState(0);

    useEffect(() => {
        if(simpleClickOnClick) actionSimpleClick(event);
        const timer = setTimeout(() => {
            // simple click
            if (click === 1&&!simpleClickOnClick) actionSimpleClick(event);
            setClick(0);
        }, delay);

        // the duration between this click and the previous one
        // is less than the value of delay = double-click
        if (click === 2) actionDoubleClick(event);

        return () => clearTimeout(timer);

    }, [click]);

    return () => setClick(prev => prev + 1);
}

export default useSingleAndDoubleClick;