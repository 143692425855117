import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/reducers'
import { editOnboardingPost, initNewOnboardingPost } from 'store/posts/actions'
import {
	editOnboardingTemplate,
	initOnboardingTemplate,
	movePostDown,
	movePostUp,
	removeOnboardingPost,
	saveOnboardingTemplate,
	setEditingTemplate
} from 'store/onboarding/actions'
import { FiArrowDown, FiArrowUp, FiPlus, FiSave, FiTrash } from 'react-icons/fi'
import {
	ActionModal,
	Button,
	ContentContainer,
	DynamicTable,
	FormItem,
	PageTitle,
	Typo
} from 'components'
import Details from '../../posts/Details'
import styles from './index.module.css'

const OnboardingEdit = () => {
	const history = useHistory()
	const params = useLocation<{ id?: string }>()
	const id = params.state?.id

	const dispatch = useDispatch()
	const template = useSelector((state: RootState) => state.onboarding.editing?.data)

	const [deleteModal, setDeleteModal] = useState<number | null>(null)
	const [incomplete, setIncomplete] = useState(false)

	const save = () => {
		if (!template?.title) return setIncomplete(true)
		dispatch(saveOnboardingTemplate())
		history.push('/onboarding')
	}

	useEffect(() => {
		if (!id) {
			dispatch(initOnboardingTemplate())
		} else {
			dispatch(setEditingTemplate(id))
		}
		// eslint-disable-next-line
	}, [id])

	return (
		<ContentContainer>
			<PageTitle text='Onboarding-Vorlage' />
			{template && (
				<>
					<FormItem
						value={template.title}
						onChange={title => dispatch(editOnboardingTemplate(template.id, { title }))}
						name='Titel der Vorlage'
						requiredMessage='Bitte geben Sie einen Titel an.'
						showRequired={incomplete && !template.title}
					/>
					<div className={styles.row}>
						<Typo variant='h3'>Beiträge</Typo>
						<Button
							onClick={() => dispatch(initNewOnboardingPost(template.posts.length))}
							icon={FiPlus}
							text='Beitrag hinzufügen'
						/>
					</div>
					<DynamicTable
						headers={['Reihenfolge', 'Titel']}
						rows={template.posts
							.sort((a, b) => (a.index < b.index ? -1 : 1))
							.map(post => ({
								key: post.index.toString(),
								cells: [
									{ content: post.index + 1 },
									{ content: post.title },
									{
										content: (
											<div className={styles.btnRow}>
												<Button
													onClick={e => {
														e.stopPropagation()
														dispatch(movePostDown(post.index))
													}}
													icon={FiArrowUp}
													transparent
												/>
												<Button
													onClick={e => {
														e.stopPropagation()
														dispatch(movePostUp(post.index))
													}}
													icon={FiArrowDown}
													transparent
												/>
												<Button
													onClick={e => {
														e.stopPropagation()
														setDeleteModal(post.index)
													}}
													icon={FiTrash}
													text='Löschen'
													transparent
												/>
											</div>
										)
									}
								]
							}))}
						onRowClick={item => dispatch(editOnboardingPost(parseInt(item.key)))}
					/>
					<Details />
					<div className={styles.row}>
						<div />
						<Button onClick={save} icon={FiSave} text='Vorlage speichern' large />
					</div>
				</>
			)}
			<ActionModal
				button={
					<Button
						onClick={() => {
							if (deleteModal!==null) dispatch(removeOnboardingPost(deleteModal)) //If number = 0 as boolean => false
							setDeleteModal(null)
						}}
						text='Löschen'
						icon={FiTrash}
					/>
				}
				hide={() => setDeleteModal(null)}
				visible={deleteModal !== null}
				text='Wollen Sie diesen Beitrag aus der Onboarding-Vorlage löschen?'
				title='Beitrag löschen'
			/>
		</ContentContainer>
	)
}

export default OnboardingEdit
