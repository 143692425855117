import React from 'react'
import {CheckBox, Typo, AnimatedView} from 'components'
import {Permissions} from '@pixel-kraft/commulino-types'
import config, {BewohnerVerzeichnis, Infosystem, files, calendar, Infotouch} from 'config'
import styles from './EditPermissions.module.css'
import Dropdown from "components/Dropdown";
import {SpecialAttachment} from "lib/globaleVars";

interface Props {
    permissions?: Permissions,
    setPermissions: (permissions: any) => void
}

const EditPermissions = ({permissions, setPermissions}: Props) => {
    const editPer: { [index: string]: string } = {
        'own': 'Eigene',
        'group': 'In der gleichen Gruppe',
        'all': 'Alle'
    }

    const AttachmentNames: { [index: string]: string } = {
        'job': 'Job Attachment erlauben'
    }

    const todoListPer: { [index: string]: string } = {
        'all': 'Alle',
        'group': "In der gleichen Gruppe"
    }

    return (
        <div style={{marginTop: 12}}>
            <Typo variant='h5'>Berechtigungen auswählen</Typo>
            <CheckBox
                checked={!!permissions?.posts?.in_app}
                text='Neue Beiträge in der App erstellen (nur Text und Bild)'
                onChange={checked => {
                    if (permissions?.posts?.backend) {
                        setPermissions(({posts, ...prev}: any) => ({
                            ...prev,
                            posts: {
                                ...posts,
                                in_app: checked,
                            }
                        }))
                    } else {
                        setPermissions(({posts, ...prev}: any) => ({
                            ...prev,
                            ...(checked && {
                                posts: {
                                    ...posts,
                                    in_app: true,
                                    edit: 'own',
                                    create_public: false
                                }
                            }),
                        }))
                    }
                }}
                style={styles.checkBox}
            />
            <CheckBox
                checked={!!permissions?.posts?.backend}
                text='Vollständige Beiträge auf der Pflegeseite erstellen'
                onChange={checked => {
                    if (permissions?.posts?.in_app) {
                        setPermissions(({posts, ...prev}: any) => ({
                            ...prev,
                            posts: {
                                ...posts,
                                backend: checked,
                            }
                        }))
                    } else {
                        setPermissions(({posts, ...prev}: any) => ({
                            ...prev,
                            ...(checked && {
                                posts: {
                                    ...posts,
                                    backend: true,
                                    edit: 'own',
                                    create_public: false
                                }
                            })
                        }))
                    }
                }}
                style={styles.checkBox}
            />
            <AnimatedView show={!!permissions?.posts?.edit} inBox={{marginLeft: 24}}>
                <div>
                    <CheckBox
                        text={'Darf öffentliche Beiträge erstellen'}
                        checked={permissions?.posts?.create_public === true}
                        onChange={checked => setPermissions((prev: any) => ({
                            ...prev,
                            posts: {
                                ...prev.posts,
                                create_public: checked
                            }
                        }))}
                    />
                    {Object.keys(SpecialAttachment).map((a, index) => {
                        return (
                            <CheckBox
                                key={index}
                                text={AttachmentNames[a] ? AttachmentNames[a] : 'UNKNOWN'}
                                checked={permissions?.posts?.attachment ? permissions.posts.attachment[a] : false}
                                onChange={checked => setPermissions(({posts, ...prev}: Permissions) => {
                                    const {attachment: {[a]: __del, ...attachment} = {}} = posts ?? {};

                                    return {
                                        ...prev,
                                        ...(posts && {
                                            posts: {
                                                ...posts,
                                                attachment: {
                                                    ...attachment,
                                                    ...(checked && {
                                                        [a]: true,
                                                    })
                                                }
                                            }
                                        })
                                    }
                                })}
                            />
                        )
                    })}
                    <div>
                        <div>
                            Welche Beiträge darf der Benutzer bearbeiten/erstellen?
                        </div>
                        <Dropdown
                            name={'Welche Posts darf er bearbeiten?'}
                            options={Object.keys(editPer).map(val => ({value: val, name: editPer[val]}))}
                            value={permissions?.posts?.edit ? editPer[permissions.posts.edit] : editPer['own']}
                            onChange={(val) => {
                                const e = Object.keys(editPer).filter(a => editPer[a] === val)[0];
                                if (e !== undefined) {
                                    setPermissions((prev: any) => ({
                                        ...prev,
                                        posts: {
                                            ...prev.posts,
                                            edit: e
                                        }
                                    }))
                                }
                            }
                            }
                        />
                    </div>
                </div>
            </AnimatedView>

            <CheckBox
                checked={!!permissions?.posts?.comments?.create_post}
                text='Darf Kommentare auf seinen eigenen Beiträge zulassen'
                onChange={checked =>
                    setPermissions((prev: any) => ({
                        ...prev,
                        posts: {
                            ...prev.posts,
                            comments: {
                                ...prev.posts?.comments,
                                create_post: checked
                            }
                        }
                    }))
                }
                style={styles.checkBox}
            />
            <CheckBox
                checked={!!permissions?.posts?.comments?.write}
                text='Darf Kommentare Schreiben'
                onChange={checked =>
                    setPermissions((prev: any) => ({
                        ...prev,
                        posts: {
                            ...prev.posts,
                            comments: {
                                ...prev.posts?.comments,
                                write: checked
                            }
                        }
                    }))
                }
                style={styles.checkBox}
            />
            <CheckBox
                checked={!!permissions?.messenger?.enabled}
                text='Messenger freigeschaltet'
                onChange={checked =>
                    setPermissions((prev: any) => ({
                        ...prev,
                        messenger: {
                            ...prev.messenger,
                            enabled: checked,
                            ...(!checked && {start_chat: false}),
                            ...(!checked && {seeAll: false})
                        }
                    }))
                }
                style={styles.checkBox}
            />
            <div style={{marginLeft: 24}}>
                <CheckBox
                    checked={!!(permissions?.messenger?.enabled && permissions?.messenger?.start_chat)}
                    text='Neue Unterhaltungen im Messenger starten'
                    onChange={checked =>
                        setPermissions((prev: any) => ({
                            ...prev,
                            messenger: {
                                ...prev.messenger,
                                start_chat: checked,
                                ...(checked && {enabled: true}),
                                ...(!checked && {seeAll: false})

                            }
                        }))
                    }
                    style={styles.checkBox}
                />
                {permissions?.messenger?.start_chat &&
                    <div style={{marginLeft: 24}}>
                        <CheckBox
                            checked={permissions.messenger.seeAll === true}
                            text={"Darf alle Benutzer sehen"}
                            onChange={checked => setPermissions((prev: any) => ({
                                ...prev,
                                messenger: {
                                    ...prev.messenger,
                                    seeAll: checked
                                }
                            }))
                            }/>
                    </div>
                }
            </div>
            {BewohnerVerzeichnis && (
                <div>
                    <CheckBox
                        checked={!!permissions?.residentDirectory?.enabled}
                        text='Bewohnerverzeichnis freigeschaltet'
                        onChange={checked =>
                            setPermissions((prev: any) => ({
                                ...prev,
                                residentDirectory: {
                                    ...prev.residentDirectory,
                                    enabled: checked,
                                    ...(!checked && {edit: false})
                                }
                            }))
                        }
                        style={styles.checkBox}
                    />
                    <div style={{marginLeft: 24}}>
                        <CheckBox
                            checked={!!(permissions?.residentDirectory?.enabled && permissions?.residentDirectory?.edit)}
                            text='Bewohnerverzeichnis bearbeiten'
                            onChange={checked =>
                                setPermissions((prev: any) => ({
                                    ...prev,
                                    residentDirectory: {
                                        ...prev.residentDirectory,
                                        edit: checked,
                                        ...(checked && {enabled: true})
                                    }
                                }))
                            }
                            style={styles.checkBox}
                        />
                    </div>
                </div>
            )}
            <CheckBox
                checked={!!permissions?.manage_users}
                text='Mitarbeiter hinzufügen und verwalten'
                onChange={checked =>
                    setPermissions((prev: any) => ({
                        ...prev,
                        manage_users: checked
                    }))
                }
                style={styles.checkBox}
            />
            <CheckBox
                checked={!!permissions?.edit_settings}
                text='Einstellungen verwalten (Kategorien, Standorte, Abteilungen...)'
                onChange={checked =>
                    setPermissions((prev: any) => ({
                        ...prev,
                        edit_settings: checked
                    }))
                }
                style={styles.checkBox}
            />
            {files &&
                <>
                    <CheckBox
                        checked={!!permissions?.files}
                        text='Datenverwaltung'
                        onChange={checked =>
                            setPermissions(({files, ...prev}: any) => ({
                                ...prev,
                                ...(checked && {
                                    files: {
                                        group: {
                                            read: true,
                                            write: true
                                        },
                                        write_special: false
                                    }
                                })
                            }))
                        }
                        style={styles.checkBox}
                    />
                    <AnimatedView show={!!permissions?.files} inBox={{marginLeft: 24}}>
                        <>
                            <Typo br>Der Ordner Alle ist immer verfügbar sobald die Datenverwaltung für den Benutzer
                                aktiviert wurde.</Typo>
                            <CheckBox
                                checked={!!permissions?.files?.user}
                                text='Persönlicher Ordner'
                                onChange={checked =>
                                    setPermissions(({files: {group, user}, ...prev}: any) => ({
                                        ...prev,
                                        files: {
                                            group,
                                            ...(checked && {
                                                user: {
                                                    read: true,
                                                    write: true
                                                }
                                            })
                                        }
                                    }))
                                }
                                style={styles.checkBox}
                            />
                            {/*<CheckBox
                            checked={!!permissions?.files?.group.read}
                            text='Hat zugriff auf die Gruppen Ordner'
                            onChange={checked =>
                                setPermissions((prev: any) => ({
                                    ...prev,
                                    files: {
                                        ...prev.files,
                                        group: {
                                            ...prev.files.group,
                                            read: checked
                                        },
                                    }
                                }))
                            }
                            style={styles.checkBox}
                        />
                        <CheckBox
                            checked={!!permissions?.files?.group.write}
                            text='Darf die Gruppen Ordner Bearbeiten'
                            onChange={checked =>
                                setPermissions((prev: any) => ({
                                    ...prev,
                                    files: {
                                        ...prev.files,
                                        group: {
                                            ...prev.files.group,
                                            write: checked
                                        },
                                    }
                                }))
                            }
                            style={styles.checkBox}
                        />
                        <CheckBox
                            checked={!!permissions?.files?.write_special}
                            text='Darf in andere Persönliche Ordner schreiben (NICHT lesen)'
                            onChange={checked =>
                                setPermissions((prev: any) => ({
                                    ...prev,
                                    files: {
                                        ...prev.files,
                                        write_special: checked
                                    }
                                }))
                            }
                            style={styles.checkBox}
                        />*/}
                        </>
                    </AnimatedView>
                </>
            }
            {Infosystem &&
                <CheckBox
                    checked={!!permissions?.infosystem}
                    text='Infosystem Einstellung Bearbeiten'
                    onChange={checked =>
                        setPermissions(({infosystem, ...prev}: any) => ({
                            ...prev,
                            ...(checked && {
                                infosystem: true,
                            })
                        }))
                    }
                    style={styles.checkBox}
                />
            }
            {calendar &&
                <CheckBox
                    checked={!!permissions?.calender}
                    text='Kalender'
                    onChange={checked =>
                        setPermissions(({calender, ...prev}: any) => ({
                            ...prev,
                            ...(checked && {
                                calender: {
                                    read: true,
                                    write: true
                                }
                            })
                        }))
                    }
                    style={styles.checkBox}
                />
            }
            {Infotouch &&
                <CheckBox
                    checked={!!permissions?.infotouch}
                    text='Pinnwand verwalten'
                    onChange={checked =>
                        setPermissions(({infotouch, ...prev}: any) => ({
                            ...prev,
                            ...(checked && {
                                infotouch: {
                                    read: true,
                                    write: true
                                }
                            })
                        }))
                    }
                    style={styles.checkBox}
                />
            }
            {(config as any).todoList &&
                <>
                    <CheckBox
                        checked={!!permissions?.todoList}
                        text={"Aufgaben erstellen"}
                        style={styles.checkBox}
                        onChange={checked =>
                            setPermissions(({todoList, ...prev}: any) => ({
                                ...prev,
                                ...(checked && {
                                    todoList: 'group'
                                })
                            }))}
                    />
                    <AnimatedView show={!!permissions?.todoList}>
                        <div className={styles.checkBox}>
                            <Dropdown
                                name={'Für wen dürfen Aufgaben erstellt werden?'}
                                options={Object.keys(todoListPer).map(val => ({value: val, name: todoListPer[val]}))}
                                value={permissions?.todoList ? todoListPer[permissions.todoList] : todoListPer['group']}
                                onChange={(val) => {
                                    const e = Object.keys(todoListPer).filter(a => todoListPer[a] === val)[0];
                                    if (e !== undefined) {
                                        setPermissions((prev: any) => ({
                                            ...prev,
                                            todoList: e
                                        }))
                                    }
                                }
                                }
                            />
                        </div>
                    </AnimatedView>
                </>
            }
            {(config as any).residents &&
                <CheckBox
                    checked={!!(permissions as any)?.residentList}
                    text={"Bewohner Liste"}
                    style={styles.checkBox}
                    onChange={checked =>
                        setPermissions(({residentList, ...prev}: any) => ({
                            ...prev,
                            ...(checked && {
                                residentList: true
                            })
                        }))}
                />

            }
        </div>
    );
}

export default EditPermissions
