import React, {useEffect} from 'react'
import styles from './Modal.module.css'


type t_main = {
    hide: () => void
    flexWidth?: boolean
    fullscreen?: boolean
    style?: string,
    modalStyle?: string
    contentStyle?: string
}

type t_data<T> = t_main&{
    dataMustBeSet: T|undefined,
    children: (data: T)=>React.ReactNode
}

type t_child = t_main&{
    isVisible: boolean,
    children: React.ReactNode
}

type t_props<T> = t_data<T> | t_child

/**
 * Shows an Overlay.
 * If isVisible is used the Children a rendert instant with the Component.
 * If dataMustBeSet is used the Overlay will show if dataMustBeSet is not undefined and then execute the Child Function
 * @param props
 * @constructor
 */
const Modal = <T,>(props: t_props<T>) => {
    useEffect(() => {
        function handleKeyDown({keyCode}: KeyboardEvent) {
            if (keyCode === 27 && ('isVisible' in props && props.isVisible || 'dataMustBeSet' in props && props.dataMustBeSet)) props.hide()
        }

        document.addEventListener('keydown', handleKeyDown)
        return () => document.removeEventListener('keydown', handleKeyDown)
    })

    return (
        <div className={`${styles.container} ${('isVisible' in props && props.isVisible || 'dataMustBeSet' in props && props.dataMustBeSet) && styles.visible} ${props.style}`} onClick={props.hide}>
            <div
                className={`${styles.contentContainer} ${props.fullscreen && styles.fullscreen} ${props.modalStyle}`}
                style={{width: props.flexWidth ? 'auto' : undefined}}
                onClick={e => e.stopPropagation()}
            >
                <div style={{maxHeight: '90vh'}} className={`${styles.content} ${props.contentStyle}`}>
                    {'isVisible' in props&&props.children}
                    {/*Only render if date is Set*/}
                    {'dataMustBeSet' in props&&props.dataMustBeSet&&props.children(props.dataMustBeSet)}
                </div>

            </div>
        </div>
    )
}

export default Modal
