import React from 'react';
import {useDrag} from "react-dnd";
import {ItemTypes} from "./constant";

type t_props = {
    children: JSX.Element | JSX.Element[]
    index: number,
}

const DragItem =React.forwardRef<HTMLDivElement,t_props>(({children, index},ref) => {
    const [{isDragging}, drag] = useDrag(() => ({
        type: ItemTypes.ITEM,
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
        item: {index}
    }))

    return (
        <div ref={ref}>
            <div ref={drag}>
                {children}
            </div>
        </div>
    )
})
DragItem.displayName="DragItem";
export default DragItem;