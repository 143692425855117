import React, { useState } from 'react'
import FormItem from 'components/FormItem'
import PageTitle from 'components/PageTitle'
import Typo from 'components/Typo'
import styles from './index.module.css'

import logo from '../../assets/commulino-color.svg'
import ColorButton from 'components/ColorButton'
import {getAuth,sendPasswordResetEmail} from "firebase/auth";
import {firebaseApp} from "lib/firebase";

const ForgotPassword = () => {
	const [email, setEmail] = useState('')
	const [done, setDone] = useState(false)
	const [error, setError] = useState(false)

	async function sendResetLink() {
		if (email) {
			try {
				await sendPasswordResetEmail(getAuth(firebaseApp),email)
				setDone(true)
			} catch {
				setError(true)
			}
		}
	}

	return (
		<div className={styles.container}>
			<div className={styles.box}>
				<img src={logo} alt='Logo' className={styles.logo} />
				<PageTitle text='Passwort vergessen' />
				{!done && (
					<>
						<Typo variant='h5'>
							Sie erhalten eine E-Mail mit einem Link zur Passwort-Wiederherstellung.
						</Typo>
						<FormItem
							name='E-Mail'
							type='email'
							value={email}
							onChange={setEmail}
							requiredMessage='E-Mail Adresse stimmt mit keinem Benutzerkonto überein.'
							showRequired={error}
						/>
						<ColorButton
							text='Wiederherstellungs-Link senden'
							onClick={sendResetLink}
						/>
					</>
				)}
				{done && (
					<Typo variant='h5'>
						{`Es wurde eine E-Mail an ${email} gesendet. Folgen Sie den Anweisungen in der E-Mail um das Passwort zurückzusetzen.`}
					</Typo>
				)}
			</div>
		</div>
	)
}

export default ForgotPassword
