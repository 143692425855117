import { Card, ContentContainer, PageTitle, Pagination } from 'components/index';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {getNextFilteredPosts, getPostsByGroup, standardPostNum_byGroup} from 'store/posts/actions';
import { RootState } from 'store/reducers';
import Post from '../pages/posts/Post';
import styles from './ShowPostOfCategory.module.css';

type t_props = {
    cat_ID: string,
}

const ShowPostOfCategory = (props:t_props) => {
    const isLoading = useSelector((state: RootState) => state.posts.loading);
    const posts = useSelector((state:RootState) => state.posts.byId);
    const category = useSelector((state: RootState) => state.config.categories.filter(c => c.id === props.cat_ID));
    const perMSG = useSelector((state: RootState) => state.posts.postsByCategory['G_'+props.cat_ID]);
    const dispatch = useDispatch();
    const [page, setPage] = useState(0)
    const count = 10
    const pages = Math.ceil(perMSG?.posts.length / count);
    useEffect(() => {
        if((!perMSG)||(perMSG&&perMSG.posts.length<standardPostNum_byGroup&&!perMSG.complete))
            dispatch(getPostsByGroup(props.cat_ID,!perMSG));
    }, [dispatch,perMSG,props.cat_ID])
    if (!(props.cat_ID.length > 0))
        return null;
    return (
        <ContentContainer loading={isLoading} style={styles.page} contentStyle={styles.content}>
            <PageTitle
                text={category[0].name}
            />
            {perMSG?.posts.slice(page * count, page * count + count).map(post => (
                <Card style={styles.card} key={post.id}>
                    <ContentContainer hideSpacer contentStyle={styles.cardContent}>
                        <Post post={posts[post.id]} />
                    </ContentContainer>
                </Card>
            ))}
            <Pagination count={pages} currentPage={page} goTo={(p)=>{
                if(!perMSG.complete&&p===pages-1)
                    dispatch(getNextFilteredPosts(props.cat_ID));
                setPage(p);
            }} style={styles.pagination} complete={perMSG?perMSG.complete:false} />
            {perMSG?.posts.length === 0 &&
                <div>
                    Es wurde noch kein {category[0].name} erstellt.
                </div>
            }
        </ContentContainer>
    )
}

export default ShowPostOfCategory;
