import React, {useEffect, useState} from "react";
import {CommuLinoLoading, ContentContainer, PageTitle} from "components";
import NewCalendar from "./components/NewCalendar";
import AddEvent from "./components/AddEvent";
import CalendarFrame from "./components/CalendarFrame";
import styles from "./index.module.css"
import {
  firebaseApp,
} from 'lib/firebase';
import {GoCalendar} from "react-icons/all";
import SelectCalendars from "./components/SelectCalendars";
import {
    collection,
    getFirestore, onSnapshot,
} from 'firebase/firestore';
import {t_eventData} from "components/Kalender/types";
import {t_infoCalenderTypes} from "@pixel-kraft/commulino-types";
import EventInfoModal from "components/Kalender/EventInfoModal";

export interface Calendars {
    calendarId: string,     // Stores the google and outlook URL
    color: string,
    email: string,
    name: string,
    timeZone: string,
    user: string,
    id: string,
    selected: boolean,
    departments?: string[],
    locations?: string[],
    infoSystemType?: t_infoCalenderTypes,
    allowEventRequest:boolean,
    type: 'outlook'|'google'
}

export interface Wiederholung {
    value: string,
    label: string,
    icon: any
}

export interface Option {
    value: string,
    label: string,
    icon: JSX.Element,
    color: string,
}

export const useCalendarOptions = (calendars:Calendars[], setChangeCode:(to:any)=>void, admin:boolean) => {
    /**
     * @param calendars It's a calendar's array, provided from admin or user.
     * @param setChangeCode To create a new reference for isEqual and avoid multi rerenders.
     * @param admin To filter by permissions.
     *
     * It returns all the calendars that are allow to create events depending on the user's permissions, if it's
     * an admin or a regular user. And by calendar's type. Outlook calendars are not allow to create events.
     * */

    const [options, setOptions] = useState<Option[]>([])
    useEffect(() => {
        if (calendars) {
            let googleCalendars: Calendars[] = calendars.filter((calendar) => calendar.type === 'google');
            if (!admin) {
                googleCalendars = googleCalendars.filter(calendar => calendar.allowEventRequest)
            }
            setOptions(googleCalendars.map((calendar) => {
                return ({
                    value: calendar.calendarId,
                    label: calendar.name,
                    icon: <GoCalendar/>,
                    color: calendar.color,
                    departments: calendar.departments ? calendar.departments : [],
                    locations: calendar.locations ? calendar.locations : [],
                    id: calendar.id
                })
            }));
            setChangeCode({}); //Create new reference for isEqual
        }
    }, [calendars])
    return options
}

const Kalender = () => {
    const [calendars, setCalendars] = useState<Calendars[]>([]);
    const [loading, setLoading] = useState(true);
    const [eventRequestModal, setEventRequestModal] = useState(false);
    const [eventDetailsModal, setEventDetailsModal] = useState(false);
    const [avoidAlertAfterDelete, setAvoidAlertAfterDelete] = useState<boolean>(false);
    const [eventData, setEventData] = useState<Partial<t_eventData>>();
    const [changeCode, setChangeCode] = useState<any>(); //It's an Object reference of the change to render components. Not re-render unnecessary times the frame
    const options = useCalendarOptions(calendars, setChangeCode,true)
    useEffect(() => {
        return onSnapshot(collection(getFirestore(firebaseApp), 'calendars'), snapshot => {
            const calendarsSnap: Calendars[] = [];
            snapshot.docs.forEach(doc => {
                const calendar = doc.data() as Calendars;
                // To handle the calendars already created that don't have this field stored in Firebase
                if (calendar.allowEventRequest === undefined) calendar["allowEventRequest"] = true;
                if (calendar.type === undefined) calendar["type"] = 'google';
                calendarsSnap.push({...calendar, id: doc.id, selected:true});
            })
            setCalendars(calendarsSnap);
            setLoading(false);
        })
    }, [])

    return (
        <ContentContainer>
            {loading ? (
                <div>
                    <CommuLinoLoading/>
                </div>
            ) : (
                <div>
                    <PageTitle text={"Kalender"}/>
                    <div style={{marginTop: "50px"}}>
                        <div style={{position: "relative"}}>
                            <div className={styles.icons}>
                                <AddEvent
                                    calendars={calendars}
                                    modal={eventRequestModal}
                                    setModal={setEventRequestModal}
                                    options={options}
                                    eventData={eventData}
                                    setEventData={setEventData}
                                    setChange={setChangeCode}
                                    admin={true}
                                />
                                <NewCalendar
                                    calendars={calendars}
                                    setChange={setChangeCode}
                                />
                            </div>
                        </div>
                        {calendars.length === 0 ? (
                            <div className={`${styles.noCalendars} ${styles.wrapperEmpty} ${styles.contentWrapper}`}>
                                <div className={styles.imageContent}>
                                    <div className={styles.emptyImage}>
                                        <GoCalendar style={{fontSize: "100px", color: "#8c8c8c"}}/>
                                    </div>
                                </div>
                                <div className={styles.textContent}>
                                    <p className={styles.notFound}>Keine Ergebnisse gefunden</p>
                                    <p style={{width: "400px"}}>Es gibt keine Kalender. Erstellen Sie einen
                                        Kalender.</p>
                                </div>
                            </div>
                        ) : (
                            <div style={{position: "relative", display: "flex"}}>
                                <div style={{minWidth: 200}}>
                                    <SelectCalendars
                                        calendars={calendars}
                                        setCalendars={setCalendars}
                                        admin={true}
                                        setChange={setChangeCode}
                                        setAvoidAlertAfterDelete={setAvoidAlertAfterDelete}
                                    />
                                </div>
                                <div style={{flexGrow: 1}}>
                                    <CalendarFrame
                                        calendars={calendars}
                                        setEventDetailsModal={setEventDetailsModal}
                                        setEventRequestModal={setEventRequestModal}
                                        options={options}
                                        setEventData={setEventData}
                                        change={changeCode}
                                        admin={true}
                                        setAvoidAlertAfterDelete={setAvoidAlertAfterDelete}
                                        avoidAlertAfterDelete={avoidAlertAfterDelete}
                                    />
                                </div>

                            </div>
                        )}

                    </div>
                </div>
            )}
            <EventInfoModal     // Outlook events
                modal={eventDetailsModal}
                options={options}
                eventData={eventData}
                setModal={setEventDetailsModal}
            />
        </ContentContainer>
    )
}

export default Kalender
