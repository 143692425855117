import React from 'react';
import styles from "../../messenger.module.css";
import ChatInfo from "../../ChatInfo";
import useWindowDimensions from "lib/useWindowDimensions";

type t_props = {
    show?: boolean,
    chatId: string,
    close: () => void,
    src?: string,
    onAction: React.ComponentProps<typeof ChatInfo>["onAction"]
}

const MessengerChatInfo = ({chatId, show, close,src,onAction}: t_props) => {
    const {height} = useWindowDimensions();

    return (
        <div style={{
            height: show ? height : 0,
            position: 'relative',
            zIndex: 2,
            width: '100%'
        }}>
            <div style={{display: 'flex', position: 'relative'}}>
                <div className={styles.tk_msg_overlay} style={{display: show ? 'block' : 'none'}}>
                    {show &&
                    <ChatInfo preLoadImg={src} id={chatId} goBack={close} onAction={onAction}
                              onSubmit={close}/>
                    }
                </div>
            </div>
        </div>
    )
}
export default MessengerChatInfo;
