import {Chat, ChatEvent, Message} from "@pixel-kraft/commulino-types";
import {
  isMessage,
  SystemMessage,
  User,
} from '@pixel-kraft/commulino-types';
import {firebaseApp} from "lib/firebase";
import {doc,getFirestore,getDoc} from "firebase/firestore";

export const getImage = (item: Chat,uid:string) => {
    if (item.group !== undefined) {
        if (item.group.photo !== undefined) {
            return item.group.photo;
        }
    } else {
        const help = item.users[0].uid === uid ? 1 : 0;
        if (item.users[help].photo !== undefined)
            return item.users[help].photo;
    }
    return undefined;
}


export const getTitele = (item: Message,uid:string,users: Pick<User, "uid" | "name" | "photo">[]) => {
    if (item.uid === uid)
        return 'Ich'
    for (const user of users) {
        if (user.uid === item.uid)
            return user.name
    }
    return ''
}
export const getMsgByID = (id: string,messages: Array<Message | SystemMessage>|undefined) => {
    if (messages) {
        for (let i = 0; i < messages.length; ++i) {
            if (id === messages[i].id)
                return messages[i];
        }
        return undefined;
    }
    return undefined;
}
export const getReply = (msg: Message | SystemMessage,uid:string,messages:Array<Message | SystemMessage>|undefined,users: Pick<User, "uid" | "name" | "photo">[]) => {
    if (isMessage(msg) && msg.replyTo) {
        const m = getMsgByID(msg.replyTo,messages) as Message;
        if (m) {
            if (m.delete || m.deleteFor?.includes(uid)) return;
            let erg: {
                title: string,
                message: string | undefined,
                photoURL?: string,
                dataURL?: string,
                termin?: ChatEvent,
            } = {
                title: getTitele(m,uid,users),
                message: m.text ? m.text : '',
            }
            if (m.images) {
                erg.photoURL = m.images[0].uri
            } else if (m.attachments) {
                erg.dataURL = m.attachments[0].source;
                erg.message = m.attachments[0].name;
            } else if (m.postAttachments) {
                for (const att of m.postAttachments) {
                    switch (att.type) {
                        case 'event': {
                            if (att.data) {
                                erg.termin = att.data;
                            }
                        }
                    }
                }
            }
            return erg;
        }
    }
}

/**
 * Es wir erst Local nach der MSG gesucht falls sie noch nicht geladen würde wird eine abfrage an firebase gestated.
 * @param chat Der zu durchsuche Chat
 * @param msgID Die msg ID nach der gesucht werden soll
 */
export const getMsgInfoByID=async (chatID:string,msgID:string,messages?: Array<Message | SystemMessage>)=>{
    const m=getMsgByID(msgID,messages);
    if(m) return m;
    const f=await getDoc(doc(getFirestore(firebaseApp),'chats',chatID,'messages',msgID));
    return f.data() as (Message | SystemMessage);
}
