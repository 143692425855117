import React, {useEffect} from 'react';
import {Redirect, useHistory} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "store/hooks";
import {logoLightSvg as logo} from "config";
import {ActivityIndicator, Row} from "components";
import {AuthSDK} from 'lib/sdk';
import {setLoginProcess} from "store/auth/actions";

const MediCareAuth = () => {
    const {isLoggedIn, personnelId} = useAppSelector((state) => state.auth)
    const {pw:password, nr:pNr} = Object.fromEntries(window.location.hash.substr(1).split(';').map(v => v.split('=')))
    const history = useHistory();
    const dispatch = useAppDispatch();

    if (!pNr||!password) return <Redirect to={'/login'}/>

    useEffect(() => {
        if (isLoggedIn && personnelId === pNr) {
            history.replace('/feed');
        } else if (isLoggedIn && personnelId !== pNr) {
            dispatch(setLoginProcess(true));
            dispatch(AuthSDK.functions.logout()); //Will change isLoggedIn, personnelId after success and triggers this hook again
        } else if(!isLoggedIn&&pNr&&password)
            dispatch(setLoginProcess(true));
            AuthSDK.functions.singInWithPerNrAndPassword(pNr,password)//Will change isLoggedIn, personnelId after success and triggers this hook again
                .then(()=>dispatch(setLoginProcess(false)))
                .catch((e)=>{
                    dispatch(setLoginProcess(false))
                    history.replace('/login')
                })

    }, [isLoggedIn, personnelId, pNr, dispatch,password])

    return (
        <Row style={{position: 'fixed', inset: 0, backgroundColor: '#f4f5f7', justifyContent: 'center'}}>
            <Row style={{
                position: 'relative',
                flex: 1,
                justifyContent: 'center'
            }}>
                <img width={200} height={33} src={logo} alt='CommuLino Logo'
                     style={{filter: 'invert(20%)', objectFit: 'contain', margin: 32}}/>
                <ActivityIndicator large color='#cbcbcb'/>
                <div style={{
                    position: 'absolute',
                    top: 104,
                    left: 0,
                    right: 0,
                    display: 'flex',
                    padding: '10px 5px',
                }}>
                    <Row style={{
                        alignItems: 'center',
                        flexFlow: 'column',
                        flex: 1,
                        maxHeight: 'calc(50vh - 72px)',
                        overflowY: 'auto'
                    }}>
                        Login...
                    </Row>
                </div>
            </Row>
        </Row>
    )
}

export default MediCareAuth;
