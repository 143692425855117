import React, {CSSProperties, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {RootState} from 'store/reducers'
import Chat from './Chat';
import {t_chatData} from './type';
import {Divider, GroupButtons, Row, SegmentedButtons, Typo} from "components";
import config from 'config';
import {icons} from 'lib/constants';
import SimpleBar from 'simplebar-react';
import {t_WebChat} from "store/chat/types";
import useWindowDimensions from "lib/useWindowDimensions";
import {Animations} from "components";

type t_props<T> = {
    onClick: (elm: t_chatData) => void;
    style?: CSSProperties,
    showScrollBar?: true,
    active?: string,
    fullWidth?: boolean,
    hideUnread?: boolean,
    filter?: {
        filter: (item: t_WebChat,data: T)=>boolean,
        data: T
    }
}
/**
 * Rendert eine Scrollebale list aus Chat Eelmenten
 * @param onClick Ruft die Function mit {id,title} auf
 */
const ChatList = <T,>(props: t_props<T>) => {
    const Chats = useSelector((state: RootState) => state.messenger.Chats);
    const uid = useSelector((state: RootState) => state.auth.uid);
    const [orderChats, setOrderChats] = useState<t_WebChat[]>([]);
    const [archivedChats, setArchivedChats] = useState<t_WebChat[]>([]);
    const [residentChats, setResidentChats] = useState<t_WebChat[]>([]);
    const {height} = useWindowDimensions();
    //const location = useLocation();
    const [show, setShow] = useState<'user' | 'archive' | 'mediCare'>('user');

    useEffect(() => {
        if (uid) {
            const help: t_WebChat[] = [];
            const archived: t_WebChat[] = [];
            const residentChat: t_WebChat[] = [];

            const getArray = (id: string) => {
                const isArchived = Chats[id].archivedBy?.includes(uid);
                const isResRel = !!Chats[id].mediCare;

                if (isArchived) return archived;
                if (isResRel) return residentChat;
                return help;
            }


            Object.keys(Chats).forEach(id => {
                getArray(id).push(Chats[id]);
                if (props.active && props.active === id) {
                    if (Chats[id].archivedBy?.includes(uid))
                        setShow('archive');
                    else if (Chats[id].mediCare)
                        setShow('mediCare');
                    else
                        setShow('user')
                }

            })
            const sortChat = (a: t_WebChat, b: t_WebChat) => {
                if (a.date < b.date)
                    return 1
                if (a.date > b.date)
                    return -1
                return 0
            }
            help.sort(sortChat);
            archived.sort(sortChat);
            residentChat.sort(sortChat);
            setOrderChats(help);
            setArchivedChats(archived);
            setResidentChats(residentChat);
        }
    }, [Chats, uid, props.active])
    if (!uid) throw new Error("Sie sind nicht Angemeldet");
    const renderChatItem = (chatData: t_WebChat) => {
        return <Chat key={chatData.id} onClick={props.onClick} chatID={chatData.id}
                     active={props.active === chatData.id}>
            <Divider/>
        </Chat>

    }

    const computeUnread = (chats: t_WebChat[]) => chats.reduce((prev, {unRead: cur}) => prev + (cur ?? 0), 0);

   const filter = (chat: t_WebChat,filter: t_props<T>['filter'])=>filter?filter.filter(chat,filter.data):true;

    return (
        <Row flex={1} style={{width: '100%'}} flexFlow={'column'}>
            <GroupButtons spaceBetween={4} value={show} onChange={(val) => setShow(val as typeof show)} >
                {[
                    [{label: `${icons.archive.label}${props.hideUnread?'':' '+computeUnread(archivedChats)}`,value: 'archive',icon: icons.archive.icon}],
                    [{label: `${icons.users.label}${props.hideUnread?'':' '+computeUnread(orderChats)}`,value: 'user',icon: icons.users.icon},((config as any).residents ? {
                        label: `${icons.mediCare.label}${props.hideUnread?'':' '+computeUnread(residentChats)}`, value: 'mediCare',icon: icons.mediCare.icon} : [])].flat()
                ]}
            </GroupButtons>
            <SimpleBar autoHide={!props.showScrollBar} style={{maxHeight: height - 123, width: '100%', ...props.style}}>
                <Animations.Switch timeout={300} unmountOnExit mountOnEnter animation={'fade'} active={show}>
                    {[
                        {
                            key: 'archive',
                            item: (archivedChats.length > 0 ? <div>{archivedChats.filter((item)=>filter(item,props.filter)).map(renderChatItem)}</div> :
                                <Typo>Keine Archivierten Unterhaltungen</Typo>)
                        },
                        {
                            key: 'mediCare',
                            item: (residentChats.length > 0 ? <div>{residentChats.filter((item)=>filter(item,props.filter)).map(renderChatItem)}</div> :
                                <Typo>Kein Chat mit einem Bewohner/Kontaktperson</Typo>)
                        },
                        {
                            key: 'user',
                            item: (orderChats.length > 0 ? <div>{orderChats.filter((item)=>filter(item,props.filter)).map(renderChatItem)}</div> :
                                <Typo>Keine Unterhaltungen</Typo>)
                        }
                    ]}

                </Animations.Switch>
            </SimpleBar>
        </Row>
    )
}

export default ChatList;
