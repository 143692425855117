import React, {useEffect, useState} from 'react';
import {ResRelSDK} from "lib/sdk";
import {ColorButton} from "components";
import {FiChevronLeft, FiX} from "react-icons/fi";
import ChatHead from "../ChatInfo/ChatHead";
import useWindowDimensions from "lib/useWindowDimensions";
import {useAppDispatch, useAppSelector} from "store/hooks";
import Container from "../ChatInfo/Container";
import MessengerTextBox from "../Messenger/MessengerTextBox";
import {t_MessageData} from "pages/chat/type";
import ChatUser from "../ChatUser";
import UltimateList from "../ChatList";
import {getObjectKeys} from "lib/helpers";
import {sendToChat,createChat} from "../../functions";
import * as media from "lib/media";
import {t_Contact} from "@pixel-kraft/commulino-types";


type t_compProps = {
    style?: React.CSSProperties,
    className?: string
}

type t_props = t_compProps & {
    goBack: () => void,
    onCreate: (id: string) => void,
    users: t_Contact[], //[...user,me]|[...res,me]|[res,...rel,me]
    mode: 'chat' | ResRelSDK.t_ChatData['mediCare']['type'],
};

const GroupScreen = ({mode, goBack, onCreate, style, users}: t_props) => {
    const {uid,mediCare} = useAppSelector((state) => state.auth);
    const [groupName, setGroupName] = useState(mode==="relativeChat"?users[1].name:'');
    const [img, setImg] = useState('');
    const [imgFile, setImgFile] = useState<Blob | undefined>(undefined);
    const {height} = useWindowDimensions();
    const dispatch = useAppDispatch();
    const [selectedUsers, setSelectedUsers] = useState(users);
    const [loading,setLoading] = useState(false);

    const check = () => {
        const error: {
            residents: boolean,
            name: boolean,
            relatives: boolean,
            users: boolean
        } = {
            residents: false,
            name: false,
            relatives: false,
            users: selectedUsers.length<2
        }

        if(selectedUsers.length>2||mode!=='chat') error.name = groupName.trim().length <= 0;

        if (mode==="relativeChat") {
            error.residents = !users.find(ResRelSDK.isRes);
        } else if(mode==="residentChat") {
            error.relatives = selectedUsers.filter(ResRelSDK.isRel).length>0;
        }
        console.log(error,selectedUsers);
        const k = getObjectKeys(error);
        for (const key of k)
            if (error[key]) return false;
        return true;
    }

    const sendAndCreate = async (msg: t_MessageData) => {
        if (check()) {
            let id='';
            setLoading(true);
            try {
                if (mode !== 'chat') {
                    const includeRes = selectedUsers.find(ResRelSDK.isRes);
                    const u = mode === "relativeChat" && !includeRes ? [users[0], ...selectedUsers] : selectedUsers;
                    id = await dispatch(createChat(u, mode, {
                        name: groupName.trim(),
                    }, mode === "relativeChat"));
                } else {
                    id = await dispatch(createChat(selectedUsers, mode, selectedUsers.length > 2 ? {
                        name: groupName.trim(),
                    } : undefined, false));
                }
                if (img && imgFile) {
                    await media.uploadGroupImage(imgFile, id); //Upload img
                }
                await sendToChat(id, msg);
            } finally {
                setLoading(false);
            }
            onCreate(id);
        }
    }

    const search = <T extends { name: string }, >(name: string, item: T) => item.name.toUpperCase().includes(name.toUpperCase());

    return (
        <div style={{...style, position: 'relative', display: 'flex', flexWrap: 'wrap'}}>
            <div style={{marginLeft: 10, width: '100%', maxWidth: 1000, marginRight: 10}}>
                <div style={{display: 'flex', alignItems: 'center', marginTop: 20, marginBottom: 20}}>
                    <div style={{display: 'flex', maxHeight: 20, alignItems: 'center'}}>
                        <ColorButton icon={FiChevronLeft} onClick={goBack}/>
                    </div>
                </div>

                <ChatHead
                    type={mode !== 'chat' || selectedUsers.length > 2 ? 'group' : 'single'}
                    chatName={mode !== 'chat' || selectedUsers.length > 2 ? groupName : selectedUsers[1]?.name}
                    imgUrl={img}
                    onChatNameChange={setGroupName}
                    onImgChange={(url, file) => {
                        setImg(url);
                        setImgFile(file);
                    }}
                    editName={mode!=="relativeChat"}
                    editImage={mode!=="relativeChat"}
                />
                <UltimateList
                    users={ResRelSDK.sortContacts(selectedUsers,uid)}
                    resident={mode === "relativeChat" ? users.find(ResRelSDK.isRes): undefined}
                    height={height * 0.7}
                    mode={mode}
                    edible
                    useContactsAsRelatedContacts={mediCare?.type==="resident"}
                    render={(user, index, arr) => <ChatUser
                        key={user.uid}
                        me={user.uid === uid}
                        user={user}
                        buttons={[{
                            icon: FiX,
                            onClick: (event) => {
                                event.stopPropagation();
                                setSelectedUsers((old) => {
                                    const tmp = [...old];
                                    const i = tmp.indexOf(user);
                                    tmp.splice(i, 1);
                                    console.log(user.name, index, i, [...arr], [...selectedUsers], [...tmp]);
                                    return tmp;
                                })
                            }
                        }]}
                    />}
                    onChange={setSelectedUsers}
                    searchFilter={{filter: search}}
                    group={mode !== 'chat' || selectedUsers.length > 2}
                />
                {selectedUsers.length > 1 && <Container style={{position: "relative", zIndex: 1}}>
                    <MessengerTextBox
                        setCurrentHeight={() => null}
                        users={[]}
                        chatId={'newChat'}
                        resetReplay={() => null}
                        demo={{
                            onSend: sendAndCreate,
                            loading
                        }}
                    />

                </Container>}

            </div>
        </div>
    )
}

export default GroupScreen;
