import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { RootState } from './reducers'

import * as usersStore from './users/stroe';


export {
	usersStore
}

export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>
