import React, {useState} from 'react';
import {Comment} from '@pixel-kraft/commulino-types';
import UserImage from "components/UserImage";
import moment from "moment/moment";
import {Button, TextBox} from "components/index";
import {FiEdit2, FiSend, FiX} from "react-icons/fi";
import {useSelector} from "react-redux";
import {RootState} from "store/reducers";
import {firebaseApp} from "lib/firebase";
import {getFirestore,updateDoc,doc} from "firebase/firestore"

interface t_props extends Comment {
    user: {
        img?: string,
        name: string
    },
    postID: string,
    commentID: string,

}

const areEqual = (prevProps: t_props, nextProps: t_props) => {
    return prevProps.user.name === nextProps.user.name && prevProps.user.img === nextProps.user.img &&
        prevProps.text === nextProps.text && prevProps.uid === nextProps.uid && prevProps.edited === nextProps.edited;
}
/**
 * Rendert eine Commentar das bearbeitet werden kann und sich nur Aktualisiert wenn die areEqual Methode false zurück liefert.
 */
const CommentElm = React.memo(({date, edited, text, user, uid,postID,commentID}: t_props) => {
    const [edit, setEdit] = useState(false);
    const [editText, setEditText] = useState(text);
    const myUid = useSelector((state: RootState) => state.auth.uid);
    const [isSending,setIsSending] = useState(false);

    const editComment=()=>{
        const t = editText.trim();
        if (t.length > 0 && uid !== null) {
            setIsSending(true);
            updateDoc(doc(getFirestore(firebaseApp),'posts',postID,'comments',commentID),{
                text: t,
                edited: Date.now()
            }).catch((e: any) => {
                console.log(e);
            }).finally(()=>{
                setEdit(false);
                setIsSending(false);
            })
        }
    }

    return (
        <div style={{
            display: 'flex',
            backgroundColor: 'lightgray',
            padding: 10,
            borderRadius: 10,
            marginBottom: 10,
            alignItems: 'center'
        }}>
            <div>
                <UserImage src={user.img} style={{height: 48, width: 48, borderRadius: '50%'}}/>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', width: '90%', marginLeft: 10}}>
                <div style={{width: '85%'}}>
                    <div>{user.name}:</div>
                    {!edit &&
                    <div style={{wordBreak: 'break-word',whiteSpace: 'pre-wrap'}}>
                        {text}
                        {edited &&
                            <div>
                                (Bearbeitet {moment(edited).format('D.MM.YY HH:mm')})
                            </div>
                        }
                    </div>
                    }
                    {edit &&
                    <TextBox
                        text={editText}
                        updateText={setEditText}
                        disabled={isSending}
                        updateHeight={() => {
                        }}
                        showTop={false}
                        closeTop={() => {
                        }}
                        buttons={[
                            {
                                icon: FiSend,
                                style: {borderRadius: '50%', backgroundColor: 'white'},
                                onClick: editComment
                            }
                        ]}
                    />
                    }
                </div>
                <div>{moment(date).format('D.MM.YY HH:mm')}</div>
            </div>
            {uid === myUid &&
            <Button light icon={edit ? FiX : FiEdit2} cssStyle={{borderRadius: '50%', marginLeft: 10}}
                    onClick={() => setEdit(!edit)}/>
            }
        </div>
    );
}, areEqual);
CommentElm.displayName = 'CommentElm';
export default CommentElm;
