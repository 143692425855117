import React from 'react';
import {useHistory, useParams} from "react-router-dom";
import {setAktiveChat} from "store/chat/actions";
import {ChatSDK} from "lib/sdk";
import {useAppDispatch} from "store/hooks";
import Messenger from "pages/chat/messenger";
import {isDev} from "lib/helpers";

const MessangerScreen = ()=>{
    const {chatId} = useParams<{chatId: string}>();
    const dispatch = useAppDispatch();
    const navigate= useHistory();

    const onAction = async (type: "leaveChat" | "deleteChat", chatId: string) => {
        dispatch(setAktiveChat(''));
        if(isDev) console.log(type, chatId);
        await dispatch(ChatSDK.functions[type](chatId));
        navigate.replace('/messenger');
    }


    return <Messenger onAction={onAction} chatID={chatId} goBack={navigate.goBack}/>
}

export default MessangerScreen;
