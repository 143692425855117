import React, {
	useState,
	useEffect,
	CSSProperties,
} from 'react';
import MediaQuery from 'react-responsive'
import {ActivityIndicator, Button, CheckBox, Icon} from './'
import Pagination from './Pagination'
import styles from './DynamicTable.module.css'
import {FiFolder} from "react-icons/fi";

interface Cell {
	key?: any,
	content: any
}

interface Row {
	key?: string,
	cells: Cell[]
}

interface Props {
	headers: string[],
	rows: Row[],
	loading?: boolean,
	selectable?: boolean,
	maxRows?: number,
	className?: string,
	style?: CSSProperties,
	onSelect?: (selected: any) => void,
	onRowClick?: (item: any) => void,
	complete?: boolean,
	load_next?:()=>void,
	gotoStartOn?: any,
	hover?: boolean,
}

const DynamicTable = ({
	headers,
	rows,
	loading,
	selectable,
	maxRows,
	style,
	className,
	onSelect,
	onRowClick,
	complete,
	load_next,
	gotoStartOn,
	hover
}: Props) => {
	const [selected, setSelected] = useState<string[]>([])
	const [currentPage, setCurrentPage] = useState(0)
	//const dispatch=useDispatch();
	let data = rows
	useEffect(()=>{
		if(gotoStartOn)
			setCurrentPage(0);
	},[gotoStartOn])
	if (maxRows) {
		data = rows.slice(maxRows * currentPage, maxRows * (currentPage + 1))
	}

	const keys = data.map(row => row?.key || '')

	const onChange = (keys: string[], change: boolean) => {
		let s = selected.filter(key => !keys.includes(key))

		if (change) {
			s = [...selected, ...keys]
		}

		setSelected(s)
		if (onSelect) onSelect(s)
	}

	useEffect(() => {
		if (selectable) {
			onChange(selected, false)
		}
		// eslint-disable-next-line
	}, [selectable, currentPage])

	return (
		<div className={className} style={style}>
			{loading && (
				<div className={styles.activityIndicator}>
					<ActivityIndicator />
				</div>
			)}
			<MediaQuery minWidth={961}>
				<table className={styles.table}>
					<tbody>
						<tr>
							{selectable && (
								<th className={styles.th}>
									<CheckBox
										onChange={change => onChange(keys, change)}
										checked={selected.length > 0 && selected.length === keys.length}
									/>
								</th>
							)}
							{headers.map((header, index) => (
								<th className={styles.th} key={index}>
									{header}
								</th>
							))}
						</tr>
						{data.map(row => {
							const { key, cells } = row
							return (
								<tr
									className={`${styles.tr} ${onRowClick && styles.clickable}`}
									key={key}
									onClick={() => (onRowClick ? onRowClick(row) : null)}
								>
									{selectable && (
										<td className={styles.td}>
											<CheckBox
												onChange={change => onChange([key || ''], change)}
												checked={!!key && selected.includes(key)}
											/>
										</td>
									)}
									{hover ?
										cells.map((item, index) => (
											<td className={styles.td} key={index}>
												{item.key === "Name" &&
												<FiFolder
													className={styles.iconFolder}
												/>}
												{item.content}
											</td>
									)) :
										cells.map((item, index) => (
											<td className={styles.td} key={index}>
												{item.content}
											</td>
									))}
								</tr>
							)
						})}
					</tbody>
				</table>
			</MediaQuery>
			<MediaQuery maxWidth={960}>
				{data.map(row => {
					const { key, cells } = row

					return (
						<div
							key={key}
							className={`${styles.tr} ${onRowClick && styles.clickable}`}
							onClick={() => (onRowClick ? onRowClick(row) : null)}
							style={{
								display: 'flex',
								marginTop: 16,
								padding: 8,
								borderRadius: 3,
								overflow: 'hidden'
							}}
						>
							{selectable && (
								<div style={{ marginRight: 8 }}>
									<CheckBox
										onChange={change => onChange([key || ''], change)}
										checked={!!key && selected.includes(key)}
									/>
								</div>
							)}
							<div>
								{cells.map((item, index) => (
									<div key={index} style={{ display: 'flex', paddingBottom: 4 }}>
										{headers[index] && (
											<div style={{ marginRight: 4, fontWeight: 500 }}>{headers[index]}:</div>
										)}
										<div style={{ flex: 1 }}>{item.content}</div>
									</div>
								))}
							</div>
						</div>
					)
				})}
			</MediaQuery>
			{maxRows && maxRows < rows.length && (
				<Pagination count={Math.ceil(rows.length / maxRows)} currentPage={currentPage} goTo={(page)=>{
					if(load_next){
						if((!complete)&&page===Math.ceil(rows.length / maxRows)-1)
							load_next();
					}
					setCurrentPage(page);
				}} complete={complete} />
			)}
		</div>
	)
}

export default DynamicTable
