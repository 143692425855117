import {
    isSystemMessage,
    Message,
    SystemMessage,
} from '@pixel-kraft/commulino-types';
import moment from 'moment';
import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {RootState} from 'store/reducers'
import {t_chatData} from './type';
import ChatItem from "./ChatItem";
import {getMsgInfoByID} from "./functions/getter";
import {userProfiles} from "store/userProfiles/types";
import {firebaseApp} from "lib/firebase";
import {getFirestore, doc, getDoc} from "firebase/firestore";
import {getMessageString} from './SystemMessage';
import {useHistory} from "react-router-dom";
import {filterTypeChange} from "lib/helpers";
import {useAppSelector} from "store/hooks";

type t_props = {
    chatID: string,
    onClick: (elm: t_chatData) => void,
    active?: boolean,
    children?: React.ReactNode
}
/**
 * Rendert die wichtigsten Chat Metadata
 * @param props
 */
const Chat = (props: t_props) => {
    const uid = useSelector((state: RootState) => state.auth.uid);
    const [img, setImg] = useState<string | undefined>(undefined);
    const chat = useAppSelector((state: RootState) => ({
        ...state.messenger.Chats[props.chatID],
        id: props.chatID,
    }));

    const {
        users,name,avatar
    } = chat;



    const [subtitle, setSubtitle] = useState('');
    const [lastMessage, setLastMessage] = useState<Message | SystemMessage>();
    const history = useHistory();
    const cancel= {stop: false}

    useEffect(() => {
        if (!chat) history.replace(`messenger/`)
    }, [chat, history])

    useEffect(() => {
        if (chat?.messages) {
            const [lastMsg,] = [...chat.messages].reverse();
            setLastMessage(lastMsg)
        }
    }, [chat?.messages])
    useEffect(() => {
            if (lastMessage && isSystemMessage(lastMessage)) {
                const {name: targetName} = chat.users.find(({uid}) => uid === lastMessage.target) ?? {};
                const {name: userName} = chat.users.find(({uid}) => uid === lastMessage.uid) ?? {};
                setSubtitle(getMessageString(lastMessage.action, targetName, userName));
                return;
            }
            const inner = async () => {
                let sub = '';
                if ('mediCare' in chat) {
                    const rels = filterTypeChange(chat.users, (u) => {
                        if ('relation' in u)
                            return u
                    });
                    if (rels.length === 1) {
                        const rel = rels[0];
                        const name = chat.users.filter(({uid}) => rel.uid === uid)[0].name;
                        return `${rel.relation}: ${name}`;
                    }
                }

                if (lastMessage) {
                    if (uid !== null && (lastMessage.deleteFor?.includes(uid))) {
                        sub = "Sie haben diese Nachricht gelöscht";
                    } else {
                        if (users) {
                            if (lastMessage.uid === uid) {
                                sub = 'Ich'
                            } else {
                                for (const user of users) {
                                    if (user.uid === lastMessage.uid)
                                        sub = user.name;
                                }
                            }
                        } else {
                            sub = '';
                        }
                        let h = '';
                        if ((!lastMessage.text) && lastMessage.replyTo) {
                            const tmp = await getMsgInfoByID(props.chatID, lastMessage.replyTo, chat.messages) as Message;
                            h = tmp.text ? tmp.text : tmp.images ? "Foto" : "Loading";
                        } else
                            h = lastMessage.text ? lastMessage.text : lastMessage.images ? "Foto" : lastMessage.attachments ? "Datei" : "Diese Nachricht wurde gelöscht";
                        sub = (sub + ": " + h);
                        if (sub.length > 25)
                            sub = sub.substr(0, 25) + "...";
                    }
                }
                return sub;
            }
            inner().then(sub => (!cancel.stop)&&setSubtitle(sub)).catch(() => setSubtitle(''));
            return ()=>{
                cancel.stop=true;
            }
        }, [lastMessage, users, uid, chat?.messages, props.chatID]
    )

    useEffect(() => {
        if (chat && !avatar && !chat.group && uid && chat.users) {
            const idIndex = chat.users[0].uid === uid ? 1 : 0;
            getDoc(doc(getFirestore(firebaseApp), 'userProfiles', chat.users[idIndex].uid)).then(data => {
                const user = data.data() as userProfiles;
                if (user.photo) (!cancel.stop)&&setImg(user.photo);
            }).catch(() => null);
        }
    }, [avatar, chat, uid])

    if (!chat) {
        return null;
    }
    if (!name) {
        return (
            <div>Der Chat wurde Gelöscht oder Sie wurden aus der Gruppe entfernt!</div>
        )
    }

    return (
        <>
            <ChatItem
                title={name}
                src={avatar ? avatar : img}
                dateString={moment(lastMessage?.date).format('D.MM.YY')}
                subtitle={subtitle}
                id={props.chatID}
                unread={chat.unRead}
                group={!!chat.group || chat.users.length > 2}
                onClick={() => props.onClick({
                    id: props.chatID,
                    title: name,
                    photo: avatar ? avatar : img
                })}
                active={props.active}
            />
            {props.children}
        </>
    )

}

export default Chat;
