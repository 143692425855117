import React, {lazy} from 'react';
import styles from "./Category.module.css";
import {Icon, Typo} from "components";
import {t_props,elm} from "../type";

const ShowCategory = lazy(()=>import('./ShowCategory'));
const EditCategory = lazy(()=>import('./EditCategory'));


const Category = ({edit, post}: t_props) => {
    const render = (One: elm) => (
        <div className={styles.container}>
            <Icon name={post.category.icon} size={17}/>
            <Typo style={styles.name} strong>
                {post.category.name}
            </Typo>
            <One/>
        </div>
    )
    if (edit) return render(EditCategory);
    else return render(ShowCategory);
}
export default Category;