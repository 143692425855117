import React from 'react';
import {ColorButton} from "components";
import {Post} from "@pixel-kraft/commulino-types";
import {useDispatch} from "react-redux";
import {setCommentariesOverlay} from "store/posts/actions";

type t_props = {
    post: Post,
}

const Commentaries = ({post}: t_props) => {
    const dispatch = useDispatch();

    if (post.hasComments === undefined) return null;
    return (
        <div style={{width: 'min-content', marginRight: 10}}>
            <ColorButton text={'Kommentare'} onClick={() => dispatch(setCommentariesOverlay(post))}/>
        </div>
    )
}
export default Commentaries;
