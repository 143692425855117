import React from "react";
import {t_infoCalenderTypes} from "@pixel-kraft/commulino-types";
import {DropDownSelector} from "components";

type t_props={
    value: t_infoCalenderTypes|undefined,
    onChange: (val:t_infoCalenderTypes|undefined)=>void,
}

const InfoSystemType=({value,onChange}:t_props)=>{
    const data={
        "Geburtstag":{
            id: "gb", label: "Geburtstag",
            data: "Ein Kalender in dem Geburtstag eingetragen werden können damit sie im Infosystem angezeigt werden können."
        },
        "Speiseplan":{
            id: "sp", label: "Speiseplan",
            data: "Speiseplan Info"
        },
        "Termine":{
            id: "te", label: "Termine",
            data: "In diesen Kalender können Termine eingetragen werden die auf dem Infosystem angezeigt werden sollen."
        }
    }

    const options:{id:string,label:string,data?:string}[] = [
        {...data["Geburtstag"]},
        {...data["Speiseplan"]},
        {...data["Termine"]},
        {
            id: "st",
            label: "Bitte Auswählen",
            data: "Ein Infosystem Kalender ist ein Kalender der in dem Infosystem angezeigt werden kann. So kann z.B. ein Speiseplan oder ein Geburtstag Kalender erstellt werden."
        }];

    const change=(val:{id:string,label:string,data?:string})=>{
        if(val.id==="st") onChange(undefined);
        else onChange(val.label as t_infoCalenderTypes)
    }

    return (
        <>
            <div style={{display: "flex", marginTop:"1vh", marginBottom: "7px"}}><div style={{fontWeight:600, paddingTop:"1px"}}>Kalenderart </div><DropDownSelector
                cssStyle={{marginLeft: 5, flexGrow: 1}}
                value={value?data[value]:options[3]}
                onSelect={change}
                options={options}
            /></div>
            <div style={{fontSize: "14px", fontWeight: 400, lineHeight: '1.2em'}}>{value?data[value].data:options[3].data}
            </div>
        </>
    )


}
export default InfoSystemType;