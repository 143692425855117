import React from 'react'
import MediaQuery from 'react-responsive'
import ActivityIndicator from './ActivityIndicator'
import Typo from './Typo'
import styles from './ContentContainer.module.css'

interface Props {
	children: React.ReactNode,
	fullHeight?: boolean,
	loading?: boolean,
	style?: string,
	contentStyle?: string,
	hideSpacer?: boolean
	styleScroll?:string
}

const ContentContainer = ({
	children,
	fullHeight,
	loading,
	style,
	contentStyle,
	styleScroll
}: Props) => (
	<div className={`content-container ${styles.container} ${style}`}>
		<MediaQuery minWidth={601}>
			<div
				className={`${styles.content} ${contentStyle} ${styleScroll}`}
				style={{ padding: 32, height: fullHeight ? '100vh' : undefined }}
			>
				{children}
			</div>
		</MediaQuery>
		<MediaQuery maxWidth={600}>
			<div
				className={`${styles.content} ${contentStyle}`}
				style={{ padding: 16, height: fullHeight ? '100vh' : undefined }}
			>
				{children}
			</div>
		</MediaQuery>
		<div className={styles.loading} style={{ opacity: loading ? 0.8 : 0, zIndex: loading ? 'auto': -1 }}>
			<Typo variant='h5'>Aktualisieren ...</Typo>
			<ActivityIndicator style={styles.indicator} large />
		</div>
	</div>
)

export default ContentContainer
