import React, {Suspense, lazy, useEffect} from "react";
import {useSelector} from "react-redux";
import {RootState} from "store/reducers";
import {ActivityIndicator, Typo} from "components";
import JobLeft from "./job/JobLeft";
import {t_User} from "./type";

const JobRight = lazy(() => import("./job/JobRight"));

type t_props = {
    jobUser?: t_User,
    jobEmail?: string,
    onEmailChange: (value: string) => void,
    onUserChange: (value: t_User) => void,
    jobMode: 'email' | 'user',
    onModeChange: (value: 'email' | 'user') => void,
}


const PostsSettings = ({onEmailChange, onUserChange, jobEmail, jobUser, jobMode, onModeChange}: t_props) => {
    const me = useSelector((state: RootState) => state.auth);


    useEffect(() => {
        if (!jobUser&&me&&me.uid&&me.name) onUserChange({uid: me.uid, name: me.name, photo: me.photo})
    }, [jobUser, me])

    if (me.uid === null) return null;

    return (
        <div style={{display: 'flex', width: '100%'}}>
            <div style={{width: '50%'}}>
                <Typo variant={"h3"}>Job Benachrichtung per {jobMode === 'user' ? "Messanger" : "Email"} senden
                    an</Typo>
                <JobLeft
                    mode={jobMode}
                    user={jobUser ?? {uid: me.uid, name: me.name ?? '', photo: me.photo}}
                    email={jobEmail}
                    onEmailChange={onEmailChange}
                    onModeChange={onModeChange}
                />
            </div>
            <div style={{width: '50%'}}>
                {jobMode === 'user' &&
                    <Suspense fallback={<ActivityIndicator large/>}>
                        <JobRight onUserChange={onUserChange}/>
                    </Suspense>
                }
            </div>
        </div>
    )
}

export default PostsSettings;
