import React from 'react';
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";
import DropArea from "components/DragAndDrop/DragList/DropArea";

type t_props = {
    items: any[],
    renderItem: (item: any,index:number) => JSX.Element,
    onChange: (items: any[]) => void,
}

const DragList = ({renderItem, items, onChange}: t_props) => {

    const render = (item: any, index: number,items:any) => {
        const changeIndex = (destinationIndex: number, item: any) => {
            const _items: any[] = [...items];
            const toSwap = _items.splice(item.index, 1);
            _items.splice(destinationIndex, 0, toSwap[0]);
            onChange(_items);
        }
        const change = (i: number) => changeIndex(index, i);
        return (
            <DropArea key={index} index={index} changeIndex={change}>
                {renderItem(item,index)}
            </DropArea>
        )
    }

    return (
        <DndProvider backend={HTML5Backend}>
            {items.map(render)}
        </DndProvider>
    )
}
export default DragList;